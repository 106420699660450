import { urls } from 'src/routes';
import { Box, Grid, Stack, Typography, useTheme } from '@mui/material';

import {
  StyledBgDecoration,
  StyledBody,
  StyledBodyBig,
  StyledBodySmall,
  StyledBodyTiny,
  StyledCard,
  StyledContainer,
  StyledDivider,
  StyledFooter,
  StyledFooterLink,
  StyledGetInTouchSection,
  StyledHeader,
  StyledHeadingOne,
  StyledHeadingThree,
  StyledHeadingTwo,
  StyledLoginLink,
  StyledMainSection,
  StyledPageWrapper,
  StyledServicesSectionHeader,
  StyledSignUpLink,
  StyledSuperscription,
  StyledTwentyOneCo,
  StyledWallet,
  StyledWrapper
} from './LandingPage.styles';

const assets = `${process.env.REACT_APP_ASSETS_URL}/images/`;
const SvgBgDecoration = `${assets}bg-decoration.svg`;
const SvgTwentyOneCo = `${assets}twenty-one-co.svg`;
const SvgTwentyOneWave = `${assets}twenty-one-wave.svg`;
const SvgWallet = `${assets}wallet.svg`;

const SERVICES: { title: string; description: string }[] = [
  {
    title: 'Dedicated IBAN Account',
    description: 'Secure your fiat money in USD, EUR, and CHF with a dedicated account, protected by trusted UK and EU banks.'
  },
  {
    title: 'Deposit Funds',
    description:
      'Streamlined fund collection from around the world through Swift, SEPA, and ACH — simplifying your deposit process and saving valuable time.'
  },
  {
    title: 'Make Global Payments',
    description: 'Effortless outgoing payments using Swift, SEPA, ACH - unlocking a world of possibilities in over 30 currencies.'
  },
  {
    title: 'Currency Swaps',
    description: 'Access competitive FX rates for seamless currency swaps, maximizing your savings and simplifying your international transactions.'
  }
];

export const LandingPage: React.FC = () => {
  const theme = useTheme();

  return (
    <StyledWrapper>
      <StyledPageWrapper>
        <StyledContainer>
          <StyledHeader>
            <StyledTwentyOneCo src={SvgTwentyOneCo} width="128" height="45" alt="21.co" />
            <nav>
              <StyledLoginLink to={urls.signin}>Login</StyledLoginLink>
            </nav>
          </StyledHeader>
        </StyledContainer>

        <StyledMainSection>
          <StyledBgDecoration src={SvgBgDecoration} alt="" />
          <StyledContainer>
            <Grid container>
              <Grid item xxs={12} md={5} zIndex={1}>
                <StyledHeadingOne variant="h1">Simplifying Financial Operations for Web3</StyledHeadingOne>
                <StyledBodyBig>21.co empowers your business with the right toolset to streamline your financial transactions.</StyledBodyBig>
                <a href="#services" aria-label="Get started">
                  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M1 24C1 11.2975 11.2975 1 24 1C36.7025 1 47 11.2975 47 24C47 36.7025 36.7025 47 24 47C11.2975 47 1 36.7025 1 24Z"
                      stroke="#F5B68C"
                      strokeWidth="2"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M19.293 21.293C19.4806 21.1055 19.7349 21.0002 20 21.0002C20.2652 21.0002 20.5195 21.1055 20.707 21.293L24 24.586L27.293 21.293C27.3853 21.1975 27.4956 21.1213 27.6176 21.0689C27.7396 21.0165 27.8709 20.9889 28.0036 20.9878C28.1364 20.9866 28.2681 21.0119 28.391 21.0622C28.5139 21.1125 28.6255 21.1867 28.7194 21.2806C28.8133 21.3745 28.8876 21.4862 28.9379 21.6091C28.9881 21.732 29.0134 21.8636 29.0123 21.9964C29.0111 22.1292 28.9835 22.2604 28.9311 22.3824C28.8787 22.5044 28.8025 22.6148 28.707 22.707L24.707 26.707C24.5195 26.8945 24.2652 26.9998 24 26.9998C23.7349 26.9998 23.4806 26.8945 23.293 26.707L19.293 22.707C19.1056 22.5195 19.0002 22.2652 19.0002 22C19.0002 21.7349 19.1056 21.4805 19.293 21.293Z"
                      fill="#F5B68C"
                    />
                  </svg>
                </a>
              </Grid>
              <Grid
                item
                xxs={12}
                md={7}
                sx={{
                  position: 'relative',
                  [theme.breakpoints.down('md')]: {
                    display: 'flex',
                    justifyContent: 'center'
                  }
                }}
              >
                <StyledWallet src={SvgWallet} alt="" />
              </Grid>
            </Grid>
          </StyledContainer>
        </StyledMainSection>

        <Box component="section" id="services" mb={10}>
          <StyledContainer>
            <Grid container>
              <StyledServicesSectionHeader
                item
                xxs={12}
                sx={{
                  [theme.breakpoints.up('md')]: {
                    backgroundImage: `url(${SvgTwentyOneWave})`
                  }
                }}
              >
                <StyledSuperscription>Our services</StyledSuperscription>
                <StyledHeadingTwo variant="h2">Explore 21.co Business Account</StyledHeadingTwo>
                <StyledBody>Save money on transfers, payments, and foreign currencies.</StyledBody>
              </StyledServicesSectionHeader>
            </Grid>
            <Grid container spacing={3} mt={{ xxs: 4, md: 5 }}>
              {SERVICES.map(({ title, description }) => (
                <Grid key={title} item xxs={12} xs={6}>
                  <StyledCard>
                    <StyledHeadingThree variant="h3">{title}</StyledHeadingThree>
                    <StyledBody>{description}</StyledBody>
                  </StyledCard>
                </Grid>
              ))}
            </Grid>
          </StyledContainer>
        </Box>

        <StyledContainer>
          <StyledGetInTouchSection>
            <StyledSuperscription>Get in touch</StyledSuperscription>
            <StyledHeadingTwo variant="h2">Join our Alpha Release</StyledHeadingTwo>
            <StyledBody mb={2.5}>
              Be among the first to experience the power of our alpha product phase by filling out the form, gaining exclusive access to the 21.co
              Business Account.
            </StyledBody>
            <StyledSignUpLink href="https://1uo7cdj94fy.typeform.com/to/m4irEsyD">
              <Typography pr={1}>Sign me up</Typography>
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M8.75149 17.6483C8.52653 17.4233 8.40015 17.1181 8.40015 16.7999C8.40015 16.4817 8.52653 16.1765 8.75149 15.9515L12.7031 11.9999L8.75149 8.0483C8.5329 7.82198 8.41195 7.51886 8.41468 7.20422C8.41742 6.88959 8.54362 6.58861 8.76611 6.36612C8.9886 6.14363 9.28957 6.01743 9.60421 6.01469C9.91885 6.01196 10.222 6.13291 10.4483 6.3515L15.2483 11.1515C15.4733 11.3765 15.5996 11.6817 15.5996 11.9999C15.5996 12.3181 15.4733 12.6233 15.2483 12.8483L10.4483 17.6483C10.2233 17.8733 9.91809 17.9996 9.59989 17.9996C9.28169 17.9996 8.97652 17.8733 8.75149 17.6483Z"
                  fill="#F5B68C"
                />
              </svg>
            </StyledSignUpLink>
          </StyledGetInTouchSection>
        </StyledContainer>

        <StyledFooter component="footer">
          <Grid container>
            <Grid item xxs={12} mb={2.25}>
              <StyledFooterLink href="mailto:support.business-account@21.co?subject=21co Business Account Support User Request">
                Contact
              </StyledFooterLink>
            </Grid>
            <Grid item xxs={12} mb={3}>
              <StyledDivider />
            </Grid>
            <Grid item xxs={12} md={5} mb={{ xxs: 2, md: 4 }}>
              <StyledBodySmall>&copy; 2023 Amun Holdings Limited and affiliated entities.</StyledBodySmall>
            </Grid>
            <Grid item xxs={12} md mb={{ xxs: 2, md: 4 }}>
              <Stack direction={{ xxs: 'column', md: 'row' }} spacing={{ xxs: 1, md: 6 }} alignItems="flex-start">
                <StyledFooterLink href={urls.disclaimers} target="_blank">
                  Disclaimers
                </StyledFooterLink>
                <StyledFooterLink href={urls.privacyPolicy} target="_blank">
                  Privacy Policy
                </StyledFooterLink>
                <StyledFooterLink href={urls.termsAndConditions} target="_blank">
                  Terms of Use
                </StyledFooterLink>
              </Stack>
            </Grid>
            <Grid item xxs={12}>
              <StyledBodyTiny>
                Payment services for Caterina B.V. are provided by The Currency Cloud Limited. Registered in England No. 06323311. Registered Office:
                Stewardship Building 1st Floor, 12 Steward Street London E1 6FQ. The Currency Cloud Limited is authorised by the Financial Conduct
                Authority under the Electronic Money Regulations 2011 for the issuing of electronic money (FRN: 900199) Payment services for Caterina
                B.V. are provided by CurrencyCloud B.V.. Registered in the Netherlands No. 72186178. Registered Office: Nieuwezijds Voorburgwal 296-
                298, Mindspace Nieuwezijds Office 001 Amsterdam. CurrencyCloud B.V. is authorised by the DNB under the Wet op het financieel toezicht
                to carry out the business of a electronic-money institution (Relation Number: R142701) Payment services for Caterina B.V. are provided
                by The Currency Cloud Inc. which operates in partnership with Community Federal Savings Bank (CFSB) to facilitate payments in all 50
                states in the US. CFSB is registered with the Federal Deposit Insurance Corporation (FDIC Certificate# 57129). The Currency Cloud Inc
                is registered with FinCEN and authorized in 39 states to transmit money (MSB Registration Number: 31000160311064). Registered Office:
                104 5th Avenue, 20th Floor, New York , NY 10011.
              </StyledBodyTiny>
            </Grid>
          </Grid>
        </StyledFooter>
      </StyledPageWrapper>
    </StyledWrapper>
  );
};
