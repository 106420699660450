import { FC, useContext, useState } from 'react';
import { useSnackbar } from 'notistack';
import { IFormHandleSubmit, IOnFormChangeProps } from 'src/interfaces/forms';
import SignInLayout from 'src/components/layouts/SignInLayout/SignInLayout';
import Form from 'src/components/Form/Form';
import { auth2faSchemaUrl } from 'src/api/formSchemas';
import ActionButtons from 'src/components/ActionButtons/ActionButtons';
import { useNavigate } from 'react-router-dom';
import { auth2fa } from 'src/api/auth';
import { useTranslation } from 'react-i18next';
import { ERROR_INCORRECT_OTP } from 'src/constants/errors';
import get from 'lodash.get';
import { useUserNavigation } from 'src/hooks/navigation';
import { StorageKey } from 'src/constants/storage';
import { urls } from 'src/routes';
import Input from 'src/components/Input/Input';
import { InvitationTokenContext } from 'src/contexts/InvitationToken.contexts';

const AuthenticationPage: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { getUserOrNavigate } = useUserNavigation();
  const { enqueueSnackbar } = useSnackbar();
  const { invitationToken } = useContext(InvitationTokenContext) ?? {};

  const [loading, setLoading] = useState<boolean>(false);

  const handleSubmit: IFormHandleSubmit = async (values, validateForm, handleError) => {
    if (validateForm(values)) {
      setLoading(true);

      auth2fa(values.otp as string, invitationToken)
        ?.then(response => {
          sessionStorage.setItem(StorageKey.AUTH_TOKEN, response.data.token);
          sessionStorage.removeItem(StorageKey.WEAK_AUTH_TOKEN);
          getUserOrNavigate(true, urls.balances);
        })
        .catch(error => {
          try {
            const { code, message } = error.response.data;
            if (code === ERROR_INCORRECT_OTP) {
              handleError('otp', message);
            } else {
              enqueueSnackbar(message, { variant: 'error' });
            }
          } catch {}
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const onFormChange = ({ values, lastChangedFieldName, submitForm }: IOnFormChangeProps) => {
    if (lastChangedFieldName === 'otp' && get(values, lastChangedFieldName, '').toString().length === 6) {
      submitForm?.();
    }
  };

  return (
    <SignInLayout loading={loading} title={t('screens.authentication.title')} subtitle={t('screens.authentication.subtitle')}>
      <Form schemaUrl={auth2faSchemaUrl} onSubmit={handleSubmit} onChange={onFormChange}>
        <Input autoFocus name="otp" type="number" fullWidth />

        <ActionButtons secondaryActionText={t('screens.authentication.backText')} onSecondaryActionClick={() => navigate(urls.reset2fa)} />
      </Form>
    </SignInLayout>
  );
};

export default AuthenticationPage;
