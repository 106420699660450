import { IFormValues, IFormErrors, IFormSchemaPropDetails, IFormProps } from 'src/interfaces/forms';
import { IGetFormSchemaResponse } from 'src/interfaces/responses';
import { FIELD_VALUE_TRUE, FIELD_VALUE_YES, LABEL_FIELD_NAME_FOR_DROPDOWN, VALUE_FIELD_NAME_FOR_DROPDOWN } from 'src/constants/form';
import cloneDeep from 'lodash.clonedeep';
import { Children, isValidElement, ReactNode } from 'react';
import get from 'lodash.get';

const deepMapValuesToErrors = (values: IFormValues, errors: IFormErrors, defaultValue = '') => {
  Object.keys(values).forEach(fieldName => {
    if (typeof values[fieldName as keyof IFormValues] === 'object') {
      errors[fieldName as keyof IFormErrors] = {};

      deepMapValuesToErrors(values[fieldName as keyof IFormValues] as IFormValues, errors[fieldName as keyof IFormErrors] as IFormErrors, '');
    } else {
      errors[fieldName as keyof IFormErrors] = defaultValue;
    }
  });
};

export const mapValuesToErrors = (values: IFormValues): IFormErrors => {
  const formErrors: IFormErrors = {};
  const valuesCpy = cloneDeep(values);

  deepMapValuesToErrors(valuesCpy, formErrors);

  return formErrors;
};

const mapEnums = (enumValue: string, idx: number, formSchemaPropDetails: IFormSchemaPropDetails) => ({
  [LABEL_FIELD_NAME_FOR_DROPDOWN]: formSchemaPropDetails['x-enumLabels'] ? formSchemaPropDetails['x-enumLabels'][idx] || enumValue : enumValue,
  [VALUE_FIELD_NAME_FOR_DROPDOWN]: enumValue
});

export const retrieveFormProps = (from: IGetFormSchemaResponse, to: IFormProps) => {
  Object.entries(from.properties).forEach(([propName, propDetails]) => {
    const formSchemaPropDetails = propDetails as IFormSchemaPropDetails;

    switch (formSchemaPropDetails.type) {
      case 'object':
        to.defaultValues[propName] = {};
        to.labels[propName] = {};
        to.types[propName] = {};
        to.enums[propName] = {};
        to.descriptions[propName] = {};
        to.placeholders[propName] = {};
        to.formatDescriptions[propName] = {};
        to.required[propName] = formSchemaPropDetails.required;

        retrieveFormProps(
          propDetails as IGetFormSchemaResponse,
          {
            defaultValues: to.defaultValues[propName],
            descriptions: to.descriptions[propName],
            placeholders: to.placeholders[propName],
            formatDescriptions: to.formatDescriptions[propName],
            types: to.types[propName],
            enums: to.enums[propName],
            required: to.required[propName],
            labels: to.labels[propName]
          } as IFormProps
        );
        break;
      default:
        to.types[propName] = formSchemaPropDetails.type;
        to.labels[propName] = formSchemaPropDetails.title;
        to.descriptions[propName] = formSchemaPropDetails.description;
        to.placeholders[propName] = formSchemaPropDetails['x-placeholder'];
        to.formatDescriptions[propName] = formSchemaPropDetails['x-formatDescription'];
        to.enums[propName] = formSchemaPropDetails.enum?.map((enumValue, idx) => mapEnums(enumValue, idx, formSchemaPropDetails));
        to.defaultValues[propName] = formSchemaPropDetails.default;

        break;
    }
  });
};

export const removeEmpty = (values: IFormValues, requiredObjectNames: string[] = []) => {
  const cleaned: IFormValues = {};

  if (values) {
    Object.keys(values).forEach(key => {
      if (values[key] || values[key] === false) {
        if (typeof values[key] === 'object' && !requiredObjectNames.find(n => n.includes(key))) {
          const object = removeEmpty(values[key] as IFormValues, requiredObjectNames);
          if (Object.keys(object).length) {
            cleaned[key] = object;
          }
        } else {
          cleaned[key] = values[key];
        }
      }
    });
  }
  return cleaned;
};

export const formatValues = (values: IFormValues, names: string[], props: IFormProps) => {
  const formattedValues: IFormValues = {};

  Object.entries(values).forEach(([fieldName, fieldValue]) => {
    const fieldType = props.types[fieldName];
    if (typeof fieldValue === 'object') {
      formattedValues[fieldName] = formatValues(values[fieldName] as IFormValues, names, props);
    } else if (fieldType === 'boolean') {
      formattedValues[fieldName] = fieldValue?.toString() === FIELD_VALUE_TRUE || fieldValue?.toString() === FIELD_VALUE_YES;
    } else if (typeof fieldValue === 'string') {
      if (fieldName === 'mobileNumber') {
        formattedValues[fieldName] = fieldValue.replace(/[^+0-9]/g, '');
      } else if (fieldName === 'iban') {
        formattedValues[fieldName] = fieldValue.replaceAll(' ', '');
      } else {
        formattedValues[fieldName] = fieldValue;
      }
    }
  });

  const requiredObjectNames = names
    .map(n => {
      const key = n.substring(0, n.lastIndexOf('.'));
      return get(props.required, key) && key;
    })
    .filter(Boolean);

  return removeEmpty(formattedValues, requiredObjectNames);
};

export const getFormNames = (children: ReactNode) => {
  const names: string[] = [];

  Children.forEach(children, child => {
    if (!isValidElement(child)) {
      return;
    } else if (child.props.children) {
      names.push(...getFormNames(child.props.children));
      return;
    } else if (child.type instanceof Function) {
      const name = child.props.name;
      names.push(name);
      return;
    }
    return;
  });

  return names.filter(Boolean);
};
