import { AxiosError, AxiosResponse } from 'axios';
import { additionalUrls } from 'src/api/additionalUrls';
import { IGetLegalEntityMembersResponse, IVerifyInvitationResponse } from 'src/interfaces/responses';
import { LegalEntityMember } from 'src/interfaces/settings';

import request from './request';

export const getLegalEntityMembers = (legalEntityUri: string): Promise<AxiosResponse<IGetLegalEntityMembersResponse, AxiosError>> | undefined =>
  request('get', `${legalEntityUri}${additionalUrls.members}`)();

export const verifyInvitation = (invitationToken: string): Promise<AxiosResponse<IVerifyInvitationResponse, AxiosError>> =>
  request('post', additionalUrls.verifyInvitation)({ invitationToken });

export const createLegalEntityMember = (legalEntityUri: string, member: LegalEntityMember): Promise<AxiosResponse<void, AxiosError>> =>
  /* TODO: Remove replacing after backend fix the URI */
  request('post', `${legalEntityUri}${additionalUrls.members}`)(member);

export const resendInvitation = (resendInvitationUri: string, otp: string): Promise<AxiosResponse<void, AxiosError>> =>
  /* TODO: Remove replacing after backend fix the URI */
  request('post', resendInvitationUri.replace('team-members', 'members'))({ otp });

export const updateLegalEntityMember = (
  uri: string,
  permissions: Pick<LegalEntityMember, 'corporateTitle' | 'role'>
): Promise<AxiosResponse<void, AxiosError>> =>
  /* TODO: Remove replacing after backend fix the URI */
  request('patch', uri.replace('team-members', 'members'))({ corporateTitle: permissions.corporateTitle, role: permissions.role });

export const removeLegalEntityMember = (uri: string): Promise<AxiosResponse<void, AxiosError>> =>
  /* TODO: Remove replacing after backend fix the URI */
  request('delete', uri.replace('team-members', 'members'))();
