import { styled, Stack, Typography } from '@mui/material';

export const StyledCapitalizedTitle = styled('span')(({ theme }) => ({
  textTransform: 'capitalize',
  marginLeft: theme.spacing(1),
  color: theme.palette.text.primary
}));

export const StyledWrappedAccountList = styled(Stack)(({ theme }) => ({
  marginTop: theme.spacing(2)
}));

export const StyledAccountWrapper = styled(Stack)(({ theme }) => ({
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(1),
  paddingLeft: theme.spacing(3),
  '&:hover': {
    background: theme.palette.custom.grey[50],
    borderRadius: theme.spacing(1),
    cursor: 'pointer'
  }
}));

export const StyledFlag = styled('img')({
  width: '32px',
  height: '32px',
  alignSelf: 'center'
});

export const StyledAccountCurrencyLabelAndCurrency = styled(Stack)(({ theme }) => ({
  marginLeft: theme.spacing(2)
}));

export const StyledAccountCurrency = styled(Typography)(({ theme }) => ({
  color: theme.palette.custom.grey[400]
}));
