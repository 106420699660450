import { styled } from '@mui/material';

export const StyledAccountGroupTogglePanel = styled('div')<{ expanded: boolean }>(({ theme, expanded }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: theme.spacing(3, 0, 2),
  '& > .MuiButtonBase-root.MuiButton-root': {
    color: theme.palette.custom.grey[400],
    padding: theme.spacing(0.5),
    '& > svg': {
      transition: 'transform 0.2s',
      transform: `rotate(${expanded ? '0' : '180deg'})`
    }
  },
  b: {
    fontWeight: 500
  }
}));

export const StyledAccountGroupToggleAction = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  '& > button': {
    marginLeft: theme.spacing(1.5)
  }
}));
