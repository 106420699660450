import { styled } from '@mui/material';

import Button from '../Button/Button';

export const StyledActionButtonsWrapper = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(3),
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2)
}));

export const StyledBackButton = styled(Button)(({ theme }) => ({
  gap: theme.spacing(1)
}));
