import { styled, TextField, Theme } from '@mui/material';

import { StyledInputProps } from './Input.interfaces';

export const getPopperStyles = (theme: Theme) => ({
  '& .PrivatePickersYear-yearButton': {
    fontSize: 13,
    fontWeight: 400,
    margin: 0
  },
  '& .MuiYearPicker-root': {
    marginRight: theme.spacing(0.5),

    '::-webkit-scrollbar': {
      width: '4px',
      padding: theme.spacing(0, 1)
    },
    '::-webkit-scrollbar-track': {
      background: 'transparent'
    },
    '::-webkit-scrollbar-thumb': {
      background: '#dadada', // TODO: This color isn't present in color palette
      borderRadius: '100px'
    }
  }
});

export const StyledTextField = styled(TextField)<StyledInputProps>(({ theme, multiline }) => ({
  width: '100%',
  minWidth: '220px',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.common.white,

  [theme.breakpoints.down('md')]: {
    minWidth: '35vw'
  },
  '& .MuiInputBase-root': {
    padding: multiline && theme.spacing(1.5, 2),
    '& input': {
      padding: theme.spacing(1.5, 2)
    }
  },
  '& .MuiInputBase-root.Mui-disabled': {
    backgroundColor: theme.palette.custom.grey.offWhite
  }
}));

export const StyledIcon = styled('div')({
  fontSize: 16,
  display: 'flex',
  justifyContent: 'center',
  cursor: 'pointer'
});
