import { useContext, useMemo, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useAccountsQuery } from 'src/api/queries/useAccounts';
import Autocomplete from 'src/components/Autocomplete/Autocomplete';
import ActionTab from 'src/components/banking/actions/ActionTab/ActionTab';
import { ActionBarContext } from 'src/contexts/ActionBar.contexts';
import { WithdrawContext } from 'src/contexts/actions/Withdraw.contexts';
import { useAllowedCountriesForPaymentQuery } from 'src/api/queries/useAllowedCountriesForPayment';
import { formatCountryDataForOptions, limitDecimals } from 'src/utils/formatting';
import { Alert } from 'src/components/Alert/Alert';
import { Account, BeneficiaryNetwork } from 'src/interfaces/banking';
import { Box, MenuItem, Select } from '@mui/material';
import { useNetworksQuery } from 'src/api/queries/useNetworks';
import FormElement from 'src/components/FormElement/FormElement';
import { mapAccountsToSelectOptions } from 'src/components/settings/modals/CompanySettingsCreateBeneficiaryModal/utils';
import { StyledListItem } from 'src/components/StyledListItem/StyledListItem';
import { useWithdrawalCurrencies } from 'src/api/queries/useWithdrawalCurrencies';
import { useRates } from 'src/api/queries/useRates';
import { WithdrawAccountAndAmount } from 'src/components/banking/WithdrawAccountAndAmount/WithdrawAccountAndAmount';

import { AlertType, WithdrawAmountAndCountryScreens } from '../Withdraw.interfaces';
import { withdrawActionTabDefaultValues } from '../Withdraw.utils';
import { WithdrawSelectBenficiary } from '../WithdrawSelectBenficiary/WithdrawSelectBenficiary';
import { WithdrawBeneficiaryDetails } from '../WithdrawBenficiaryDetails/WithdrawBenficiaryDetails';

import { StyledAlert, StyledAutocompleteWrapper } from './WithdrawAmountAndCountry.styles';

export const WithdrawAmountAndCountry = () => {
  const actionBarContext = useContext(ActionBarContext);
  const {
    amount,
    setAmount,
    outgoingCurrency,
    setOutgoingCurrency,
    account,
    setAccount,
    country,
    setCountry,
    beneficiaryDetails,
    setBeneficiaryDetails,
    beneficiaryNetwork,
    setBeneficiaryNetwork,
    isPreviousBeneficiary
  } = useContext(WithdrawContext) ?? {};
  const { t } = useTranslation();
  const [nextScreen, setNextScreen] = useState<WithdrawAmountAndCountryScreens>();
  const [alert, setAlert] = useState<AlertType | undefined>();

  const { data: allowedCountriesData, isLoading: isLoadingAllowedCountries } = useAllowedCountriesForPaymentQuery();
  const { data: accountsData, isLoading: isLoadingUserAccounts } = useAccountsQuery();
  const accounts = accountsData?.data;
  const isLoading = isLoadingUserAccounts || isLoadingAllowedCountries;
  const { data: withdrawalCurrenciesData } = useWithdrawalCurrencies(account);
  const outgoingCurrencies = withdrawalCurrenciesData?.data;
  const { data: ratesData } = useRates(account?.currency ?? '', outgoingCurrency ?? '');
  const rates = ratesData?.data;
  const estimatedRates = rates ? `${rates.forward} ${rates.quote} = 1 ${rates.base}` : undefined;

  const accountOptions = useMemo(() => mapAccountsToSelectOptions(accounts ?? []), [accounts]);
  const countryDataList = useMemo(() => formatCountryDataForOptions(allowedCountriesData?.data), [allowedCountriesData?.data]);

  const { data: networkListData } = useNetworksQuery(`${account?._links?.networks?.uri}` ?? '', country ?? '', outgoingCurrency ?? undefined);
  if (setBeneficiaryNetwork && networkListData?.data && beneficiaryNetwork && !networkListData?.data.includes(beneficiaryNetwork)) {
    setBeneficiaryNetwork();
  }

  const onWithdrawBalanceTypeChange = useCallback(
    (acc: { target: { value: unknown } }) => {
      const newOption = accountOptions.find(ac => ac && ac.key === acc.target.value) ?? null;
      if (newOption) {
        setAccount?.(accounts?.find(acc => acc.currencyLabel === newOption.value) as Account);
        setCountry?.('');
        setBeneficiaryNetwork?.();
      }
    },
    [accounts, accountOptions, setAccount, setCountry, setBeneficiaryNetwork]
  );

  const renderWithdrawBalanceTypeValue = useCallback(
    (selectedAcc: string | []) => {
      if (selectedAcc.length === 0) {
        return t('companySettings.beneficiaries.selectBalance');
      }
      const option = accountOptions.find(option => option.value === selectedAcc);
      return option ? <StyledListItem option={option} key={option.key} smallFlag hideLabel /> : '';
    },
    [accountOptions, t]
  );

  const flowScreens = {
    withdrawBeneficiaryDetails: <WithdrawBeneficiaryDetails />,
    withdrawSelectBeneficiary: <WithdrawSelectBenficiary />
  };

  const invalidAmount = Number(amount) < 1;
  const insufficientBalance =
    !!account?.balance &&
    (account.currency === outgoingCurrency
      ? Number(amount) > Number(account.balance)
      : Number(amount) / Number(rates?.forward ?? 1) > Number(account.balance));

  const handleSubmit = () => {
    if (invalidAmount) {
      return setAlert('minAmount');
    } else if (!country) {
      return setAlert('missingBeneficiaryBank');
    } else if (insufficientBalance) {
      return setAlert('insufficientBalance');
    } else if (!beneficiaryNetwork) {
      return setAlert('missingBeneficiaryNetwork');
    }
    if (!beneficiaryDetails && setBeneficiaryDetails && country) {
      setBeneficiaryDetails({ address: { country: country } });
    }
    setNextScreen('withdrawBeneficiaryDetails');
  };

  const countriesError = !countryDataList.length;
  return nextScreen ? (
    flowScreens[nextScreen]
  ) : actionBarContext ? (
    <ActionTab
      primaryActionTextKey={countriesError ? undefined : 'common.continue'}
      onPrimaryActionClick={handleSubmit}
      onGoBack={() => {
        setNextScreen('withdrawSelectBeneficiary');
      }}
      onClose={actionBarContext.closeActionTab}
      activeStepIndex={1}
      loading={isLoading}
      {...withdrawActionTabDefaultValues}
    >
      {!!alert && <StyledAlert alertVariant="error">{t(`banking.actions.withdraw.${alert}`)}</StyledAlert>}
      {accountsData?.data && (
        <>
          <WithdrawAccountAndAmount
            disabled={!!isPreviousBeneficiary}
            currencies={outgoingCurrencies ?? []}
            currency={outgoingCurrency ?? account?.currency}
            setCurrency={setOutgoingCurrency}
            amount={amount}
            setAmount={amount => {
              if (setAmount) {
                setAmount(limitDecimals(amount));
              }
            }}
            account={account}
            invalidAmount={insufficientBalance || (alert === 'minAmount' && invalidAmount)}
            estimatedRate={estimatedRates}
          />
          <Box mt={2}>
            <FormElement label={t('banking.actions.withdraw.details.withdrawBalance')}>
              <Select
                disabled={!!isPreviousBeneficiary}
                displayEmpty
                id="withdraw-balance-select"
                value={account?.currencyLabel ?? ''}
                placeholder={t('companySettings.beneficiaries.selectBalance')}
                renderValue={renderWithdrawBalanceTypeValue}
                onChange={onWithdrawBalanceTypeChange}
                sx={theme => ({
                  '& .MuiSelect-select': {
                    padding: theme.spacing(1.5625, 1.75)
                  }
                })}
              >
                <MenuItem disabled value="" />
                {accountOptions.map(option => (
                  <MenuItem value={option.key} key={option.key}>
                    <StyledListItem option={option} />
                  </MenuItem>
                ))}
              </Select>
            </FormElement>
          </Box>
          <StyledAutocompleteWrapper>
            {countriesError ? (
              <Alert>{t('banking.actions.withdraw.amountAndCountry.noCountries')}</Alert>
            ) : (
              <Autocomplete
                placeholder={t('banking.actions.withdraw.searchForCountry')}
                error={alert === 'missingBeneficiaryBank' ? t('formValidation.required') : undefined}
                value={country}
                handleOnChange={(_name, val) => {
                  if (setCountry) {
                    setCountry(String(val));
                    if (setBeneficiaryNetwork) {
                      setBeneficiaryNetwork(undefined);
                    }
                    if (setBeneficiaryDetails) {
                      setBeneficiaryDetails(undefined);
                    }
                    if (alert === 'missingBeneficiaryBank') {
                      setAlert(undefined);
                    }
                  }
                }}
                name="country"
                dataList={countryDataList}
                label={t('banking.actions.withdraw.countryOfBeneficiariesBank')}
                disabled={isPreviousBeneficiary}
                showOptionValue
              />
            )}
          </StyledAutocompleteWrapper>
          <FormElement label={t('companySettings.beneficiaries.network')}>
            <Select
              disabled={!Boolean(account && country) || isPreviousBeneficiary}
              id="network-select"
              displayEmpty
              value={beneficiaryNetwork ?? ''}
              error={alert === 'missingBeneficiaryNetwork' ? t('formValidation.required') : undefined}
              renderValue={selectedNet => {
                if (selectedNet?.length === 0) {
                  return t('companySettings.beneficiaries.selectNetwork');
                }
                return selectedNet;
              }}
              onChange={(net: { target: { value: string } }) => {
                if (net.target.value && setBeneficiaryNetwork) {
                  setBeneficiaryNetwork(net.target.value as BeneficiaryNetwork);
                }
              }}
              sx={theme => ({
                '& .MuiSelect-select': {
                  padding: theme.spacing(1.5625, 1.75)
                }
              })}
            >
              <MenuItem disabled value="" />
              {networkListData?.data?.map(network => (
                <MenuItem value={network} key={network}>
                  {network}
                </MenuItem>
              ))}
            </Select>
          </FormElement>
        </>
      )}
    </ActionTab>
  ) : null;
};
