import { AdditionalUrlName } from '../interfaces/urls';

export const additionalUrls: Record<AdditionalUrlName, string> = {
  '2fa': '/2fa',
  additionalInfo: '/additional-info',
  allowedCountriesForPayment: '/allowed-countries-for-payment',
  auth: '/auth',
  bankLookup: '/bank-lookup',
  beneficiaries: '/beneficiaries',
  changePassword: '/change-password',
  countries: '/countries',
  cryptoPaymentOrder: '/crypto-payment-order',
  enable: '/enable',
  kycCheck: '/kyc/check',
  kycStart: '/kyc/initiate-session',
  legalEntities: '/legal-entities',
  login: '/login',
  personalInfo: '/personal-info',
  me: '/me',
  members: '/members',
  networks: '/networks',
  notificationsAuth: '/notifications-auth',
  orders: '/orders',
  paymentOrder: '/payment-order',
  preferences: '/preferences',
  rates: '/rates/custom',
  refData: '/ref-data',
  registrations: '/registrations',
  resendInvitation: '/resend-invitation',
  resendVerificationEmail: '/resend-verification-email',
  request: '/request',
  requestForPayment: '/request-for-payment',
  requestForSwap: '/request-for-swap',
  reset: '/reset',
  resetPassword: '/reset-password',
  resetPasswordRequest: '/reset-password-request',
  schema: '/schema',
  signup: '/signup',
  startIdentityVerification: '/start-identity-verification',
  swapOrder: '/swap-order',
  swapQuotes: '/swap-quotes',
  transactions: '/transactions',
  users: '/users',
  verifyEmail: '/verify-email',
  verifyInvitation: '/verify-invitation',
  validateResetToken: '/validate-token',
  waitlist: '/waitlist',
  withdrawalCurrencies: '/withdrawal-currencies'
};
