import { FC, useState, useEffect, useMemo } from 'react';
import {
  formaNationalPhoneNumberBasedOnRegion,
  getCountryCallingCodeFromRegionCode,
  getListOfCountriesCallingCodes,
  getParsedPhoneNumber,
  getPlaceholderExample,
  getRegionCodeFromCountryCode
} from 'src/utils/phoneNumber';
import { toKebabCase } from 'src/utils/strings';
import { ICountryCallingCodeAndAbbreviation } from 'src/interfaces/countries';

import FormElement from '../FormElement/FormElement';
import { StyledLiContent } from '../Autocomplete/Autocomplete.styles';
import Highlight from '../Highlight/Highlight';

import {
  StyledRow,
  StyledCountryAutocomplete,
  StyledCountryAutocompleteInput,
  StyledCountryAutocompletePopper,
  StyledNumberInput
} from './PhoneNumber.styles';
import { PhoneNumberComponentProps, PhoneNumberState } from './PhoneNumber.interfaces';

const getOptionsList = (countriesPhoneNumberCodes: ICountryCallingCodeAndAbbreviation[]): string[] =>
  countriesPhoneNumberCodes.map(option => `+${option.countryCallingCode} ${option.countryName}`);

const getSelectedOption = (
  value: string,
  countriesPhoneNumberCodes: ICountryCallingCodeAndAbbreviation[]
): ICountryCallingCodeAndAbbreviation | undefined => countriesPhoneNumberCodes.find(item => value.includes(item.countryName));

const getFormatedPlaceholder = (placeholder: string) => ({
  dropdownPlaceholder: placeholder.slice(0, placeholder.indexOf(' ')),
  inputPlaceholder: placeholder.slice(placeholder.indexOf(' ') + 1)
});

const getFullNumber = (phoneNumber: PhoneNumberState) => `${phoneNumber.countryCode}${phoneNumber.nationalNumber}`;

const PhoneNumber: FC<PhoneNumberComponentProps> = ({
  name,
  error,
  value = '',
  label,
  labelTooltip,
  initialCountry,
  countriesList = [],
  placeholder = '',
  disabled,
  handleError,
  handleOnChange,
  onSubmitEditing,
  ...props
}) => {
  const placeholders = getFormatedPlaceholder(placeholder);

  const countriesPhoneNumberCodes: ICountryCallingCodeAndAbbreviation[] = useMemo(
    () => getListOfCountriesCallingCodes(countriesList),
    [countriesList]
  );

  const countryDropdownOptions = [...getOptionsList(countriesPhoneNumberCodes)];

  const [phoneNumberState, setPhoneNumberState] = useState<PhoneNumberState>({
    nationalNumber: '',
    countryCode: ''
  });

  const [inputPlaceholder, setInputPlaceholder] = useState(placeholders.inputPlaceholder);

  useEffect(() => {
    if (value) {
      const parsedPhone = getParsedPhoneNumber(value);
      setPhoneNumberState({
        nationalNumber: formaNationalPhoneNumberBasedOnRegion(parsedPhone.nationalNumber, parsedPhone.countryNameAbbreviation),
        countryCode: parsedPhone.countryCodeValue
      });
    } else if (initialCountry) {
      setPhoneNumberState({
        nationalNumber: '',
        countryCode: getCountryCallingCodeFromRegionCode(initialCountry)
      });
      setInputPlaceholder(getPlaceholderExample(initialCountry));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onDropdownChange = (event: React.SyntheticEvent, newValue: string) => {
    if (event && event.type) {
      if (event.type === 'click' || event.type === 'keydown') {
        const selectedCountry = getSelectedOption(newValue, countriesPhoneNumberCodes);

        const countryCallingCode = selectedCountry ? selectedCountry.countryCallingCode : '';

        const regionCode = getRegionCodeFromCountryCode(countryCallingCode);

        const newPhoneNumberState = {
          nationalNumber: formaNationalPhoneNumberBasedOnRegion(phoneNumberState.nationalNumber, regionCode),
          countryCode: `+${countryCallingCode}`
        };

        setPhoneNumberState(newPhoneNumberState);

        if (handleOnChange) {
          handleOnChange(name, getFullNumber(newPhoneNumberState));
        }

        if (!phoneNumberState.nationalNumber.length) {
          setInputPlaceholder(getPlaceholderExample(regionCode));
        }
      }
      if (event.type === 'change') {
        setPhoneNumberState({
          ...phoneNumberState,
          countryCode: newValue
        });
      }
      if (handleError && error) {
        handleError(name, '');
      }
    }
  };

  const onNationalNumberInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.value.length) {
      setInputPlaceholder(getPlaceholderExample(getRegionCodeFromCountryCode(phoneNumberState.countryCode)));
    }
    const newPhoneNumberState = {
      ...phoneNumberState,
      nationalNumber: formaNationalPhoneNumberBasedOnRegion(event.target.value, getRegionCodeFromCountryCode(phoneNumberState.countryCode))
    };
    setPhoneNumberState(newPhoneNumberState);
    if (handleOnChange) {
      handleOnChange(name, getFullNumber(newPhoneNumberState));
    }

    if (handleError && error) {
      handleError(name, '');
    }
  };

  return (
    <FormElement label={label} labelTooltip={labelTooltip} error={error}>
      <StyledRow>
        <StyledCountryAutocomplete
          PopperComponent={({ ...props }) => <StyledCountryAutocompletePopper placement="bottom-start" {...props} />}
          disabled={disabled}
          disablePortal
          disableClearable
          forcePopupIcon={false}
          id={`${toKebabCase(label || 'default')}-country-code-dropdown`}
          options={countryDropdownOptions}
          inputValue={phoneNumberState.countryCode}
          onInputChange={onDropdownChange}
          renderInput={params => <StyledCountryAutocompleteInput placeholder={placeholders.dropdownPlaceholder} error={!!error} {...params} />}
          renderOption={(props, option) => (
            <li {...props}>
              <StyledLiContent>
                <Highlight search={phoneNumberState.countryCode} text={option} />
              </StyledLiContent>
            </li>
          )}
          filterOptions={(options, state) => options.filter(o => o.toLowerCase().includes(state.inputValue.toLowerCase()))}
          {...props}
        />

        <StyledNumberInput
          autoComplete="off"
          disabled={disabled}
          error={!!error}
          placeholder={inputPlaceholder}
          id={`${toKebabCase(label || 'default')}-national-number-input`}
          type="tel"
          value={phoneNumberState.nationalNumber}
          onChange={onNationalNumberInputChange}
          onKeyPress={e => {
            if (onSubmitEditing && e.key === 'Enter') {
              onSubmitEditing();
              e.preventDefault();
            }
          }}
        />
      </StyledRow>
    </FormElement>
  );
};

export default PhoneNumber;
