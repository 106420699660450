import { Box, Stack, styled, Typography } from '@mui/material';

export const StyledButton = styled(Stack)(({ theme }) => ({
  justifyContent: 'space-between',
  minHeight: 104,
  borderRadius: theme.spacing(1),
  marginInline: theme.spacing(-2),
  '&:hover': {
    background: theme.palette.custom.grey[50],
    cursor: 'pointer'
  },
  '.MuiSvgIcon-root': {
    fontSize: 48,
    margin: theme.spacing(2)
  }
}));

export const StyledButtonLabel = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(1),
  color: theme.palette.custom.grey[400]
}));

export const TextWrapper = styled(Box)(({ theme }) => ({
  margin: theme.spacing(2, 0),
  marginRight: theme.spacing(2)
}));

export const ButtonTitle = styled(Typography)(({ theme }) => ({
  marginBotom: theme.spacing(1)
}));

export const StyledWithdrawMethodLabel = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(4)
}));
