import { Subpage } from 'src/components/layouts/SettingsLayout/SettingsLayout.interfaces';
import AboutForm from 'src/components/settings/personalSettings/AboutForm/AboutForm';
import ProfileForm from 'src/components/settings/personalSettings/ProfileForm/ProfileForm';
import SecurityForm from 'src/components/settings/personalSettings/SecurityForm/SecurityForm';

export const PERSONAL_SETTINGS_SUBPAGES: Subpage[] = [
  {
    path: 'profile',
    component: ProfileForm,
    i18nTitle: 'personalSettings.common.profile'
  },
  {
    path: 'security',
    component: SecurityForm,
    i18nTitle: 'personalSettings.common.security'
  },
  {
    path: 'about',
    component: AboutForm,
    i18nTitle: 'personalSettings.common.about'
  }
];
