export const ERROR_INCORRECT_OTP = 'incorrect_otp';
export const ERROR_INCORRECT_BACKUP_CODE = 'incorrect_backup_code';

export const ERROR_AUTH_DEVICE_EXPIRED = 'auth_device_expired';

export const ERROR_INVALID_TOKEN = 'invalid_token';

export const ERROR_INCORRECT_PASSWORD = 'incorrect_password';

export const ERROR_UNKNOWN_EMAIL = 'unknown_email';
export const ERROR_EMAIL_ALREADY_USED = 'email_already_used';

export const TOS_AND_PP_NOT_ACCEPTED = 'tos_and_pp_not_accepted';
export const PROVIDERS_TOS_AND_PP_NOT_ACCEPTED = 'providers_tos_and_pp_not_accepted';
export const E_SIGN_AGREEMENT_NOT_ACCEPTED = 'e_sign_agreement_not_accepted';

export const ERROR_INVALID_BIRTHDATE = 'invalid_birthdate';

export const ERROR_INVALID_PHONE_NUMBER = 'invalid_phone_number';

export const ERROR_INVALID_COUNTRY = 'invalid_country';
export const ERROR_UNSUPPORTED_COUNTRY = 'unsupported_country';
