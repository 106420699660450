import { Typography } from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useCountriesQuery } from 'src/api/queries/useCountries';
import { StyledCurrencyFlag, StyledDetails } from 'src/components/banking/actions/withdraw/WithdrawDetails/WithdrawDetails.styles';
import CopyItem from 'src/components/banking/CopyItem/CopyItem';
import { Account, Beneficiary } from 'src/interfaces/banking';
import { getCurrencyImage } from 'src/utils/styles';
import { formatBankDetails, formatBeneficiary } from 'src/utils/formatting';
import { useSnackbar } from 'notistack';
import ActionButtons from 'src/components/ActionButtons/ActionButtons';
import { createBeneficiary, updateBeneficiary } from 'src/api/banking';
import { AxiosError } from 'axios';
import { IResponseError } from 'src/interfaces/responses';
import { QueryKey } from 'src/constants/queryKeys';

export const StepThree: FC<{
  beneficiaryDetails: Beneficiary;
  crudType: 'edit' | 'create';
  accounts: Account[];
  onClose: () => void;
  onBack: () => void;
}> = ({ beneficiaryDetails = {}, crudType, accounts, onClose, onBack }) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const beneficiaryAccount = useMemo(
    () => accounts.find(acc => acc._links.self.uri === beneficiaryDetails._links?.account.uri),
    [accounts, beneficiaryDetails._links?.account.uri]
  );

  const { data: countries } = useCountriesQuery(!!beneficiaryDetails?.address?.country);
  const differentCurrencies = beneficiaryAccount?.currency !== beneficiaryDetails.targetCurrency;

  const accountUri = beneficiaryDetails?._links?.account.uri ?? '';
  const submitOrderMutation = useMutation(
    () => (crudType === 'create' ? createBeneficiary(accountUri, beneficiaryDetails) : updateBeneficiary(accountUri, beneficiaryDetails)),
    {
      onSuccess: () => {
        onClose();
      },
      onError: (error: AxiosError<IResponseError>) => {
        const message = error?.response?.data?.message;
        enqueueSnackbar(message ?? t('screens.error'), { variant: 'error' });
      },
      onSettled: () => queryClient.invalidateQueries([QueryKey.QUERY_BENEFICIARIES])
    }
  );

  const renderItem = (key: string, value: string, multiline = false, currency?: string) => (
    <CopyItem
      multiline={multiline}
      label={t(`companySettings.beneficiaries.details.${key}`)}
      value={value}
      valueDecoration={currency ? <StyledCurrencyFlag src={getCurrencyImage(currency)} alt={`${currency} flag`} /> : undefined}
      disableCopy
    />
  );

  return (
    <>
      <Typography variant="strongBody1">{t('companySettings.beneficiaries.reviewBeneficiaryDetails')}</Typography>
      <StyledDetails>
        {accountUri && renderItem('balance', beneficiaryAccount?.currency ?? '', false, beneficiaryAccount?.currency)}
        {accountUri &&
          beneficiaryDetails &&
          differentCurrencies &&
          renderItem('outgoingCurrency', beneficiaryDetails.targetCurrency ?? '', false, beneficiaryDetails.targetCurrency)}
        {beneficiaryDetails && renderItem('beneficiary', formatBeneficiary(beneficiaryDetails, countries?.data), true)}
        {beneficiaryDetails?.iban && renderItem('iban', beneficiaryDetails.iban)}
        {beneficiaryDetails?.accountNumber && renderItem('accountNumber', beneficiaryDetails.accountNumber)}
        {(beneficiaryDetails?.bicSwiftCode || beneficiaryDetails?.routingNumber) &&
          renderItem(
            beneficiaryDetails?.bicSwiftCode ? 'bicSwiftCode' : 'routingNumber',
            formatBankDetails(beneficiaryDetails, countries?.data),
            true
          )}
        {beneficiaryDetails?.network && renderItem('network', beneficiaryDetails.network)}
        <ActionButtons
          primaryActionText={
            crudType === 'create' ? t('companySettings.beneficiaries.actionButton') : t('companySettings.beneficiaries.actionButtonEdit')
          }
          onPrimaryActionClick={() => submitOrderMutation.mutate()}
          secondaryActionText={t('common.goBack')}
          onSecondaryActionClick={onBack}
        />
      </StyledDetails>
    </>
  );
};
