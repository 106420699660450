import { styled } from '@mui/material';
import MuiAlert from '@mui/material/Alert';

import { CustomAlertProps } from './Alert.interfaces';

export const StyledMuiAlert = styled(MuiAlert, {
  shouldForwardProp: prop => prop !== 'alertVariant'
})<CustomAlertProps>(({ theme, alertVariant }) => ({
  minWidth: 280,
  alignItems: 'center',
  borderRadius: 12,
  ...(() => {
    switch (alertVariant) {
      case 'warning':
      case 'error':
        return {
          backgroundColor: theme.palette.error.light,
          color: theme.palette.error.main,
          '.MuiAlert-icon': {
            color: theme.palette.error.main
          }
        };
      case 'success':
        return {
          backgroundColor: theme.palette.success.light,
          color: theme.palette.success.dark,
          '.MuiAlert-icon': {
            color: theme.palette.success.dark
          }
        };
      case 'info':
      default:
        return {
          backgroundColor: theme.palette.grey[100],
          color: theme.palette.grey[600],
          '.MuiAlert-icon': {
            color: theme.palette.grey[600]
          }
        };
    }
  })(),
  '.MuiSvgIcon-root': {
    fontSize: 16
  },
  '.MuiAlert-action': {
    alignSelf: 'normal',
    color: theme.palette.grey[400]
  }
}));
