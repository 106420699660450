import { alpha, Avatar, styled, Typography, Dialog } from '@mui/material';
import { NavLink } from 'react-router-dom';
import SvgIcon from 'src/components/SvgIcon/SvgIcon';
import { MENU_BAR_WIDTH } from 'src/constants/dimensions';

import { StyledAvatarProps, StyledMenuBarComponentsProps } from './MenuBar.interfaces';

export const StyledMenuBar = styled('div', {
  shouldForwardProp: prop => prop !== 'hidden'
})<StyledMenuBarComponentsProps>(({ theme, hidden }) => ({
  position: 'fixed',
  overflowY: 'auto',
  background: theme.palette.custom.coralDusk[900],
  width: hidden ? MENU_BAR_WIDTH.hidden : MENU_BAR_WIDTH.expanded,
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(3, 2, 4),
  ...(hidden && { alignItems: 'center' }),
  [theme.breakpoints.down('md')]: {
    width: '100vw'
  }
}));

export const StyledLogoAndToggleIcon = styled('div', {
  shouldForwardProp: prop => prop !== 'hidden'
})<StyledMenuBarComponentsProps>(({ theme, hidden }) => ({
  display: 'flex',
  alignItems: 'center',
  ...(hidden && { justifyContent: 'center' }),
  gap: '100px',
  flexShrink: 0,
  height: '56px',
  padding: theme.spacing(1, 2, 1),
  marginBottom: theme.spacing(2),
  justifyContent: 'space-between',
  '.MuiSvgIcon-root': {
    cursor: 'pointer'
  }
}));

export const StyledSvgIcon = styled(SvgIcon)({
  fontSize: '72px'
});

export const StyledNavLinksSection = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',

  '&:last-child': {
    marginTop: theme.spacing(4)
  }
}));

export const StyledAnchorNavLink = styled('a', {
  shouldForwardProp: prop => prop !== 'hidden' && prop !== 'disabled'
})<StyledMenuBarComponentsProps>(({ theme, hidden, disabled }) => ({
  display: 'flex',
  justifyContent: hidden ? 'center' : undefined,
  alignItems: 'center',
  gap: theme.spacing(2),
  height: '40px',
  padding: theme.spacing(1, 2.5),
  color: alpha(theme.palette.common.white, disabled ? 0.35 : 1),
  ...theme.typography.body2,
  textDecoration: 'none',
  marginBottom: hidden ? theme.spacing(0.5) : theme.spacing(1),
  pointerEvents: disabled ? 'none' : 'auto',

  '&:last-child': {
    marginBottom: 0
  },
  '.MuiSvgIcon-root': {
    fontSize: '1rem',
    minWidth: 24,

    g: {
      opacity: disabled ? 0.15 : 0.35
    }
  },
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, disabled ? 0 : 0.15),
    borderRadius: theme.shape.borderRadius,

    '.MuiSvgIcon-root g': {
      opacity: disabled ? 0.15 : 1
    }
  },
  '&.active': {
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    borderRadius: theme.shape.borderRadius,

    '.MuiSvgIcon-root g': {
      opacity: disabled ? 0.35 : 1
    }
  }
}));

export const StyledNavLink = styled(NavLink, {
  shouldForwardProp: prop => prop !== 'hidden' && prop !== 'disabled'
})<StyledMenuBarComponentsProps>(({ theme, hidden, disabled }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: hidden ? 'center' : undefined,
  gap: theme.spacing(2),
  height: '40px',
  padding: theme.spacing(1, 2.5),
  color: alpha(theme.palette.common.white, disabled ? 0.35 : 1),
  ...theme.typography.body2,
  textDecoration: 'none',
  marginBottom: hidden ? theme.spacing(0.5) : theme.spacing(1),
  pointerEvents: disabled ? 'none' : 'auto',

  '&:last-child': {
    marginBottom: 0
  },
  '.MuiSvgIcon-root': {
    fontSize: '1rem',
    minWidth: 24,

    g: {
      opacity: disabled ? 0.15 : 0.35
    }
  },
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, disabled ? 0 : 0.15),
    borderRadius: theme.shape.borderRadius,

    '.MuiSvgIcon-root g': {
      opacity: disabled ? 0.15 : 1
    }
  },
  '&.active': {
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    borderRadius: theme.shape.borderRadius,

    '.MuiSvgIcon-root g': {
      opacity: disabled ? 0.35 : 1
    }
  }
}));

export const StyledNavLinksLabel = styled(Typography)(({ theme }) => ({
  color: theme.palette.custom.grey[300],
  margin: theme.spacing(2)
}));

export const StyledNavLinks = styled('div')<StyledMenuBarComponentsProps>(({ hidden }) => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  justifyContent: 'space-between',
  alignSelf: hidden ? 'stretch' : undefined
}));

export const StyledAvatar = styled(Avatar, {
  shouldForwardProp: prop => prop !== 'avatarColor'
})<StyledAvatarProps>(({ theme, avatarColor }) => ({
  width: '24px',
  height: '24px',
  backgroundColor: avatarColor ?? theme.palette.custom.coralDusk[400],
  fontSize: '15px'
}));

export const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiPaper-root': {
    [theme.breakpoints.down('md')]: {
      margin: 0
    }
  }
}));
