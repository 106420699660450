import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { IGetOrdersResponse } from 'src/interfaces/responses';
import { QueryKey } from 'src/constants/queryKeys';

import { getOrders } from '../banking';

export const useOrdersQuery = (entityUri: string): UseQueryResult<AxiosResponse<IGetOrdersResponse, AxiosError> | undefined> =>
  useQuery([QueryKey.QUERY_ORDERS, entityUri], () => getOrders(entityUri, 'pending'), {
    enabled: !!entityUri
  });
