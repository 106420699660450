import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { IGetOrderResponse } from 'src/interfaces/responses';
import { QueryKey } from 'src/constants/queryKeys';

import { requestOrderRefresh } from '../banking';

export const useOrderRefreshQuery = (uri: string, enabled: boolean): UseQueryResult<AxiosResponse<IGetOrderResponse, AxiosError> | undefined> =>
  useQuery([QueryKey.QUERY_ORDER_REFRESH, uri], () => requestOrderRefresh(uri), {
    enabled: !!uri && enabled
  });
