import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { AxiosResponse, AxiosError } from 'axios';
import { QueryKey } from 'src/constants/queryKeys';
import { IKYCStartResponse } from 'src/interfaces/responses';

import { getKYCStartData } from '../onboarding';

export const useKYCStartDataQuery = (
  userUri: string
): UseQueryResult<AxiosResponse<IKYCStartResponse, AxiosError>, { response: AxiosResponse<AxiosError> }> =>
  useQuery([QueryKey.QUERY_KYC_START_DATA, userUri], () => getKYCStartData(userUri), {
    enabled: !!userUri
  });
