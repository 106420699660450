import { Stack, Typography } from '@mui/material';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ActionTab from 'src/components/banking/actions/ActionTab/ActionTab';
import { ActionBarContext } from 'src/contexts/ActionBar.contexts';
import { WithdrawContext } from 'src/contexts/actions/Withdraw.contexts';
import { Account, CurrencyType, Beneficiary, BeneficiaryNetwork } from 'src/interfaces/banking';
import { useAccountsQuery } from 'src/api/queries/useAccounts';
import { WithdrawPaymentDetails } from 'src/interfaces/contexts';
import { useLocation } from 'react-router-dom';
import { getDefaultAccount } from 'src/utils/accounts';
import { useBeneficiariesQuery } from 'src/api/queries/useBeneficiaries';
import { getSelectedLegalEntityUri } from 'src/utils/storage';

import { BeneficiarySearchBlock } from '../BeneficiarySearchBlock/BeneficiarySearchBlock';
import { WithdrawAmountAndCountry } from '../WithdrawAmountAndCountry/WithdrawAmountAndCountry';
import { WithdrawSelectBeneficiaryScreens } from '../Withdraw.interfaces';
import { withdrawActionTabDefaultValues } from '../Withdraw.utils';
import WithdrawMethodSelection from '../WithdrawMethodSelection/WithdrawMethodSelection';

import {
  StyledBeneficiaryFieldLabel,
  StyledAddButton,
  StyledEntityText,
  StyledAddCircle,
  StyledLoader,
  StyledAlert
} from './WithdrawSelectBenficiary.styles';

export const WithdrawSelectBenficiary = () => {
  const { t } = useTranslation();
  const location = useLocation();

  const accountUri = (location.state as Account)?._links?.self?.uri;

  const [nextScreen, setNextScreen] = useState<WithdrawSelectBeneficiaryScreens>();
  const flowScreens = {
    withdrawMethodSelection: <WithdrawMethodSelection />,
    withdrawAmountAndCountry: <WithdrawAmountAndCountry />
  };

  const actionBarContext = useContext(ActionBarContext);
  const entityUri = getSelectedLegalEntityUri();
  const { data: beneficiariesData, isLoading: areBeneficiariesLoading, isError: isBeneficiariesError } = useBeneficiariesQuery(entityUri);
  const beneficiariesList = beneficiariesData?.data ?? [];
  const [amount, setAmount] = useState<string>('0');
  const [account, setAccount] = useState<Account | undefined>();
  const [outgoingCurrency, setOutgoingCurrency] = useState<string | undefined>();
  const [country, setCountry] = useState<string | undefined>('');
  const [beneficiaryNetwork, setBeneficiaryNetwork] = useState<BeneficiaryNetwork | undefined>();
  const [beneficiaryType, setBeneficiaryType] = useState<string>('business');
  const [beneficiaryDetails, setBeneficiaryDetails] = useState<Beneficiary | undefined>();
  const [isPreviousBeneficiary, setIsPreviousBeneficiary] = useState(false);
  const [paymentDetails, setPaymentDetails] = useState<WithdrawPaymentDetails>();
  const { data: accountsData, isLoading: isLoadingAccounts } = useAccountsQuery();
  const accounts: Account[] = accountsData?.data.filter(account => !!account._actions?.send && account.type === CurrencyType.Fiat) ?? [];

  const addNewBeneficiary = () => {
    setBeneficiaryDetails(undefined);
    setCountry('');
    setAmount('0');
    setIsPreviousBeneficiary(false);
    if (accountsData?.data) {
      const defaultAccount = getDefaultAccount(accounts, accountUri);
      setAccount(defaultAccount);
      setOutgoingCurrency(defaultAccount?.currency);
    }
    setNextScreen('withdrawAmountAndCountry');
  };

  return (
    <WithdrawContext.Provider
      value={{
        amount,
        setAmount,
        outgoingCurrency,
        setOutgoingCurrency,
        country,
        setCountry,
        account,
        setAccount,
        beneficiaryNetwork,
        setBeneficiaryNetwork,
        beneficiaryType,
        setBeneficiaryType,
        beneficiaryDetails,
        setBeneficiaryDetails,
        isPreviousBeneficiary,
        paymentDetails,
        setPaymentDetails
      }}
    >
      {nextScreen ? (
        flowScreens[nextScreen]
      ) : actionBarContext ? (
        <ActionTab
          onClose={actionBarContext.closeActionTab}
          onGoBack={actionBarContext.returnToActionBar}
          activeStepIndex={0}
          {...withdrawActionTabDefaultValues}
          loading={isLoadingAccounts}
        >
          <Stack>
            <Typography variant="strongBody1">{t('banking.actions.withdraw.createSelectBeneficiary')}</Typography>
            <StyledBeneficiaryFieldLabel variant="body2">{t('banking.actions.withdraw.newBeneficiary')}</StyledBeneficiaryFieldLabel>
            <StyledAddButton onClick={() => addNewBeneficiary()}>
              <StyledAddCircle icon="addCircle" fontSize="small" />
              <StyledEntityText variant="strongBody1">{t('banking.actions.withdraw.addNew')}</StyledEntityText>
            </StyledAddButton>
            {!areBeneficiariesLoading ? (
              isBeneficiariesError ? (
                <StyledAlert>{t('banking.actions.withdraw.beneficiariesError')}</StyledAlert>
              ) : (
                <BeneficiarySearchBlock
                  beneficiaryList={beneficiariesList ?? []}
                  accounts={accounts}
                  onOptionSelect={val => {
                    const selectedBeneficiary = beneficiariesList?.find(benef => val.key === benef._id);
                    const selectedBeneficiaryAccountUri = selectedBeneficiary?._links?.account.uri;
                    setBeneficiaryDetails(selectedBeneficiary);
                    setBeneficiaryType(selectedBeneficiary?.companyName ? 'business' : 'individual');
                    setBeneficiaryNetwork(selectedBeneficiary?.network);
                    setIsPreviousBeneficiary(true);
                    if (selectedBeneficiaryAccountUri) {
                      const selectedBeneficiaryAccount = accountsData?.data.find(
                        (acc: Account) => acc._links.self.uri === selectedBeneficiaryAccountUri
                      );
                      setAccount(selectedBeneficiaryAccount);
                      setOutgoingCurrency(selectedBeneficiary.targetCurrency);
                    }
                    if (selectedBeneficiary?.bank?.country) {
                      setCountry(selectedBeneficiary?.bank?.country);
                    }
                    setNextScreen('withdrawAmountAndCountry');
                  }}
                />
              )
            ) : (
              <StyledLoader anchorToRelative />
            )}
          </Stack>
        </ActionTab>
      ) : null}
    </WithdrawContext.Provider>
  );
};
