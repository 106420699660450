import { CircularProgress, styled } from '@mui/material';
import Button from 'src/components/Button/Button';
import SvgIcon from 'src/components/SvgIcon/SvgIcon';

import { StyledEntityProps } from './EntitySwitch.interfaces';

export const StyledSvgIcon = styled(SvgIcon)({
  fontSize: '40px'
});

export const StyledContent = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2)
}));

export const StyledEntity = styled('div', {
  shouldForwardProp: prop => prop !== 'active' && prop !== 'clickable'
})<StyledEntityProps>(({ theme, active, clickable }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  cursor: clickable ? 'pointer' : 'default',
  padding: theme.spacing(3),
  gap: theme.spacing(2),
  border: `1px solid ${active ? theme.palette.custom.coralDusk[400] : theme.palette.custom.grey[100]}`,
  borderRadius: '16px',
  background: active ? theme.palette.primary.light : 'transparent',

  '&:hover': clickable && {
    outline: `1px solid ${theme.palette.custom.coralDusk[400]}`,
    borderColor: theme.palette.custom.coralDusk[400]
  },

  svg: {
    fontSize: '48px'
  },
  '.MuiAvatar-root': {
    width: '48px',
    height: '48px',
    backgroundColor: theme.palette.custom.coralDusk[400],
    fontSize: '15px'
  }
}));

export const StyledEntityText = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'flex-start',
  textAlign: 'left'
});

export const StyledButton = styled(Button)(({ theme }) => ({
  '&.MuiButton-root': {
    color: theme.palette.custom.coralDusk[400]
  },

  svg: { fontSize: '14px' }
}));

export const StyledLoader = styled(CircularProgress)(({ theme }) => ({
  padding: 5,
  margin: theme.spacing(2),
  alignSelf: 'center'
}));
