import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { IGetOrderResponse } from 'src/interfaces/responses';
import { QueryKey } from 'src/constants/queryKeys';

import { getOrder } from '../banking';

export const useOrderQuery = (uri: string): UseQueryResult<AxiosResponse<IGetOrderResponse, AxiosError> | undefined> =>
  useQuery([QueryKey.QUERY_ORDER, uri], () => getOrder(uri), {
    enabled: !!uri
  });
