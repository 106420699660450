import { styled, Typography } from '@mui/material';
import SvgIcon from 'src/components/SvgIcon/SvgIcon';

import { StyledElipsisText, StyledValueProps } from './CopyItem.interfaces';

export const StyledCopyItem = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'top',
  flexDirection: 'row',
  justifyContent: 'space-between',
  padding: theme.spacing(0.5, 0)
}));

export const StyledLabel = styled(Typography)(({ theme }) => ({
  color: theme.palette.custom.grey[400],
  marginRight: theme.spacing(1)
}));

export const StyledValueDecoration = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  marginRight: theme.spacing(1)
}));

export const StyledValue = styled('div', {
  shouldForwardProp: prop => prop !== 'isLink' && prop !== 'disableCopy'
})<StyledValueProps>(({ theme, isLink, highlight, disableCopy }) => ({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'row',
  cursor: disableCopy ? 'auto' : 'pointer',
  maxWidth: '70%',

  ...(isLink && {
    color: theme.palette.custom.coralDusk[400],
    cursor: 'pointer'
  }),

  ...(highlight && {
    color: '#FFB800'
  })
}));

export const StyledIcon = styled('div')(({ theme }) => ({
  marginLeft: theme.spacing(2)
}));

export const StyledLinkIcon = styled(SvgIcon)(({ theme }) => ({
  fontSize: 16,
  marginLeft: theme.spacing(0.5)
}));

export const ElipsisText = styled(Typography, {
  shouldForwardProp: prop => prop !== 'multiline'
})<StyledElipsisText>(({ multiline }) => ({
  overflow: 'hidden',
  textAlign: 'right',
  whiteSpace: multiline ? 'pre-line' : 'nowrap',
  wordBreak: multiline ? 'break-word' : 'normal',
  textOverflow: 'ellipsis'
}));
