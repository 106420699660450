import { IUseFormData, IUseFormDataReturnProps } from 'src/components/Form/hooks/useFormData.interfaces';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { IFormValues, IFormErrors, IFormProps } from 'src/interfaces/forms';
import { IGetFormSchemaResponse } from 'src/interfaces/responses';
import Ajv, { DefinedError } from 'ajv';
import addFormats from 'ajv-formats';
import merge from 'lodash.merge';
import cloneDeep from 'lodash.clonedeep';
import isEqual from 'lodash.isequal';
import { handleGenericError, handleLimitError, handlePatternError, handleRequiredError } from 'src/utils/formValidators';
import { mapValuesToErrors, removeEmpty, retrieveFormProps } from 'src/utils/formData';
import { getFormSchema } from 'src/api/formSchemas';
import i18n from 'src/i18n';
import { AJV_OPTIONS } from 'src/constants/form';
import get from 'lodash.get';

const ajv = new Ajv(AJV_OPTIONS);
addFormats(ajv);

const useFormData = ({ names = [], schemaUrl, initialValues }: IUseFormData): IUseFormDataReturnProps => {
  const { t } = i18n;

  const schemaRef = useRef<string>();
  const [values, setValues] = useState<IFormValues>({});
  const [errors, setErrors] = useState<IFormErrors>({});
  const [schema, setSchema] = useState<IGetFormSchemaResponse | undefined>();
  const [formProps, setFormProps] = useState<IFormProps>({
    defaultValues: {},
    types: {},
    enums: {},
    descriptions: {},
    placeholders: {},
    formatDescriptions: {},
    required: {},
    labels: {}
  });
  const [formLoading, setFormLoading] = useState<boolean>(true);
  const validate = useMemo(() => (schema ? ajv.compile(schema) : undefined), [schema]);

  useEffect(() => {
    if (schemaUrl && schemaUrl !== schemaRef.current) {
      schemaRef.current = schemaUrl;

      setFormLoading(true);
      getFormSchema(schemaUrl)
        ?.then(response => {
          if (typeof response.data === 'object') {
            setErrors({});
            setSchema(response.data);
            setValues(removeEmpty(initialValues ?? {}));
          } else {
            setFormLoading(false);
          }
        })
        .catch(() => setFormLoading(false));
    }
  }, [initialValues, schemaUrl]);

  useEffect(() => {
    if (schema) {
      const tmpFormProps = {
        defaultValues: {},
        types: {},
        enums: {},
        descriptions: {},
        placeholders: {},
        formatDescriptions: {},
        required: {},
        labels: {}
      };

      retrieveFormProps(schema, tmpFormProps);

      setFormProps(tmpFormProps);
      setFormLoading(false);
    }
  }, [schema]);

  useEffect(() => {
    if (formProps?.defaultValues) {
      setValues(prevState => merge(cloneDeep(prevState), formProps.defaultValues));
    }
  }, [formProps?.defaultValues]);

  const validateForm = useCallback(
    (vals: IFormValues, ignoreNames = false) => validate?.(vals) || handleValidationErrors(ignoreNames),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [validate]
  );

  const handleValidationErrors = useCallback(
    (ignoreNames = false) => {
      const tmpErrors = mapValuesToErrors(values);
      for (const err of validate?.errors as DefinedError[]) {
        switch (err.keyword) {
          case 'required':
            handleRequiredError(tmpErrors, err);
            break;
          case 'format':
          case 'enum':
            handleGenericError(tmpErrors, err, t('formValidation.invalid'));
            break;
          case 'pattern':
            handlePatternError(tmpErrors, err);
            break;
          case 'minLength':
            handleLimitError(tmpErrors, err, t('formValidation.minLength'));
            break;
          case 'maxLength':
            handleLimitError(tmpErrors, err, t('formValidation.maxLength'));
            break;
        }
      }

      const errors = removeEmpty(tmpErrors);
      if (!ignoreNames && names && names.map(k => get(errors, k)).filter(Boolean).length === 0) {
        return true;
      }

      setErrors(prevState => (isEqual(prevState, errors) ? prevState : errors));
      return false;
    },
    [validate, values, names, t]
  );

  return {
    names,
    values,
    errors,
    setValues,
    validateForm,
    setErrors,
    formLoading,
    formProps
  };
};

export default useFormData;
