import { TextField, Popper, styled, Autocomplete, Typography } from '@mui/material';

export const StyledAutocomplete = styled(Autocomplete)(({ theme }) => ({
  '& + .MuiAutocomplete-popper': {
    '.MuiAutocomplete-listbox': {
      padding: 0,
      margin: theme.spacing(1),

      '::-webkit-scrollbar': {
        width: '4px'
      },
      '::-webkit-scrollbar-track': {
        background: 'transparent'
      },
      '::-webkit-scrollbar-thumb': {
        background: '#dadada', // TODO: This color isn't present in color palette
        borderRadius: '100px'
      }
    },
    '.MuiAutocomplete-option': {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      marginRight: theme.spacing(0.5),
      borderRadius: '4px',

      '&[aria-selected="true"]': {
        color: theme.palette.custom.coralDusk[400],
        backgroundColor: 'transparent'
      },
      '&.Mui-focused': {
        backgroundColor: theme.palette.custom.grey[50],

        '&:active': {
          backgroundColor: theme.palette.custom.coralDusk[50]
        }
      }
    }
  }
})) as typeof Autocomplete;

export const StyledTextField = styled(TextField)(({ theme }) => ({
  width: '100%',
  minWidth: '220px',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.common.white,

  '& .MuiInputBase-root': {
    height: '48px',
    padding: 0,
    '& .MuiInputBase-input': {
      padding: theme.spacing(1.5, 2)
    }
  },
  '& .MuiInputBase-root.Mui-disabled': {
    backgroundColor: theme.palette.custom.grey.offWhite
  }
}));

export const StyledPopper = styled(Popper)(({ theme }) => ({
  boxShadow: theme.shadows[5],
  borderRadius: theme.shape.borderRadius
}));

export const StyledLiContent = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%'
});

export const StyledOptionValue = styled(Typography)(({ theme }) => ({
  color: theme.palette.custom.grey[300]
}));
