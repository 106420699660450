/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useContext, useState } from 'react';
import ActionTab from 'src/components/banking/actions/ActionTab/ActionTab';
import { ActionBarContext } from 'src/contexts/ActionBar.contexts';
import { IFormHandleSubmit } from 'src/interfaces/forms';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import { WithdrawContext } from 'src/contexts/actions/Withdraw.contexts';
import ActionButtons from 'src/components/ActionButtons/ActionButtons';
import Autocomplete from 'src/components/Autocomplete/Autocomplete';
import Form from 'src/components/Form/Form';
import Input from 'src/components/Input/Input';
import { IFormValues } from 'src/interfaces/forms';
import FormElement from 'src/components/FormElement/FormElement';
import { BankInfoExtended, Beneficiary } from 'src/interfaces/banking';
import { bankLookup } from 'src/api/banking';
import { IdentifierType } from 'src/constants/banking';
import { IError } from 'src/interfaces/global';
import { enqueueSnackbar } from 'notistack';
import IBAN from 'iban';
import { paymentOrderSchemaUrl } from 'src/api/formSchemas';

import { WithdrawAmountAndCountry } from '../WithdrawAmountAndCountry/WithdrawAmountAndCountry';
import { WithdrawPaymentDetails } from '../WithdrawPaymentDetails/WithdrawPaymentDetails';
import { withdrawActionTabDefaultValues } from '../Withdraw.utils';
import { WithdrawBenficiaryDetailsScreens } from '../Withdraw.interfaces';

import { StyledToggleButton, StyledToggleButtonGroup } from './WithdrawBenficiaryDetails.styles';

export const WithdrawBeneficiaryDetails = () => {
  const { t } = useTranslation();
  const actionBarContext = useContext(ActionBarContext);
  const {
    account,
    country,
    beneficiaryType,
    setBeneficiaryType,
    beneficiaryDetails,
    setBeneficiaryDetails,
    beneficiaryNetwork,
    isPreviousBeneficiary
  } = useContext(WithdrawContext) ?? {};

  const [nextScreen, setNextScreen] = useState<WithdrawBenficiaryDetailsScreens>();

  const handleSubmit: IFormHandleSubmit = async (values, validateForm, handleError) => {
    if (setBeneficiaryDetails && validateForm(values)) {
      const beneficiary = values.beneficiary as unknown as Beneficiary;
      const beneficiaryIban = beneficiary?.iban ?? '';
      if (beneficiary.hasOwnProperty('iban') && !IBAN.isValid(beneficiaryIban)) {
        handleError('beneficiary.iban', t('banking.actions.withdraw.beneficiary.invalidIban'));
        return;
      }
      const identifier = beneficiary?.bicSwiftCode || beneficiary?.routingNumber || beneficiaryIban;
      if (identifier) {
        const isBicSwift = beneficiary?.bicSwiftCode;
        const identifierType = isBicSwift ? IdentifierType.BicSwift : beneficiary?.routingNumber ? IdentifierType.RoutingNumber : IdentifierType.Iban;
        bankLookup(identifier, identifierType, true)
          .then(response => {
            setNextScreen('withdrawPaymentDetails');
            setBeneficiaryDetails({
              ...beneficiary,
              bank: {
                address: response.data.address,
                country: response.data.country,
                name: response.data.name
              },
              bicSwiftCode:
                identifierType === IdentifierType.Iban ? (response.data as BankInfoExtended).bicSwift : beneficiary.bicSwiftCode || undefined
            });
          })
          .catch(error => {
            if (error?.response?.data?.errors?.find((e: IError) => ['invalid_aba', 'invalid_bic'].includes(e.code))) {
              handleError(isBicSwift ? 'beneficiary.bicSwiftCode' : 'beneficiary.routingNumber', t('formValidation.invalid'));
            } else {
              enqueueSnackbar(t('screens.error'), { variant: 'error' });
            }
          });
      } else {
        setBeneficiaryDetails(beneficiary);
        setNextScreen('withdrawPaymentDetails');
      }
    }
  };

  const flowScreens = {
    withdrawAmountAndCountry: <WithdrawAmountAndCountry />,
    withdrawPaymentDetails: <WithdrawPaymentDetails />
  };

  return nextScreen ? (
    flowScreens[nextScreen]
  ) : actionBarContext ? (
    <ActionTab activeStepIndex={2} onClose={actionBarContext.closeActionTab} {...withdrawActionTabDefaultValues}>
      {setBeneficiaryDetails && (
        <>
          <StyledToggleButtonGroup
            value={beneficiaryType}
            exclusive
            onChange={
              !isPreviousBeneficiary
                ? (_, value) => {
                    if (setBeneficiaryType) {
                      setBeneficiaryType(value);
                    }
                    setBeneficiaryDetails(undefined);
                  }
                : undefined
            }
          >
            <StyledToggleButton value="business" disabled={!!beneficiaryDetails}>
              <Typography variant="strongBody3">{t('banking.actions.withdraw.business')}</Typography>
            </StyledToggleButton>
            <StyledToggleButton value="individual">
              <Typography variant="strongBody3">{t('banking.actions.withdraw.individual')}</Typography>
            </StyledToggleButton>
          </StyledToggleButtonGroup>
          <Form
            schemaUrl={paymentOrderSchemaUrl(account?._links.self.uri ?? '', country!, beneficiaryType!, beneficiaryNetwork)}
            initialValues={{
              beneficiary: beneficiaryDetails as unknown as IFormValues
            }}
            onSubmit={handleSubmit}
            disabled={isPreviousBeneficiary}
          >
            <Input name="beneficiary.companyName" />
            <Input name="beneficiary.firstName" />
            <Input name="beneficiary.lastName" />

            <Input name="beneficiary.iban" inputProps={{ sx: { textTransform: 'uppercase' } }} />
            <Input name="beneficiary.accountNumber" />
            <Input name="beneficiary.routingNumber" />
            <Input name="beneficiary.aba" />
            <Input name="beneficiary.bicSwiftCode" />

            <FormElement label={t('banking.actions.withdraw.beneficiary.address')}>
              <Autocomplete name="beneficiary.address.country" showOptionValue />
              <Input name="beneficiary.address.street" />
              <Input name="beneficiary.address.postalCode" />
              <Input name="beneficiary.address.city" />
              <Input name="beneficiary.address.state" />
            </FormElement>

            <ActionButtons
              primaryActionText={t('common.continue')}
              secondaryActionText={t('common.goBack')}
              onSecondaryActionClick={() => setNextScreen('withdrawAmountAndCountry')}
            />
          </Form>
        </>
      )}
    </ActionTab>
  ) : null;
};
