import { FC, PropsWithChildren } from 'react';

import { MenuProps } from './Menu.interfaces';
import { StyledMenu } from './Menu.styles';

const Menu: FC<PropsWithChildren<MenuProps>> = ({ open, onClose, anchorEl, children, size = 'large' }) => (
  <StyledMenu disablePortal open={open} anchorEl={anchorEl} onClose={onClose} size={size}>
    {children}
  </StyledMenu>
);

export default Menu;
