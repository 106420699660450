import { FC, useEffect, useRef, useState } from 'react';
import { resendVerificationEmail, verifyEmail } from 'src/api/onboarding';
import Loader from 'src/components/Loader/Loader';
import DialogLayout from 'src/components/layouts/DialogLayout/DialogLayout';
import LabelValue from 'src/components/LabelValue/LabelValue';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import StatusIcon from 'src/components/StatusIcon/StatusIcon';
import SvgIcon from 'src/components/SvgIcon/SvgIcon';
import { StorageKey } from 'src/constants/storage';
import { urls } from 'src/routes';
import { enqueueSnackbar } from 'notistack';
import { ONBOARDING_EMAIL_VERIFICATION } from 'src/constants/onboarding';

import { StyledEmailRounded, StyledValidty } from './VerifyEmailPage.styles';

const VerifyEmailPage: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { token } = useParams();
  const [searchParams] = useSearchParams();
  const email = location?.state?.email ?? '';
  const region = searchParams.get('region') ?? '';

  const tokenRef = useRef<string>();
  const [loading, setLoading] = useState<boolean>(!!token);
  const [error, setError] = useState<boolean>(false);

  useEffect(() => {
    if (token && token !== tokenRef.current) {
      tokenRef.current = token;

      verifyEmail(token)
        ?.then(response => {
          sessionStorage.setItem(StorageKey.WEAK_AUTH_TOKEN, response.data.signupToken);
          navigate(urls.emailVerified);
        })
        .catch(_ => {
          setError(true);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [token, navigate]);

  if (token) {
    if (loading) {
      return <Loader />;
    } else if (error) {
      return (
        <DialogLayout
          isOnboardingLayout
          headerImage={<StatusIcon error icon={<SvgIcon icon="warning" />} />}
          heading={t('common.invalidToken')}
          subheading={t('screens.verifyEmail.error.subtitle')}
          secondaryBackAction
          secondaryActionText={t('screens.verifyEmail.error.backText')}
          onSecondaryActionClick={() => navigate(urls.signin)}
        />
      );
    }
  } else if (!email) {
    return (
      <DialogLayout
        isOnboardingLayout
        headerImage={<StatusIcon error icon={<SvgIcon icon="warning" />} />}
        heading={t('screens.verifyEmail.missingEmail.title')}
        subheading={t('screens.verifyEmail.missingEmail.subtitle')}
        secondaryBackAction
        secondaryActionText={t('common.backToHomepage')}
        onSecondaryActionClick={() => navigate(urls.signin)}
      />
    );
  }

  return (
    <DialogLayout
      isOnboardingLayout
      headerImage={<StatusIcon icon={<SvgIcon icon="email" />} />}
      heading={t('screens.verifyEmail.title')}
      subheading={t('screens.verifyEmail.subtitle')}
      secondaryActionText={t('screens.verifyEmail.backText')}
      onSecondaryActionClick={() => {
        setLoading(true);
        resendVerificationEmail(email, region)
          ?.then(_ => {
            enqueueSnackbar(t('screens.verifyEmail.resend.success'), {
              variant: 'success'
            });
          })
          .catch(_ => {
            enqueueSnackbar(t('screens.verifyEmail.resend.error'), {
              variant: 'error'
            });
          })
          .finally(() => {
            setLoading(false);
          });
      }}
      activeStepName={ONBOARDING_EMAIL_VERIFICATION}
    >
      <LabelValue label={t('screens.verifyEmail.label')} value={email} icon={<StyledEmailRounded fontSize="small" />} />
      <StyledValidty>{t('screens.verifyEmail.validity')}</StyledValidty>
    </DialogLayout>
  );
};

export default VerifyEmailPage;
