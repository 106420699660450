import { Stack, styled, Toolbar } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { StyledInfoBox } from 'src/components/InfoBox/InfoBox.styles';
import { ACTION_BAR_WIDTH, MENU_BAR_WIDTH } from 'src/constants/dimensions';

import { StyledContentProps, StyledContentWrapperProps } from './BankingLayout.interfaces';

export const StyledBankingLayout = styled(Stack)({
  minHeight: '100vh',
  overflowX: 'hidden'
});

export const StyledContentWrapper = styled('div', {
  shouldForwardProp: prop => prop !== 'actionBarExpanded' && prop !== 'menuBarExpanded'
})<StyledContentWrapperProps>(({ theme, actionBarExpanded, menuBarExpanded }) => ({
  padding: theme.spacing(6),
  marginLeft: menuBarExpanded ? MENU_BAR_WIDTH.expanded : MENU_BAR_WIDTH.hidden,
  width: `calc(100% - ${getBarsSumWidth(actionBarExpanded, menuBarExpanded)})`,
  [theme.breakpoints.down('md')]: {
    width: '100vw',
    marginLeft: 0,
    padding: theme.spacing(2)
  }
}));

export const StyledContent = styled('div', {
  shouldForwardProp: prop => prop !== 'smallerMargin' && prop !== 'disabled'
})<StyledContentProps>(({ theme, smallerMargin, disabled }) => ({
  marginTop: theme.spacing(smallerMargin ? 3 : 6),
  opacity: disabled ? 0.5 : 1,
  pointerEvents: disabled ? 'none' : 'all',
  [theme.breakpoints.down('md')]: {
    marginTop: theme.spacing(1)
  }
}));

export const StyledInfoBlocks = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(3)
}));

export const StyledInfoBoxFullWidth = styled(StyledInfoBox)({
  maxWidth: '100%'
});

export const StyledBankingLayoutMobileHeader = styled(Toolbar)(({ theme }) => ({
  height: '5vh',
  backgroundColor: theme.palette.custom.coralDusk[400]
}));

const getBarsSumWidth = (actionBarExpanded: boolean, menuBarExpanded: boolean) =>
  `${
    (actionBarExpanded ? parseInt(ACTION_BAR_WIDTH.expanded, 10) : parseInt(ACTION_BAR_WIDTH.hidden, 10)) +
    (menuBarExpanded ? parseInt(MENU_BAR_WIDTH.expanded, 10) : parseInt(MENU_BAR_WIDTH.hidden, 10))
  }px`;

export const StyledMenuIcon = styled(MenuIcon)(({ theme }) => ({
  color: theme.palette.primary.contrastText
}));
