import { styled } from '@mui/material';
import { Alert } from 'src/components/Alert/Alert';

export const StyledAlert = styled(Alert)(({ theme }) => ({
  marginBottom: theme.spacing(2)
}));

export const StyledFormWrapper = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(2)
}));

export const StyledActionButtons = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(5)
}));
