import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import DialogLayout from 'src/components/layouts/DialogLayout/DialogLayout';
import { urls } from 'src/routes';
import { isLoggedIn } from 'src/utils/authentication';

const NotFoundPage: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <DialogLayout
      headerImage={<img src={`${process.env.REACT_APP_ASSETS_URL}/images/not-found.png`} alt="Not found" />}
      heading={t('screens.pageNotFound.title')}
      subheading={t('screens.pageNotFound.subtitle')}
      secondaryBackAction
      secondaryActionText={t('screens.pageNotFound.backText')}
      onSecondaryActionClick={() => navigate(isLoggedIn() ? urls.balances : urls.signin)}
    />
  );
};

export default NotFoundPage;
