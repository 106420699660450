import { AxiosError } from 'axios';
import { useSnackbar } from 'notistack';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useVerifyInvitationToken } from 'src/api/queries/useLegalEntityMembers';
import InvitationLinkExpired from 'src/components/settings/companySettings/InvitationLinkExpired/InvitationLinkExpired';
import { IError } from 'src/interfaces/global';
import { urls } from 'src/routes';

export const VerifyInvitationPage: FC = () => {
  const { mutateAsync: verifyInvitationToken } = useVerifyInvitationToken();
  const { invitationToken } = useParams();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const [showExpired, setShowExpired] = useState(false);

  useEffect(() => {
    if (invitationToken) {
      verifyInvitationToken(invitationToken)
        .then(res => {
          navigate(res.data.existingUser ? urls.signin : urls.signup, {
            state: {
              email: res.data.email,
              invitationToken
            }
          });
        })
        .catch((err: AxiosError<IError>) => {
          enqueueSnackbar(err.response?.data.message ?? t('screens.error'), {
            variant: 'error'
          });
          setShowExpired(true);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invitationToken]);

  return showExpired ? <InvitationLinkExpired /> : null;
};
