import { styled, Typography } from '@mui/material';
import { StyledPasswordCriteriaProps } from 'src/hooks/usePassword/usePassword.interfaces';

export const StyledPasswordTooltip = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1.5)
}));

export const StyledPasswordCriteria = styled(Typography, {
  shouldForwardProp: prop => prop !== 'fulfilled'
})<StyledPasswordCriteriaProps>(({ theme, fulfilled }) => ({
  color: fulfilled ? theme.palette.custom.coralDusk[400] : theme.palette.text.primary
}));
