import { Grid, List, Stack, styled, Typography } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import MenuIcon from '@mui/icons-material/Menu';

import { StyledImage } from '../DialogLayout/DialogLayout.styles';

export const StyledWrapper = styled(Grid, {
  shouldForwardProp: prop => prop !== 'onboardingLayout'
})<{ onboardingLayout: boolean }>(({ theme, onboardingLayout }) => ({
  padding: onboardingLayout ? theme.spacing(1) : 0,
  backgroundColor: onboardingLayout ? theme.palette.custom.coralDusk[900] : theme.palette.common.white,
  minHeight: '100vh',
  overflowX: 'auto',
  paddingBottom: '10vh',
  alignContent: 'flex-start'
}));

export const StyledContent = styled('div', {
  shouldForwardProp: prop => prop !== 'hideRadius'
})<{ hideRadius: boolean }>(({ theme, hideRadius }) => ({
  backgroundColor: theme.palette.common.white,
  width: '100%',
  padding: theme.spacing(0, 3, 3),
  ...(!hideRadius && {
    boxShadow: theme.shadows[8],
    borderRadius: theme.shape.borderRadius
  })
}));

export const StyledSteps = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  marginBottom: theme.spacing(5)
}));

export const StyledArrowBackIcon = styled(ArrowBackIcon, {
  shouldForwardProp: prop => prop !== 'hideArrow'
})<{ hideArrow: boolean }>(({ theme, hideArrow }) => ({
  color: theme.palette.common.white,
  ...(hideArrow && {
    visibility: 'hidden'
  })
}));

export const StyledMobileHeader = styled(Stack, {
  shouldForwardProp: prop => prop !== 'onboardingLayout'
})<{ onboardingLayout: boolean }>(({ theme, onboardingLayout }) => ({
  marginTop: theme.spacing(3),
  marginInline: theme.spacing(3),
  width: '100%',
  justifyContent: 'space-between',
  ...(onboardingLayout && {
    color: theme.palette.common.white
  })
}));

export const StyledMenuIcon = styled(MenuIcon, {
  shouldForwardProp: prop => prop !== 'onboardingLayout'
})<{ onboardingLayout: boolean }>(({ theme, onboardingLayout }) => ({
  color: onboardingLayout ? theme.palette.common.white : theme.palette.custom.grey[300]
}));

export const StyledList = styled(List)(({ theme }) => ({
  backgroundColor: theme.palette.custom.coralDusk[900]
}));

export const StyledMobileImage = styled(StyledImage)(({ theme }) => ({
  marginTop: theme.spacing(3),
  marginBottom: theme.spacing(4)
}));

export const StyledText = styled(Typography)(({ theme }) => ({
  margin: theme.spacing(3, 0, 4, 0)
}));

export const StyledTextSubheading = styled(StyledText)(() => ({
  fontWeight: 400
}));
