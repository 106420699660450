import i18next from 'i18next';
import { FC } from 'react';
import { Grid, Typography, useMediaQuery } from '@mui/material';
import { DialogNavLinks } from 'src/components/DialogNavLinks/DialogNavLinks';
import { useTranslation } from 'react-i18next';
import {
  StyledContent,
  StyledContentWrapper,
  StyledLogo,
  StyledOnboardingImage,
  StyledStep,
  StyledSteps,
  StyledStepsWrapper,
  StyledWrapper
} from 'src/components/layouts/DialogLayout/DialogLayout.styles';
import { urls } from 'src/routes';
import { useNavigate } from 'react-router-dom';
import theme from 'src/constants/theme';
import ActionButtons from 'src/components/ActionButtons/ActionButtons';

import { StyledLastUpdated, StyledTitle, StyledTermsWrapper, StyledLink } from './TermsAndConditions.styles';

const DISCLAIMER = 'Disclaimers';
const PRIVACY_POLICY = 'Privacy Policy';
const TERMS_AND_CONDITIONS = 'Terms and conditions';

const DisclaimerSteps = [
  {
    name: DISCLAIMER,
    value: i18next.t('disclaimers.disclaimers'),
    href: () => urls.disclaimers
  },
  {
    name: PRIVACY_POLICY,
    value: i18next.t('disclaimers.privacyPolicy'),
    href: () => urls.privacyPolicy
  },
  {
    name: TERMS_AND_CONDITIONS,
    value: i18next.t('disclaimers.termsAndConditions'),
    href: () => urls.termsAndConditions
  }
];

export const TermsAndConditionsPage: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const isMobileView = useMediaQuery(theme.breakpoints.down('md'));

  const handleNavigate = <T,>(evt: React.MouseEvent<T, MouseEvent>, href: string) => {
    evt.preventDefault();
    navigate(href);
  };

  return (
    <StyledWrapper container isOnboardingLayout>
      <StyledLogo icon="logo" />
      <StyledOnboardingImage src={`${process.env.REACT_APP_ASSETS_URL}/images/onboarding.png`} alt="Onboarding image" />
      <>
        <Grid item xs={3} md={1} lg={2} />
        {!isMobileView && (
          <StyledStepsWrapper item xs={3} lg={2} isTypeformContent={false}>
            <StyledSteps>
              {DisclaimerSteps.map(step => (
                <StyledStep
                  isActive={TERMS_AND_CONDITIONS === step.name}
                  key={step.name}
                  variant="strongBody1"
                  href={step.href()}
                  onClick={evt => handleNavigate(evt, step.href())}
                >
                  {t(step.value)}
                </StyledStep>
              ))}
            </StyledSteps>

            <DialogNavLinks />
          </StyledStepsWrapper>
        )}
        <StyledContentWrapper item xs={12} sm={5} lg={6} centeredContentWrapper>
          <StyledContent
            contentPadding={5}
            contentMargin={0}
            withOnboardingSteps
            isOnboardingLayout
            centeredContent={false}
            anchorToParent
            isTypeformContent={false}
          >
            <StyledTitle variant="h3">Terms of Use</StyledTitle>
            <Typography variant="body1">
              This section contains the Terms of Use for 21.co and its affiliated service providers. It is important to review and understand these
              terms as they govern your use of our services. Please take a moment to familiarize yourself with the following Terms of Use to ensure a
              clear understanding of your rights and obligations while using our services.
            </Typography>
            <StyledLastUpdated variant="subtitle3">Last Updated: May 11, 2023</StyledLastUpdated>
            <StyledTermsWrapper>
              <Typography variant="subtitle3" component="p">
                21.co Terms of Use
              </Typography>
              <StyledLink href="https://linktr.ee/21.co">Download PDF</StyledLink>
              <Typography variant="subtitle3" component="p">
                CurrencyCloud Terms of use
              </Typography>
              <StyledLink href="https://linktr.ee/21.co">Link to the Terms of Use</StyledLink>
            </StyledTermsWrapper>
            <ActionButtons secondaryActionText={t('disclaimers.backText')} onSecondaryActionClick={() => navigate(urls.signin)} />
          </StyledContent>
        </StyledContentWrapper>
      </>
    </StyledWrapper>
  );
};
