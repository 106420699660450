import { alpha, Avatar, styled } from '@mui/material';

import { StyledAvatarProps, StyledProfileNavItemProps } from './ProfileNavItem.interfaces';

export const StyledProfileNavItem = styled('div', {
  shouldForwardProp: prop => prop !== 'hidden' && prop !== 'bordered'
})<StyledProfileNavItemProps>(({ theme, hidden, bordered }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: theme.spacing(1.5),
  cursor: 'pointer',
  ...(bordered &&
    !hidden && {
      border: `1px solid ${alpha(theme.palette.common.white, 0.15)}`
    }),
  borderRadius: theme.shape.borderRadius,
  color: theme.palette.common.white,
  marginBottom: hidden ? theme.spacing(0.5) : theme.spacing(1),
  padding: theme.spacing(hidden ? 1.5 : 1, 2),

  '.MuiTypography-root': {
    letterSpacing: '-0.01em',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    maxWidth: '116px',
    overflow: 'hidden'
  },
  '.MuiSvgIcon-root': {
    fontSize: '1rem',
    cursor: 'pointer',

    path: {
      fill: theme.palette.common.white
    }
  }
}));

export const StyledAvatar = styled(Avatar, {
  shouldForwardProp: prop => prop !== 'avatarColor'
})<StyledAvatarProps>(({ theme, avatarColor }) => ({
  width: '24px',
  height: '24px',
  backgroundColor: avatarColor ?? theme.palette.custom.coralDusk[400],
  fontSize: '15px'
}));

export const StyledProfileName = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(1.5)
}));
