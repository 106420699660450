import { Typography } from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { useSnackbar } from 'notistack';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { deleteBeneficiary } from 'src/api/banking';
import ActionButtons from 'src/components/ActionButtons/ActionButtons';
import Modal from 'src/components/Modal/Modal';
import { QueryKey } from 'src/constants/queryKeys';
import { IResponseError } from 'src/interfaces/responses';

import { CompanySettingsBeneficiaryRemoveModalProps } from '../CompanySettingsCreateBeneficiaryModal/CompanySettingsCreateBeneficiaryModal.interfaces';
import { StyledModalWrapper } from '../CompanySettingsCreateBeneficiaryModal/CompanySettingsCreateBeneficiaryModal.styles';

export const CompanySettingsRemoveBeneficiaryModal: FC<CompanySettingsBeneficiaryRemoveModalProps> = ({
  isOpen,
  onClose,
  beneficiary,
  legalEntity,
  ...restProps
}) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const entityName = legalEntity.name;
  const beneficiaryName = beneficiary?.companyName;
  const firstName = beneficiary?.firstName;
  const lastName = beneficiary?.lastName;
  const displayName = beneficiaryName ? beneficiaryName : `${firstName} ${lastName}`;

  const handleRemoveBeneficiary = () => {
    submitBeneficiaryRemoval.mutate();
  };

  const submitBeneficiaryRemoval = useMutation(() => deleteBeneficiary(beneficiary), {
    onSuccess: () => {
      onClose();
    },
    onError: (error: AxiosError<IResponseError>) => {
      const message = error?.response?.data?.message;
      enqueueSnackbar(message ?? t('screens.error'), { variant: 'error' });
    },
    onSettled: () => queryClient.invalidateQueries([QueryKey.QUERY_BENEFICIARIES])
  });

  return (
    <Modal onClose={onClose} isOpen={isOpen} title={t('companySettings.beneficiaries.removeBeneficiary')} {...restProps}>
      <StyledModalWrapper>
        <Typography variant="body1">
          {t('companySettings.beneficiaries.areYouSureToPermanentlyRemove1')} <strong>{displayName}</strong>{' '}
          {t('companySettings.beneficiaries.areYouSureToPermanentlyRemove2')} <strong>{entityName}</strong>{' '}
          {t('companySettings.beneficiaries.areYouSureToPermanentlyRemove3')}
        </Typography>

        <ActionButtons
          primaryActionText={t('common.remove')}
          onPrimaryActionClick={handleRemoveBeneficiary}
          secondaryActionText={t('common.cancel')}
          onSecondaryActionClick={onClose}
        />
      </StyledModalWrapper>
    </Modal>
  );
};
