import { Divider, styled, Typography } from '@mui/material';
import Button from 'src/components/Button/Button';
import SvgIcon from 'src/components/SvgIcon/SvgIcon';
import { ACTION_BAR_WIDTH } from 'src/constants/dimensions';

import { StyledActiveIndicatorProps } from './ActionTab.interfaces';

export const StyledActionTab = styled('div')(({ theme }) => ({
  boxShadow: theme.shadows[10],
  backgroundColor: theme.palette.common.white,
  width: ACTION_BAR_WIDTH.expanded,
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(4),
  [theme.breakpoints.down('md')]: {
    width: '100vw'
  }
}));

export const StyledHeader = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'space-between'
}));

export const StyledHeaderText = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(0.25)
}));

export const StyledDescription = styled(Typography)(({ theme }) => ({
  color: theme.palette.custom.grey[400]
}));

export const StyledCloseButton = styled(Button)(({ theme }) => ({
  alignSelf: 'flex-start',
  backgroundColor: theme.palette.common.white
}));

export const StyledCloseIcon = styled(SvgIcon)(() => ({
  fontSize: '12px'
}));

export const StyledDividerWrapper = styled('div')(({ theme }) => ({
  position: 'relative',
  margin: theme.spacing(4, -4, 0)
}));

export const StyledDivider = styled(Divider)(({ theme }) => ({
  borderColor: theme.palette.custom.grey[50],
  position: 'absolute',
  width: '100%'
}));

export const StyledActiveIndicator = styled(Divider, {
  shouldForwardProp: prop => prop !== 'activeStepIndex' && prop !== 'totalSteps'
})<StyledActiveIndicatorProps>(({ theme, activeStepIndex, totalSteps }) => ({
  width: `calc(360px / ${totalSteps} * ${activeStepIndex + 1})`,
  borderColor: theme.palette.custom.coralDusk[400],
  position: 'absolute'
}));

export const StyledContent = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(4)
}));

export const StyledButtons = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(2),
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1)
}));
