import { Typography } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import ActionButtons from 'src/components/ActionButtons/ActionButtons';
import Modal from 'src/components/Modal/Modal';
import { DeleteInviteModalProps } from 'src/components/modals/settings/companySettings/DeleteInviteModal/DeleteInviteModal.interfaces';
import { StyledModalWrapper } from 'src/components/modals/styles';

const DeleteInviteModal: FC<DeleteInviteModalProps> = ({ isOpen, member, entityInUse, onInvitationRemove, onClose, ...restProps }) => {
  const { t } = useTranslation();

  return (
    <Modal onClose={onClose} isOpen={isOpen} title={t('companySettings.members.deleteInvite')} {...restProps}>
      <StyledModalWrapper>
        {/* TODO: Replace with content from API */}
        <Typography variant="body1" letterSpacing="-0.01em">
          {t('companySettings.members.areYouSure1')}{' '}
          <Typography variant="button">
            {member?.firstName} {member?.lastName}
          </Typography>{' '}
          {t('companySettings.members.areYouSure2')} <Typography variant="button">{entityInUse?.name ?? 'Company'}</Typography>
          {t('companySettings.members.areYouSure3')}
        </Typography>

        <ActionButtons
          primaryActionText={t('common.delete')}
          onPrimaryActionClick={onInvitationRemove}
          secondaryActionText={t('common.cancel')}
          onSecondaryActionClick={onClose}
        />
      </StyledModalWrapper>
    </Modal>
  );
};

export default DeleteInviteModal;
