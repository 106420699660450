import { Typography } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import ActionButtons from 'src/components/ActionButtons/ActionButtons';
import Modal from 'src/components/Modal/Modal';
import { RemoveMemberModalProps } from 'src/components/modals/settings/companySettings/RemoveMemberModal/RemoveMemberModal.interfaces';
import { StyledModalWrapper } from 'src/components/modals/styles';

const RemoveMemberModal: FC<RemoveMemberModalProps> = ({ isOpen, onRemove, onClose, member, entity, ...restProps }) => {
  const { t } = useTranslation();

  return (
    <Modal onClose={onClose} isOpen={isOpen} title={t('companySettings.members.removeMember')} {...restProps}>
      <StyledModalWrapper>
        <Typography variant="body1" letterSpacing="-0.01em">
          {t('companySettings.members.removeMemberMessage1')}
          <Typography variant="button">
            {member?.firstName} {member?.lastName}
          </Typography>{' '}
          {t('companySettings.members.removeMemberMessage2')}
          <Typography variant="button">{entity?.name ?? 'Company'}</Typography>
          {t('companySettings.members.removeMemberMessage3')}
        </Typography>

        <ActionButtons
          primaryActionText={t('common.remove')}
          onPrimaryActionClick={onRemove}
          secondaryActionText={t('common.cancel')}
          onSecondaryActionClick={onClose}
        />
      </StyledModalWrapper>
    </Modal>
  );
};

export default RemoveMemberModal;
