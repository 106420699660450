import { IChange } from 'src/interfaces/forms';
import { GeocoderAddress, GeocoderPlaceResult, GooglePlacePredictions, FormAddressFieldsNames } from 'src/interfaces/googleApis';

const postalAddressMatchingTypes = {
  street: {
    matchingType: 'route'
  },
  address: {
    matchingType: 'address'
  },
  streetNumber: {
    matchingType: 'street_number'
  },
  apartment: {
    matchingType: 'subpremise'
  },
  city: {
    matchingType: 'locality'
  },
  country: {
    matchingType: 'country'
  },
  postalCode: {
    matchingType: 'postal_code'
  },
  state: {
    matchingType: 'administrative_area_level_1'
  }
};

const getPostalAddressMatchingByTypeResults = (result: google.maps.GeocoderResult, matchingType: string): GeocoderAddress => {
  const addressComponents = result?.address_components;
  return addressComponents.find(item => item.types.includes(matchingType));
};

export const getPlaceResults = (geocoder: google.maps.Geocoder, placeDescription: string, country = ''): Promise<GeocoderPlaceResult> => {
  const componentRestrictions = country.length === 2 ? { country } : {};
  return geocoder
    .geocode({
      address: placeDescription,
      componentRestrictions
    })
    .catch(reason => console.warn(reason));
};

export const getAutocompletePredictions = (
  googleAutocopleteService: google.maps.places.AutocompleteService,
  text: string,
  country?: string
): Promise<GooglePlacePredictions> =>
  googleAutocopleteService
    .getPlacePredictions({
      input: text,
      componentRestrictions: country ? { country } : undefined,
      types: [postalAddressMatchingTypes.street.matchingType]
    })
    .catch(reason => console.warn(reason));

export const mapAddressFields = (placeResult: google.maps.GeocoderResult): IChange[] => {
  const changes: IChange[] = [
    {
      name: FormAddressFieldsNames.Street,
      value: getPostalAddressMatchingByTypeResults(placeResult, postalAddressMatchingTypes.street.matchingType)?.long_name ?? ''
    },
    {
      name: FormAddressFieldsNames.StreetNumber,
      value: getPostalAddressMatchingByTypeResults(placeResult, postalAddressMatchingTypes.streetNumber.matchingType)?.long_name ?? ''
    },
    {
      name: FormAddressFieldsNames.AdditionalDetails,
      value: getPostalAddressMatchingByTypeResults(placeResult, postalAddressMatchingTypes.apartment.matchingType)?.long_name ?? ''
    },
    {
      name: FormAddressFieldsNames.City,
      value: getPostalAddressMatchingByTypeResults(placeResult, postalAddressMatchingTypes.city.matchingType)?.long_name ?? ''
    },
    {
      name: FormAddressFieldsNames.PostalCode,
      value: getPostalAddressMatchingByTypeResults(placeResult, postalAddressMatchingTypes.postalCode.matchingType)?.long_name ?? ''
    },
    {
      name: FormAddressFieldsNames.State,
      value: getPostalAddressMatchingByTypeResults(placeResult, postalAddressMatchingTypes.state.matchingType)?.long_name ?? ''
    }
  ];

  return changes;
};

export const hasStreetAddress = (changes: IChange[]) =>
  !!changes.find(changeItem => changeItem.name === FormAddressFieldsNames.Street && changeItem.value);
