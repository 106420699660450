import { TransactionType } from 'src/interfaces/banking';
import { IOption } from 'src/interfaces/forms';

import { ActionTabDefaults } from '../ActionTab/ActionTab.interfaces';

export interface OptionInterface extends IOption {
  imageUrl?: string;
  value: string;
  label: string;
  key: string;
}

export const withdrawActionTabDefaultValues: ActionTabDefaults = {
  titleKey: 'banking.actions.withdraw.title',
  descriptionKey: 'banking.actions.withdraw.description',
  type: TransactionType.Withdraw
};
