import { FC, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { IFormHandleSubmit } from 'src/interfaces/forms';
import SignInLayout from 'src/components/layouts/SignInLayout/SignInLayout';
import Form from 'src/components/Form/Form';
import { authLoginSchemaUrl } from 'src/api/formSchemas';
import Input from 'src/components/Input/Input';
import ActionButtons from 'src/components/ActionButtons/ActionButtons';
import { useLocation, useNavigate } from 'react-router-dom';
import { authLogin } from 'src/api/auth';
import { urls } from 'src/routes';
import { StorageKey } from 'src/constants/storage';
import Checkbox from 'src/components/Checkbox/Checkbox';
import { InvitationTokenContext } from 'src/contexts/InvitationToken.contexts';

import { StyledAdditionalInfo, StyledLink, StyledSwitch, StyledHighlightSignUp } from './SignInPage.styles';

const SignInPage: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { state } = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState<boolean>(false);
  const [rememberMe, setRememberMe] = useState<boolean>(false);
  const { setInvitationToken } = useContext(InvitationTokenContext) ?? {};

  const { email: invitationEmail, invitationToken } = state || {};

  const handleSubmit: IFormHandleSubmit = async (values, validateForm, handleError) => {
    if (validateForm(values)) {
      setLoading(true);
      authLogin(values)
        ?.then(response => {
          sessionStorage.setItem(StorageKey.WEAK_AUTH_TOKEN, response.data.weakToken);

          invitationToken && setInvitationToken?.(invitationToken);

          if (response.data._actions['2fa']) {
            navigate(urls.authentication);
          } else if (response.data._actions.request2fa) {
            navigate(urls.enable2fa);
          }
        })
        .catch(error => {
          try {
            const { message } = error.response.data;
            if (error.response.status === 401) {
              handleError('password', t('screens.signIn.error'));
            } else {
              enqueueSnackbar(message, { variant: 'error' });
            }
          } catch {}
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  return (
    <SignInLayout loading={loading} title={t('screens.signIn.title')}>
      <Form schemaUrl={authLoginSchemaUrl} initialValues={invitationEmail ? { email: invitationEmail } : undefined} onSubmit={handleSubmit}>
        <Input name="email" type="email" disabled={!!invitationEmail} fullWidth />
        <Input name="password" type="password" fullWidth />

        <StyledAdditionalInfo>
          <Checkbox
            name="rememberMe"
            label={t('common.rememberMe')}
            value={rememberMe}
            handleOnChange={(_, value) => setRememberMe(value === true)}
          />
          <StyledLink variant="button2" onClick={() => navigate(urls.forgotPassword)}>
            {t('common.forgotPassword')}
          </StyledLink>
        </StyledAdditionalInfo>

        <ActionButtons primaryActionText={t('common.signIn')} />

        <StyledSwitch variant="strongBody1" onClick={() => navigate(urls.signup)}>
          {t('common.dontHaveAccount')} <StyledHighlightSignUp variant="button">{t('common.signUp')}</StyledHighlightSignUp>
        </StyledSwitch>
      </Form>
    </SignInLayout>
  );
};

export default SignInPage;
