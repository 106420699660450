import { BarToggleStates } from 'src/interfaces/styles';

export const ACTION_BAR_WIDTH: BarToggleStates = {
  hidden: '92px',
  expanded: '360px'
};

export const MENU_BAR_WIDTH: BarToggleStates = {
  hidden: '88px',
  expanded: '244px'
};
