import { Typography } from '@mui/material';
import React, { FC } from 'react';
import { getCurrencyImage } from 'src/utils/styles';

import { AccountsTabListItemProps } from './AccountsTabListItem.interfaces';
import { StyledTabListItem, StyledTabListFlag, StyledTabListCurrencyInfo } from './AccountsTabListItem.styles';
const AccountsTabListItem: FC<AccountsTabListItemProps> = ({ account, onClick }) => (
  <StyledTabListItem onClick={() => onClick(account)}>
    <StyledTabListCurrencyInfo>
      <StyledTabListFlag src={getCurrencyImage(account.currency)} alt={`${account.currency} flag`} />
      <div>
        <Typography variant="strongBody2">{account.currencyLabel}</Typography>
        <Typography variant="body3">{account.currency}</Typography>
      </div>
    </StyledTabListCurrencyInfo>
    <Typography variant="strongBody3">
      {account.balance} {account.currency}
    </Typography>
  </StyledTabListItem>
);

export default AccountsTabListItem;
