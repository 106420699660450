import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ActionTab from 'src/components/banking/actions/ActionTab/ActionTab';
import SvgIcon from 'src/components/SvgIcon/SvgIcon';
import { IconKeys } from 'src/components/SvgIcon/SvgIcon.interfaces';
import { ActionBarContext } from 'src/contexts/ActionBar.contexts';

import { WithdrawMethodSelectionScreens } from '../Withdraw.interfaces';
import { withdrawActionTabDefaultValues } from '../Withdraw.utils';
import { WithdrawBulkWire } from '../WithdrawBulkWire/WithdrawBulkWire';
import { WithdrawCrypto } from '../WithdrawCrypto/WithdrawCrypto';
import { WithdrawSelectBenficiary } from '../WithdrawSelectBenficiary/WithdrawSelectBenficiary';

import { StyledButton, TextWrapper, ButtonTitle, StyledWithdrawMethodLabel, StyledButtonLabel } from './WithdrawMethodSelection.styles';

const WithdrawMethodSelection = () => {
  const actionBarContext = useContext(ActionBarContext);
  const { t } = useTranslation();
  const [nextScreen, setNextScreen] = useState<WithdrawMethodSelectionScreens>();

  const getWithdrawTypeButton = (screenKey: WithdrawMethodSelectionScreens) => {
    let buttonKey = screenKey as string;
    if (screenKey === 'withdrawSelectBeneficiary') {
      buttonKey = 'singleWire';
    }
    return (
      <StyledButton direction="row" onClick={() => handleOnClick(screenKey)}>
        <SvgIcon icon={buttonKey as IconKeys} />
        <TextWrapper>
          <ButtonTitle variant="strongBody1">{t(`banking.actions.withdraw.${buttonKey}`)}</ButtonTitle>
          <StyledButtonLabel variant="body3">{t(`banking.actions.withdraw.${buttonKey}Desc`)}</StyledButtonLabel>
        </TextWrapper>
      </StyledButton>
    );
  };
  const handleOnClick = (type: WithdrawMethodSelectionScreens) => setNextScreen(type);

  const flowScreens = {
    withdrawSelectBeneficiary: <WithdrawSelectBenficiary />,
    bulkWire: <WithdrawBulkWire />,
    cryptoWire: <WithdrawCrypto />
  };

  return nextScreen ? (
    flowScreens[nextScreen]
  ) : actionBarContext ? (
    <ActionTab onGoBack={actionBarContext.returnToActionBar} onClose={actionBarContext.closeActionTab} {...withdrawActionTabDefaultValues}>
      <StyledWithdrawMethodLabel variant="subtitle3">{t('banking.actions.withdraw.selectWithdrawMethod')}</StyledWithdrawMethodLabel>

      {getWithdrawTypeButton('withdrawSelectBeneficiary')}
      {/* TODO: Re-add if implemented {getWithdrawTypeButton('bulkWire')} */}
      {/* TODO: Re-add if implemented {getWithdrawTypeButton('cryptoWire')} */}
    </ActionTab>
  ) : null;
};
export default WithdrawMethodSelection;
