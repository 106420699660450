import { useInfiniteQuery } from '@tanstack/react-query';
import { useEffect } from 'react';
import { DEFAULT_COUNT_LIMIT } from 'src/constants/pagination';
import { PaginationResponses } from 'src/interfaces/pagination';

export const usePagination = (
  uri: string,
  loadMore: boolean,
  queryKey: string,
  fetchReq: (uri: string, count: number, offset?: number) => PaginationResponses,
  count = DEFAULT_COUNT_LIMIT
) => {
  const {
    data: paginationData,
    isLoading: isLoadingPaginationData,
    fetchNextPage,
    isError: isPaginationError
  } = useInfiniteQuery([queryKey, uri], {
    queryFn: async res => {
      const offset = res.pageParam ?? 0;
      const data = await fetchReq(uri, count, offset);
      const nextPageValue = data?.data.length ? offset + count : null;

      return { response: data?.data ?? [], nextPage: nextPageValue };
    },
    getNextPageParam: lastPage => lastPage?.nextPage ?? undefined
  });

  useEffect(() => {
    if (loadMore) {
      fetchNextPage();
    }
  }, [loadMore, paginationData, fetchNextPage]);

  return { paginationData, isLoadingPaginationData, isPaginationError };
};
