import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import SettingsLayout from 'src/components/layouts/SettingsLayout/SettingsLayout';

import { COMPANY_SETTINGS_SUBPAGES } from './CompanySettingsPage.constants';

const CompanySettingsPage: FC = () => {
  const { t } = useTranslation();

  return (
    <SettingsLayout
      navigationTitle={t('banking.navigation.companySettings')}
      logoutVisible={false}
      sidebarTitle={t('companySettings.common.sidebarTitle')}
      subpages={COMPANY_SETTINGS_SUBPAGES}
    />
  );
};

export default CompanySettingsPage;
