import { styled, Typography } from '@mui/material';

import { StyledAccountTileProps } from './AccountsTabGridItem.interfaces';

export const StyledAccountTile = styled('li', {
  shouldForwardProp: prop => prop !== 'withExchangedBalance'
})<StyledAccountTileProps>(({ theme, withExchangedBalance, disabled }) => ({
  border: `1px solid ${theme.palette.custom.grey[100]}`,
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(3),
  minWidth: 272,
  minHeight: 152,
  display: 'flex',
  cursor: 'pointer',
  flexDirection: 'column',
  opacity: disabled ? 0.5 : 1,
  pointerEvents: disabled ? 'none' : 'all',
  justifyContent: 'space-between',
  backgroundColor: theme.palette.common.white,
  gap: withExchangedBalance ? '21px' : theme.spacing(5),
  '&:hover': {
    borderColor: theme.palette.custom.coralDusk[400]
  },
  [theme.breakpoints.down('md')]: {
    gap: '10px'
  }
}));

export const StyledAccountTileTopPart = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  gap: theme.spacing(2),

  '.MuiSvgIcon-root': {
    fontSize: '1rem',
    cursor: 'pointer'
  }
}));

export const StyledAccountflagTitleWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(2)
}));

export const StyledAccountFlag = styled('img')({
  width: '40px',
  height: '40px'
});

export const StyledAccountNameAndCurrency = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  minWidth: '128px'
});

export const StyledAccountCurrencyName = styled(Typography)(({ theme }) => ({
  color: theme.palette.custom.grey.offBlack,
  letterSpacing: '-0.01em'
}));

export const StyledAccountName = styled(Typography)(({ theme }) => ({
  color: theme.palette.custom.grey[400]
}));

export const StyledAccountTileBottomPart = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(0.5)
}));

export const StyledAccountBalance = styled(Typography)(({ theme }) => ({
  fontSize: '24px',
  lineHeight: '24px',
  letterSpacing: '-0.01em',
  fontWeight: 600,
  color: theme.palette.custom.grey.offBlack,
  [theme.breakpoints.down('md')]: {
    fontSize: 16
  }
}));

export const StyledAccountCurrency = styled(Typography)(({ theme }) => ({
  color: theme.palette.custom.grey[300]
}));

export const StyledAccountBalanceExchanged = styled(Typography)(({ theme }) => ({
  color: theme.palette.custom.grey[300],
  [theme.breakpoints.down('md')]: {
    fontSize: 12
  }
}));
