import { FC, useEffect, useRef, useState } from 'react';
import { useSnackbar } from 'notistack';
import { IFormHandleSubmit } from 'src/interfaces/forms';
import { resetPasswordSchemaUrl } from 'src/api/formSchemas';
import Form from 'src/components/Form/Form';
import ActionButtons from 'src/components/ActionButtons/ActionButtons';
import DialogLayout from 'src/components/layouts/DialogLayout/DialogLayout';
import SvgIcon from 'src/components/SvgIcon/SvgIcon';
import StatusIcon from 'src/components/StatusIcon/StatusIcon';
import { useNavigate, useParams } from 'react-router-dom';
import { resetPassword, validateResetToken } from 'src/api/auth';
import { useTranslation } from 'react-i18next';
import { ERROR_INVALID_TOKEN } from 'src/constants/errors';
import { urls } from 'src/routes';
import { usePassword } from 'src/hooks/usePassword/usePassword';

const ResetPasswordPage: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const params = useParams();

  const { enqueueSnackbar } = useSnackbar();
  const { renderPasswordInput, renderPasswordConfirmationInput, password, checkForPasswordConfirmationErrors } = usePassword();

  const tokenRef = useRef<string>();
  const [token, setToken] = useState<string>(params?.token ?? '');
  const [success, setSuccess] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [contentLoading, setContentLoading] = useState<boolean>(!!token);

  useEffect(() => {
    if (token && token !== tokenRef.current) {
      tokenRef.current = token;

      validateResetToken(token)
        ?.catch(_ => {
          setToken('');
        })
        .finally(() => {
          setContentLoading(false);
        });
    }
  }, [token]);

  const handleSubmit: IFormHandleSubmit = async (_, validateForm) => {
    let valid = true;

    if (checkForPasswordConfirmationErrors()) {
      valid = false;
    }

    if (validateForm({ password }) && valid) {
      setLoading(true);

      resetPassword(token, password as string)
        ?.then(_ => {
          setSuccess(true);
        })
        .catch(error => {
          try {
            const { code, message } = error.response.data;
            if (code === ERROR_INVALID_TOKEN) {
              setToken('');
            } else {
              enqueueSnackbar(message, { variant: 'error' });
            }
          } catch {}
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  if (contentLoading) {
    return <DialogLayout contentLoading />;
  }
  if (success) {
    return (
      <DialogLayout
        headerImage={<StatusIcon icon={<SvgIcon icon="check" />} />}
        heading={t('screens.resetPassword.success.title')}
        subheading={t('screens.resetPassword.success.subtitle')}
        primaryActionText={t('common.continue')}
        onPrimaryActionClick={() => navigate(urls.signin)}
      />
    );
  } else if (!token) {
    return (
      <DialogLayout
        headerImage={<StatusIcon error icon={<SvgIcon icon="warning" />} />}
        heading={t('screens.resetPassword.invalidToken.title')}
        subheading={t('screens.resetPassword.invalidToken.subtitle')}
        secondaryBackAction
        secondaryActionText={t('screens.resetPassword.invalidToken.backText')}
        onSecondaryActionClick={() => navigate(urls.signin)}
      />
    );
  }

  return (
    <DialogLayout
      headerImage={<StatusIcon icon={<SvgIcon icon="key" />} />}
      loading={loading}
      heading={t('screens.resetPassword.title')}
      subheading={t('screens.resetPassword.subtitle')}
    >
      <Form schemaUrl={resetPasswordSchemaUrl} onSubmit={handleSubmit}>
        {renderPasswordInput()}
        {renderPasswordConfirmationInput(true)}
        <ActionButtons
          primaryActionText={t('common.changePassword')}
          secondaryBackAction
          secondaryActionText={t('screens.resetPassword.backText')}
          onSecondaryActionClick={() => navigate(urls.signin)}
        />
      </Form>
    </DialogLayout>
  );
};

export default ResetPasswordPage;
