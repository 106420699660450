import { Account, CurrencyType, Beneficiary } from 'src/interfaces/banking';
import { getCurrencyImage } from 'src/utils/styles';

import { StepOneData } from './CompanySettingsCreateBeneficiaryModal.interfaces';

export const getStepOneData = (beneficiary?: Beneficiary, accountsData?: Account[]): StepOneData => {
  const accountUri = beneficiary?._links?.account.uri ?? '';
  const beneficiaryAccount = accountsData?.find(acc => acc._links.accounts?.uri === accountUri);
  return {
    type: beneficiaryAccount?.type ?? CurrencyType.Fiat,
    network: beneficiary?.network ?? '',
    accountUri: beneficiary?._links?.account.uri ?? '',
    targetCurrency: beneficiary?.targetCurrency ?? '',
    country: beneficiary?.bank?.country ?? ''
  };
};

export const mapAccountsToSelectOptions = (accounts: Account[]) =>
  accounts.map(account => ({
    imageUrl: getCurrencyImage(account?.currency ?? ''),
    value: account.currencyLabel,
    label: `${account.currency}`,
    key: String(account._links.self.uri)
  })) ?? [];

export const BENEFICIARY_FORM_TYPE = {
  BUSINESS: 'business',
  INDIVIDUAL: 'individual'
};
