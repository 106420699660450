import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { IGetTransactionResponse } from 'src/interfaces/responses';
import { QueryKey } from 'src/constants/queryKeys';

import { getTransaction } from '../banking';

export const useTransactionQuery = (uri: string): UseQueryResult<AxiosResponse<IGetTransactionResponse, AxiosError> | undefined> =>
  useQuery([QueryKey.QUERY_TRANSACTION, uri], () => getTransaction(uri), {
    enabled: !!uri
  });
