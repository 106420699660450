import { styled, Avatar, Typography, Grid } from '@mui/material';
import Button from 'src/components/Button/Button';
import Input from 'src/components/Input/Input';

export const StyledTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
  marginBottom: theme.spacing(3)
}));

export const StyledAvatar = styled(Avatar, {
  shouldForwardProp: prop => prop !== 'avatarColor'
})<{ avatarColor: string }>(({ theme, avatarColor }) => ({
  width: 32,
  height: 32,
  backgroundColor: avatarColor ?? theme.palette.custom.coralDusk[400],
  fontSize: 15,
  color: theme.palette.custom.coralDusk[400],
  marginRight: theme.spacing(1.75)
}));

export const StyledRow = styled(Grid)(({ theme }) => ({
  paddingBottom: theme.spacing(4),
  marginBottom: theme.spacing(4),
  borderBottom: `solid 1px ${theme.palette.custom.grey[50]}`
}));

export const StyledLeftItem = styled(Grid)({
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  flexWrap: 'wrap',

  '& > div': {
    marginBottom: 0,
    width: '100%'
  }
});

export const StyledRightItem = styled(Grid)({
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'flex-start',
  flexWrap: 'wrap',

  '& > div': {
    marginBottom: 0,
    width: '100%'
  }
});

export const StyledRowTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
  marginBottom: theme.spacing(0.25),
  display: 'block',
  width: '100%'
}));

export const StyledRowSubtitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.custom.grey[400],
  display: 'block',
  width: '100%'
}));

export const StyledInput = styled(Input)({
  '.MuiInputBase-root': {
    height: 'auto'
  }
});

export const StyledButton = styled(Button)({
  '.MuiInputBase-root': {
    height: 'auto'
  }
});

export const StyledActivityWrapper = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(3.5)
}));

export const StyledActivityRow = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  marginLeft: theme.spacing(1),
  marginBottom: theme.spacing(2)
}));

export const StyledActivityTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.custom.grey[400],
  marginBottom: theme.spacing(1.5)
}));

export const StyledActivityRowDataTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary
}));

export const StyledActivityRowDataSubtitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.custom.grey[300]
}));

export const StyledModalSubtitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.custom.grey[600],
  marginBottom: theme.spacing(2.5)
}));
