import { Link, styled } from '@mui/material';

export const StyledWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  position: 'absolute',
  left: theme.spacing(6),
  bottom: theme.spacing(6),
  width: `calc(100% - ${theme.spacing(12)})`
}));

export const StyledReferenceIconWrapper = styled('div')({
  '& .MuiSvgIcon-root': {
    fontSize: '1rem'
  }
});

export const StyledLink = styled(Link)(({ theme }) => ({
  color: theme.palette.custom.grey[300],
  textDecoration: 'none',
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
  fontWeight: 500,
  fontSize: '14px',
  lineHeight: '20px'
}));
