import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { IRequestPaymentResponse } from 'src/interfaces/responses';
import { QueryKey } from 'src/constants/queryKeys';

import { requestPayment } from '../banking';

export const usePaymentRequestQuery = (
  sourceAccountId: string,
  amount?: string,
  bankCountry?: string,
  network?: string
): UseQueryResult<AxiosResponse<IRequestPaymentResponse, AxiosError> | undefined> =>
  useQuery([QueryKey.QUERY_PAYMENT_REQUEST, sourceAccountId, amount], () => requestPayment(sourceAccountId, amount, bankCountry, network), {
    enabled: !!sourceAccountId
  });
