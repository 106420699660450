import { Typography } from '@mui/material';
import { FC, PropsWithChildren } from 'react';
import SvgIcon from 'src/components/SvgIcon/SvgIcon';

import { ModalLayoutProps } from './ModalLayout.interfaces';
import {
  StyledContent,
  StyledHeader,
  StyledHeaderText,
  StyledSubtitle,
  StyledCloseButton,
  StyledHeaderGroup,
  StyledActions
} from './ModalLayout.styles';

const ModalLayout: FC<PropsWithChildren<ModalLayoutProps>> = ({ icon, title, subtitle, children, actions, onClose }) => (
  <>
    <StyledHeader>
      <StyledHeaderGroup>
        {icon}
        <StyledHeaderText>
          <Typography variant="strongBody3">{title}</Typography>
          <StyledSubtitle variant="body3">{subtitle}</StyledSubtitle>
        </StyledHeaderText>
      </StyledHeaderGroup>
      {onClose && (
        <StyledCloseButton onClick={onClose}>
          <SvgIcon icon="close" />
        </StyledCloseButton>
      )}
    </StyledHeader>
    <StyledContent>{children}</StyledContent>
    {actions && <StyledActions>{actions}</StyledActions>}
  </>
);

export default ModalLayout;
