import { ChangeEvent, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ActionTab from 'src/components/banking/actions/ActionTab/ActionTab';
import { ActionTabStep } from 'src/constants/actions';
import { Account, CurrencyType, TransactionType } from 'src/interfaces/banking';
import { Typography } from '@mui/material';
import { formatAmount } from 'src/utils/localization';
import { useAccountsQuery } from 'src/api/queries/useAccounts';
import { useSwapQuotesQuery } from 'src/api/queries/useSwapQuotes';
import AccountMenu from 'src/components/banking/AccountMenu/AccountMenu';
import { getFormSchema } from 'src/api/formSchemas';
import { additionalUrls } from 'src/api/additionalUrls';
import { getIdFromUri } from 'src/utils/strings';
import { ActionBarContext } from 'src/contexts/ActionBar.contexts';
import { SwapContext } from 'src/contexts/actions/Swap.contexts';
import { AccountAndAmount } from 'src/components/banking/AccountAndAmount/AccountAndAmount';
import { useLocation } from 'react-router-dom';
import { getDefaultAccount } from 'src/utils/accounts';
import { Alert } from 'src/components/Alert/Alert';
import { limitDecimals } from 'src/utils/formatting';
import Tooltip from 'src/components/Tooltip/Tooltip';
import { useAmountInput } from 'src/hooks/useAmountInput';

import SwapDetails from '../SwapDetails/SwapDetails';
import { swapActionTabDefaultValues } from '../Swap.utils';

import {
  StyledBalance,
  StyledLabel,
  StyledEstimatedRate,
  StyledRate,
  StyledTo,
  StyledButton,
  StyledIcon,
  StyledSwitch,
  StyledInput,
  StyledInfoIcon,
  StyledRateLabel,
  StyledAlert
} from './SwapSelection.styles';
import { AlertType } from './SwapSelection.interfaces';

const SwapSelection = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const context = useContext(SwapContext);
  const actionBarContext = useContext(ActionBarContext);

  const accountUri = (location.state as Account)?._links?.self?.uri;

  const { data: swapQuotesData, isError: isQuotesError } = useSwapQuotesQuery();
  const { data: accountsData, isLoading: isAccountsLoading } = useAccountsQuery();

  const [rate, setRate] = useState<string | undefined>(context?.rate);
  const [amount, setAmount] = useState<string | undefined>((!context?.targetAmount ? context?.amount : '0') ?? '0');

  const [sourceAccount, setSourceAccount] = useState<Account | undefined>(context?.sourceAccount);
  const [targetAccount, setTargetAccount] = useState<Account | undefined>(context?.targetAccount);
  const calculatedTargetAmount = useMemo(
    () => (rate && amount ? (Number(rate) * Number(amount)).toFixed(targetAccount?.type === CurrencyType.Crypto ? 5 : 2) : 0),
    [rate, targetAccount, amount]
  );
  const [targetAmount, setTargetAmount] = useState<string | undefined>(context?.targetAmount ?? calculatedTargetAmount.toString());

  const [accounts, setAccounts] = useState<Account[]>([]);
  const [alert, setAlert] = useState<AlertType | undefined>();
  const [targetAccountIds, setTargetAccountIds] = useState<string[]>([]);
  const [step, setStep] = useState<ActionTabStep | undefined>(undefined);

  const { clearPrefilledAmountInput, prefillAmountInput } = useAmountInput(targetAmount, setTargetAmount);

  const calcAmount = useMemo(
    () => (amountToCalc: string) => {
      const newAmount = Number(amountToCalc) / Number(rate);
      return Math.abs(newAmount - Number(amount)) < 0.01 ? amount : newAmount.toFixed(2);
    },
    [rate, amount]
  );

  useEffect(() => {
    if (swapQuotesData?.data) {
      const rate = swapQuotesData.data[`${sourceAccount?.currency}${targetAccount?.currency}`];
      if (rate) {
        setRate(rate);
      }
    }
  }, [swapQuotesData?.data, sourceAccount?.currency, targetAccount?.currency]);

  useEffect(() => {
    if (!isAccountsLoading && accountsData?.data) {
      const accounts = accountsData.data.filter(a => a._actions?.swap);
      setAccounts(accounts);
      if (!sourceAccount) {
        setSourceAccount(getDefaultAccount(accounts, accountUri));
      }
    }
  }, [accountsData?.data, isAccountsLoading, accountUri, sourceAccount]);

  useEffect(() => {
    if (targetAmount !== undefined && Number(targetAmount) !== 0) {
      setAmount(calcAmount(targetAmount));
    }

    if (rate && amount !== undefined && Number(amount) !== 0) {
      setTargetAmount(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rate]);

  useEffect(() => {
    if (sourceAccount?._links?.self?.uri) {
      getFormSchema(`${sourceAccount._links.self.uri}${additionalUrls.swapOrder}`)?.then(schema =>
        setTargetAccountIds(schema.data.properties.targetAccountId.enum)
      );
    }
  }, [sourceAccount]);

  const invalidAmount = sourceAccount?.type === CurrencyType.Fiat && Number(amount) < 1;
  const invalidTargetAmount = targetAccount?.type === CurrencyType.Fiat && Number(targetAmount || calculatedTargetAmount) < 1;
  const insufficientBalance = !!(sourceAccount?.balance && Number(amount) > Number(sourceAccount.balance));

  const handleSubmit = () => {
    if (!targetAccount) {
      return setAlert('missingBalance');
    } else if (!rate) {
      return setAlert('missingRate');
    } else if (invalidAmount || invalidTargetAmount) {
      return setAlert('minAmount');
    } else if (insufficientBalance) {
      return setAlert('insufficientBalance');
    }
    setStep(ActionTabStep.Next);
  };

  if (actionBarContext && isQuotesError) {
    return (
      <ActionTab
        {...swapActionTabDefaultValues}
        onGoBack={actionBarContext.returnToActionBar}
        onClose={actionBarContext.closeActionTab}
        activeStepIndex={0}
      >
        <Alert>{t('banking.actions.swap.error')}</Alert>
      </ActionTab>
    );
  }

  return (
    <SwapContext.Provider
      value={{
        rate,
        setRate,
        amount,
        setAmount,
        targetAmount,
        setTargetAmount,
        targetAccount,
        setTargetAccount,
        sourceAccount,
        setSourceAccount
      }}
    >
      {step === ActionTabStep.Next ? (
        <SwapDetails />
      ) : actionBarContext ? (
        <ActionTab
          {...swapActionTabDefaultValues}
          loading={isAccountsLoading}
          primaryActionTextKey="common.continue"
          onPrimaryActionClick={handleSubmit}
          onGoBack={actionBarContext.returnToActionBar}
          onClose={actionBarContext.closeActionTab}
          activeStepIndex={0}
        >
          {!!alert && <StyledAlert alertVariant="error">{t(`banking.actions.swap.${alert}`)}</StyledAlert>}

          <AccountAndAmount
            accounts={accounts?.filter(a => a._links.self.uri !== targetAccount?._links.self.uri)}
            amount={amount}
            setAmount={amount => {
              setAmount(limitDecimals(amount, sourceAccount?.type === CurrencyType.Crypto ? 5 : 2));
              setTargetAmount(amount !== '' && Number(amount) !== 0 && targetAccount ? undefined : '0');
            }}
            account={sourceAccount}
            setAccount={account => {
              setSourceAccount(account);
              setTargetAccount(undefined);
            }}
            invalidAmount={insufficientBalance || (alert === 'minAmount' && invalidAmount)}
          />

          <StyledSwitch>
            <StyledButton
              hasIcon
              onClick={
                targetAccount && sourceAccount
                  ? () => {
                      setSourceAccount(targetAccount);
                      setTargetAccount(sourceAccount);
                    }
                  : undefined
              }
              disabled={!targetAccount}
              size="medium"
              startIcon={<StyledIcon icon={`transaction${TransactionType.Swap}`} />}
            />
          </StyledSwitch>

          <StyledTo>
            <AccountMenu
              accounts={accounts.filter(a => {
                const uri = a._links.self.uri;
                return uri !== sourceAccount?._links.self.uri && targetAccountIds.includes(getIdFromUri(uri));
              })}
              value={targetAccount?._links.self.uri}
              onChange={setTargetAccount}
            />
            <StyledLabel variant="body3">{t('common.to')}</StyledLabel>
            <StyledInput
              type="number"
              autoComplete="off"
              onFocus={() => clearPrefilledAmountInput()}
              onBlur={() => prefillAmountInput('0')}
              variant="standard"
              value={targetAmount !== undefined ? targetAmount : calculatedTargetAmount}
              error={alert === 'minAmount' && invalidTargetAmount}
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                const amount = event.target.value;
                setTargetAmount(limitDecimals(amount, targetAccount?.type === CurrencyType.Crypto ? 5 : 2));
                if (rate) {
                  setAmount(calcAmount(amount));
                }
              }}
            />

            {rate && targetAccount && sourceAccount && (
              <StyledEstimatedRate>
                <StyledRateLabel>
                  <Typography variant="strongBody4">{t('banking.actions.swap.estimatedRate')}</Typography>
                  <Tooltip
                    dark
                    arrow
                    title={
                      <Typography variant="body3" textAlign="center">
                        {t('banking.actions.swap.estimatedRateInfo')}
                      </Typography>
                    }
                  >
                    <div>
                      <StyledInfoIcon icon="info" />
                    </div>
                  </Tooltip>
                </StyledRateLabel>
                <StyledRate>
                  <Tooltip
                    title={<Typography variant="body4">{`${formatAmount(rate)} ${targetAccount.currency} = 1 ${sourceAccount.currency}`}</Typography>}
                    dark
                    arrow
                    placement="bottom"
                  >
                    <Typography variant="strongBody4">
                      {`${formatAmount(rate, Number(rate) > 1000 ? 2 : 4)} ${targetAccount.currency} = 1 ${sourceAccount.currency}`}
                    </Typography>
                  </Tooltip>
                </StyledRate>
              </StyledEstimatedRate>
            )}
            {targetAccount && (
              <StyledBalance>
                <Typography variant="body4">{t('banking.actions.swap.balance')}</Typography>
                <Typography variant="body4">{`${formatAmount(targetAccount.balance)} ${targetAccount.currency}`}</Typography>
              </StyledBalance>
            )}
          </StyledTo>
        </ActionTab>
      ) : null}
    </SwapContext.Provider>
  );
};

export default SwapSelection;
