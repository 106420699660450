import { EventEmitter2 } from 'eventemitter2'

// Declare on() methods
interface EventMap<T> {
  flush: (items: QueueItem<T>[]) => void
}

export declare interface Queue<T> {
  on<U extends keyof EventMap<T>>(event: U, listener: EventMap<T>[U]): this
}

export type QueueItem<T> = { timestamp: number; item: T }

export class Queue<T> extends EventEmitter2 {
  private limit = 5
  private queue: QueueItem<T>[] = []
  public paused = false

  constructor(props?: { limit?: number; paused?: boolean }) {
    super()
    this.limit = props?.limit || 1
    this.paused = props?.paused ?? this.paused
  }

  public push(item: T | T[]) {
    if (Array.isArray(item)) {
      item.forEach((i) => this.queue.push({ timestamp: Date.now(), item: i }))
    } else {
      this.queue.push({ timestamp: Date.now(), item })
    }

    this.flush()
    return this
  }

  public resume() {
    this.paused = false
    this.flush()
    return this
  }

  public pause() {
    this.paused = true
    return this
  }

  public flush(force = false) {
    if (force || (!this.paused && this.queue.length >= this.limit)) {
      this.performFlush()
    }
  }

  private performFlush() {
    // Splice is destructive and removes first x items from the this.queue automatically
    const batch = this.queue.splice(0, this.limit || 1)

    if (batch.length) {
      this.emit('flush', batch)
    }

    // Check again in case the queue is longer than the limit
    if (this.queue.length) {
      this.flush()
    }

    return this
  }
}
