import { Tab, Typography } from '@mui/material';
import { FC, PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';

import { TabsProps } from './Tabs.interfaces';
import { StyledCount, StyledLabel, StyledTabs } from './Tabs.styles';

const Tabs: FC<PropsWithChildren<TabsProps>> = ({ tabs, counts, children, ...restProps }) => {
  const { t } = useTranslation();
  return (
    <>
      <StyledTabs {...restProps}>
        {tabs.map((tab, i) => (
          <Tab
            key={tab.state}
            label={
              <StyledLabel>
                <Typography>{t(tab.i18nTitle)}</Typography>
                {counts && counts[i] > 0 && <StyledCount>{counts[i]}</StyledCount>}
              </StyledLabel>
            }
            disableRipple
          />
        ))}
      </StyledTabs>
      {children}
    </>
  );
};

export default Tabs;
