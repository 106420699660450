import { Autocomplete, Popper, styled } from '@mui/material';
import { StyledTextField, StyledAutocomplete } from 'src/components/Autocomplete/Autocomplete.styles';
import SvgIcon from 'src/components/SvgIcon/SvgIcon';

export const PlaceStyledTextField = styled(StyledTextField)({
  width: '100%'
});

export const StyledGoogleAutocomplete = styled(StyledAutocomplete)(({ theme }) => ({
  '& .MuiAutocomplete-popupIndicator': {
    transform: 'none',
    marginRight: theme.spacing(1)
  }
})) as typeof Autocomplete;

export const StyledPopper = styled(Popper)(({ theme }) => ({
  boxShadow: theme.shadows[5],
  borderRadius: theme.shape.borderRadius
}));

export const StyledSvgIcon = styled(SvgIcon)({
  width: '16px',
  height: '16px'
});
