import { Options } from 'ajv';

export const LABEL_FIELD_NAME_FOR_DROPDOWN = 'label';
export const VALUE_FIELD_NAME_FOR_DROPDOWN = 'value';

export const FIELD_VALUE_TRUE = 'true';
export const FIELD_VALUE_YES = 'yes';
export const FIELD_VALUE_NO = 'no';

export const AJV_OPTIONS: Options = {
  allErrors: true,
  verbose: true,
  strictSchema: false
};
