import { Tooltip, styled } from '@mui/material';

import { StyledTooltipProps } from './Tooltip.interfaces';

const StyledTooltip = styled(Tooltip, {
  shouldForwardProp: prop => prop !== 'dark'
})<StyledTooltipProps>(({ theme, dark }) => ({
  '+ .MuiTooltip-popper > .MuiTooltip-tooltip': {
    maxWidth: '332px',
    padding: dark ? theme.spacing(1, 1.5) : theme.spacing(1.75, 2.5),
    borderRadius: dark ? 6 : theme.shape.borderRadius,
    boxShadow: theme.shadows[5],
    backgroundColor: dark ? theme.palette.custom.grey.offBlack : theme.palette.common.white,
    color: dark ? theme.palette.common.white : theme.palette.custom.grey.offBlack
  },
  '+ .MuiTooltip-popper > .MuiTooltip-tooltip> .MuiTooltip-arrow': {
    color: dark ? theme.palette.custom.grey.offBlack : theme.palette.common.white
  }
}));

export default StyledTooltip;
