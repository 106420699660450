import { styled, Typography } from '@mui/material';

export const StyledLastUpdated = styled(Typography)(({ theme }) => ({
  marginBlock: theme.spacing(5)
}));

export const StyledTitle = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(2)
}));

export const StyledPolicyWrapper = styled('div')({
  gap: '16px',
  display: 'flex',
  flexDirection: 'column',
  color: '#888888'
});
