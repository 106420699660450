/* eslint-disable @typescript-eslint/no-explicit-any */
import { AxiosError, AxiosResponse } from 'axios';
import { IEnable2faResponse, IAuth2faResponse, IAuthLoginResponse, IRequest2faResponse, IReset2faResponse } from 'src/interfaces/responses';
import { IFormValues } from 'src/interfaces/forms';

import { additionalUrls } from './additionalUrls';
import request from './request';

const auth2faUrl = `${additionalUrls.auth}${additionalUrls['2fa']}`;

export const authLogin = (body: IFormValues): Promise<AxiosResponse<IAuthLoginResponse, AxiosError>> | undefined =>
  request('post', `${additionalUrls.auth}${additionalUrls.login}`)(body);

export const auth2fa = (otp: string, invitationToken?: string): Promise<AxiosResponse<IAuth2faResponse, AxiosError>> | undefined =>
  request('post', auth2faUrl)({ otp, invitationToken });

export const request2fa = (): Promise<AxiosResponse<IRequest2faResponse, AxiosError>> | undefined =>
  request('post', `${auth2faUrl}${additionalUrls.request}`)();

export const enable2fa = (otp: string): Promise<AxiosResponse<IEnable2faResponse, AxiosError>> | undefined =>
  request('post', `${auth2faUrl}${additionalUrls.enable}`)({ otp });

export const reset2fa = (otpBackupCode: string): Promise<AxiosResponse<IReset2faResponse, AxiosError>> | undefined =>
  request('post', `${auth2faUrl}${additionalUrls.reset}`)({ otpBackupCode });

export const resetPassword = (verificationToken: string, password: string): Promise<AxiosResponse<void, AxiosError>> | undefined =>
  request('post', additionalUrls.resetPassword)({ verificationToken, password });

export const changePassword = (passwordData: IFormValues): Promise<AxiosResponse<void, AxiosError>> | undefined =>
  request('post', additionalUrls.changePassword)(passwordData);

export const resetPasswordRequest = (email: string): Promise<AxiosResponse<void, AxiosError>> | undefined =>
  request('post', additionalUrls.resetPasswordRequest)({ email });

export const validateResetToken = (token: string): Promise<AxiosResponse<void, AxiosError>> | undefined =>
  request('post', additionalUrls.validateResetToken)({ token });
