/* TODO: Change settings to something more concise */
import { Tab } from 'src/interfaces/global';
import { CompanySettingsBeneficiariesTabState } from 'src/interfaces/settings';

export enum LegalEntityMemberStatus {
  Accepted = 'ACCEPTED',
  Invited = 'INVITED',
  Expired = 'EXPIRED'
}

export const COMPANY_SETTINGS_BENEFICIARIES_TABS: Tab[] = [
  {
    state: CompanySettingsBeneficiariesTabState.FiatBeneficiaries,
    i18nTitle: 'companySettings.beneficiaries.fiatBeneficiaries'
  },
  {
    state: CompanySettingsBeneficiariesTabState.CryptoBeneficiaries,
    i18nTitle: 'companySettings.beneficiaries.cryptoBeneficiaries'
  }
];
