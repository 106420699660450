import { MenuItem, Select, Typography } from '@mui/material';
import { FC, useMemo, useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNetworksQuery } from 'src/api/queries/useNetworks';
import ActionButtons from 'src/components/ActionButtons/ActionButtons';
import Autocomplete from 'src/components/Autocomplete/Autocomplete';
import { OptionInterface } from 'src/components/banking/actions/withdraw/Withdraw.utils';
import FormElement from 'src/components/FormElement/FormElement';
import Loader from 'src/components/Loader/Loader';
import { Account, CurrencyType } from 'src/interfaces/banking';
import { formatCountryDataForOptions } from 'src/utils/formatting';
import { useWithdrawalCurrencies } from 'src/api/queries/useWithdrawalCurrencies';
import { IGetWithdrawalCurrencies } from 'src/interfaces/responses';
import { StyledListItem as StyledCurrencyListItem } from 'src/components/banking/WithdrawAccountAndAmount/WithdrawAccountAndAmount.styles';
import { StyledListItem } from 'src/components/StyledListItem/StyledListItem';

import { StepOneData } from './CompanySettingsCreateBeneficiaryModal.interfaces';
import { StyledStepOneTitle, StyledStepOneWrapper } from './CompanySettingsCreateBeneficiaryModal.styles';
import { mapAccountsToSelectOptions } from './utils';

export const StepOne: FC<{
  savedData: StepOneData;
  accounts?: Account[];
  onContinue: (stepOneData: StepOneData) => void;
  onClose: () => void;
  isLoadingAllowedCountries: boolean;
  allowedCountriesData: { [key: string]: string };
}> = ({ savedData, accounts = [], onContinue, onClose, isLoadingAllowedCountries, allowedCountriesData }) => {
  const { t } = useTranslation();

  const [selectedAccount, setSelectedAccount] = useState<OptionInterface | null>();

  const [country, setCountry] = useState<string>('');

  const [selectedTargetCurrency, setSelectedTargetCurrency] = useState<IGetWithdrawalCurrencies[number] | null>(null);

  const [selectedNetwork, setSelectedNetwork] = useState<string>('');

  const { data: networkListData, isFetching: isLoadingNetworks } = useNetworksQuery(
    selectedAccount ? `${selectedAccount?.key}/networks` : '',
    country ?? '',
    selectedTargetCurrency?.currency ?? undefined
  );

  const countryDataList = useMemo(() => formatCountryDataForOptions(allowedCountriesData), [allowedCountriesData]);

  const options = useMemo(() => mapAccountsToSelectOptions(accounts), [accounts]);

  const { data: withdrawalCurrencies } = useWithdrawalCurrencies(selectedAccount?.key);

  useEffect(() => {
    if (networkListData?.data.length === 1) {
      setSelectedNetwork(networkListData?.data[0]);
    }
  }, [networkListData?.data]);

  useEffect(() => {
    if (savedData) {
      const initialAcc = options.find(option => option.key === savedData.accountUri) ?? null;
      setSelectedAccount(initialAcc);
      setCountry(savedData.country);
      setSelectedNetwork(savedData.network);
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (savedData && selectedNetwork) {
      const initialTargetCurrency = withdrawalCurrencies?.data.find(({ currency }) => currency === savedData?.targetCurrency) ?? null;
      setSelectedTargetCurrency(initialTargetCurrency);
    }
  }, [withdrawalCurrencies, savedData, selectedNetwork]);

  const handleAccountSelected = useCallback((option: OptionInterface) => {
    setSelectedAccount(option);
  }, []);

  const handleCurrencySelected = useCallback((option: IGetWithdrawalCurrencies[number]) => {
    setSelectedTargetCurrency(option);
  }, []);

  const handleNetworkSelected = useCallback((value: string) => {
    setSelectedNetwork(value);
  }, []);

  const handleCountrySelected = useCallback((value: string) => {
    setCountry(value);
    setSelectedTargetCurrency(null);
    setSelectedNetwork('');
  }, []);

  const renderBalanceTypeValue = useCallback(
    (selectedAcc: string | []) => {
      if (selectedAcc.length === 0) {
        return t('companySettings.beneficiaries.selectBalance');
      }
      const option = options.find(option => option.key === selectedAcc);
      return option ? <StyledListItem option={option} key={option.key} hideLabel /> : '';
    },
    [options, t]
  );

  const onBalanceTypeChange = useCallback(
    (acc: { target: { value: unknown } }) => {
      const newOption = options.find(ac => ac && ac.key === acc.target.value) ?? null;
      if (newOption) {
        handleAccountSelected(newOption);
        setCountry('');
        setSelectedTargetCurrency(null);
        setSelectedNetwork('');
      }
    },
    [options, handleAccountSelected]
  );

  const renderCurrencyTypeValue = useCallback(
    (selectedCurr: string) => {
      if (String(selectedCurr).length === 0) {
        return t('companySettings.beneficiaries.selectCurrency');
      }
      const currency = withdrawalCurrencies?.data.find(({ currency }) => currency === selectedCurr);
      return (
        <>
          {currency?.currency}{' '}
          <Typography color="gray" display="inline">
            ({currency?.currencyLabel})
          </Typography>
        </>
      );
    },
    [t, withdrawalCurrencies]
  );

  const onCurrencyTypeChange = useCallback(
    (curr: { target: { value: string } }) => {
      const newCurrency = withdrawalCurrencies?.data.find(({ currency }) => currency === curr.target.value) ?? null;
      if (newCurrency) {
        handleCurrencySelected(newCurrency);
      }
    },
    [withdrawalCurrencies, handleCurrencySelected]
  );

  return (
    <StyledStepOneWrapper>
      {isLoadingAllowedCountries ? (
        <Loader anchorToRelative />
      ) : (
        <>
          <StyledStepOneTitle>{t('companySettings.beneficiaries.stepOneTitle')}</StyledStepOneTitle>
          <FormElement label={t('companySettings.beneficiaries.withdrawBalance')}>
            <Select
              id="select-balance"
              displayEmpty
              value={selectedAccount?.key ?? ''}
              renderValue={renderBalanceTypeValue}
              onChange={onBalanceTypeChange}
              placeholder={t('companySettings.beneficiaries.selectBalance')}
            >
              <MenuItem disabled value="" />
              {options.map(option => (
                <MenuItem value={option.key} key={option.key}>
                  <StyledListItem option={option} />
                </MenuItem>
              ))}
            </Select>
          </FormElement>
          {!isLoadingAllowedCountries && (
            <Autocomplete
              placeholder={t('banking.actions.withdraw.searchForCountry')}
              value={country}
              handleOnChange={(_name, val) => {
                handleCountrySelected(String(val));
              }}
              name="country"
              dataList={countryDataList}
              label={t('banking.actions.withdraw.countryOfBeneficiariesBank')}
              disabled={!selectedAccount}
              showOptionValue
            />
          )}
          <FormElement label={t('companySettings.beneficiaries.outgoingCurrency')}>
            <Select
              id="currency-select"
              disabled={!Boolean(selectedAccount && country)}
              displayEmpty
              value={selectedTargetCurrency?.currency ?? ''}
              renderValue={renderCurrencyTypeValue}
              onChange={onCurrencyTypeChange}
            >
              <MenuItem disabled value="" />
              {withdrawalCurrencies?.data.map(option => (
                <MenuItem value={option.currency} key={option.currency}>
                  <StyledCurrencyListItem>
                    <span>{option.currency}</span> <span>({option.currencyLabel})</span>
                  </StyledCurrencyListItem>
                </MenuItem>
              ))}
            </Select>
          </FormElement>
          {isLoadingNetworks ? (
            <Loader anchorToRelative />
          ) : (
            <FormElement label={t('companySettings.beneficiaries.network')}>
              <Select
                disabled={!Boolean(selectedAccount && country && selectedTargetCurrency)}
                id="network-select"
                displayEmpty
                value={Boolean(selectedAccount && country && selectedTargetCurrency) ? selectedNetwork : ''}
                renderValue={selectedNet => {
                  if (selectedNet?.length === 0) {
                    return t('companySettings.beneficiaries.selectNetwork');
                  }
                  return selectedNet;
                }}
                onChange={(net: { target: { value: string } }) => {
                  if (net.target.value) {
                    handleNetworkSelected(net.target.value);
                  }
                }}
              >
                <MenuItem disabled value="" />
                {networkListData?.data?.map(network => (
                  <MenuItem value={network} key={network}>
                    {network}
                  </MenuItem>
                ))}
              </Select>
            </FormElement>
          )}
          <ActionButtons
            primaryActionText={t('common.continue')}
            onPrimaryActionClick={() => {
              if (selectedAccount && selectedTargetCurrency && selectedNetwork && country) {
                onContinue({
                  type: CurrencyType.Fiat,
                  network: selectedNetwork,
                  accountUri: selectedAccount.key,
                  targetCurrency: selectedTargetCurrency.currency,
                  country
                });
                return;
              }
            }}
            secondaryActionText={t('common.cancel')}
            onSecondaryActionClick={onClose}
          />
        </>
      )}
    </StyledStepOneWrapper>
  );
};
