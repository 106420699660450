import Pusher from 'pusher-js';
import { additionalUrls } from 'src/api/additionalUrls';
import { CUSTOM_HEADERS } from 'src/constants/headers';
import { getAuthorizationHeader, getSignature } from 'src/utils/authentication';

import { PusherNotificationMessage } from '../interfaces/notifications';

const timestamp = Date.now();

class PusherClient {
  private static instance: Pusher | undefined;

  private constructor() {
    // Private constructor to prevent external instantiation
  }

  public static destroy(): void {
    if (PusherClient.instance) {
      PusherClient.instance.user.unbind(process.env.REACT_APP_PUSHER_EVENT);
      PusherClient.instance.connection.disconnect();
    }
    PusherClient.instance = undefined;
  }

  public static initialize(onConnectionBind: () => void, onUserBind: (msg: PusherNotificationMessage) => void): Pusher {
    if (!PusherClient.instance) {
      PusherClient.instance = new Pusher(process.env.REACT_APP_PUSHER_APP_KEY, {
        cluster: process.env.REACT_APP_PUSHER_CLUSTER,
        userAuthentication: {
          endpoint: `${process.env.REACT_APP_API_URL}${additionalUrls.notificationsAuth}`,
          transport: process.env.REACT_APP_PUSHER_TRANSPORT_TYPE,
          headers: {
            authorization: getAuthorizationHeader(),
            [CUSTOM_HEADERS.XTIMESTAMP]: timestamp,
            [CUSTOM_HEADERS.XSIGNATURE]: getSignature(timestamp, 'POST', additionalUrls.notificationsAuth)
          }
        }
      });
      PusherClient.instance.signin();
      PusherClient.instance.connection.bind('connected', onConnectionBind);
      PusherClient.instance.user.bind(process.env.REACT_APP_PUSHER_EVENT, onUserBind);
    }
    return PusherClient.instance;
  }

  public static isConnected(): boolean {
    return !!PusherClient.instance;
  }
}

export default PusherClient;
