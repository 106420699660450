import { styled } from '@mui/material';
import { ViewModeType } from 'src/components/ViewMode/ViewMode.interfaces';

export const StyledAccountTiles = styled('ul')<{ expanded: boolean; viewMode: ViewModeType }>(({ theme, expanded, viewMode }) => ({
  padding: theme.spacing(0, 1),
  marginInline: theme.spacing(-1),
  marginTop: theme.spacing(3),
  display: 'grid',
  gridTemplateColumns: viewMode === 'grid' ? 'repeat(4, 1fr)' : '1fr',
  gap: viewMode === 'grid' ? theme.spacing(3) : 0,
  height: expanded ? '100%' : 0,
  overflow: 'hidden',
  maxHeight: '100%',
  [theme.breakpoints.down('md')]: {
    gridTemplateColumns: '1fr'
  }
}));

export const StyledAccountFilter = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between'
}));
