import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import DialogLayout from 'src/components/layouts/DialogLayout/DialogLayout';
import StatusIcon from 'src/components/StatusIcon/StatusIcon';
import SvgIcon from 'src/components/SvgIcon/SvgIcon';
import { urls } from 'src/routes';

const InvalidTokenPage: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <DialogLayout
      headerImage={<StatusIcon error icon={<SvgIcon icon="warning" />} />}
      heading={t('screens.invalidToken.title')}
      subheading={t('screens.invalidToken.subtitle')}
      secondaryBackAction
      secondaryActionText={t('screens.invalidToken.backText')}
      onSecondaryActionClick={() => navigate(urls.signin)}
    />
  );
};

export default InvalidTokenPage;
