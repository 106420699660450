import { useContext, useState } from 'react';
import ActionTab from 'src/components/banking/actions/ActionTab/ActionTab';
import { ActionBarContext } from 'src/contexts/ActionBar.contexts';

import { WithdrawBulkWireScreens } from '../Withdraw.interfaces';
import { withdrawActionTabDefaultValues } from '../Withdraw.utils';
import WithdrawMethodSelection from '../WithdrawMethodSelection/WithdrawMethodSelection';

export const WithdrawBulkWire = () => {
  const [nextScreen, setNextScreen] = useState<WithdrawBulkWireScreens>();
  const actionBarContext = useContext(ActionBarContext);

  const flowScreens = {
    withdrawMethodSelection: <WithdrawMethodSelection />
  };

  return nextScreen ? (
    flowScreens[nextScreen]
  ) : actionBarContext ? (
    <ActionTab
      onGoBack={() => setNextScreen('withdrawMethodSelection')}
      onClose={actionBarContext.closeActionTab}
      activeStepIndex={1}
      {...withdrawActionTabDefaultValues}
    >
      Withdraw bulk wire
    </ActionTab>
  ) : null;
};
