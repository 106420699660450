import { styled } from '@mui/material';

import { StyledStatusIconProps } from './StatusIcon.interfaces';

const StyledStatusIcon = styled('div', {
  shouldForwardProp: prop => prop !== 'error'
})<StyledStatusIconProps>(({ theme, error }) => ({
  width: 80,
  height: 80,
  borderRadius: 40,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: error ? theme.palette.error.light : theme.palette.custom.coralDusk[50],
  boxShadow: error ? theme.shadows[7] : theme.shadows[6]
}));

export default StyledStatusIcon;
