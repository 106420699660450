import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { inviteTeamMemberSchemaUrl } from 'src/api/formSchemas';
import Button from 'src/components/Button/Button';
import Form from 'src/components/Form/Form';
import get from 'lodash.get';
import Input from 'src/components/Input/Input';
import Modal from 'src/components/Modal/Modal';
import { StyledModalWrapper } from 'src/components/modals/styles';
import { ButtonVariant } from 'src/constants/variants';
import { IOnFormChangeProps } from 'src/interfaces/forms';
import { urls } from 'src/routes';
import { getSelectedLegalEntityUri } from 'src/utils/storage';

import { ResendInvitationModalProps } from './ResendInvitationModal.interfaces';

export const ResendInvitationModal: FC<ResendInvitationModalProps> = ({ isOpen, onClose, onSubmit, ...restProps }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleFormChange = ({ values, lastChangedFieldName, submitForm }: IOnFormChangeProps) => {
    if (lastChangedFieldName === 'otp' && get(values, lastChangedFieldName, '').toString().length === 6) {
      submitForm?.();
    }
  };

  return (
    <Modal onClose={onClose} isOpen={isOpen} title={t('companySettings.members.resendInvitationModalTitle')} {...restProps}>
      <StyledModalWrapper>
        <Form schemaUrl={inviteTeamMemberSchemaUrl(getSelectedLegalEntityUri())} onSubmit={onSubmit} onChange={handleFormChange}>
          <Input autoFocus name="otp" type="number" fullWidth />

          <Button
            variant={ButtonVariant.Text}
            sx={{
              marginTop: 2
            }}
            fullWidth
            onClick={() => navigate(urls.reset2fa)}
          >
            {t('screens.authentication.backText')}
          </Button>
        </Form>
      </StyledModalWrapper>
    </Modal>
  );
};
