import { FormControl, styled, Typography } from '@mui/material';
import { Stack } from '@mui/system';

export const StyledContainer = styled(Stack)(({ theme }) => ({
  height: 'fitContent',
  '&.MuiStack-root': {
    marginTop: theme.spacing(1)
  }
}));

export const StyledModalHeading = styled(Typography)(({ theme }) => ({
  color: theme.palette.custom.grey[600],
  letterSpacing: '-0.01em'
}));

export const StyledStepperWrapper = styled(Stack)(({ theme }) => ({
  marginInline: theme.spacing(-2, 2)
}));

export const StyledNetworkFormControl = styled(FormControl)(({ theme }) => ({
  marginInline: theme.spacing(-2, 2)
}));

export const StyledStepOneTitle = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(3)
}));

export const StyledModalWrapper = styled(Stack)(({ theme }) => ({
  width: '520px',
  marginTop: theme.spacing(-2)
}));

export const StyledStepOneWrapper = styled(Stack)(({ theme }) => ({
  '.MuiSelect-select': {
    '&.Mui-disabled': {
      backgroundColor: theme.palette.custom.grey.offWhite,
      color: theme.palette.custom.grey[100]
    }
  }
}));
