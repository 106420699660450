import { styled, Tabs } from '@mui/material';

export const StyledTabs = styled(Tabs)(({ theme }) => ({
  marginBottom: theme.spacing(3),

  '.MuiTabs-flexContainer': {
    borderBottom: `1px solid ${theme.palette.custom.grey[100]}`
  },
  '.MuiTabs-indicator': {
    height: '1px'
  },
  '.MuiTab-root': {
    minWidth: 'unset',
    padding: 0,
    marginRight: theme.spacing(2),

    '.MuiTypography-root': {
      color: theme.palette.custom.grey[400],
      ...theme.typography.body2
    },

    '&.Mui-selected': {
      '.MuiTypography-root': {
        color: theme.palette.custom.coralDusk[400],
        ...theme.typography.strongBody2
      }
    },
    '&:last-child': {
      marginRight: 0
    }
  }
}));

export const StyledLabel = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: theme.spacing(1)
}));

export const StyledCount = styled('div')(({ theme }) => ({
  height: '20px',
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1.25),
  borderRadius: '6px',
  color: theme.palette.custom.coralDusk[400],
  background: theme.palette.custom.coralDusk[50],
  ...theme.typography.strongBody4
}));
