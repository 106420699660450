export const getIdFromUri = (uri: string) => uri.split('/').pop() ?? '';

export const toKebabCase = (str: string) =>
  str
    .replace(/([a-z])([A-Z])/g, '$1-$2')
    .replace(/[\s_]+/g, '-')
    .toLowerCase();

export const toMiddleCut = (value: string, length = 8): string =>
  value.length > length ? `${value.substring(0, length / 2)}...${value.substring(value.length - length / 2, value.length)}` : value;

export const capitalize = (s: string) => s.toLowerCase().replace(/^_*(.)|_+(.)/g, (s, c, d) => (c ? c.toUpperCase() : ` ${d.toUpperCase()}`));
