import { Address, Beneficiary, Counterpart } from 'src/interfaces/banking';
import { IAllowedCountriesResponse, ICountryAbrreviations } from 'src/interfaces/responses';

const translateCountry = (country: string, countries?: ICountryAbrreviations[]) => countries?.find(c => c.alpha2 === country)?.name ?? country;

export const formatBeneficiary = (beneficiary: Beneficiary, countries?: ICountryAbrreviations[]): string =>
  formatAddress(beneficiary.address, countries, formatBeneficiaryName(beneficiary));

export const formatBeneficiaryName = (beneficiary: Beneficiary): string => {
  const { firstName, lastName, companyName } = beneficiary;
  return companyName || [firstName, lastName].filter(Boolean).join(' ');
};

export const formatAddress = (address?: Address, countries?: ICountryAbrreviations[], name?: string): string =>
  [
    name,
    [address?.street, address?.houseNo].filter(Boolean).join(' '),
    [address?.postalCode, address?.city].filter(Boolean).join(' '),
    address?.state,
    countries && address?.country ? translateCountry(address.country, countries) : address?.country
  ]
    .filter(Boolean)
    .join('\n');

export const formatBankDetails = (beneficiary: Beneficiary, countries?: ICountryAbrreviations[]): string => {
  const { bank, bicSwiftCode, routingNumber } = beneficiary;
  return [
    bicSwiftCode,
    routingNumber,
    bank?.name,
    ...(bank?.address?.split(',') ?? []),
    countries && bank?.country ? translateCountry(bank.country, countries) : bank?.country
  ]
    .filter(Boolean)
    .join('\n');
};

export const formatCounterpart = (counterpart: Counterpart, countries?: ICountryAbrreviations[]): string => {
  const { name, address, country, ibanOrAccountNr, bicOrRoutingCode } = counterpart;
  return [
    name,
    ibanOrAccountNr,
    bicOrRoutingCode,
    ...(address?.split(',') ?? []),
    countries && country ? translateCountry(country, countries) : country
  ]
    .filter(Boolean)
    .join('\n');
};

export const formatCountryDataForOptions = (countriesObject?: IAllowedCountriesResponse) =>
  countriesObject
    ? Object.entries(countriesObject).map(([key, value]) => ({
        label: value,
        value: key
      }))
    : [];

export const limitDecimals = (amount: string, decimals = 2) => {
  const parts = amount.split('.');
  return parts.length > 1 && parts[1].length > decimals ? `${parts[0]}.${parts[1].substring(0, decimals)}` : amount;
};
