import { QueryKey } from 'src/constants/queryKeys';
import { AccountDetailsTabState, AccountsDashboardTabState, AccountHoldingsTabState } from 'src/interfaces/banking';
import { Tab } from 'src/interfaces/global';
import { CompanySettingsTeamMembersTabState } from 'src/interfaces/settings';

export const COMPANY_SETTINGS_TEAM_MEMBERS_TABS: Tab[] = [
  {
    state: CompanySettingsTeamMembersTabState.ExistingMembers,
    i18nTitle: 'companySettings.members.existingMembers'
  },
  {
    state: CompanySettingsTeamMembersTabState.InvitedMembers,
    i18nTitle: 'companySettings.members.invitedMembers'
  }
];

export const ACCOUNTS_BALANCES_TABS: Tab[] = [
  {
    state: AccountsDashboardTabState.All,
    i18nTitle: 'banking.tabs.all'
  },
  {
    state: AccountsDashboardTabState.Fiat,
    i18nTitle: 'banking.tabs.fiat'
  }
  // {
  //   state: AccountsDashboardTabState.Crypto,
  //   i18nTitle: 'banking.tabs.crypto'
  // }
];

export const TRANSACTION_TABS: Tab[] = [
  {
    state: AccountDetailsTabState.Transactions,
    i18nTitle: 'banking.tabs.transactions',
    invalidationKey: QueryKey.QUERY_TRANSACTIONS
  },
  {
    state: AccountDetailsTabState.Orders,
    i18nTitle: 'banking.tabs.orders',
    invalidationKey: QueryKey.QUERY_ORDERS
  }
];

export const ACCOUNT_DETAILS_TABS: Tab[] = [
  ...TRANSACTION_TABS,
  {
    state: AccountDetailsTabState.AccountDetails,
    i18nTitle: 'banking.tabs.accountDetails',
    invalidationKey: QueryKey.QUERY_ACCOUNT_DETAILS
  }
  // {
  //   state: AccountDetailsTabState.Settings,
  //   i18nTitle: 'banking.tabs.settings'
  // }
];

export const ACCOUNT_HOLDINGS_TABS: Tab[] = [
  {
    state: AccountHoldingsTabState.AllHoldings,
    i18nTitle: 'All Holdings'
  }
];
