import { useMemo, useState } from 'react';
import BankingLayout from 'src/components/layouts/BankingLayout/BankingLayout';
import Transactions from 'src/components/banking/Transactions/Transactions';
import Tabs from 'src/components/banking/Tabs/Tabs';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Account, AccountDetailsTabState, Transaction } from 'src/interfaces/banking';
import { useOrdersQuery } from 'src/api/queries/useOrders';
import { getTransactionsFromOrders } from 'src/utils/transactions';
import { getIdFromUri } from 'src/utils/strings';
import { useAccountDetailsQuery } from 'src/api/queries/useAccountDetails';
import { AccountDetailsInfo } from 'src/components/banking/AccountDetailsInfo/AccountDetailsInfo';
import { useQueryClient } from '@tanstack/react-query';
import { Alert } from 'src/components/Alert/Alert';
import { useTranslation } from 'react-i18next';
import { useInView } from 'react-intersection-observer';
import { urls } from 'src/routes';
import { usePagination } from 'src/hooks/usePagination';
import { getTransactions } from 'src/api/banking';
import { QueryKey } from 'src/constants/queryKeys';
import { getSelectedLegalEntityUri } from 'src/utils/storage';
import { ACCOUNT_DETAILS_TABS } from 'src/constants/tabs';

const AccountDetailsPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const queryClient = useQueryClient();
  const { accountId, tab } = useParams();

  const [accountOrders, setAccountOrders] = useState<Transaction[]>();

  const entityUri = getSelectedLegalEntityUri();

  const stateAccount = location.state as Account;
  const uri = stateAccount?._links?.self?.uri ?? urls.accountDetails.replace(':accountId', accountId ?? '');

  const { ref, inView: loadMore } = useInView();
  const { paginationData: transactionsData, isPaginationError: isTransactionsError } = usePagination(
    uri,
    loadMore,
    QueryKey.QUERY_TRANSACTIONS,
    getTransactions
  );

  const transactions = useMemo(() => transactionsData?.pages.map(group => group.response).flat(), [transactionsData]);

  const { data: accountDetails, isError: isDetailsError } = useAccountDetailsQuery(uri);
  const { data: orders, isLoading: isLoadingOrders, isError: isOrdersError } = useOrdersQuery(entityUri);

  useMemo(() => {
    if (uri && !isLoadingOrders && orders?.data) {
      setAccountOrders(getTransactionsFromOrders(orders.data.filter(o => o.sourceAccountId === getIdFromUri(uri))));
    }
  }, [uri, isLoadingOrders, orders]);

  const account = accountDetails?.data ?? stateAccount;
  if (!account) {
    return <>{isDetailsError && t('banking.errors.noAccount')}</>;
  }

  // Orders
  const ordersTab = isOrdersError ? <Alert>{t('banking.errors.orders')}</Alert> : <Transactions orders transactions={accountOrders} />;

  // Transactions
  const transactionsTab = isTransactionsError ? (
    <Alert>{t('banking.errors.transactions')}</Alert>
  ) : (
    <Transactions transactions={transactions} viewBottomRef={ref} />
  );

  const detailsTab = isDetailsError ? <Alert>{t('banking.errors.accountDetails')}</Alert> : <AccountDetailsInfo account={account} />;

  // Settings
  const settingsTab = <div>Settings tab</div>;

  let tabIndex = ACCOUNT_DETAILS_TABS.findIndex(t => t.state === tab);
  if (tabIndex === -1) {
    tabIndex = 0;
  }
  const contents = [transactionsTab, ordersTab, detailsTab, settingsTab];
  const content = (
    <Tabs
      tabs={ACCOUNT_DETAILS_TABS}
      counts={ACCOUNT_DETAILS_TABS.map(t => (t.state === AccountDetailsTabState.Orders ? accountOrders?.length ?? 0 : 0))}
      value={tabIndex}
      onChange={(_, value) => {
        const { state, invalidationKey } = ACCOUNT_DETAILS_TABS[value];
        navigate(`${uri}/${state}`, { state: account });
        if (invalidationKey) {
          queryClient.invalidateQueries([invalidationKey]);
        }
      }}
    >
      {contents[tabIndex]}
    </Tabs>
  );

  return (
    <BankingLayout
      type={account?.type}
      currency={account?.currency}
      currencyLabel={account?.currencyLabel}
      totalBalance={account?.balance}
      valuation={account?.valuation}
      metaData={account?.metaData}
      content={content}
      onClose={() => navigate(urls.balances)}
      mobileHeader={t('banking.tabs.accountDetails')}
    />
  );
};

export default AccountDetailsPage;
