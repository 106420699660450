import { Box, styled, Typography } from '@mui/material';
import Button from 'src/components/Button/Button';

import { StyledDetailsWihBorderProps } from './AccountDetailsInfo.interfaces';

export const StyledContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  gap: theme.spacing(3)
}));

export const StyledDetailsWihBorder = styled('div', {
  shouldForwardProp: prop => prop !== 'inActionTab'
})<StyledDetailsWihBorderProps>(({ theme, inActionTab }) => ({
  ...(!inActionTab && {
    border: `1px solid ${theme.palette.custom.grey[100]}`,
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(3)
  }),
  width: '100%',
  flex: 2,
  display: 'flex',
  flexDirection: 'column',
  background: theme.palette.common.white
}));

export const StyledTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.custom.grey[300],
  marginTop: theme.spacing(3),
  marginBottom: theme.spacing(1)
}));

export const StyledQRWrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center'
});

export const StyledButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(3)
}));

export const StyledInfo = styled('div')(({ theme }) => ({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(3)
}));
