import { styled, Typography } from '@mui/material';

export const StyledFilter = styled(Typography)(({ theme }) => ({
  // TODO: Add/unify typography variant instead
  fontWeight: 600,
  color: theme.palette.custom.grey.offBlack
}));

export const StyledFilterValue = styled(Typography)(({ theme }) => ({
  cursor: 'pointer',
  color: theme.palette.custom.coralDusk[400]
}));
