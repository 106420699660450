import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonVariant } from 'src/constants/variants';
import Button from 'src/components/Button/Button';
import { urls } from 'src/routes';

import {
  StyledTitle,
  StyledPersonalSettingsAboutFormRow,
  StyledPersonalSettingsAboutFormRowTitle,
  StyledPersonalSettingsAboutFormRowButtonIcon
} from './AboutForm.styles';

const AboutForm: FC = () => {
  const { t } = useTranslation();

  const renderPersonalSettingsAboutFormRow = (title: string, link: string) => (
    <StyledPersonalSettingsAboutFormRow>
      <StyledPersonalSettingsAboutFormRowTitle variant="subtitle3">{title}</StyledPersonalSettingsAboutFormRowTitle>

      <Button
        icon={<StyledPersonalSettingsAboutFormRowButtonIcon icon="externalLink" />}
        variant={ButtonVariant.Secondary}
        onClick={() => window.open(link, '_blank')}
      >
        {t('personalSettings.about.buttonTitle')}
      </Button>
    </StyledPersonalSettingsAboutFormRow>
  );

  return (
    <>
      <StyledTitle variant="subtitle3">{t('personalSettings.about.title')}</StyledTitle>

      {renderPersonalSettingsAboutFormRow(t('personalSettings.about.faq'), 'https://google.com')}
      {renderPersonalSettingsAboutFormRow(t('personalSettings.about.termsAndConditions'), `${urls.termsAndConditions}`)}
      {renderPersonalSettingsAboutFormRow(t('personalSettings.about.privacyPolicy'), `${urls.privacyPolicy}`)}
    </>
  );
};

export default AboutForm;
