import { FC } from 'react';
import { useIdle } from 'src/hooks/useIdle';

import { ProtectedRouteProps } from './ProtectedRoute.interfaces';

const ProtectedRoute: FC<ProtectedRouteProps> = ({ children, pauseIdleTimer = false }) => {
  useIdle({
    paused: pauseIdleTimer
  });

  return <>{children}</>;
};

export default ProtectedRoute;
