import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import DialogLayout from 'src/components/layouts/DialogLayout/DialogLayout';
import { ONBOARDING_IDENTITY_VERIFICATION } from 'src/constants/onboarding';
import StatusIcon from 'src/components/StatusIcon/StatusIcon';
import SvgIcon from 'src/components/SvgIcon/SvgIcon';
import LabelValue from 'src/components/LabelValue/LabelValue';
import { SUPPORT_BUSINESS_EMAIL } from 'src/constants/mailing';

import { StyledText, StyledEmailRounded } from './ProfileRejectedPage.styles';

const ProfileRejectedPage: FC = () => {
  const { t } = useTranslation();
  const location = useLocation();

  const email = location?.state?.email ?? '';

  return (
    <DialogLayout
      headerImage={<StatusIcon icon={<SvgIcon icon="email" />} />}
      heading={t('screens.profileRejected.title')}
      subheading={t('screens.profileRejected.subtitle')}
      isOnboardingLayout
      withOnboardingSteps
      centeredContent
      activeStepName={ONBOARDING_IDENTITY_VERIFICATION}
      onPrimaryActionClick={() => {
        window.location.assign(`mailto:${SUPPORT_BUSINESS_EMAIL}?subject=${t('getHelpEmailData.subject')}`);
      }}
      primaryActionText={t('screens.profileRejected.contactSupport')}
    >
      {!!email && <LabelValue label={t('screens.profileRejected.label')} value={email} icon={<StyledEmailRounded fontSize="small" />} />}

      <StyledText variant="body1">{t('screens.profileRejected.notReceivedEmail')}</StyledText>
    </DialogLayout>
  );
};

export default ProfileRejectedPage;
