import { toKebabCase } from 'src/utils/strings';
import { FC, useState } from 'react';
import { Typography } from '@mui/material';
import { AddRounded, RemoveRounded } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

import SvgIcon from '../SvgIcon/SvgIcon';
import FormElement from '../FormElement/FormElement';

import { StyledRow, StyledFormControlLabel, StyledCheckbox, StyledDescription, StyledExpandable } from './Checkbox.styles';
import CheckboxProps from './Checkbox.interfaces';

const Checkbox: FC<CheckboxProps> = ({
  name,
  label,
  error,
  disabled,
  handleError,
  handleOnChange,
  value = false,
  editable = true,
  description,
  ...restProps
}) => {
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState<boolean>(false);

  const renderCheckbox = () => (
    <StyledCheckbox
      checked={value}
      checkedIcon={<SvgIcon icon="checkboxTick" />}
      indeterminateIcon={<SvgIcon icon="checkboxIndetermine" />}
      disabled={!editable || disabled}
      icon={<SvgIcon icon={`checkbox${disabled ? 'Disabled' : 'Default'}`} />}
      id={`${toKebabCase(name)}-checkbox`}
      isError={!!error}
      onChange={(_, checked) => {
        if (handleOnChange) {
          handleOnChange(name, checked);
        }

        if (handleError && error) {
          handleError(name, '');
        }
      }}
      disableRipple
      {...restProps}
    />
  );

  return (
    <FormElement error={error}>
      <StyledRow>
        {!!label ? (
          <StyledFormControlLabel
            control={renderCheckbox()}
            label={typeof label === 'string' ? <Typography variant="strongBody1">{label}</Typography> : label}
          />
        ) : (
          renderCheckbox()
        )}
        {description && (
          <StyledExpandable onClick={() => setExpanded(!expanded)}>
            {expanded ? <RemoveRounded color="primary" fontSize="small" /> : <AddRounded color="primary" fontSize="small" />}
            <Typography variant="button2" color="primary">
              {expanded ? t('common.less') : t('common.more')}
            </Typography>
          </StyledExpandable>
        )}
      </StyledRow>
      {expanded && <StyledDescription>{description}</StyledDescription>}
    </FormElement>
  );
};

export default Checkbox;
