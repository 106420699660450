import { FC } from 'react';
import { ButtonVariant } from 'src/constants/variants';
import { toKebabCase } from 'src/utils/strings';

import { ButtonProps } from './Button.interfaces';
import StyledButton from './Button.styles';

const Button: FC<ButtonProps> = ({
  children,
  hasIcon = false,
  icon,
  startIcon,
  title,
  variant = ButtonVariant.Primary,
  size = 'large',
  ...restProps
}) => (
  <StyledButton
    disableRipple
    disableFocusRipple
    hasIcon={hasIcon}
    variant={variant}
    size={size}
    id={`${toKebabCase(title || `${children}` || 'default')}-button`}
    startIcon={startIcon || icon}
    {...restProps}
  >
    {title}
    {!title && children}
  </StyledButton>
);

export default Button;
