import { styled } from '@mui/material';

export const StyledViewModeWrapper = styled('div')(({ theme }) => ({
  '& > .MuiButtonBase-root.MuiButton-root': {
    padding: theme.spacing(0.5)
  },
  '& > .MuiButtonBase-root:first-of-type': {
    marginRight: theme.spacing(3),
    position: 'relative',

    '&::after': {
      content: '""',
      position: 'absolute',
      width: '1px',
      height: '100%',
      right: '-13px',
      background: theme.palette.custom.grey[100]
    }
  },

  '& > .MuiButton-outlinedPrimary': {
    svg: {
      fill: '#888888'
    }
  }
}));
