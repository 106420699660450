import { styled, Typography } from '@mui/material';
import Button from 'src/components/Button/Button';

import { TotalBalanceContainerProops, StyledTotalBalanceTitleAndInfoIconProps } from './TotalBalance.interfaces';

export const StyledTotalBalanceTitleAndInfoIcon = styled('div', {
  shouldForwardProp: prop => prop !== 'disabled'
})<StyledTotalBalanceTitleAndInfoIconProps>(({ theme, disabled }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
  opacity: disabled ? 0.5 : 1,
  pointerEvents: disabled ? 'none' : 'all',

  '.MuiSvgIcon-root': {
    fontSize: '1rem'
  }
}));

export const StyledIcon = styled('div')({
  cursor: 'pointer'
});

export const StyledTotalBalanceTitle = styled(Typography)(({ theme }) => ({
  letterSpacing: '-0.01em',
  color: theme.palette.custom.grey[400]
}));

export const StyledAccountFlag = styled('img')({
  width: '24px',
  height: '24px'
});

export const StyledTotalBalanceContainer = styled('div', {
  shouldForwardProp: prop => prop !== 'disabled'
})<TotalBalanceContainerProops>(({ disabled }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  opacity: disabled ? 0.5 : 1,
  pointerEvents: disabled ? 'none' : 'all'
}));

export const StyledTotalBalance = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(1),
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
  [theme.breakpoints.down('md')]: {
    flexWrap: 'wrap'
  }
}));

export const StyledTotalBalanceAmount = styled(Typography)(({ theme }) => ({
  color: theme.palette.custom.grey.offBlack,
  fontWeight: 700
}));

export const StyledTotalBalanceCurrency = styled(Typography)(({ theme }) => ({
  color: theme.palette.custom.grey[300],
  letterSpacing: '-0.02em'
}));

export const StyledTotalBalanceChangeWrapper = styled('div')(({ theme }) => ({
  marginLeft: theme.spacing(1),
  backgroundColor: '#dfeeda', // This color is not in theme, need to ask designers
  borderRadius: '6px',
  padding: theme.spacing(0.5, 0.75)
}));

export const StyledTotalBalanceChange = styled(Typography)(({ theme }) => ({
  fontWeight: 700,
  fontSize: '12px',
  lineHeight: '20px',
  letterSpacing: '-0.01em',
  color: theme.palette.custom.coralDusk[500]
}));

export const StyledLabelValue = styled('div')({
  display: 'flex',
  flexDirection: 'column'
});

export const StyledLabel = styled(Typography)(({ theme }) => ({
  color: theme.palette.custom.grey[400]
}));

export const StyledAccountInfo = styled('div')(({ theme }) => ({
  marginLeft: theme.spacing(5),
  [theme.breakpoints.down('md')]: {
    marginLeft: 0
  }
}));

export const StyledButtons = styled('div')(({ theme }) => ({
  display: 'flex',
  marginTop: theme.spacing(3),
  gap: theme.spacing(2)
}));

export const StyledCloseButton = styled(Button)(({ theme }) => ({
  width: 28,
  height: 28,
  backgroundColor: theme.palette.common.white,
  [theme.breakpoints.down('md')]: {
    marginLeft: theme.spacing(3),
    alignSelf: 'flex-start'
  },
  '.MuiButton-startIcon': {
    margin: 0
  }
}));

export const StyledTrackWalletButton = styled(Button)(({ theme }) => ({
  '&.MuiButton-outlinedPrimary': {
    background: theme.palette.background.paper,
    color: theme.palette.custom.coralDusk[400],
    padding: theme.spacing(1.75, 2),
    maxHeight: '40px'
  },
  '.MuiSvgIcon-root': {
    width: '16px',
    height: '16px'
  }
}));
