import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAccountDetailsQuery } from 'src/api/queries/useAccountDetails';
import { useAccountsQuery } from 'src/api/queries/useAccounts';
import ActionTab from 'src/components/banking/actions/ActionTab/ActionTab';
import { Account, CurrencyType } from 'src/interfaces/banking';
import { useContext } from 'react';
import { ActionBarContext } from 'src/contexts/ActionBar.contexts';
import { Typography } from '@mui/material';
import { getCurrencyImage } from 'src/utils/styles';
import { DepositContext } from 'src/contexts/actions/Deposit.contexts';

import { DepositAccountDetailsScreens, DepositSelectionNextScreens } from '../Deposit.interfaces';
import { depositActionTabDefaultValues, getFormatedCurrencyText } from '../Deposit.utils';
import { DepositAccountDetails } from '../DepositAccountDetails/DepositAccountDetails';
import { DepositCrypto } from '../DepositCrypto/DepositCrypto';
import { DepositIsFromCryptoTrading } from '../DepositIsFromCryptoTrading/DepositIsFromCryptoTrading';

import {
  StyledAccountWrapper,
  StyledAccountCurrency,
  StyledAccountCurrencyLabelAndCurrency,
  StyledFlag,
  StyledWrappedAccountList
} from './DepositSelection.styles';

const DepositSelection = () => {
  const { t } = useTranslation();
  const actionBarContext = useContext(ActionBarContext);

  const [nextScreen, setNextScreen] = useState<DepositSelectionNextScreens>();
  const [accountUri, setAccountUri] = useState('');
  const [usdAccount, setUsdAccount] = useState<Account>();
  const [depositAccountDetailsPreviousScreen, setDepositAccountDetailsPreviousScreen] = useState<DepositAccountDetailsScreens>();

  const { data: accountsData, isLoading: areAccountsLoading, isSuccess: areAccountsLoaded } = useAccountsQuery();
  const { data: singleAccountData, isLoading: isLoadingSingleAccount, isSuccess: isSingleAccountLoaded } = useAccountDetailsQuery(accountUri, true);
  const accounts: Account[] = useMemo(() => accountsData?.data ?? [], [accountsData?.data]);

  const account = singleAccountData?.data;

  const handleOnAccountClick = (chosenAccount: Account) => {
    if (chosenAccount.type === CurrencyType.Fiat) {
      if (chosenAccount.metaData?.name === 'Trading') {
        setDepositAccountDetailsPreviousScreen('depositFiatUSD');
        setNextScreen('depositFiatUSD');
      } else {
        setNextScreen('depositFiat');
      }
    } else {
      setNextScreen('depositCrypto');
    }
    setAccountUri(chosenAccount?._links?.self?.uri ?? '');
  };

  const flowScreens = {
    depositFiat: <DepositIsFromCryptoTrading />,
    depositFiatUSD: <DepositAccountDetails />,
    depositCrypto: <DepositCrypto />
  };

  return (
    <DepositContext.Provider
      value={{
        account,
        accounts,
        usdAccount,
        setUsdAccount,
        depositAccountDetailsPreviousScreen,
        setDepositAccountDetailsPreviousScreen
      }}
    >
      {nextScreen && isSingleAccountLoaded ? (
        flowScreens[nextScreen]
      ) : actionBarContext && areAccountsLoaded ? (
        <ActionTab
          {...depositActionTabDefaultValues}
          onGoBack={actionBarContext.returnToActionBar}
          onClose={actionBarContext.closeActionTab}
          activeStepIndex={0}
          loading={areAccountsLoading || !!(isLoadingSingleAccount && nextScreen)}
        >
          <Typography variant="subtitle3">{t('banking.actions.deposit.selectDepositCurrency')}</Typography>
          <StyledWrappedAccountList>
            {accounts.map(account => (
              <StyledAccountWrapper direction="row" onClick={() => handleOnAccountClick(account)} key={getFormatedCurrencyText(account)}>
                <StyledFlag src={getCurrencyImage(account.currency)} alt={`${account.currency} flag`} />
                <StyledAccountCurrencyLabelAndCurrency>
                  <Typography variant="strongBody1">{account.currencyLabel ?? account.currency}</Typography>
                  <StyledAccountCurrency variant="body3">{getFormatedCurrencyText(account)}</StyledAccountCurrency>
                </StyledAccountCurrencyLabelAndCurrency>
              </StyledAccountWrapper>
            ))}
          </StyledWrappedAccountList>
        </ActionTab>
      ) : null}
    </DepositContext.Provider>
  );
};

export default DepositSelection;
