import { styled } from '@mui/material';
import { Alert } from 'src/components/Alert/Alert';

export const StyledAutocompleteWrapper = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(2)
}));

export const StyledAlert = styled(Alert)(({ theme }) => ({
  marginBottom: theme.spacing(2)
}));

export const StyledBox = styled('div')(({ theme }) => ({
  background: theme.palette.custom.grey[50],
  borderRadius: 12
}));
