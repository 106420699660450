import { forwardRef, useCallback } from 'react';
import { useSnackbar, CustomContentProps, SnackbarContent } from 'notistack';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';

import { Alert } from '../Alert/Alert';

const Snackbar = forwardRef<HTMLDivElement, CustomContentProps>(({ id, variant, message }, ref) => {
  const { closeSnackbar } = useSnackbar();

  const handleDismiss = useCallback(() => {
    closeSnackbar(id);
  }, [id, closeSnackbar]);

  return (
    <SnackbarContent ref={ref}>
      <Alert
        alertVariant={variant}
        action={
          <IconButton aria-label="close" onClick={handleDismiss}>
            <CloseIcon />
          </IconButton>
        }
      >
        <div dangerouslySetInnerHTML={{ __html: message! }} />
      </Alert>
    </SnackbarContent>
  );
});

export default Snackbar;
