import { ChangeEvent, FC, useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { InputAdornment, useTheme } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { toKebabCase } from 'src/utils/strings';
import useCopyToClipboard from 'src/utils/useCopyToClipboard';
import Tooltip from 'src/components/Tooltip/Tooltip';
import { localizedDateFormat } from 'src/utils/localization';

import SvgIcon from '../SvgIcon/SvgIcon';
import FormElement from '../FormElement/FormElement';

import { InputProps } from './Input.interfaces';
import { StyledTextField, StyledIcon, getPopperStyles } from './Input.styles';

const Input: FC<InputProps> = ({
  name,
  copyable,
  disabled = false,
  error,
  label,
  labelTooltip,
  tooltip,
  tooltipPlacement,
  trailingIcon,
  type,
  value,
  multiline,
  placeholder,
  handleError,
  handleOnChange,
  onSubmitEditing,
  ...props
}) => {
  const theme = useTheme();
  const [, copy] = useCopyToClipboard();

  const [showPassword, setShowPassword] = useState<boolean>(!(type === 'password'));

  const inputFormat = localizedDateFormat();
  const handleClickShowPassword = () => setShowPassword(!showPassword);

  const renderInput = () =>
    type === 'date' ? (
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DesktopDatePicker
          disabled={disabled}
          inputFormat={inputFormat}
          value={!!value ? dayjs(value) : null}
          disableFuture={name === 'dateOfBirth'}
          onChange={(newValue: Dayjs | null) => {
            if (handleOnChange) {
              handleOnChange(name, `${newValue?.format('YYYY-MM-DD')}` || '');
            }

            if (handleError && !!error) {
              handleError(name, '');
            }
          }}
          components={{
            OpenPickerIcon: () => (
              <StyledIcon>
                <SvgIcon icon="calendar" fontSize="inherit" />
              </StyledIcon>
            )
          }}
          renderInput={({ ...params }) => (
            <StyledTextField
              placeholder={placeholder || inputFormat.toLowerCase()}
              id={`${toKebabCase(label || 'default')}-${type}-input`}
              {...props}
              {...params}
              error={!!error}
            />
          )}
          PopperProps={{ sx: getPopperStyles(theme) }}
        />
      </LocalizationProvider>
    ) : (
      <StyledTextField
        autoComplete="off"
        error={!!error}
        disabled={disabled}
        multiline={multiline}
        id={`${toKebabCase(label || 'default')}-${type}-input`}
        type={showPassword ? 'text' : 'password'}
        value={value}
        placeholder={placeholder}
        onChange={(event: ChangeEvent<HTMLInputElement>) => {
          if (handleOnChange) {
            handleOnChange(name, event.target.value);
          }

          if (handleError && error) {
            handleError(name, '');
          }
        }}
        onKeyPress={e => {
          if (!multiline && onSubmitEditing && e.key === 'Enter') {
            onSubmitEditing();
            e.preventDefault();
          }
        }}
        InputProps={{
          endAdornment: (
            <>
              {!!value && type === 'password' && (
                <InputAdornment position="end">
                  <StyledIcon onClick={handleClickShowPassword}>
                    <SvgIcon icon={showPassword ? 'visibility' : 'visibilityOff'} fontSize="inherit" />
                  </StyledIcon>
                </InputAdornment>
              )}

              {!!value && copyable && (
                <InputAdornment position="end">
                  <StyledIcon onClick={() => copy(value)}>
                    <SvgIcon icon="copyClipboard" fontSize="inherit" />
                  </StyledIcon>
                </InputAdornment>
              )}

              {trailingIcon && <InputAdornment position="end">{trailingIcon}</InputAdornment>}
            </>
          )
        }}
        {...props}
      />
    );

  return (
    <FormElement label={label} labelTooltip={labelTooltip} error={error}>
      {!!tooltip ? (
        <Tooltip title={tooltip} placement={tooltipPlacement}>
          {renderInput()}
        </Tooltip>
      ) : (
        renderInput()
      )}
    </FormElement>
  );
};

export default Input;
