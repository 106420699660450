import { styled } from '@mui/material';

export const StyledTabListItem = styled('li')(({ theme }) => ({
  position: 'relative',
  padding: theme.spacing(2, 0),
  borderBottom: `1px solid ${theme.palette.grey[300]}`,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  transformStyle: 'preserve-3d',
  zIndex: 1,
  cursor: 'pointer',
  '&::before': {
    content: '""',
    position: 'absolute',
    display: 'block',
    inset: theme.spacing(2, 0),
    backgroundColor: theme.palette.custom.grey[50],
    opacity: 0,
    transform: 'translateZ(-1px)',
    zIndex: 0,
    borderRadius: 6
  },
  '&:hover': {
    '&::before': {
      opacity: 1,
      inset: theme.spacing(1, -1)
    }
  }
}));

export const StyledTabListCurrencyInfo = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center'
}));
export const StyledTabListFlag = styled('img')(({ theme }) => ({
  width: '32px',
  height: '32px',
  marginRight: theme.spacing(2)
}));
