import { Typography } from '@mui/material';
import { FC, PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonVariant } from 'src/constants/variants';
import { TransactionType } from 'src/interfaces/banking';
import {
  DEPOSIT_ACTION_DEFAULT_TOTAL_STEPS,
  SWAP_ACTION_DEFAULT_TOTAL_STEPS,
  TRANSFER_ACTION_DEFAULT_TOTAL_STEPS,
  WITHDRAW_ACTION_DEFAULT_TOTAL_STEPS
} from 'src/constants/actions';
import Loader from 'src/components/Loader/Loader';
import ActionButtons from 'src/components/ActionButtons/ActionButtons';

import { ActionTabProps } from './ActionTab.interfaces';
import {
  StyledActionTab,
  StyledActiveIndicator,
  StyledButtons,
  StyledCloseButton,
  StyledCloseIcon,
  StyledContent,
  StyledDescription,
  StyledDivider,
  StyledDividerWrapper,
  StyledHeader,
  StyledHeaderText
} from './ActionTab.styles';

const ActionTab: FC<PropsWithChildren<ActionTabProps>> = ({
  titleKey,
  descriptionKey,
  primaryActionTextKey,
  goBackActionTextKey,
  loading = false,
  children,
  onPrimaryActionClick,
  onGoBack,
  onClose,
  activeStepIndex,
  type,
  totalSteps
}) => {
  const { t } = useTranslation();

  const getTotalStepsForTransactionType = (transactionType: TransactionType) => {
    switch (transactionType) {
      case TransactionType.Deposit:
        return DEPOSIT_ACTION_DEFAULT_TOTAL_STEPS;
      case TransactionType.Swap:
        return SWAP_ACTION_DEFAULT_TOTAL_STEPS;
      case TransactionType.Withdraw:
        return WITHDRAW_ACTION_DEFAULT_TOTAL_STEPS;
      case TransactionType.Transfer:
        return TRANSFER_ACTION_DEFAULT_TOTAL_STEPS;
    }
  };

  return (
    <StyledActionTab>
      <StyledHeader>
        <StyledHeaderText>
          <Typography variant="subtitle3">{t(titleKey)}</Typography>
          <StyledDescription variant="body3">{t(descriptionKey)}</StyledDescription>
        </StyledHeaderText>
        <StyledCloseButton variant={ButtonVariant.Secondary} hasIcon onClick={onClose} size="small" startIcon={<StyledCloseIcon icon="close" />} />
      </StyledHeader>
      <StyledDividerWrapper>
        <StyledDivider />
        {activeStepIndex !== undefined && (
          <StyledActiveIndicator activeStepIndex={activeStepIndex} totalSteps={totalSteps ?? getTotalStepsForTransactionType(type)} />
        )}
      </StyledDividerWrapper>
      {loading ? (
        <StyledContent>
          <Loader anchorToRelative />
        </StyledContent>
      ) : (
        <>
          <StyledContent>{children}</StyledContent>
          {(onPrimaryActionClick || onGoBack) && (
            <StyledButtons>
              <ActionButtons
                primaryActionText={primaryActionTextKey && t(primaryActionTextKey)}
                onPrimaryActionClick={onPrimaryActionClick}
                secondaryActionText={onGoBack && t(goBackActionTextKey ?? 'common.goBack')}
                onSecondaryActionClick={onGoBack}
              />
            </StyledButtons>
          )}
        </>
      )}
    </StyledActionTab>
  );
};

export default ActionTab;
