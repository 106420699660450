import { styled } from '@mui/material';
import SvgIcon from 'src/components/SvgIcon/SvgIcon';

import { StyledToggleIconProps } from './ToggleIcon.interfaces';

export const StyledToggleIcon = styled(SvgIcon, {
  shouldForwardProp: prop => prop !== 'rotate'
})<StyledToggleIconProps>(({ rotate }) => ({
  fontSize: '12px',
  ...(rotate && { transform: 'rotate(180deg)' })
}));
