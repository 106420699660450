import { AxiosError, AxiosResponse } from 'axios';
import {
  IGetTransactionsResponse,
  IGetLegalEntitiesResponse,
  IGetAccountsResponse,
  IGetAccountDetailsResponse,
  IGetSwapQuotesResponse,
  IGetOrdersResponse,
  IRequestSwapResponse,
  IGetOrderResponse,
  IBankLookupResponse,
  IGetBeneficiariesResponse,
  IAllowedCountriesResponse,
  IGetTransactionResponse,
  IGetNetworksResponse,
  IGetLegalEntityResponse,
  IRequestPaymentResponse,
  IGetWithdrawalCurrencies,
  IGetRates
} from 'src/interfaces/responses';
import { IdentifierType } from 'src/constants/banking';
import { Beneficiary, CryptoBeneficiary, Order, SwapType } from 'src/interfaces/banking';

import { WithdrawPaymentDetails } from '../interfaces/contexts';

import { additionalUrls } from './additionalUrls';
import request from './request';

export const getAccounts = (uri: string): Promise<AxiosResponse<IGetAccountsResponse, AxiosError>> | undefined => request('get', uri)();

export const getAccountDetails = (uri: string): Promise<AxiosResponse<IGetAccountDetailsResponse, AxiosError>> | undefined => request('get', uri)();

export const getWithdrawalCurrencies = (uri: string): Promise<AxiosResponse<IGetWithdrawalCurrencies, AxiosError>> | undefined =>
  request('get', `${uri}${additionalUrls.withdrawalCurrencies}`)();

export const getLegalEntities = (userUri: string): Promise<AxiosResponse<IGetLegalEntitiesResponse, AxiosError>> | undefined =>
  request('get', `${userUri}${additionalUrls.legalEntities}`)();

export const getLegalEntity = (entityUri: string): Promise<AxiosResponse<IGetLegalEntityResponse, AxiosError>> | undefined =>
  request('get', `${entityUri}`)();

export const getTransactions = (uri: string, count: number, offset = 0): Promise<AxiosResponse<IGetTransactionsResponse, AxiosError>> | undefined =>
  request('get', `${uri}${additionalUrls.transactions}${count ? `?count=${count}&offset=${offset}` : ''}`)();

export const getTransaction = (uri: string): Promise<AxiosResponse<IGetTransactionResponse, AxiosError>> | undefined => request('get', uri)();

export const getRates = (base: string, quote: string): Promise<AxiosResponse<IGetRates, AxiosError>> | undefined =>
  request('get', `${additionalUrls.rates}?base=${base}&quote=${quote}`)();

export const bankLookup = (
  identifierValue: string,
  identifierType = IdentifierType.BicSwift,
  isExtended = false
): Promise<AxiosResponse<IBankLookupResponse, AxiosError>> =>
  request('post', `${additionalUrls.bankLookup}?isExtended=${isExtended}`)({ identifierType, identifierValue });

export const getSwapQuotes = (): Promise<AxiosResponse<IGetSwapQuotesResponse, AxiosError>> | undefined =>
  request('get', additionalUrls.swapQuotes)();

export const requestSwap = (
  sourceAccountId: string,
  targetAccountId: string,
  amount?: string
): Promise<AxiosResponse<IRequestSwapResponse, AxiosError>> | undefined =>
  request('post', additionalUrls.requestForSwap)({ sourceAccountId, targetAccountId, amount });

export const submitSwapOrder = (
  sourceAccountUri: string,
  targetAccountId: string,
  amount: string,
  fixedSide = SwapType.Source
): Promise<AxiosResponse<Order, AxiosError>> =>
  request('post', `${sourceAccountUri}${additionalUrls.swapOrder}`)({ targetAccountId, amount, fixedSide });

export const requestPayment = (
  sourceAccountId: string,
  amount?: string,
  bankCountry?: string,
  network?: string
): Promise<AxiosResponse<IRequestPaymentResponse, AxiosError>> | undefined =>
  request('post', additionalUrls.requestForPayment)({ sourceAccountId, amount, bankCountry, network });

export const submitPaymentOrder = (
  accountUri: string,
  amount: string,
  beneficiary: Beneficiary,
  paymentDetails: WithdrawPaymentDetails
): Promise<AxiosResponse<Order, AxiosError>> =>
  request('post', `${accountUri}${additionalUrls.paymentOrder}`)({ ...paymentDetails, amount, beneficiary });

export const submitCryptoPaymentOrder = (
  accountUri: string,
  amount: string,
  beneficiary: CryptoBeneficiary,
  paymentDetails: WithdrawPaymentDetails
): Promise<AxiosResponse<void, AxiosError>> =>
  request('post', `${accountUri}${additionalUrls.cryptoPaymentOrder}`)({ ...paymentDetails, beneficiary, amount });

export const updateOrder = (uri: string): Promise<AxiosResponse<void, AxiosError>> => request('post', uri)();

export const getOrder = (uri: string): Promise<AxiosResponse<IGetOrderResponse, AxiosError>> | undefined => request('get', uri)();

export const requestOrderRefresh = (uri: string): Promise<AxiosResponse<IGetOrderResponse, AxiosError>> | undefined =>
  request('post', `${uri}/refresh`)();

export const getOrders = (entityUri: string, status?: string): Promise<AxiosResponse<IGetOrdersResponse, AxiosError>> | undefined =>
  request('get', `${entityUri}${additionalUrls.orders}${status ? `?status=${status}` : ''}`)();

export const getBeneficiaries = (
  entityUri: string,
  filterValue: string
): Promise<AxiosResponse<IGetBeneficiariesResponse, AxiosError>> | undefined => {
  const requestUrl = `${entityUri}${additionalUrls.beneficiaries}?${filterValue}`;
  return request('get', requestUrl)();
};

export const getAllowedCountriesForPayement = (): Promise<AxiosResponse<IAllowedCountriesResponse[]>> =>
  request('get', `${additionalUrls.refData}${additionalUrls.allowedCountriesForPayment}`)();

export const createBeneficiary = (accountUri: string, beneficiary: Beneficiary): Promise<AxiosResponse<void, AxiosError>> =>
  request('post', `${accountUri}${additionalUrls.beneficiaries}`)({ ...beneficiary });

export const updateBeneficiary = (accountUri: string, beneficiary: Beneficiary): Promise<AxiosResponse<void, AxiosError>> =>
  request('patch', `${accountUri}${additionalUrls.beneficiaries}/${beneficiary._id}`)({ ...beneficiary });

export const deleteBeneficiary = (beneficiary: Beneficiary): Promise<AxiosResponse<void, AxiosError>> =>
  request('delete', `${beneficiary._links?.account.uri}${additionalUrls.beneficiaries}/${beneficiary._id}`)();

export const getNetworks = (
  accountNetworkUri: string,
  bankCountryCode: string,
  targetCurrency?: string
): Promise<AxiosResponse<IGetNetworksResponse, AxiosError>> =>
  request('get', `${accountNetworkUri}?bankCountry=${bankCountryCode}${targetCurrency ? `&targetCurrency=${targetCurrency}` : ''}`)();
