import { Box, Typography } from '@mui/material';
import { FC, PropsWithChildren } from 'react';
import Tooltip from 'src/components/Tooltip/Tooltip';
import SvgIcon from 'src/components/SvgIcon/SvgIcon';

import { FormElementProps } from './FormElement.interfaces';
import { StyledFormElement, StyledLabel, StyledError } from './FormElement.styles';

const FormElement: FC<PropsWithChildren<FormElementProps>> = ({ label, labelTooltip, error, children }) => (
  <StyledFormElement>
    {label && (
      <StyledLabel>
        <Typography variant="strongBody1">{label}</Typography>
        <Box sx={{ flexDirection: 'row' }}>
          {labelTooltip && (
            <Tooltip
              dark
              arrow
              title={
                <Typography variant="body3" textAlign="center" sx={{ whiteSpace: 'pre-line' }}>
                  {labelTooltip}
                </Typography>
              }
            >
              <div>
                <SvgIcon icon="info" />
              </div>
            </Tooltip>
          )}
        </Box>
      </StyledLabel>
    )}
    {children}
    {error && <StyledError variant="strongBody3">{error}</StyledError>}
  </StyledFormElement>
);

export default FormElement;
