import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Typography, Divider, MenuItem } from '@mui/material';
import { useState, FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonVariant } from 'src/constants/variants';
import Button from 'src/components/Button/Button';
import Menu from 'src/components/Menu/Menu';
import { WALLET_ACTIONS } from 'src/constants/banking';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import { AccountsTabGroupToggleProps } from './AccountsTabGroupToggle.interfaces';
import { StyledAccountGroupTogglePanel, StyledAccountGroupToggleAction } from './AccountsTabGroupToggle.styles';

const AccountsTabGroupToggle: FC<AccountsTabGroupToggleProps> = ({ total, name, currency, children, onChange }) => {
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState(true);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClose = (value?: string) => {
    setAnchorEl(null);
    if (value && onChange) {
      onChange(value);
    }
  };

  return (
    <>
      <StyledAccountGroupTogglePanel expanded={expanded}>
        <Button onClick={() => setExpanded(!expanded)} variant={ButtonVariant.Text} color="inherit" hasIcon size="small">
          <ArrowDropDownIcon />
          <span>{name}</span>
        </Button>
        <StyledAccountGroupToggleAction>
          <Typography variant="body2">
            <b>{total.toFixed(2)}</b> {` in ${currency}`}
          </Typography>

          {false && ( //mocked for now
            <>
              <Button
                size="small"
                variant={ButtonVariant.Secondary}
                hasIcon
                onClick={event => setAnchorEl(event.currentTarget)}
                startIcon={<MoreVertIcon />}
              />
              <Menu open={open} anchorEl={anchorEl} onClose={() => handleClose()}>
                {WALLET_ACTIONS.map(i => (
                  <MenuItem disableRipple key={i.value} onClick={() => handleClose(i.value)}>
                    {t(i.label)}
                  </MenuItem>
                ))}
              </Menu>
            </>
          )}
        </StyledAccountGroupToggleAction>
      </StyledAccountGroupTogglePanel>
      <Divider />
      {children(expanded)}
    </>
  );
};

export default AccountsTabGroupToggle;
