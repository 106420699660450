import { styled, ToggleButton, ToggleButtonGroup } from '@mui/material';

export const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: theme.spacing(3),
  backgroundColor: theme.palette.custom.grey[50],

  '& .MuiToggleButtonGroup-grouped': {
    margin: theme.spacing(0.5),
    border: 0,
    color: theme.palette.custom.grey[400],

    '&.Mui-disabled': {
      border: 0
    },
    '&.Mui-selected': {
      color: theme.palette.text.primary,
      borderRadius: theme.shape.borderRadius,
      backgroundColor: theme.palette.primary.contrastText,
      boxShadow: theme.shadows[1],

      '&:hover': {
        color: theme.palette.text.primary
      }
    },
    '&:not(:first-of-type)': {
      borderRadius: theme.shape.borderRadius
    },
    '&:first-of-type': {
      borderRadius: theme.shape.borderRadius
    }
  }
}));

export const StyledToggleButton = styled(ToggleButton)(({ theme }) => ({
  width: '50%',
  margin: theme.spacing(1)
}));
