import i18next from 'i18next';
import { FC } from 'react';
import { Grid, Typography, useMediaQuery } from '@mui/material';
import { DialogNavLinks } from 'src/components/DialogNavLinks/DialogNavLinks';
import { useTranslation } from 'react-i18next';
import {
  StyledContent,
  StyledContentWrapper,
  StyledLogo,
  StyledOnboardingImage,
  StyledStep,
  StyledSteps,
  StyledStepsWrapper,
  StyledWrapper
} from 'src/components/layouts/DialogLayout/DialogLayout.styles';
import { urls } from 'src/routes';
import { useNavigate } from 'react-router-dom';
import theme from 'src/constants/theme';
import ActionButtons from 'src/components/ActionButtons/ActionButtons';

import { StyledLastUpdated, StyledTitle, StyledPolicyWrapper } from './Disclaimers.styles';

const DISCLAIMER = 'Disclaimers';
const PRIVACY_POLICY = 'Privacy Policy';
const TERMS_AND_CONDITIONS = 'Terms and conditions';

const DisclaimerSteps = [
  {
    name: DISCLAIMER,
    value: i18next.t('disclaimers.disclaimers'),
    href: () => urls.disclaimers
  },
  {
    name: PRIVACY_POLICY,
    value: i18next.t('disclaimers.privacyPolicy'),
    href: () => urls.privacyPolicy
  },
  {
    name: TERMS_AND_CONDITIONS,
    value: i18next.t('disclaimers.termsAndConditions'),
    href: () => urls.termsAndConditions
  }
];

export const DisclaimersPage: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const isMobileView = useMediaQuery(theme.breakpoints.down('md'));

  const handleNavigate = <T,>(evt: React.MouseEvent<T, MouseEvent>, href: string) => {
    evt.preventDefault();
    navigate(href);
  };

  return (
    <StyledWrapper container isOnboardingLayout>
      <StyledLogo icon="logo" />
      <StyledOnboardingImage src={`${process.env.REACT_APP_ASSETS_URL}/images/onboarding.png`} alt="Onboarding image" />
      <>
        <Grid item xs={3} md={1} lg={2} />
        {!isMobileView && (
          <StyledStepsWrapper item xs={3} lg={2} isTypeformContent={false}>
            <StyledSteps>
              {DisclaimerSteps.map(step => (
                <StyledStep
                  isActive={DISCLAIMER === step.name}
                  key={step.name}
                  variant="strongBody1"
                  href={step.href()}
                  onClick={evt => handleNavigate(evt, step.href())}
                >
                  {t(step.value)}
                </StyledStep>
              ))}
            </StyledSteps>

            <DialogNavLinks />
          </StyledStepsWrapper>
        )}
        <StyledContentWrapper item xs={12} sm={5} lg={6} centeredContentWrapper>
          <StyledContent
            contentPadding={5}
            contentMargin={0}
            withOnboardingSteps
            isOnboardingLayout
            centeredContent={false}
            anchorToParent
            isTypeformContent={false}
          >
            <StyledTitle variant="h3">Disclaimers</StyledTitle>
            <Typography variant="body1">
              Read our general disclaimers below to learn more about your rights and responsibilities as a 21.co user.
            </Typography>
            <StyledLastUpdated variant="subtitle3">Last Updated: April 4, 2023</StyledLastUpdated>
            <StyledTitle variant="subtitle3">Payment Services</StyledTitle>
            <StyledPolicyWrapper>
              <Typography variant="strongBody4" component="p">
                Neither Currencycloud B.V. nor any other member of the Currencycloud Group offers services related to cryptocurrencies or
                crypto-assets (including but not limited to storage, exchange or transfer services) or has any involvement in crypto. All services are
                related to fiat e-money only.
              </Typography>
              <Typography variant="strongBody4" component="p">
                Payment services for Caterina B.V. are provided by The Currency Cloud Limited. Registered in England No. 06323311. Registered Office:
                Stewardship Building 1st Floor, 12 Steward Street London E1 6FQ. The Currency Cloud Limited is authorised by the Financial Conduct
                Authority under the Electronic Money Regulations 2011 for the issuing of electronic money (FRN: 900199)
              </Typography>
              <Typography variant="strongBody4" component="p">
                Payment services for Caterina B.V. are provided by CurrencyCloud B.V.. Registered in the Netherlands No. 72186178. Registered Office:
                Nieuwezijds Voorburgwal 296- 298, Mindspace Nieuwezijds Office 001 Amsterdam. CurrencyCloud B.V. is authorised by the DNB under the
                Wet op het financieel toezicht to carry out the business of a electronic-money institution (Relation Number: R142701)
              </Typography>
              <Typography variant="strongBody4" component="p">
                Payment services for Caterina B.V. are provided by The Currency Cloud Inc. which operates in partnership with Community Federal
                Savings Bank (CFSB) to facilitate payments in all 50 states in the US. CFSB is registered with the Federal Deposit Insurance
                Corporation (FDIC Certificate# 57129). The Currency Cloud Inc is registered with FinCEN and authorized in 39 states to transmit money
                (MSB Registration Number: 31000160311064). Registered Office: 104 5th Avenue, 20th Floor, New York , NY 10011.
              </Typography>
            </StyledPolicyWrapper>
            <ActionButtons secondaryActionText={t('disclaimers.backText')} onSecondaryActionClick={() => navigate(urls.signin)} />
          </StyledContent>
        </StyledContentWrapper>
      </>
    </StyledWrapper>
  );
};
