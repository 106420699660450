import { Typography } from '@mui/material';
import { FC, PropsWithChildren, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Lottie from 'lottie-react';
import Loader from 'src/components/Loader/Loader';
import signInLayoutAnimation from 'src/assets/lottieAnimations/signInLayoutAnimation.json';
import SvgIcon from 'src/components/SvgIcon/SvgIcon';
import { urls } from 'src/routes';
import { MenuBarContext } from 'src/contexts/MenuBar.contexts';

import { StyledLink, StyledReferenceIconWrapper } from '../FooterLayout/FooterLayout.styles';

import SignInLayoutProps from './SignInLayout.interfaces';
import {
  StyledChildren,
  StyledFooterContentWrapper,
  StyledHeading,
  StyledLeftSide,
  StyledLinkWrapper,
  StyledLogo,
  StyledMainContent,
  StyledRightSide,
  StyledSubtitle,
  StyledVideoWrapper,
  StyledWrapper
} from './SignInLayout.styles';

const SignInLayout: FC<PropsWithChildren<SignInLayoutProps>> = ({ title, subtitle, children, loading = false, contentLoading = false }) => {
  const { t } = useTranslation();
  const { setMenuBarHidden } = useContext(MenuBarContext);

  useEffect(() => {
    setMenuBarHidden(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <StyledWrapper>
      <StyledLeftSide>
        <StyledVideoWrapper>
          <Lottie animationData={signInLayoutAnimation} loop rendererSettings={{ preserveAspectRatio: 'xMidYMid slice' }} />
        </StyledVideoWrapper>
        <StyledLogo icon="logo" />
        <StyledHeading variant="h2">{t('common.onboardingSlogan')}</StyledHeading>
      </StyledLeftSide>
      <StyledRightSide>
        <StyledMainContent>
          {!contentLoading ? (
            <>
              {title && <Typography variant="h1">{title}</Typography>}
              {subtitle && <StyledSubtitle>{subtitle}</StyledSubtitle>}
              {children && <StyledChildren>{children}</StyledChildren>}
            </>
          ) : (
            <Loader />
          )}
        </StyledMainContent>
        <StyledFooterContentWrapper>
          <StyledLinkWrapper>
            <StyledLink href={`${urls.disclaimers}`}>
              {t('common.disclaimers')}
              <StyledReferenceIconWrapper>
                <SvgIcon icon="reference" />
              </StyledReferenceIconWrapper>
            </StyledLink>
            <StyledLink href={`${urls.privacyPolicy}`}>
              {t('common.privacyPolicy')}
              <StyledReferenceIconWrapper>
                <SvgIcon icon="reference" />
              </StyledReferenceIconWrapper>
            </StyledLink>
            <StyledLink href={`${urls.termsAndConditions}`}>
              {t('common.termsAndConditions')}
              <StyledReferenceIconWrapper>
                <SvgIcon icon="reference" />
              </StyledReferenceIconWrapper>
            </StyledLink>
            <StyledLink href="#">{`${t('common.copyright')} ${new Date().getFullYear()}`}</StyledLink>
          </StyledLinkWrapper>
          <Typography>{t('common.servicesProvidedByCurrencyCloud')}</Typography>
        </StyledFooterContentWrapper>
      </StyledRightSide>
      {loading && <Loader />}
    </StyledWrapper>
  );
};

export default SignInLayout;
