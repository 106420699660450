import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import SettingsLayout from 'src/components/layouts/SettingsLayout/SettingsLayout';

import { PERSONAL_SETTINGS_SUBPAGES } from './PersonalSettingsPage.constants';

const PersonalSettingsPage: FC = () => {
  const { t } = useTranslation();

  return (
    <SettingsLayout
      navigationTitle={t('banking.navigation.personalSettings')}
      sidebarTitle={t('personalSettings.common.sidebarTitle')}
      subpages={PERSONAL_SETTINGS_SUBPAGES}
    />
  );
};

export default PersonalSettingsPage;
