import { ICountryAbrreviations } from 'src/interfaces/responses';
import { PhoneNumberUtil, PhoneNumber as VendorPhoneNumber } from 'google-libphonenumber';
import { ICountryCallingCodeAndAbbreviation } from 'src/interfaces/countries';

const phoneUtil = PhoneNumberUtil.getInstance();

const parseRawNumber = (rawNumber: string, region?: string | undefined) => phoneUtil.parseAndKeepRawInput(rawNumber, region);

const getRegionCodeFromParsedNumber = (parsedPhoneNumber: VendorPhoneNumber) => phoneUtil.getRegionCodeForNumber(parsedPhoneNumber);

export const getRegionCodeFromCountryCode = (regionCallingCode: string): string => phoneUtil.getRegionCodeForCountryCode(Number(regionCallingCode));

export const getCountryCallingCodeFromRegionCode = (countryAbbr: string): string => `+${phoneUtil.getCountryCodeForRegion(countryAbbr)}`;

export const getListOfCountriesCallingCodes = (countriesList: ICountryAbrreviations[] = []): ICountryCallingCodeAndAbbreviation[] =>
  countriesList
    .filter(country => phoneUtil.getSupportedRegions().includes(country.alpha2))
    .map(country => ({
      countryName: country.name,
      countryAbbreviation: country.alpha2,
      countryCallingCode: phoneUtil.getCountryCodeForRegion(country.alpha2) as unknown as string
    }));

export const getParsedPhoneNumber = (value: string) => {
  const phoneNumberValues = {
    nationalNumber: '',
    countryCodeValue: '',
    countryNameAbbreviation: ''
  };
  if (value) {
    const phoneNumber: VendorPhoneNumber | undefined = parseRawNumber(value);
    phoneNumberValues.countryCodeValue = `+${phoneNumber.getCountryCode()}`;
    phoneNumberValues.nationalNumber = `${phoneNumber.getNationalNumber()}`;
    phoneNumberValues.countryNameAbbreviation = getRegionCodeFromParsedNumber(phoneNumber) ?? '';
  }
  return phoneNumberValues;
};

export const formaNationalPhoneNumberBasedOnRegion = (num: string | number, regionCode = '') => {
  try {
    const number = parseRawNumber(String(num), regionCode);
    const formatedNumber = phoneUtil.formatInOriginalFormat(number, phoneUtil.getRegionCodeForNumber(number));
    return formatedNumber;
  } catch (e) {}

  return String(num);
};

export const getPlaceholderExample = (regionCode = 'CH') => {
  const example = phoneUtil.getExampleNumber(regionCode);
  return phoneUtil.formatInOriginalFormat(example, phoneUtil.getRegionCodeForNumber(example));
};
