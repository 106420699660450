import { Grid, Typography, useMediaQuery } from '@mui/material';
import { FC, PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import { ONBOARDING_STEPS } from 'src/constants/onboarding';
import { OnboardingStepName, CorporateOnboardingStepName } from 'src/interfaces/types';
import theme from 'src/constants/theme';
import ActionButtons from 'src/components/ActionButtons/ActionButtons';
import FooterLayout from 'src/components/layouts/FooterLayout/FooterLayout';
import Loader from 'src/components/Loader/Loader';
import { DialogNavLinks } from 'src/components/DialogNavLinks/DialogNavLinks';
import { MobileDialogLayout } from 'src/components/layouts/MobileDialogLayout/MobileDialogLayout';

import { DialogLayoutProps } from './DialogLayout.interfaces';
import {
  StyledContent,
  StyledContentWrapper,
  StyledImage,
  StyledLogo,
  StyledOnboardingImage,
  StyledStep,
  StyledSteps,
  StyledStepsWrapper,
  StyledSubtitle,
  StyledWrapper
} from './DialogLayout.styles';

const DialogLayout: FC<PropsWithChildren<DialogLayoutProps>> = ({
  headerImage,
  children,
  heading,
  subheading,
  primaryActionText,
  onPrimaryActionClick,
  secondaryActionText,
  secondaryBackAction,
  onSecondaryActionClick,
  loading = false,
  contentPadding,
  contentMargin = 0,
  contentLoading = false,
  isOnboardingLayout = false,
  withOnboardingSteps = false,
  isTypeformContent = false,
  onboardingSteps = ONBOARDING_STEPS,
  centeredContent = false,
  activeStepName,
  anchorToParent = false,
  centeredContentWrapper = false,
  showNavLinks
}) => {
  const { t } = useTranslation();
  const isMobileView = useMediaQuery(theme.breakpoints.down('md'));

  return !isMobileView ? (
    <StyledWrapper isOnboardingLayout={isOnboardingLayout} container>
      <StyledLogo icon="logo" />
      {isOnboardingLayout && <StyledOnboardingImage src={`${process.env.REACT_APP_ASSETS_URL}/images/onboarding.png`} alt="Onboarding image" />}
      {!withOnboardingSteps ? (
        <Grid item xs={0} sm={3} />
      ) : (
        <>
          <Grid item xs={3} md={1} lg={isTypeformContent ? 1 : 2} />
          <StyledStepsWrapper item xs={isTypeformContent ? 2 : 3} lg={2} isTypeformContent={isTypeformContent}>
            <StyledSteps>
              {onboardingSteps.map(step => (
                <StyledStep isActive={activeStepName === step.name} key={step.name} variant="strongBody1">
                  {t(step.value)}
                </StyledStep>
              ))}
            </StyledSteps>
            <DialogNavLinks />
          </StyledStepsWrapper>
        </>
      )}
      {!withOnboardingSteps && showNavLinks && <DialogNavLinks absolutePosition={showNavLinks} />}
      <StyledContentWrapper item xs={12} sm={withOnboardingSteps ? 5 : 6} lg={6} centeredContentWrapper={centeredContentWrapper}>
        <StyledContent
          contentPadding={contentPadding ?? 5}
          contentMargin={contentMargin}
          withOnboardingSteps={withOnboardingSteps}
          isOnboardingLayout={isOnboardingLayout}
          centeredContent={centeredContent}
          anchorToParent={anchorToParent}
          isTypeformContent={isTypeformContent}
        >
          {headerImage && <StyledImage>{headerImage}</StyledImage>}
          {heading && <Typography variant={isOnboardingLayout && withOnboardingSteps ? 'h3' : 'h1'}>{heading}</Typography>}
          {subheading && <StyledSubtitle variant="body1">{subheading}</StyledSubtitle>}
          {contentLoading ? <Loader anchorToRelative /> : children}
          <ActionButtons
            primaryActionText={primaryActionText}
            onPrimaryActionClick={onPrimaryActionClick}
            secondaryActionText={secondaryActionText}
            secondaryBackAction={secondaryBackAction}
            onSecondaryActionClick={onSecondaryActionClick}
          />
        </StyledContent>
      </StyledContentWrapper>
      {!isOnboardingLayout && <FooterLayout />}
      {loading && <Loader />}
    </StyledWrapper>
  ) : (
    <MobileDialogLayout
      loading={loading}
      contentLoading={contentLoading}
      heading={heading}
      subheading={subheading}
      isOnboardingLayout={isOnboardingLayout}
      onboardingSteps={onboardingSteps}
      activeStepName={activeStepName as OnboardingStepName | CorporateOnboardingStepName}
      primaryActionText={primaryActionText}
      onPrimaryActionClick={onPrimaryActionClick}
      headerImage={headerImage}
      secondaryBackAction={secondaryBackAction}
      secondaryActionText={secondaryActionText}
      onSecondaryActionClick={onSecondaryActionClick}
    >
      {children}
    </MobileDialogLayout>
  );
};

export default DialogLayout;
