import { TransactionFilter, AccountSorts, AccountDetailsHintIcons, BeneficiaryFilter } from 'src/interfaces/banking';
import { IOption } from 'src/interfaces/forms';

/* TODO: Rename this file probably for something more concise - banking is really everything in this app */
export enum IdentifierType {
  BicSwift = 'bic_swift',
  Iban = 'iban',
  RoutingNumber = 'aba'
}

export const ACCOUNT_SORTS: IOption[] = [
  {
    value: AccountSorts.Default,
    label: 'banking.sorts.default'
  },
  {
    value: AccountSorts.AlphabeticalAZ,
    label: 'banking.sorts.alphabeticalAZ'
  },
  {
    value: AccountSorts.AlphabeticalZA,
    label: 'banking.sorts.alphabeticalZA'
  },
  {
    value: AccountSorts.BalanceHighest,
    label: 'banking.sorts.balanceHighest'
  },
  {
    value: AccountSorts.BalanceLowest,
    label: 'banking.sorts.balanceLowest'
  }
];

export const TRANSACTION_FILTERS: IOption[] = [
  {
    value: TransactionFilter.All,
    label: 'banking.transactions.sorts.all'
  },
  {
    value: TransactionFilter.Swaps,
    label: 'banking.transactions.sorts.swaps'
  },
  {
    value: TransactionFilter.Deposits,
    label: 'banking.transactions.sorts.deposits'
  },
  {
    value: TransactionFilter.Withdraws,
    label: 'banking.transactions.sorts.withdraws'
  }
  // TODO: Re-enable after MVP
  // {
  //   value: TransactionFilter.Transfers,
  //   label: 'banking.transactions.sorts.transfers'
  // }
];

export const BENEFICIARY_FILTERS: IOption[] = [
  {
    value: BeneficiaryFilter.Newest,
    label: 'companySettings.beneficiaries.newest'
  },
  {
    value: BeneficiaryFilter.Oldest,
    label: 'companySettings.beneficiaries.oldest'
  },
  {
    value: BeneficiaryFilter.Alphabetical,
    label: 'companySettings.beneficiaries.alphabetical'
  }
];

export const ACCOUNT_DETAIL_HINT_ICONS: AccountDetailsHintIcons = {
  important: 'warning',
  transfer: 'coins'
};

export const WALLET_ACTIONS: IOption[] = [
  {
    value: 'edit',
    label: 'Edit Wallet'
  },
  {
    value: 'disconnect',
    label: 'Disconnect Wallet'
  }
];
