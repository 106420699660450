import { styled, Typography } from '@mui/material';

export const StyledFormElement = styled('div')(({ theme }) => ({
  display: 'flex',
  textAlign: 'left',
  flexDirection: 'column',
  marginBottom: theme.spacing(3)
}));

export const StyledLabel = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
  marginBottom: theme.spacing(1),

  '.MuiSvgIcon-root': {
    fontSize: '1rem',
    cursor: 'pointer'
  }
}));

export const StyledError = styled(Typography)(({ theme }) => ({
  marginTop: 8,
  color: theme.palette.error.main
}));
