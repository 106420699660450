import { useMutation, useQuery, useQueryClient, UseQueryResult } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { IGetLegalEntityMembersResponse, IResponseError } from 'src/interfaces/responses';
import { QueryKey } from 'src/constants/queryKeys';
import { LegalEntityMember } from 'src/interfaces/settings';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

import {
  createLegalEntityMember,
  getLegalEntityMembers,
  removeLegalEntityMember,
  resendInvitation,
  updateLegalEntityMember,
  verifyInvitation
} from '../settings';

/* TODO: Make a template/common way to create queries/mutations (naming, structure etc.) */
export const useGetLegalEntityMembers = (
  legalEntityUri: string
): UseQueryResult<AxiosResponse<IGetLegalEntityMembersResponse, AxiosError> | undefined> =>
  useQuery([QueryKey.QUERY_LEGAL_ENTITY_MEMBERS, legalEntityUri], () => getLegalEntityMembers(legalEntityUri), {
    enabled: !!legalEntityUri
  });

export const useVerifyInvitationToken = () =>
  useMutation({
    mutationFn: (invitationToken: string) => verifyInvitation(invitationToken)
  });

export const useCreateLegalEntityMember = (legalEntityUri: string) => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  return useMutation({
    mutationFn: (member: LegalEntityMember) => createLegalEntityMember(legalEntityUri, member),
    onSuccess: () => {
      enqueueSnackbar(t('companySettings.members.inviteMemberSnackbarContent'), {
        variant: 'info'
      });
      queryClient.invalidateQueries([QueryKey.QUERY_LEGAL_ENTITY_MEMBERS]);
    },
    onError: (error: AxiosError<IResponseError>) => {
      const message = error?.response?.data?.message;
      enqueueSnackbar(message ?? t('screens.error'), { variant: 'error' });
    }
  });
};

export const useResendInvitation = (resendInvitationUri: string) => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  return useMutation({
    mutationFn: (otp: string) => resendInvitation(resendInvitationUri, otp),
    onSuccess: () => {
      enqueueSnackbar(t('companySettings.members.resendInvitationSnackbarContent'), {
        variant: 'info'
      });
      queryClient.invalidateQueries([QueryKey.QUERY_LEGAL_ENTITY_MEMBERS]);
    },
    onError: (error: AxiosError<IResponseError>) => {
      const message = error?.response?.data?.message;
      enqueueSnackbar(message ?? t('screens.error'), { variant: 'error' });
    }
  });
};

export const useUpdateLegalEntityMember = (uri: string) => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (permissions: Pick<LegalEntityMember, 'corporateTitle' | 'role'>) => updateLegalEntityMember(uri, permissions),
    onSuccess: () => {
      enqueueSnackbar(t('companySettings.members.updateMemberSnackbarContent'), {
        variant: 'info'
      });
      queryClient.invalidateQueries([QueryKey.QUERY_LEGAL_ENTITY_MEMBERS]);
    },
    onError: (error: AxiosError<IResponseError>) => {
      const message = error?.response?.data?.message;
      enqueueSnackbar(message ?? t('screens.error'), { variant: 'error' });
    }
  });
};

export const useRemoveLegalEntityMember = (uri: string) => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  return useMutation({
    mutationFn: () => removeLegalEntityMember(uri),
    onSuccess: () => {
      // TODO: Change with with translation (now on Figma we don't have screen for that)
      enqueueSnackbar('Member has been removed', {
        variant: 'success'
      });
      queryClient.invalidateQueries([QueryKey.QUERY_LEGAL_ENTITY_MEMBERS]);
    },
    onError: (error: AxiosError<IResponseError>) => {
      const message = error?.response?.data?.message;
      enqueueSnackbar(message ?? t('screens.error'), { variant: 'error' });
    }
  });
};
