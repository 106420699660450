import { UseQueryResult, useQuery } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { QueryKey } from 'src/constants/queryKeys';
import { IGetRates } from 'src/interfaces/responses';

import { getRates } from '../banking';

export const useRates = (
  base: string,
  quote: string
): UseQueryResult<AxiosResponse<IGetRates, AxiosError>, { response: AxiosResponse<AxiosError> }> => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  return useQuery([QueryKey.QUERY_RATES, base, quote], () => getRates(base, quote), {
    onError: error => {
      enqueueSnackbar(error?.response?.data?.message ?? t('screens.error'), {
        variant: 'error'
      });
    },
    enabled: !!base && !!quote && base !== quote
  });
};
