import { useSnackbar } from 'notistack';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useIdleTimer } from 'react-idle-timer';
import { useNavigate } from 'react-router-dom';
import { urls } from 'src/routes';
import { isLoggedIn, logout } from 'src/utils/authentication';

const TIMEOUT_WARNING = 'timeout-warning';
const TIMEOUT_SESSION_EXPIRED = 'timeout-session-expired';

export const useIdle = ({ paused = false, defaultWarningDuration = 60_000, defaultTimeout = 600_000 }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const timeout = 1000 * 60 * process.env.REACT_APP_IDLE_TIMEOUT_MINUTES || defaultTimeout;
  const warningDuration = 1000 * 60 * process.env.REACT_APP_IDLE_TIMEOUT_WARNING_MINUTES || defaultWarningDuration;

  const idleTimer = useIdleTimer({
    onIdle: () => {
      if (isLoggedIn()) {
        logout();
        navigate(urls.signin);
        enqueueSnackbar(t('common.sessionExpired'), { variant: 'info', persist: true, key: TIMEOUT_SESSION_EXPIRED });
      }
    },
    onPrompt: () => {
      enqueueSnackbar(t('common.sessionTimeoutPrompt'), { variant: 'warning', autoHideDuration: warningDuration, key: TIMEOUT_WARNING });
    },
    onAction: (_, idleTimer) => {
      closeSnackbar(TIMEOUT_WARNING);
      idleTimer?.activate();
    },
    throttle: 500,
    promptBeforeIdle: warningDuration,
    timeout
  });

  useEffect(() => {
    if (paused) {
      idleTimer.pause();
    } else {
      idleTimer.reset();
      idleTimer.resume();
    }
    return () => {
      if (paused) {
        idleTimer.reset();
        idleTimer.resume();
      }
    };
  }, [idleTimer, paused]);

  useEffect(() => {
    if (!isLoggedIn()) {
      navigate(urls.signin);
    }
  }, [navigate]);

  useEffect(() => {
    closeSnackbar(TIMEOUT_SESSION_EXPIRED);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return idleTimer;
};
