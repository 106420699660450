import { createTheme, Shadows } from '@mui/material';

const theme = createTheme({
  breakpoints: {
    values: {
      xxs: 0,
      xs: 600,
      sm: 816,
      md: 928,
      lg: 1284,
      xl: 1440
    }
  },
  palette: {
    background: {
      default: '#FAFAFA'
    },
    text: {
      primary: '#111',
      secondary: '#393939'
    },
    primary: {
      main: '#DA674E',
      dark: '#A83B23',
      light: '#FAEAE7',
      contrastText: '#fff'
    },
    error: {
      main: '#D21414',
      dark: '#8E2020',
      light: '#F8DCDC'
    },
    success: {
      main: '#327c65',
      light: '#DFEEDA'
    },
    custom: {
      gold: {
        100: '#F5EDE2',
        200: '#EAD5BB',
        400: '#B6A48E',
        600: '#837260',
        800: '#4F4133',
        accent: '#F8E8CA'
      },
      coralDusk: {
        50: '#FAEAE7',
        100: '#F4D3CC',
        200: '#EEBCB1',
        300: '#E38D7B',
        400: '#DA674E',
        500: '#A83B23',
        700: '#802C1A',
        900: '#140704'
      },
      grey: {
        50: '#f0f0f0',
        100: '#ddd',
        200: '#b4b4b4',
        300: '#888',
        400: '#606060',
        600: '#393939',
        offBlack: '#111',
        offWhite: '#fafafa'
      }
    }
  },
  components: {
    MuiLink: {
      styleOverrides: {
        root: {
          color: '#606060',
          cursor: 'pointer',
          textDecoration: 'none'
        }
      }
    },
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          body3: 'p',
          body4: 'p',
          button2: 'p',
          subtitle3: 'p',
          strongBody1: 'p',
          strongBody2: 'p',
          strongBody3: 'p'
        }
      }
    }
  },
  spacing: 8,
  shape: {
    borderRadius: 8
  },
  typography: {
    fontFamily: ['Inter', 'Roboto', 'Helvetica', 'Arial', 'sans-serif'].join(','),
    h1: {
      fontSize: '40px',
      lineHeight: '48px',
      fontWeight: 700
    },
    h2: {
      fontSize: '40px',
      lineHeight: '48px',
      fontWeight: 400
    },
    h3: {
      fontSize: '24px',
      lineHeight: '32px',
      fontWeight: 700
    },
    h4: {
      fontSize: '24px',
      lineHeight: '32px',
      fontWeight: 400
    },
    subtitle1: {
      fontSize: '20px',
      lineHeight: '32px',
      fontWeight: 700
    },
    subtitle2: {
      fontSize: '20px',
      lineHeight: '32px',
      fontWeight: 500
    },
    subtitle3: {
      fontSize: '16px',
      lineHeight: '24px',
      fontWeight: 500,
      letterSpacing: '-0.01em'
    },
    body1: {
      fontSize: '16px',
      lineHeight: '24px'
    },
    body2: {
      fontSize: '15px',
      lineHeight: '24px'
    },
    body3: {
      fontSize: '14px',
      lineHeight: '20px',
      fontWeight: 400,
      letterSpacing: '-0.01em'
    },
    body4: {
      fontSize: '12px',
      lineHeight: '16px',
      fontWeight: 400
    },
    strongBody1: {
      fontSize: '16px',
      lineHeight: '24px',
      fontWeight: 500
    },
    strongBody2: {
      fontSize: '15px',
      lineHeight: '24px',
      fontWeight: 500
    },
    strongBody3: {
      fontSize: '14px',
      lineHeight: '20px',
      letterSpacing: '-0.01em',
      fontWeight: 500
    },
    strongBody4: {
      fontSize: '12px',
      lineHeight: '16px',
      fontWeight: 500
    },
    button: {
      fontSize: '16px',
      lineHeight: '20px',
      textTransform: 'none',
      fontWeight: 600
    },
    button2: {
      fontSize: '15px',
      lineHeight: '20px',
      textTransform: 'none',
      fontWeight: 600
    }
  },
  shadows: [
    'none',
    '0px 4px 4px rgba(50, 124, 101, 0.05), inset 0px 1px 0px rgba(255, 255, 255, 0.1)',
    'inset 0px 1px 2px rgba(255, 255, 255, 0.05)',
    '0px 2px 4px rgba(17, 17, 17, 0.05)',
    '0px 4px 16px rgba(17, 17, 17, 0.15)',
    '0px 1px 1px rgba(17, 17, 17, 0.08), 0px 4px 16px rgba(17, 17, 17, 0.08), 0px 4px 12px rgba(17, 17, 17, 0.04), 0px 2px 6px rgba(17, 17, 17, 0.12)',
    '0px 24px 24px rgba(50, 124, 101, 0.05)', // StatusIcon
    '0px 24px 24px rgba(210, 20, 20, 0.05)', // StatusIcon error
    '0px 16px 24px rgba(0, 0, 0, 0.05)', // Onboarding layout content box shadow
    '0px 1px 2px rgba(0, 0, 0, 0.03), 0px 4px 6px rgba(0, 0, 0, 0.02)', // Banking top navigation box shadow
    '-1px 0px 0px #F0F0F0', // Banking layout sidebar box shadow
    '0px 1px 0px #DDDDDD', // EntitySwitch header
    'inset 0px 1px 3px rgba(44, 34, 76, 0.15)', // AccountMenu
    '0px 24px 24px rgba(218, 103, 78, 0.05)',
    ...Array(14).fill('none')
  ] as Shadows
});

export default theme;
