import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { AxiosResponse, AxiosError } from 'axios';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { QueryKey } from 'src/constants/queryKeys';
import { ICountryAbrreviations } from 'src/interfaces/responses';

import { getCountries } from '../countries';

export const useCountriesQuery = (
  enabled = true,
  showSnackbar = false
): UseQueryResult<AxiosResponse<ICountryAbrreviations[], AxiosError>, { response: AxiosResponse<AxiosError> }> => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  return useQuery([QueryKey.QUERY_COUNTRIES], () => getCountries(), {
    enabled,
    onError: error => {
      if (showSnackbar) {
        enqueueSnackbar(error?.response?.data?.message ?? t('screens.error'), {
          variant: 'error'
        });
      }
      return error.response;
    }
  });
};
