import { FC } from 'react';
import DialogLayout from 'src/components/layouts/DialogLayout/DialogLayout';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import StatusIcon from 'src/components/StatusIcon/StatusIcon';
import SvgIcon from 'src/components/SvgIcon/SvgIcon';
import { urls } from 'src/routes';
import { ONBOARDING_EMAIL_VERIFICATION } from 'src/constants/onboarding';

const EmailVerifiedPage: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <DialogLayout
      isOnboardingLayout
      headerImage={<StatusIcon icon={<SvgIcon icon="check" />} />}
      heading={t('screens.verifyEmail.success.title')}
      subheading={t('screens.verifyEmail.success.subtitle')}
      primaryActionText={t('common.continue')}
      onPrimaryActionClick={() => navigate(urls.enable2fa)}
      activeStepName={ONBOARDING_EMAIL_VERIFICATION}
    />
  );
};

export default EmailVerifiedPage;
