import { Select, styled, Typography } from '@mui/material';

export const StyledDropdown = styled(Select)(({ theme }) => ({
  width: '100%',
  minWidth: '220px',
  height: '48px',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.common.white,
  [theme.breakpoints.down('md')]: {
    minWidth: '35vw'
  },
  '.MuiPaper-root': {
    overflowY: 'hidden',

    '.MuiList-root': {
      overflowY: 'auto',
      maxHeight: '40vh',
      padding: 0,
      margin: theme.spacing(1),

      '::-webkit-scrollbar': {
        width: '4px'
      },
      '::-webkit-scrollbar-track': {
        background: 'transparent'
      },
      '::-webkit-scrollbar-thumb': {
        background: '#dadada', // TODO: This color isn't present in color palette
        borderRadius: '100px'
      },
      '.MuiMenuItem-root': {
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        marginRight: theme.spacing(0.5),
        borderRadius: '4px',

        '&[aria-selected="true"]': {
          color: theme.palette.custom.coralDusk[400],
          backgroundColor: 'transparent'
        },
        '&:hover': {
          background: theme.palette.custom.grey[50]
        },
        '&.Mui-selected:active': {
          backgroundColor: theme.palette.custom.coralDusk[50]
        }
      }
    }
  },
  '& .MuiSelect-select': {
    padding: theme.spacing(1.5, 2)
  },
  '& .MuiSelect-select.Mui-disabled': {
    backgroundColor: theme.palette.custom.grey.offWhite
  },
  '.MuiMenu-paper': {
    boxShadow: theme.shadows[5]
  }
}));

export const StyledPlaceholder = styled(Typography)(({ theme }) => ({
  color: theme.palette.custom.grey[300]
}));
