import { Theme } from '@mui/material';
import { TransactionType } from 'src/interfaces/banking';

import { BackgroundColorVariantForTransactionTypeProps } from '../interfaces/styles';

export const getBackgroundColorVariantForTransactionType = (
  theme: Theme,
  transactionType: TransactionType
): BackgroundColorVariantForTransactionTypeProps => {
  switch (transactionType) {
    case TransactionType.Swap:
      return {
        defaultColor: theme.palette.custom.coralDusk[400],
        hoverColor: theme.palette.custom.coralDusk[500]
      };
    case TransactionType.Transfer:
      // TODO: Add hover bg color for transfer when will be available
      return {
        defaultColor: theme.palette.custom.coralDusk[300]
      };
    case TransactionType.Deposit:
      return {
        defaultColor: theme.palette.custom.gold[600],
        hoverColor: theme.palette.custom.gold[800]
      };
    case TransactionType.Withdraw:
      return {
        defaultColor: theme.palette.custom.gold[400],
        hoverColor: theme.palette.custom.gold[600]
      };
  }
};

export const getCurrencyImage = (currency: string) => `${process.env.REACT_APP_ASSETS_URL}/images/currencies/${currency?.toLowerCase()}.png`;
