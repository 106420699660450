import { LegalEntityMemberStatus } from 'src/constants/settings';
import { IAction } from 'src/interfaces/global';

export interface LegalEntityMember {
  created: string;
  status: LegalEntityMemberStatus;
  firstName: string;
  lastName: string;
  contact: {
    email: string;
  };
  corporateTitle: string;
  role: string;
  _actions?: {
    edit?: IAction;
    delete?: IAction;
    resendInvitation?: IAction;
  };
}

export enum CompanySettingsTeamMembersTabState {
  ExistingMembers = 'existing_members',
  InvitedMembers = 'invited_members'
}

export enum CompanySettingsBeneficiariesTabState {
  FiatBeneficiaries = 'fiat_beneficiaries',
  CryptoBeneficiaries = 'crypto_beneficiaries'
}
