import { Drawer, useMediaQuery } from '@mui/material';
import { FC, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import MenuBar from 'src/components/banking/MenuBar/MenuBar';
import SvgIcon from 'src/components/SvgIcon/SvgIcon';
import theme from 'src/constants/theme';
import { MenuBarContext } from 'src/contexts/MenuBar.contexts';
import { IconKeys } from 'src/components/SvgIcon/SvgIcon.interfaces';
import SettingsLogoutModal from 'src/components/modals/settings/SettingsLogoutModal/SettingsLogoutModal';

import { SettingsLayoutProps, Subpage } from './SettingsLayout.interfaces';
import {
  StyledContentWithoutMenuBar,
  StyledMenuIcon,
  StyledMobileLogoutButtonWrapper,
  StyledSettingsLayout,
  StyledSettingsLayoutMobileHeader,
  StyledSettingsMobileHeader,
  StyledSubPageButton,
  StyledSubPageButtonText,
  StyledSubPagesContentWrapper,
  StyledSubPagesNavWrapper,
  StyledSubPagesTitle
} from './SettingsLayout.styles';

const SettingsLayout: FC<SettingsLayoutProps> = ({ navigationTitle, sidebarTitle, subpages, logoutVisible = true }) => {
  const { t } = useTranslation();
  const menuBarContext = useContext(MenuBarContext);
  const isMobileView = useMediaQuery(theme.breakpoints.down('md'));
  const [subpage, setSubpage] = useState<Subpage>(subpages[0]);
  const [isLogoutModalOpened, setIsLogoutModalOpened] = useState<boolean>(false);

  return (
    <>
      <StyledSettingsLayout direction={!isMobileView ? 'row' : 'column'}>
        {isMobileView ? (
          <>
            <StyledSettingsLayoutMobileHeader>
              <StyledMenuIcon onClick={() => menuBarContext.setMenuBarHidden(false)} />
              <StyledSettingsMobileHeader>{navigationTitle}</StyledSettingsMobileHeader>
              {logoutVisible && (
                <StyledMobileLogoutButtonWrapper onClick={() => setIsLogoutModalOpened(true)}>
                  <SvgIcon icon="logoutSettingsWhite" />
                </StyledMobileLogoutButtonWrapper>
              )}
            </StyledSettingsLayoutMobileHeader>
            <Drawer variant="persistent" anchor="left" open={!menuBarContext.menuBarHidden}>
              <MenuBar
                hidden={menuBarContext.menuBarHidden}
                setHidden={() => menuBarContext.setMenuBarHidden(!menuBarContext.menuBarHidden)}
                disabled={false}
              />
            </Drawer>
          </>
        ) : (
          <MenuBar hidden={menuBarContext.menuBarHidden} disabled={false} setHidden={menuBarContext.setMenuBarHidden} />
        )}
        <StyledContentWithoutMenuBar menuBarExpanded={!menuBarContext.menuBarHidden}>
          <StyledSubPagesNavWrapper>
            <StyledSubPagesTitle variant="strongBody3">{sidebarTitle}</StyledSubPagesTitle>

            {subpages.map(s => (
              <StyledSubPageButton key={s.path} onClick={() => setSubpage(s)} isActive={s === subpage}>
                <SvgIcon icon={s.path as IconKeys} />

                <StyledSubPageButtonText variant="strongBody2">{t(s.i18nTitle)}</StyledSubPageButtonText>
              </StyledSubPageButton>
            ))}

            {!isMobileView && logoutVisible && (
              <StyledSubPageButton key={subpage?.path} onClick={() => setIsLogoutModalOpened(true)}>
                <SvgIcon icon="logoutSettings" />
                <StyledSubPageButtonText variant="body2">{t('common.logout')}</StyledSubPageButtonText>
              </StyledSubPageButton>
            )}
          </StyledSubPagesNavWrapper>

          <StyledSubPagesContentWrapper>
            <subpage.component />
          </StyledSubPagesContentWrapper>
        </StyledContentWithoutMenuBar>
      </StyledSettingsLayout>

      {logoutVisible && <SettingsLogoutModal isOpen={isLogoutModalOpened} onClose={() => setIsLogoutModalOpened(false)} />}
    </>
  );
};

export default SettingsLayout;
