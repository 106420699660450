import { SdkError, init } from 'onfido-sdk-ui';
import { FC, memo, useLayoutEffect } from 'react';
import { useKYCStartDataQuery } from 'src/api/queries/useKYCStartData';
import { useUserOrNavigateQuery } from 'src/api/queries/useUserOrNavigate';

import { StyledOnfidoContainer, onfidoCustomUI } from './Onfido.styles';
import { OnfidoProps } from './Onfido.interfaces';

const ONFIDO_CONTAINER_ID = 'onfido-mount';

const handleError = (err: SdkError): void => console.warn('OnfidoSDK: Error:', err.message);

const Onfido: FC<OnfidoProps> = ({ setInProgress }) => {
  const { data: user } = useUserOrNavigateQuery();
  const { data: kycStartData } = useKYCStartDataQuery(user?._links.self?.uri ?? '');
  const kycData = kycStartData?.data;

  const handleKYCComplete = async (): Promise<void> => {
    setInProgress(true);
  };

  useLayoutEffect(() => {
    if (!!document.getElementById(ONFIDO_CONTAINER_ID) && !!kycData?.sdkToken && !!kycData?.workflowRunId) {
      init({
        region: kycData.region,
        token: kycData.sdkToken,
        containerId: ONFIDO_CONTAINER_ID,
        onComplete: handleKYCComplete,
        onError: handleError,
        customUI: onfidoCustomUI,
        workflowRunId: kycData.workflowRunId
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [kycData]);

  return <StyledOnfidoContainer id={ONFIDO_CONTAINER_ID} />;
};

export default memo(Onfido);
