import { FormControl, RadioGroup, styled } from '@mui/material';

import { StyledFormControlProps, StyledRadioGroupProps } from './RadioGroup.interfaces';

export const StyledFormControl = styled(FormControl, {
  shouldForwardProp: prop => prop !== 'horizontal'
})<StyledFormControlProps>(({ horizontal }) => ({
  ...(horizontal && { flexDirection: 'row', alignItems: 'center' })
}));

export const StyledRadioGroup = styled(RadioGroup, {
  shouldForwardProp: prop => prop !== 'horizontal'
})<StyledRadioGroupProps>(({ horizontal }) => ({
  ...(horizontal && { flexDirection: 'row', alignItems: 'center' })
}));

export default StyledFormControl;
