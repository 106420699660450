import BankingLayout from 'src/components/layouts/BankingLayout/BankingLayout';
import { useAccountsQuery } from 'src/api/queries/useAccounts';
import { useQueryClient } from '@tanstack/react-query';
import { useMemo, useState } from 'react';
import { AccountDetailsTabState, Transaction } from 'src/interfaces/banking';
import { useOrdersQuery } from 'src/api/queries/useOrders';
import { getTransactionsFromOrders } from 'src/utils/transactions';
import Transactions from 'src/components/banking/Transactions/Transactions';
import Tabs from 'src/components/banking/Tabs/Tabs';
import { Alert } from 'src/components/Alert/Alert';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { useInView } from 'react-intersection-observer';
import { usePagination } from 'src/hooks/usePagination';
import { getTransactions } from 'src/api/banking';
import { QueryKey } from 'src/constants/queryKeys';
import { TRANSACTION_TABS } from 'src/constants/tabs';
import { getMatchedAccounts } from 'src/utils/accounts';
import { getSelectedLegalEntityUri } from 'src/utils/storage';

import { getTotalBalance } from '../BalancesPage/BalancesPage.utils';

const TransactionsPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const queryClient = useQueryClient();

  const [accountOrders, setAccountOrders] = useState<Transaction[]>();

  const entityUri = getSelectedLegalEntityUri();

  const { ref, inView: loadMore } = useInView();
  const {
    paginationData: transactionsData,
    isLoadingPaginationData: isLoadingTransactions,
    isPaginationError: isTransactionsError
  } = usePagination(entityUri, loadMore, QueryKey.QUERY_TRANSACTIONS, getTransactions);

  const transactions = useMemo(() => transactionsData?.pages.map(group => group.response).flat(), [transactionsData]);

  const { data: orders, isLoading: isOrdersLoading, isError: isOrdersError } = useOrdersQuery(entityUri);

  const { data: accountsData, isLoading } = useAccountsQuery();
  const accounts = useMemo(() => getMatchedAccounts(accountsData?.data ?? []), [accountsData?.data]);

  useMemo(() => {
    if (!isOrdersLoading && orders?.data) {
      setAccountOrders(getTransactionsFromOrders(orders.data));
    }
  }, [isOrdersLoading, orders]);

  // Orders
  const ordersTab = isOrdersError ? <Alert>{t('banking.errors.orders')}</Alert> : <Transactions orders transactions={accountOrders} />;

  // Transactions
  const transactionsTab = isTransactionsError ? (
    <Alert>{t('banking.errors.transactions')}</Alert>
  ) : (
    <Transactions transactions={transactions} viewBottomRef={ref} />
  );

  let tabIndex = TRANSACTION_TABS.findIndex(t => location.pathname.includes(t.state as string));
  if (tabIndex === -1) {
    tabIndex = 0;
  }
  const contents = [transactionsTab, ordersTab];
  const content = (
    <Tabs
      tabs={TRANSACTION_TABS}
      counts={TRANSACTION_TABS.map(t => (t.state === AccountDetailsTabState.Orders ? accountOrders?.length ?? 0 : 0))}
      value={tabIndex}
      onChange={(_, value) => {
        const { state, invalidationKey } = TRANSACTION_TABS[value];
        navigate(`/${state}`);
        if (invalidationKey) {
          queryClient.invalidateQueries([invalidationKey]);
        }
      }}
    >
      {contents[tabIndex]}
    </Tabs>
  );

  return (
    <BankingLayout
      combined
      loading={isLoading || isLoadingTransactions || isOrdersLoading}
      currency="USD"
      totalBalance={getTotalBalance(accounts)}
      content={content}
      disabled={accountsData?.data.length === 0}
      mobileHeader={t('banking.navigation.transactions')}
    />
  );
};

export default TransactionsPage;
