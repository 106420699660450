import { FormControlLabel, Checkbox, styled, alpha, Typography } from '@mui/material';

import { StyledCheckboxProps } from './Checkbox.interfaces';

export const StyledRow = styled('div')({
  display: 'flex',
  alignItems: 'center',
  alignContent: 'space-between'
});

export const StyledFormControlLabel = styled(FormControlLabel)({
  margin: 0,
  fontWeight: 500
});

export const StyledCheckbox = styled(Checkbox, {
  shouldForwardProp: prop => prop !== 'isError'
})<StyledCheckboxProps>(({ theme, isError }) => ({
  padding: 0,
  margin: '0 12px 0 2px',
  width: '20px',
  height: '20px',
  borderRadius: '4px',
  ...(isError && { outline: `1px solid ${theme.palette.error.main}` }),

  '.MuiSvgIcon-root': {
    fill: 'none',
    borderRadius: '4px'
  },
  '&.Mui-checked': {
    ...(isError && {
      outline: `1px solid ${theme.palette.custom.coralDusk[400]}`
    }),

    '.MuiSvgIcon-root': {
      backgroundColor: theme.palette.custom.coralDusk[400]
    },
    '&:hover .MuiSvgIcon-root': {
      backgroundColor: theme.palette.custom.coralDusk[500]
    }
  },
  '&.Mui-focusVisible': {
    outline: `4px solid ${alpha(theme.palette.custom.coralDusk[400] || theme.palette.primary.main, 0.15)}`,
    outlineOffset: 2
  },
  '&:not(.Mui-checked):hover': {
    outline: `1px solid ${theme.palette.custom.grey[300]}`,
    outlineOffset: 1
  }
}));

export const StyledExpandable = styled('div')({
  flexGrow: 1,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'end',
  cursor: 'pointer'
});

export const StyledDescription = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(2),
  whiteSpace: 'pre-line',
  color: theme.palette.text.secondary
}));
