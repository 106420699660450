import { AxiosError, AxiosResponse } from 'axios';
import { IIdentityVerificationResponse, IKYCStartResponse, ISignupResponse, IVerifyEmailResponse } from 'src/interfaces/responses';
import { IFormValues } from 'src/interfaces/forms';

import { additionalUrls } from './additionalUrls';
import request from './request';

export const signup = (body: IFormValues): Promise<AxiosResponse<ISignupResponse, AxiosError>> | undefined =>
  request('post', additionalUrls.signup)(body);

export const waitlist = (email: string, countryOfResidence: string, locale: string): Promise<AxiosResponse<void, AxiosError>> | undefined =>
  request('post', additionalUrls.waitlist + additionalUrls.registrations)({ email, countryOfResidence, locale });

export const verifyEmail = (verificationToken: string): Promise<AxiosResponse<IVerifyEmailResponse, AxiosError>> | undefined =>
  request('post', additionalUrls.verifyEmail)({ verificationToken });

export const resendVerificationEmail = (email: string, region: string): Promise<AxiosResponse<void, AxiosError>> | undefined =>
  request('post', additionalUrls.resendVerificationEmail)({ email, region });

export const savePersonalInfo = (userUri: string, body: IFormValues): Promise<AxiosResponse<void, AxiosError>> | undefined =>
  request('post', `${userUri}${additionalUrls.personalInfo}`)(body);

export const saveAdditionalInfo = (userUri: string, body: IFormValues): Promise<AxiosResponse<void, AxiosError>> | undefined =>
  request('post', `${userUri}${additionalUrls.additionalInfo}`)(body);

export const startIdentityVerification = (
  userUri: string,
  parameters: unknown
): Promise<AxiosResponse<IIdentityVerificationResponse, AxiosError>> | undefined =>
  request('post', `${userUri}${additionalUrls.startIdentityVerification}`)(parameters);

export const getKYCStartData = (userUri: string): Promise<AxiosResponse<IKYCStartResponse, AxiosError>> | undefined =>
  request('post', `${userUri}${additionalUrls.kycStart}`)();
