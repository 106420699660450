import { FC } from 'react';

import SvgIcon from '../SvgIcon/SvgIcon';

import { CustomAlertProps } from './Alert.interfaces';
import { StyledMuiAlert } from './Alert.styles';

export const Alert: FC<CustomAlertProps> = ({ alertVariant = 'error', ...props }) => (
  <StyledMuiAlert
    icon={
      <SvgIcon
        icon={(() => {
          switch (alertVariant) {
            case 'success':
              return 'successSnack';
            case 'warning':
            case 'error':
              return 'errorSnack';
            case 'default':
            case 'info':
            default:
              return 'infoSnack';
          }
        })()}
      />
    }
    alertVariant={alertVariant}
    severity={alertVariant !== 'default' ? alertVariant : 'info'}
    {...props}
  />
);
