import { styled, Typography } from '@mui/material';
import SvgIcon from 'src/components/SvgIcon/SvgIcon';

export const StyledTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
  marginBottom: theme.spacing(3)
}));

export const StyledPersonalSettingsAboutFormRow = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'flex-start',
  justifyContent: 'space-between',
  padding: theme.spacing(4, 0),
  borderBottom: `solid 1px ${theme.palette.custom.grey[50]}`
}));

export const StyledPersonalSettingsAboutFormRowTitle = StyledTitle;

export const StyledPersonalSettingsAboutFormRowButtonIcon = styled(SvgIcon)({
  fontSize: 16
});
