import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { IGetAccountsResponse } from 'src/interfaces/responses';
import { QueryKey } from 'src/constants/queryKeys';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { getSelectedLegalEntityUri } from 'src/utils/storage';

import { getAccounts } from '../banking';

export const useAccountsQuery = (): UseQueryResult<AxiosResponse<IGetAccountsResponse, AxiosError>, { response: AxiosResponse<AxiosError> }> => {
  const { t } = useTranslation();

  const entityUri = getSelectedLegalEntityUri();
  const { enqueueSnackbar } = useSnackbar();

  return useQuery([QueryKey.QUERY_ENTITY_ACCOUNTS, entityUri], () => getAccounts(`${entityUri}/accounts`), {
    onError: error => {
      enqueueSnackbar(error?.response?.data?.message ?? t('screens.error'), {
        variant: 'error'
      });
    },
    enabled: !!entityUri
  });
};
