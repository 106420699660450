import { FC, PropsWithChildren } from 'react';

import { IconTextProps } from './IconText.interfaces';
import { StyledIcon, StyledLabelValue } from './IconText.styles';

const IconText: FC<PropsWithChildren<IconTextProps>> = ({ icon, children }) => (
  <StyledLabelValue>
    <StyledIcon>{icon}</StyledIcon>
    {children}
  </StyledLabelValue>
);

export default IconText;
