import { sendAnalytics } from '~utils/onfidoApi'
import type { ExtendedStepTypes, UrlsConfig } from '~types/commons'
import type {
  AnalyticsTrackedEventDetail,
  LegacyTrackedEventNames,
} from '~types/tracker'
import { reduxStore } from 'components/ReduxAppWrapper'
import { analyticsEventsMapping } from './trackerData'
import { trackException } from './'
import * as execeptionTracking from '~core/ExceptionHandler'
import { createAnalyticsPayload } from '~core/Analytics/createAnalyticsPayload'
import { Queue } from '~core/util/Queue'

let currentStepType: ExtendedStepTypes | undefined
let analyticsSessionUuid: string | undefined
let token: string | undefined
let urls: UrlsConfig
let isCrossDeviceClient: boolean | undefined
let enabled: boolean | undefined
let sdkConfigurationIsLoaded = false
let currentScriptHostname: string | undefined

const listener = () => {
  const store = reduxStore.getState()
  token = store.globals.token
  currentStepType = store.globals.currentStepType
  urls = store.globals.urls
  isCrossDeviceClient = store.globals.isCrossDeviceClient
  analyticsSessionUuid = store.globals.analyticsSessionUuid
  enabled =
    store.globals.sdkConfiguration?.sdk_features?.enable_in_house_analytics

  sdkConfigurationIsLoaded = !!store.globals.sdkConfiguration
  if (sdkConfigurationIsLoaded && queue.paused) {
    queue.resume()
  }
  currentScriptHostname = store.globals.currentScriptHostname
}

reduxStore.subscribe(listener)

type AnalyticsProperties = {
  country_code?: string
  document_format?: string
  document_side?: string
  event_type?: string
  is_autocapture?: string
  step?: string
}

type sendAnalyticsEventProps = {
  event: LegacyTrackedEventNames
  eventProperties: Optional<Record<string, unknown>>
}

const queue = new Queue<sendAnalyticsEventProps>({
  limit: 1,
  paused: true,
}).on('flush', (batch) => {
  batch.forEach(({ item: { event, eventProperties } }) => {
    sendAnalyticsEvent(event, eventProperties)
  })
})

export const sendAnalyticsEvent = (
  event: LegacyTrackedEventNames,
  eventProperties: Optional<Record<string, unknown>>
): void => {
  if (!sdkConfigurationIsLoaded) {
    queue.push({ event, eventProperties })
    return
  }

  const eventData = analyticsEventsMapping.get(event)

  if (!eventData?.eventName) {
    const msg = `Legacy event is not mapped - ${event}`
    console.error(msg)
    trackException(msg)
    return
  }

  const properties = {
    step: currentStepType,
    is_cross_device: isCrossDeviceClient,
    ...eventProperties,
    ...eventData?.properties,
  }

  const analyticsPayload = createAnalyticsPayload<AnalyticsProperties>({
    event: eventData.eventName,
    current_script_hostname: currentScriptHostname,
    properties,
  })

  // Broadcast analytics event for subscribers, e.g.: Passive Signals
  dispatchEvent(
    new CustomEvent<AnalyticsTrackedEventDetail>('analyticsTrackedEvent', {
      detail: { eventName: eventData.eventName, properties },
    })
  )

  // Do not send requests without analyticsSessionUuid
  // We need at least one identification property to identify the flow
  if (!enabled || !token) return
  if (!analyticsSessionUuid) {
    trackException('No analytics session uuid while sending analytics event')
    return
  }

  // TODO: Convert analytics to include an eventemitter
  execeptionTracking.addBreadcrumb({
    message: `Analytics event: ${eventData?.eventName}`,
    data: properties,
  })

  const payload = JSON.stringify({ events: [analyticsPayload] })
  const url = `${urls.onfido_api_url}/v4/analytics`

  sendAnalytics(url, payload, token)
}
