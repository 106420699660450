import { FC, useState } from 'react';
import { useSnackbar } from 'notistack';
import { IFormHandleSubmit } from 'src/interfaces/forms';
import Form from 'src/components/Form/Form';
import { resetPasswordRequestSchemaUrl } from 'src/api/formSchemas';
import Input from 'src/components/Input/Input';
import ActionButtons from 'src/components/ActionButtons/ActionButtons';
import { useNavigate } from 'react-router-dom';
import { resetPasswordRequest } from 'src/api/auth';
import { useTranslation } from 'react-i18next';
import { ERROR_UNKNOWN_EMAIL } from 'src/constants/errors';
import DialogLayout from 'src/components/layouts/DialogLayout/DialogLayout';
import StatusIcon from 'src/components/StatusIcon/StatusIcon';
import SvgIcon from 'src/components/SvgIcon/SvgIcon';
import { Link, Typography } from '@mui/material';
import { urls } from 'src/routes';

const ForgotPasswordPage: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);

  const handleSubmit: IFormHandleSubmit = async (values, validateForm, handleError) => {
    if (validateForm(values)) {
      setLoading(true);

      resetPasswordRequest(values.email as string)
        ?.then(_ => {
          setSuccess(true);
        })
        .catch(error => {
          try {
            const { code, message } = error.response.data;
            if (code === ERROR_UNKNOWN_EMAIL) {
              handleError('email', message);
            } else {
              enqueueSnackbar(message, { variant: 'error' });
            }
          } catch {}
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  if (success) {
    return (
      <DialogLayout
        headerImage={<StatusIcon icon={<SvgIcon icon="email" />} />}
        heading={t('screens.forgotPassword.success.title')}
        subheading={t('screens.forgotPassword.success.subtitle')}
        secondaryBackAction
        secondaryActionText={t('screens.forgotPassword.backText')}
        onSecondaryActionClick={() => navigate(urls.signin)}
      >
        <Typography color="text.secondary">
          {t('screens.forgotPassword.success.didntGetEmail')}{' '}
          <Link
            color="text.secondary"
            onClick={e => {
              window.location.href = 'mailto:support@caterina.com';
              e.preventDefault();
            }}
          >
            {t('screens.forgotPassword.success.customerSupport')}
          </Link>
        </Typography>
      </DialogLayout>
    );
  }

  return (
    <DialogLayout
      loading={loading}
      headerImage={<StatusIcon icon={<SvgIcon icon="key" />} />}
      heading={t('screens.forgotPassword.title')}
      subheading={t('screens.forgotPassword.subtitle')}
    >
      <Form schemaUrl={resetPasswordRequestSchemaUrl} onSubmit={handleSubmit}>
        <Input name="email" type="email" />

        <ActionButtons
          primaryActionText={t('screens.forgotPassword.button')}
          secondaryBackAction
          secondaryActionText={t('screens.forgotPassword.backText')}
          onSecondaryActionClick={() => navigate(urls.signin)}
        />
      </Form>
    </DialogLayout>
  );
};

export default ForgotPasswordPage;
