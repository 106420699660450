import { Avatar, styled } from '@mui/material';
import Button from 'src/components/Button/Button';

export const StyledListRow = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: theme.spacing(1.5, 1),
  borderBottom: `1px solid ${theme.palette.custom.grey[50]}`
}));

export const StyledListRowMetadata = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1.75)
}));

export const StyledListRowAvatar = styled(Avatar)(({ theme }) => ({
  width: '32px',
  height: '32px',
  backgroundColor: theme.palette.custom.coralDusk[50],
  color: theme.palette.custom.coralDusk[400],
  ...theme.typography.body2,
  letterSpacing: '-0.01em'
}));

export const StyledListRowActionButton = styled(Button)(({ theme }) => ({
  '&.MuiButton-sizeLarge': {
    padding: '1.5px 6.5px'
  },
  '&.MuiButton-textPrimary': {
    color: theme.palette.custom.grey[300],

    ':hover': {
      backgroundColor: 'transparent',
      color: theme.palette.custom.grey[300]
    }
  }
}));

export const StyledRightSide = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(2)
}));
