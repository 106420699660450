import { Box, Drawer, Typography } from '@mui/material';
import { FC, PropsWithChildren, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import ActionButtons from 'src/components/ActionButtons/ActionButtons';
import { DialogNavLinks } from 'src/components/DialogNavLinks/DialogNavLinks';
import Loader from 'src/components/Loader/Loader';
import { MobileStepper } from 'src/components/MobileStepper/MobileStepper';

import { MobileDialogLayoutProps } from './MobileDialogLayout.interfaces';
import {
  StyledWrapper,
  StyledContent,
  StyledArrowBackIcon,
  StyledMobileHeader,
  StyledMenuIcon,
  StyledList,
  StyledMobileImage,
  StyledText,
  StyledTextSubheading
} from './MobileDialogLayout.styles';

export const MobileDialogLayout: FC<PropsWithChildren<MobileDialogLayoutProps>> = ({
  children,
  heading,
  subheading,
  loading = false,
  contentLoading = false,
  onboardingSteps,
  activeStepName,
  isOnboardingLayout = false,
  primaryActionText,
  onPrimaryActionClick,
  headerImage,
  secondaryBackAction,
  secondaryActionText,
  onSecondaryActionClick
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const toggleDrawer = () => setIsDrawerOpen(!isDrawerOpen);

  const activeStepIndex = useMemo(
    () => (onboardingSteps && activeStepName ? onboardingSteps.findIndex(step => step.name === activeStepName) ?? -1 : -1),
    [onboardingSteps, activeStepName]
  );

  const onboardingStep = onboardingSteps && activeStepIndex > -1 ? onboardingSteps[activeStepIndex] : undefined;

  const mobileHeadingText = onboardingStep ? t(onboardingStep.value) : heading;

  return (
    <StyledWrapper container onboardingLayout={isOnboardingLayout}>
      <StyledMobileHeader direction="row" marginBottom={activeStepName ? 0 : 2} onboardingLayout={isOnboardingLayout}>
        <StyledArrowBackIcon onClick={() => navigate(-1)} hideArrow={activeStepIndex > 0} />

        {!!mobileHeadingText && (
          <Typography variant="button" textAlign="center">
            {mobileHeadingText}
          </Typography>
        )}
        <StyledMenuIcon onClick={toggleDrawer} onboardingLayout={isOnboardingLayout} />
      </StyledMobileHeader>
      <Drawer open={isDrawerOpen} onClose={toggleDrawer} anchor="top">
        <Box sx={{ width: 'auto' }} onClick={toggleDrawer} onKeyDown={toggleDrawer}>
          <StyledList>
            <DialogNavLinks />
          </StyledList>
        </Box>
      </Drawer>
      {onboardingSteps && activeStepIndex !== -1 && <MobileStepper totalSteps={onboardingSteps.length} activeStep={activeStepIndex + 1} />}
      <StyledContent hideRadius={!isOnboardingLayout}>
        {headerImage && <StyledMobileImage>{headerImage}</StyledMobileImage>}
        {headerImage && (
          <StyledText textAlign="center" variant="h3">
            {heading}
          </StyledText>
        )}
        {subheading && (
          <StyledTextSubheading textAlign={headerImage ? 'center' : 'left'} variant="subtitle3">
            {subheading}
          </StyledTextSubheading>
        )}
        {contentLoading ? <Loader anchorToRelative /> : children}
        <ActionButtons
          primaryActionText={primaryActionText}
          onPrimaryActionClick={onPrimaryActionClick}
          secondaryActionText={secondaryActionText}
          secondaryBackAction={secondaryBackAction}
          onSecondaryActionClick={onSecondaryActionClick}
        />
      </StyledContent>
      {loading && <Loader />}
    </StyledWrapper>
  );
};
