import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { IGetNetworksResponse } from 'src/interfaces/responses';
import { QueryKey } from 'src/constants/queryKeys';

import { getNetworks } from '../banking';

export const useNetworksQuery = (
  accountUri: string,
  bankCountryCode: string,
  targetCurrency?: string
): UseQueryResult<AxiosResponse<IGetNetworksResponse, AxiosError>, { response: AxiosResponse<AxiosError> }> =>
  useQuery(
    [QueryKey.QUERY_NETWORKS, accountUri, bankCountryCode, targetCurrency],
    () => getNetworks(accountUri ?? '', bankCountryCode, targetCurrency),
    {
      enabled: !!accountUri && !!bankCountryCode
    }
  );
