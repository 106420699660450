import { useContext, useState } from 'react';
import ActionTab from 'src/components/banking/actions/ActionTab/ActionTab';
import { ActionBarContext } from 'src/contexts/ActionBar.contexts';
import { WithdrawCryptoContext } from 'src/contexts/actions/Withdraw.contexts';
import { Account, CurrencyType, CryptoBeneficiary } from 'src/interfaces/banking';
import { useAccountsQuery } from 'src/api/queries/useAccounts';
import { WithdrawPaymentDetails } from 'src/interfaces/contexts';
import { AccountAndAmount } from 'src/components/banking/AccountAndAmount/AccountAndAmount';
import { useLocation } from 'react-router-dom';
import { getDefaultAccount } from 'src/utils/accounts';
import { cryptoPaymentOrderSchemaUrl } from 'src/api/formSchemas';
import { IFormHandleSubmit, IFormValues } from 'src/interfaces/forms';
import Form from 'src/components/Form/Form';
import Input from 'src/components/Input/Input';
import ActionButtons from 'src/components/ActionButtons/ActionButtons';
import { useTranslation } from 'react-i18next';

import { AlertType, WithdrawCryptoScreens } from '../Withdraw.interfaces';
import { withdrawActionTabDefaultValues } from '../Withdraw.utils';
import WithdrawMethodSelection from '../WithdrawMethodSelection/WithdrawMethodSelection';
import WithdrawCryptoDetails from '../WithdrawCryptoDetails/WithdrawCryptoDetails';

import { StyledActionButtons, StyledAlert, StyledFormWrapper } from './WithdrawCrypto.styles';

export const WithdrawCrypto = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const actionBarContext = useContext(ActionBarContext);
  const cryptoContext = useContext(WithdrawCryptoContext);

  const { data: accountsData, isLoading: isLoadingAccounts } = useAccountsQuery();

  const accountUri = (location.state as Account)?._links?.self?.uri;
  const accounts: Account[] = accountsData?.data.filter(account => !!account._actions?.send && account.type === CurrencyType.Crypto) ?? [];

  const [nextScreen, setNextScreen] = useState<WithdrawCryptoScreens>();

  const [alert, setAlert] = useState<AlertType | undefined>();
  const [amount, setAmount] = useState<string>(cryptoContext?.amount ?? '0');
  const [account, setAccount] = useState<Account | undefined>(cryptoContext?.account ?? getDefaultAccount(accounts, accountUri));
  const [paymentDetails, setPaymentDetails] = useState<WithdrawPaymentDetails>(cryptoContext?.paymentDetails ?? {});
  const [beneficiaryDetails, setBeneficiaryDetails] = useState<CryptoBeneficiary | undefined>(cryptoContext?.beneficiaryDetails ?? {});

  const initialValues = paymentDetails as unknown as IFormValues;

  const flowScreens = {
    withdrawMethodSelection: <WithdrawMethodSelection />,
    withdrawCryptoDetails: <WithdrawCryptoDetails />
  };

  const invalidAmount = Number(amount) <= 0;
  const insufficientBalance = !!(account?.balance && Number(amount) > Number(account.balance));

  const handleSubmit: IFormHandleSubmit = async (values, validateForm) => {
    if (invalidAmount) {
      return setAlert('minAmountCrypto');
    } else if (insufficientBalance) {
      return setAlert('insufficientBalance');
    }
    if (setPaymentDetails && validateForm(values)) {
      const beneficiary = values?.beneficiary as unknown as CryptoBeneficiary;
      setBeneficiaryDetails({
        walletAddress: beneficiary.walletAddress
      });
      setPaymentDetails(values as unknown as WithdrawPaymentDetails);
      setNextScreen('withdrawCryptoDetails');
    }
  };

  return (
    <WithdrawCryptoContext.Provider
      value={{
        amount,
        setAmount,
        account,
        setAccount,
        paymentDetails,
        setPaymentDetails,
        beneficiaryDetails,
        setBeneficiaryDetails
      }}
    >
      {nextScreen ? (
        flowScreens[nextScreen]
      ) : actionBarContext ? (
        <ActionTab onClose={actionBarContext.closeActionTab} activeStepIndex={1} {...withdrawActionTabDefaultValues} loading={isLoadingAccounts}>
          {!!alert && <StyledAlert alertVariant="error">{t(`banking.actions.withdraw.${alert}`)}</StyledAlert>}
          <AccountAndAmount
            accounts={accounts}
            amount={amount}
            setAmount={setAmount}
            account={account}
            setAccount={setAccount}
            invalidAmount={insufficientBalance || (alert === 'minAmountCrypto' && invalidAmount)}
          />
          <StyledFormWrapper>
            <Form schemaUrl={cryptoPaymentOrderSchemaUrl(account?._links.self.uri ?? '')} initialValues={initialValues} onSubmit={handleSubmit}>
              <Input name="beneficiary.walletAddress" multiline maxRows={2} />
              <Input name="vendorNote" multiline maxRows={4} />
              <Input name="personalNote" multiline maxRows={2} />

              <StyledActionButtons>
                <ActionButtons
                  primaryActionText={t('common.continue')}
                  secondaryActionText={t('common.goBack')}
                  onSecondaryActionClick={() => setNextScreen('withdrawMethodSelection')}
                />
              </StyledActionButtons>
            </Form>
          </StyledFormWrapper>
        </ActionTab>
      ) : null}
    </WithdrawCryptoContext.Provider>
  );
};
