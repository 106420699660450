import { useMediaQuery } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Route as RouteComponent, Routes, useLocation } from 'react-router-dom';
import { PusherNotificationSeverity } from 'src/constants/notifications';
import { QueryKey } from 'src/constants/queryKeys';
import theme from 'src/constants/theme';
import { InvitationTokenContext } from 'src/contexts/InvitationToken.contexts';
import { MenuBarContext } from 'src/contexts/MenuBar.contexts';
import { PusherNotificationMessage } from 'src/interfaces/notifications';
import { isLoggedIn } from 'src/utils/authentication';

import { defaultRoutes, protectedRoutes } from './routes';
import SignInPage from './pages/SignInPage/SignInPage';
import NotFoundPage from './pages/NotFoundPage/NotFoundPage';
import { Environment } from './constants/environment';
import ProtectedRoute from './components/ProtectedRoute/ProtectedRoute';
import { getEnvironment } from './utils/environment';
import PusherClient from './utils/pusher';

const App = () => {
  const env = getEnvironment();

  const location = useLocation();
  const isMobileView = useMediaQuery(theme.breakpoints.down('md'));
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const [menuBarHidden, setMenuBarHidden] = useState<boolean>(isMobileView);
  const [invitationToken, setInvitationToken] = useState<string | undefined>();
  const [isNotificationServiceConnected, setIsNotificationServiceConnected] = useState<boolean>(false);

  useEffect(() => {
    if (isLoggedIn() && !PusherClient.isConnected()) {
      PusherClient.initialize(
        () => setIsNotificationServiceConnected(true),
        (msg: PusherNotificationMessage) => {
          enqueueSnackbar(msg.text, {
            variant: msg.severity ? (msg.severity.toLowerCase() as PusherNotificationSeverity) : PusherNotificationSeverity.INFO
          });
          queryClient.invalidateQueries([QueryKey.QUERY_ORDERS]);
          queryClient.invalidateQueries([QueryKey.QUERY_TRANSACTIONS]);
        }
      );
      console.log('Listening for notifications...');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, isNotificationServiceConnected]);

  return (
    <HelmetProvider>
      <Helmet>
        <title>
          Caterina
          {env !== Environment.PROD ? ` - ${env}` : ''}
        </title>
      </Helmet>
      <MenuBarContext.Provider
        value={{
          menuBarHidden,
          setMenuBarHidden
        }}
      >
        <InvitationTokenContext.Provider
          value={{
            invitationToken,
            setInvitationToken
          }}
        >
          <Routes>
            {protectedRoutes.map(route => (
              <RouteComponent
                key={route.path}
                path={route.path}
                element={
                  <ProtectedRoute pauseIdleTimer={route.pauseIdleTimer}>
                    <route.component />
                  </ProtectedRoute>
                }
              />
            ))}
            {defaultRoutes.map(route => (
              <RouteComponent key={route.path} path={route.path} element={<route.component />} />
            ))}
            <RouteComponent index element={<SignInPage />} />
            <RouteComponent path="*" element={<NotFoundPage />} />
          </Routes>
        </InvitationTokenContext.Provider>
      </MenuBarContext.Provider>
    </HelmetProvider>
  );
};

export default App;
