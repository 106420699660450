import { FormControlLabel } from '@mui/material';
import { FC } from 'react';

import RadioButtonProps from './RadioButton.interfaces';
import StyledRadioButton from './RadioButton.styles';

const RadioButton: FC<RadioButtonProps> = ({ value, ...restProps }) => (
  <FormControlLabel value={value} control={<StyledRadioButton disableFocusRipple disableRipple disableTouchRipple />} {...restProps} />
);

export default RadioButton;
