import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import Form from 'src/components/Form/Form';
import Input from 'src/components/Input/Input';
import ActionButtons from 'src/components/ActionButtons/ActionButtons';
import { Box } from '@mui/material';
import Modal from 'src/components/Modal/Modal';
import { changePasswordSchemaUrl } from 'src/api/formSchemas';
import { usePassword } from 'src/hooks/usePassword/usePassword';
import { changePassword } from 'src/api/auth';
import { enqueueSnackbar } from 'notistack';
import { ERROR_INCORRECT_PASSWORD } from 'src/constants/errors';
import { IFormHandleSubmit } from 'src/interfaces/forms';

import { StyledSubtitle } from './AuthenticationModal.styles';
import { AuthenticationModalProps } from './AuthenticationModal.interfaces';

const AuthenticationPasswordModal: FC<AuthenticationModalProps> = ({ isOpen, onClose }) => {
  const { t } = useTranslation();

  const { renderPasswordInput, renderPasswordConfirmationInput, password, checkForPasswordConfirmationErrors, clearPasswordInputs } = usePassword();

  const handleClosePasswordChangingModal = () => {
    clearPasswordInputs();
    onClose();
  };

  const handlePasswordChangeSubmit: IFormHandleSubmit = async (values, validateForm, handleError) => {
    let valid = true;

    if (checkForPasswordConfirmationErrors()) {
      valid = false;
    }

    const formValues = {
      ...values,
      newPassword: password
    };

    if (validateForm(formValues) && valid) {
      changePassword(formValues)
        ?.then(_ => {
          handleClosePasswordChangingModal();
          enqueueSnackbar(t('personalSettings.security.changePassword.success'), { variant: 'success' });
        })
        .catch(error => {
          try {
            const { code, message } = error.response.data;
            if (code === ERROR_INCORRECT_PASSWORD) {
              handleError('oldPassword', message);
            } else {
              enqueueSnackbar(message, { variant: 'error' });
            }
          } catch {}
        });
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={handleClosePasswordChangingModal} title={t('personalSettings.security.changePassword.title')}>
      <StyledSubtitle variant="body1">{t('personalSettings.security.changePassword.modalSubtitle')}</StyledSubtitle>

      <Form schemaUrl={changePasswordSchemaUrl} onSubmit={handlePasswordChangeSubmit}>
        <Input name="oldPassword" type="password" />
        {renderPasswordInput('newPassword')}
        {renderPasswordConfirmationInput(true)}

        <Box sx={{ m: 5 }} />
        <ActionButtons primaryActionText={t('common.confirm')} />
      </Form>
    </Modal>
  );
};

export default AuthenticationPasswordModal;
