import { Typography } from '@mui/material';
import { FC } from 'react';
import SvgIcon from 'src/components/SvgIcon/SvgIcon';

import { ProfileNavItemProps } from './ProfileNavItem.interfaces';
import { StyledAvatar, StyledProfileNavItem, StyledProfileName } from './ProfileNavItem.styles';

const ProfileNavItem: FC<ProfileNavItemProps> = ({ hidden, hiddenArrow = false, bordered = true, profileName, avatarColor, onClick }) => (
  <StyledProfileNavItem bordered={bordered} hidden={hidden} onClick={onClick}>
    <StyledProfileName>
      <StyledAvatar avatarColor={avatarColor}>{profileName.charAt(0)}</StyledAvatar>
      {!hidden && <Typography variant="body2">{profileName}</Typography>}
    </StyledProfileName>
    {!hidden && !hiddenArrow && <SvgIcon icon="arrowDown" />}
  </StyledProfileNavItem>
);

export default ProfileNavItem;
