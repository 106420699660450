import { MoreVert } from '@mui/icons-material';
import { MenuItem, Typography } from '@mui/material';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Menu from 'src/components/Menu/Menu';
import { CompanySettingsMembersListProps } from 'src/components/settings/companySettings/MembersList/MembersList.interfaces';
import { LegalEntityMemberStatus } from 'src/constants/settings';
import { ButtonVariant } from 'src/constants/variants';
import { LegalEntityMember } from 'src/interfaces/settings';
import { capitalize } from 'src/utils/strings';

import {
  StyledEmptyExistingMembersList,
  StyledEmptyExistingMembersListDescription,
  StyledEmptyExistingMembersListIcon,
  StyledInviteExpiredChip,
  StyledListRow,
  StyledListRowActionButton,
  StyledListRowAvatar,
  StyledListRowMetadata,
  StyledRightSide
} from './MembersList.styles';

const MembersList: FC<CompanySettingsMembersListProps> = ({
  members,
  menuItems,
  menuAnchorEl,
  menuOpen,
  onMenuClose,
  setMenuAnchorEl,
  isMenuDisabled
}) => {
  const [selectedMember, setSelectedMember] = useState<LegalEntityMember | null>(null);
  const { t } = useTranslation();

  const renderMenu = (member: LegalEntityMember) => (
    <Menu open={menuOpen} size="small" anchorEl={menuAnchorEl} onClose={onMenuClose}>
      {menuItems(member).map(menuItem => (
        <MenuItem key={menuItem.title} disableRipple onClick={menuItem.onClick}>
          {menuItem.title}
        </MenuItem>
      ))}
    </Menu>
  );

  if (!members.length) {
    return (
      <StyledEmptyExistingMembersList>
        <StyledEmptyExistingMembersListIcon icon="emailBig" />
        <Typography variant="h3">{t('companySettings.members.emptyExistingMembersListTitle')}</Typography>
        <StyledEmptyExistingMembersListDescription>
          {t('companySettings.members.emptyExistingMembersListContent')}
        </StyledEmptyExistingMembersListDescription>
      </StyledEmptyExistingMembersList>
    );
  }

  return (
    <>
      {members.map(member => (
        <StyledListRow key={member.contact.email}>
          <StyledListRowMetadata>
            <StyledListRowAvatar>{Array.from(member.firstName)[0]}</StyledListRowAvatar>
            <div>
              <Typography
                sx={{
                  letterSpacing: '-0.01em'
                }}
                variant="body1"
              >
                {member.firstName} {member.lastName}
              </Typography>
              <Typography
                variant="body3"
                sx={theme => ({
                  color: theme.palette.custom.grey[300]
                })}
              >
                {member.contact.email} · {capitalize(member.corporateTitle)} · {capitalize(member.role)}
              </Typography>
            </div>
          </StyledListRowMetadata>
          <StyledRightSide>
            {member.status === LegalEntityMemberStatus.Expired && (
              <StyledInviteExpiredChip label={<Typography variant="strongBody3">{t('companySettings.members.inviteExpired')}</Typography>} />
            )}
            {!isMenuDisabled?.(member) && menuItems(member).length > 0 && (
              <StyledListRowActionButton
                hasIcon
                variant={ButtonVariant.Text}
                icon={<MoreVert />}
                onClick={evt => {
                  setMenuAnchorEl(evt.currentTarget);
                  setSelectedMember(member);
                }}
              />
            )}
          </StyledRightSide>
        </StyledListRow>
      ))}
      {selectedMember && renderMenu(selectedMember)}
    </>
  );
};

export default MembersList;
