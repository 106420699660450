import { styled } from '@mui/material';

const StyledIcon = styled('div')(({ theme }) => ({
  width: 40,
  height: 40,
  borderRadius: 6,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: theme.palette.common.white,
  boxShadow: theme.shadows[3]
}));

export default StyledIcon;
