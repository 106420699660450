import { Button, styled } from '@mui/material';

import { StyledButtonProps } from './Button.interfaces';

const StyledButton = styled(Button, {
  shouldForwardProp: prop => prop !== 'hasIcon'
})<StyledButtonProps>(({ theme, hasIcon }) => ({
  transition: 'none',
  minWidth: 'initial',
  padding: 0,
  gap: '10.25px',

  '.MuiButton-startIcon': {
    margin: 0
  },
  '&.MuiButton-containedPrimary': {
    boxShadow: theme.shadows[1],

    '&:focus-visible': {
      border: `3px solid ${theme.palette.custom.coralDusk[50]}`,
      margin: '-3px',
      backgroundColor: theme.palette.custom.coralDusk[400]
    },
    '&:active': {
      backgroundColor: theme.palette.custom.coralDusk[700]
    },
    '&:disabled': {
      backgroundColor: theme.palette.custom.grey[300],
      boxShadow: theme.shadows[2],
      color: theme.palette.common.white
    }
  },
  '&.MuiButton-outlinedPrimary': {
    border: `1px solid ${theme.palette.custom.grey[100]}`,
    color: theme.palette.custom.grey.offBlack,

    '& .MuiButton-startIcon': {
      color: theme.palette.custom.grey[300]
    },
    '&:hover': {
      borderColor: theme.palette.custom.grey[200],
      backgroundColor: theme.palette.common.white
    },
    '&:active': {
      borderColor: theme.palette.custom.coralDusk[400]
    },
    '&:focus-visible': {
      border: `2px solid ${theme.palette.custom.coralDusk[500]}`,
      margin: '-2px'
    },
    '&:disabled': {
      color: theme.palette.custom.grey[200]
    }
  },
  '&.MuiButton-textPrimary': {
    backgroundColor: 'transparent',
    border: 0,
    color: theme.palette.custom.coralDusk[400],

    '&:hover': {
      color: theme.palette.custom.coralDusk[500],
      backgroundColor: theme.palette.custom.coralDusk[50]
    },
    '&:disabled': {
      color: theme.palette.custom.grey[200],

      '& .MuiButton-startIcon': {
        color: theme.palette.custom.grey[300]
      }
    },
    '&:active': {
      backgroundColor: theme.palette.custom.coralDusk[100]
    },
    '&:focus-visible': {
      border: `2px solid ${theme.palette.custom.coralDusk[400]}`,
      margin: '-2px'
    }
  },
  '&.MuiButton-sizeSmall': {
    borderRadius: '6px',
    padding: hasIcon ? theme.spacing(1) : theme.spacing(0.75, 1.5),
    fontSize: theme.typography.button2.fontSize
  },
  '&.MuiButton-sizeMedium': {
    padding: hasIcon ? theme.spacing(1.5) : theme.spacing(1.25, 2)
  },
  '&.MuiButton-sizeLarge': {
    padding: hasIcon ? theme.spacing(2) : theme.spacing(1.75, 3)
  }
}));

export default StyledButton;
