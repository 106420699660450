import { Account, AccountDetailsObjectEntries } from 'src/interfaces/banking';

export const getWalletAddressAndCopyText = (objectEntries: AccountDetailsObjectEntries[], isCrypto: boolean) => {
  let copyText = '';
  let walletAddress = '';
  objectEntries.forEach(([_group, items]) => {
    Object.entries(items).forEach(([key, value]) => {
      if (!isCrypto) {
        copyText += `${copyText && '\n'}${value}`;
      } else if (key === 'walletAddress') {
        walletAddress = value as string;
      }
    });
  });
  return {
    walletAddress,
    copyText
  };
};

export const getAccountDetailsObjectEntries = (account?: Account, keyToFilterOut = '') =>
  account?.details ? Object.entries(account.details).filter(([key, _]) => key !== keyToFilterOut) : [];
