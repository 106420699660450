import { Typography, useTheme } from '@mui/material';
import { FC, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SvgIcon from 'src/components/SvgIcon/SvgIcon';
import { bankingBottomRoutes, bankingRoutes, urls } from 'src/routes';
import { NavGroup } from 'src/interfaces/routes';
import { EntityStatus } from 'src/interfaces/banking';
import { useLocation, useNavigate } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import { useLegalEntitiesQuery } from 'src/api/queries/useLegalEntities';
import { useUserOrNavigateQuery } from 'src/api/queries/useUserOrNavigate';
import { QueryKey } from 'src/constants/queryKeys';
import { getEntityFromEntities } from 'src/utils/entity';
import { getSelectedLegalEntityUri } from 'src/utils/storage';
import { getHelpMailto } from 'src/utils/mailing';

import EntitySwitch from '../EntitySwitch/EntitySwitch';
import ProfileNavItem from '../ProfileNavItem/ProfileNavItem';
import ToggleIcon from '../ToggleIcon/ToggleIcon';

import { MenuBarProps } from './MenuBar.interfaces';
import {
  StyledNavLink,
  StyledNavLinksLabel,
  StyledNavLinksSection,
  StyledNavLinks,
  StyledMenuBar,
  StyledLogoAndToggleIcon,
  StyledSvgIcon,
  StyledAvatar,
  StyledDialog,
  StyledAnchorNavLink
} from './MenuBar.styles';

const MenuBar: FC<MenuBarProps> = ({ hidden, setHidden, disabled }) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [entitySwitchDialogOpen, setEntitySwitchDialogOpen] = useState<boolean>(false);
  const [entityUri, setEntityUri] = useState(getSelectedLegalEntityUri());
  const { data: user } = useUserOrNavigateQuery();
  const userUri = user?._links.self?.uri ?? '';
  const { data: legalEntitiesData, isLoading: isLoadingEntities } = useLegalEntitiesQuery(userUri);

  const getEntityFromEntitiesCallback = useCallback(() => {
    const entities = legalEntitiesData?.data ?? [];
    return getEntityFromEntities(entityUri, entities);
  }, [legalEntitiesData, entityUri]);

  const entityInUse = getEntityFromEntitiesCallback();

  // TODO: Add check for empty or deleted entity
  const EntitySwitchDialog = (
    <StyledDialog open={entitySwitchDialogOpen} onClose={() => setEntitySwitchDialogOpen(false)}>
      <EntitySwitch
        activeUri={entityUri}
        onClose={() => setEntitySwitchDialogOpen(false)}
        onSelectEntity={entity => {
          if (entity.status === EntityStatus.Approved) {
            setEntitySwitchDialogOpen(false);
            if (entity._links.self.uri !== entityUri) {
              navigate(urls.balances);
              queryClient.invalidateQueries([QueryKey.QUERY_ENTITY_ACCOUNTS]);
              setEntityUri(entity._links?.self?.uri);
            }
          }
        }}
      />
    </StyledDialog>
  );

  const generateNavRoutes = (routes: NavGroup, disabled?: boolean) =>
    routes.navs.map(route => {
      let to = route.path;
      if (to === urls.transactions && location.pathname === urls.orders) {
        to = urls.orders;
      }
      if (t(route.i18nTitle) === t('banking.navigation.getHelp')) {
        return (
          <StyledAnchorNavLink hidden={hidden} key={route.i18nTitle} href={getHelpMailto(user, legalEntitiesData?.data)} disabled={disabled}>
            {route.icon && <SvgIcon icon={route.icon} />}
            {!hidden && <Typography variant="inherit">{t(route.i18nTitle)}</Typography>}
          </StyledAnchorNavLink>
        );
      }
      return (
        <StyledNavLink hidden={hidden} key={route.i18nTitle} to={to} disabled={disabled}>
          {route.icon && <SvgIcon icon={route.icon} />}
          {!hidden && <Typography variant="inherit">{t(route.i18nTitle)}</Typography>}
        </StyledNavLink>
      );
    });

  return (
    <>
      {EntitySwitchDialog}
      <StyledMenuBar hidden={hidden}>
        <StyledLogoAndToggleIcon hidden={hidden}>
          {!hidden && <StyledSvgIcon icon="logo" onClick={() => navigate(urls.balances)} />}
          <ToggleIcon onClick={() => setHidden(prevState => !prevState)} rotate={!hidden} />
        </StyledLogoAndToggleIcon>
        {!isLoadingEntities && (
          <StyledNavLinksSection>
            <ProfileNavItem
              hidden={hidden}
              onClick={() => setEntitySwitchDialogOpen(true)}
              profileName={entityInUse?.name || 'Empty'} // TODO: Take first
            />
          </StyledNavLinksSection>
        )}
        {!hidden && <StyledNavLinksLabel variant="strongBody3">{t('banking.navigation.navLabel')}</StyledNavLinksLabel>}
        <StyledNavLinks hidden={hidden}>
          <StyledNavLinksSection>{generateNavRoutes(bankingRoutes, disabled)}</StyledNavLinksSection>

          <StyledNavLinksSection>
            <StyledNavLink hidden={hidden} to={urls.personalSettings}>
              <>
                <StyledAvatar avatarColor={theme.palette.primary.main}>{(user?.firstName ?? t('common.user'))?.charAt(0)}</StyledAvatar>
                {!hidden && <Typography variant="inherit">{user?.firstName ?? t('common.user')}</Typography>}
              </>
            </StyledNavLink>
            {generateNavRoutes(bankingBottomRoutes)}
          </StyledNavLinksSection>
        </StyledNavLinks>
      </StyledMenuBar>
    </>
  );
};

export default MenuBar;
