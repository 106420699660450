import { styled, Typography } from '@mui/material';
import Button from 'src/components/Button/Button';
import { ACTION_BAR_WIDTH } from 'src/constants/dimensions';

import { StyledActionBarComponentsProps, StyledActionButtonsProps } from './ActionBar.interfaces';

export const StyledActionBar = styled('div', {
  shouldForwardProp: prop => prop !== 'hidden'
})<StyledActionBarComponentsProps>(({ theme, hidden }) => ({
  background: theme.palette.common.white,
  boxShadow: theme.shadows[10],
  width: hidden ? ACTION_BAR_WIDTH.hidden : ACTION_BAR_WIDTH.expanded,
  display: 'flex',
  flexDirection: 'column',
  padding: hidden ? theme.spacing(3.25) : theme.spacing(4),
  zIndex: 1,
  ...(hidden && { alignItems: 'center' }),
  [theme.breakpoints.down('md')]: {
    width: '100vw'
  }
}));

export const StyledToggleButton = styled(Button)({
  width: 28,
  height: 28
});

export const StyledExpandedHeader = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(3)
}));

export const StyledDescription = styled(Typography)(({ theme }) => ({
  color: theme.palette.custom.grey[400],
  marginTop: theme.spacing(1)
}));

export const StyledActionButtons = styled('div', {
  shouldForwardProp: prop => prop !== 'disabled'
})<StyledActionButtonsProps>(({ theme, disabled }) => ({
  display: 'flex',
  gap: theme.spacing(1),
  margin: theme.spacing(3, -1.5, 0),
  flexDirection: 'column',
  opacity: disabled ? 0.5 : 1,
  pointerEvents: disabled ? 'none' : 'all'
}));
