import { FC, useState } from 'react';
import DialogLayout from 'src/components/layouts/DialogLayout/DialogLayout';
import { ONBOARDING_IDENTITY_VERIFICATION } from 'src/constants/onboarding';
import { useUserNavigation } from 'src/hooks/navigation';
import SvgIcon from 'src/components/SvgIcon/SvgIcon';
import StatusIcon from 'src/components/StatusIcon/StatusIcon';
import { useTranslation } from 'react-i18next';
import { useKYCStartDataQuery } from 'src/api/queries/useKYCStartData';
import { useUserOrNavigateQuery } from 'src/api/queries/useUserOrNavigate';
import Onfido from 'src/components/Onfido/Onfido';

const KYCPage: FC = () => {
  const { t } = useTranslation();
  const { getUserOrNavigate } = useUserNavigation();

  const [inProgress, setInProgress] = useState<boolean>(false);
  const { data: user } = useUserOrNavigateQuery();
  const { isLoading } = useKYCStartDataQuery(user?._links.self?.uri ?? '');

  if (inProgress) {
    return (
      <DialogLayout
        heading={t('screens.kycInProgress.title')}
        subheading={t('screens.kycInProgress.subtitle')}
        headerImage={<StatusIcon icon={<SvgIcon icon="person" />} />}
        centeredContent
        isOnboardingLayout
        withOnboardingSteps
        activeStepName={ONBOARDING_IDENTITY_VERIFICATION}
        onPrimaryActionClick={() => getUserOrNavigate(true)}
        primaryActionText={t('screens.kycInProgress.button')}
      />
    );
  }

  return (
    <DialogLayout
      contentLoading={isLoading}
      isOnboardingLayout
      withOnboardingSteps
      contentPadding={2}
      activeStepName={ONBOARDING_IDENTITY_VERIFICATION}
    >
      <Onfido setInProgress={setInProgress} />
    </DialogLayout>
  );
};

export default KYCPage;
