import { FC, useEffect, useRef, useState } from 'react';
import { useSnackbar } from 'notistack';
import { IFormHandleSubmit, IFormValues } from 'src/interfaces/forms';
import Form from 'src/components/Form/Form';
import { additionalInfoSchemaUrl } from 'src/api/formSchemas';
import { saveAdditionalInfo } from 'src/api/onboarding';
import DialogLayout from 'src/components/layouts/DialogLayout/DialogLayout';
import { ONBOARDING_ADDITIONAL_INFO } from 'src/constants/onboarding';
import { useTranslation } from 'react-i18next';
import { useUserNavigation } from 'src/hooks/navigation';
import ActionButtons from 'src/components/ActionButtons/ActionButtons';
import { useNavigate } from 'react-router-dom';
import Checkbox from 'src/components/Checkbox/Checkbox';
import { urls } from 'src/routes';

const IdentityVerificationPage: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { getUserOrNavigate } = useUserNavigation();

  const userRef = useRef<boolean>(true);
  const { enqueueSnackbar } = useSnackbar();

  const [userUri, setUserUri] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [initialValues, setInitialValues] = useState<IFormValues>();
  const [contentLoading, setContentLoading] = useState<boolean>(true);

  useEffect(() => {
    if (userRef.current) {
      userRef.current = false;

      getUserOrNavigate(false)?.then(response => {
        setContentLoading(false);
        if (response) {
          setUserUri(response._links.self.uri);
          setInitialValues({
            noPep: response.noPep
          });
        }
      });
    }
  }, [getUserOrNavigate]);

  const handleSubmit: IFormHandleSubmit = async (values, validateForm) => {
    if (validateForm(values)) {
      setLoading(true);

      saveAdditionalInfo(userUri, values)
        ?.then(_ => {
          getUserOrNavigate();
        })
        .catch(error => {
          try {
            const { message } = error.response.data;
            enqueueSnackbar(message, { variant: 'error' });
          } catch {}
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  return (
    <DialogLayout
      loading={loading}
      contentLoading={contentLoading}
      heading={t('screens.additionalInfo.title')}
      subheading={t('screens.additionalInfo.subtitle')}
      isOnboardingLayout
      withOnboardingSteps
      activeStepName={ONBOARDING_ADDITIONAL_INFO}
    >
      <Form initialValues={initialValues} schemaUrl={additionalInfoSchemaUrl} onSubmit={handleSubmit}>
        <Checkbox name="noPep" />

        <ActionButtons
          primaryActionText={t('common.continue')}
          secondaryActionText={t('common.goToPreviousStep')}
          onSecondaryActionClick={() => navigate(urls.personalInformation)}
        />
      </Form>
    </DialogLayout>
  );
};

export default IdentityVerificationPage;
