import { styled } from '@mui/material';

const StyledSvg = styled('div')({
  display: 'flex',

  '& .MuiSvgIcon-root': {
    color: 'transparent'
  }
});

export default StyledSvg;
