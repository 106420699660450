import { Box, styled, Typography } from '@mui/material';

export const StyledQRWrapper = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(2),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center'
}));

export const StyledLightGrayText = styled(Typography)(({ theme }) => ({
  color: theme.palette.custom.grey[400]
}));

export const StyledDisabledWalletTextField = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(4),
  marginBottom: theme.spacing(3),
  textAlign: 'center',
  lineBreak: 'anywhere',
  border: `1px solid ${theme.palette.custom.grey[100]}`,
  boxShadow: theme.shadows[2],
  borderRadius: theme.spacing(1),
  background: theme.palette.custom.grey[50],
  padding: theme.spacing(1, 2)
}));
