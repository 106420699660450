import { FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import ActionTab from 'src/components/banking/actions/ActionTab/ActionTab';
import StatusIcon from 'src/components/StatusIcon/StatusIcon';
import SvgIcon from 'src/components/SvgIcon/SvgIcon';
import { ActionBarContext } from 'src/contexts/ActionBar.contexts';
import { WithdrawContext, WithdrawCryptoContext } from 'src/contexts/actions/Withdraw.contexts';
import { formatBeneficiaryName } from 'src/utils/formatting';

import { withdrawActionTabDefaultValues } from '../Withdraw.utils';

import { StyledContent, StyledReviewIcon, StyledText, StyledTitle } from './WithdrawConfirmation.styles';
import { WithdrawConfirmationProps } from './WithdrawConfirmation.interfaces';

const WithdrawConfirmation: FC<WithdrawConfirmationProps> = ({ waitingForApproval }) => {
  const { t } = useTranslation();
  const actionBarContext = useContext(ActionBarContext);
  const { amount, account, beneficiaryDetails = {} } = useContext(WithdrawContext) ?? {};
  const cryptoContext = useContext(WithdrawCryptoContext);

  return actionBarContext ? (
    <ActionTab
      primaryActionTextKey="common.done"
      onPrimaryActionClick={actionBarContext.closeActionTab}
      onClose={actionBarContext.closeActionTab}
      activeStepIndex={5}
      {...withdrawActionTabDefaultValues}
    >
      {waitingForApproval ? (
        <StyledContent>
          <StyledReviewIcon>
            <SvgIcon icon="timeWhite" />
          </StyledReviewIcon>
          <StyledTitle variant="subtitle1">{t('banking.actions.withdraw.waitingForApproval.title')}</StyledTitle>
          <StyledText>
            {t(
              'banking.actions.withdraw.waitingForApproval.text',
              cryptoContext
                ? {
                    amount: cryptoContext.amount,
                    currency: beneficiaryDetails.targetCurrency || cryptoContext.account?.currency,
                    beneficiary: cryptoContext.beneficiaryDetails?.walletAddress
                  }
                : {
                    amount,
                    currency: beneficiaryDetails.targetCurrency || account?.currency,
                    beneficiary: formatBeneficiaryName(beneficiaryDetails)
                  }
            )}
          </StyledText>
        </StyledContent>
      ) : (
        <StyledContent>
          <StatusIcon icon={<SvgIcon icon="check" />} />
          <StyledTitle variant="subtitle1">{t('banking.actions.withdraw.confirmation.title')}</StyledTitle>
          <StyledText>
            {t(
              'banking.actions.withdraw.confirmation.text',
              cryptoContext
                ? {
                    amount: cryptoContext.amount,
                    currency: beneficiaryDetails.targetCurrency || cryptoContext.account?.currency,
                    beneficiary: cryptoContext.beneficiaryDetails?.walletAddress
                  }
                : {
                    amount,
                    currency: beneficiaryDetails.targetCurrency || account?.currency,
                    beneficiary: formatBeneficiaryName(beneficiaryDetails)
                  }
            )}
          </StyledText>
        </StyledContent>
      )}
    </ActionTab>
  ) : null;
};

export default WithdrawConfirmation;
