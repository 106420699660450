import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { IRequestSwapResponse } from 'src/interfaces/responses';
import { QueryKey } from 'src/constants/queryKeys';

import { requestSwap } from '../banking';

export const useSwapRequestQuery = (
  sourceAccountId: string,
  targetAccountId: string,
  amount?: string
): UseQueryResult<AxiosResponse<IRequestSwapResponse, AxiosError> | undefined> =>
  useQuery(
    [QueryKey.QUERY_ACCOUNT_SWAP_REQUEST, sourceAccountId, targetAccountId, amount],
    () => requestSwap(sourceAccountId, targetAccountId, amount),
    { enabled: !!sourceAccountId && !!targetAccountId }
  );
