import { useSyncExternalStore } from 'preact/compat'
import { useEffect, useMemo } from 'preact/hooks'
import {
  AnyStateMachine,
  InternalMachineOptions,
  interpret,
  InterpreterFrom,
  Prop,
  StateFrom,
} from 'xstate'

type UseMachineReturn<
  TMachine extends AnyStateMachine,
  TInterpreter = InterpreterFrom<TMachine>
> = [StateFrom<TMachine>, Prop<TInterpreter, 'send'>, TInterpreter]

type MachineOptions<TMachine extends AnyStateMachine> = {
  context?: Partial<TMachine['__TContext']>
} & InternalMachineOptions<
  TMachine['__TContext'],
  TMachine['__TEvent'],
  TMachine['__TResolvedTypesMeta']
>

export const useMachine = <TMachine extends AnyStateMachine>(
  machine: TMachine,
  options: MachineOptions<TMachine>
): UseMachineReturn<TMachine> => {
  const service = useMemo(() => {
    const { context, ...config } = options

    const machineWithConfig = machine
      .withConfig(config as any)
      .withContext({ ...machine.context, ...context })

    return interpret(machineWithConfig)
  }, [])

  const store = useSyncExternalStore(
    (handler) => {
      const { unsubscribe } = service.subscribe(handler)
      return unsubscribe
    },
    () => service.getSnapshot()
  )

  useEffect(() => {
    service.start()
    return () => {
      service.stop()
    }
  }, [])

  return [store, service.send, service] as any
}
