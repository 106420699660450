import SignInPage from 'src/pages/SignInPage/SignInPage';
import SignUpPage from 'src/pages/SignUpPage/SignUpPage';
import AuthenticationPage from 'src/pages/AuthenticationPage/AuthenticationPage';
import Enable2faPage from 'src/pages/Enable2faPage/Enable2faPage';
import InvalidTokenPage from 'src/pages/InvalidTokenPage/InvalidTokenPage';
import WaitlistPage from 'src/pages/WaitlistPage/WaitlistPage';
import VerifyEmailPage from 'src/pages/VerifyEmailPage/VerifyEmailPage';
import ResetPasswordPage from 'src/pages/ResetPasswordPage/ResetPasswordPage';
import ForgotPasswordPage from 'src/pages/ForgotPasswordPage/ForgotPasswordPage';
import PersonalInformationPage from 'src/pages/PersonalInformationPage/PersonalInformationPage';
import AdditionalInformationPage from 'src/pages/AdditionalInformationPage/AdditionalInformationPage';
import KYCPage from 'src/pages/KYCPage/KYCPage';
import BalancesPage from 'src/pages/BalancesPage/BalancesPage';
import AccountDetailsPage from 'src/pages/AccountDetailsPage/AccountDetailsPage';
import EmailVerifiedPage from 'src/pages/EmailVerifiedPage/EmailVerifiedPage';
import { CorporateAccountPage } from 'src/pages/CorporateAccountPage/CorporateAccountPage';
import { CorporateInformationPage } from 'src/pages/CorporateInformationPage/CorporateInformationPage';
import ProfileRejectedPage from 'src/pages/ProfileRejectedPage/ProfileRejectedPage';
import EntitySelectionPage from 'src/pages/EntitySelectionPage/EntitySelectionPage';
import Reset2faPage from 'src/pages/Reset2faPage/Reset2faPage';
import LegalEntityPage from 'src/pages/LegalEntityPage/LegalEntityPage';
import PersonalSettingsPage from 'src/pages/PersonalSettingsPage/PersonalSettingsPage';
import TransactionsPage from 'src/pages/TransactionsPage/TransactionsPage';
import CompanySettingsPage from 'src/pages/CompanySettingsPage/CompanySettingsPage';
import { VerifyInvitationPage } from 'src/pages/VerifyInvitationPage/VerifyInvitationPage';
import { DisclaimersPage } from 'src/pages/DisclaimersPage/DisclaimersPage';
import { PrivacyPolicyPage } from 'src/pages/PrivacyPolicyPage/PrivacyPolicyPage';
import { TermsAndConditionsPage } from 'src/pages/TermsAndConditionsPage/TermsAndConditionsPage';
import { LandingPage } from 'src/pages/LandingPage/LandingPage';

import { UrlName } from '../interfaces/urls';
import { NavGroup, ProtectedRoute, Route } from '../interfaces/routes';

export const urls: Record<UrlName, string> = {
  accountDetails: '/accounts/:accountId',
  accountDetailsTab: '/accounts/:accountId/:tab',
  additionalInformation: '/additional-information',
  authentication: '/authentication',
  balances: '/balances',
  companySettings: '/company-settings',
  corporateAccount: '/corporate-account',
  corporateInformation: '/corporate-information',
  disclaimers: '/disclaimers',
  emailVerified: '/email-verified',
  emailVerify: '/email-verify',
  enable2fa: '/enable-2fa',
  entitySelection: '/entity-selection',
  forgotPassword: '/forgot-password',
  help: '/help',
  invalidToken: '/invalid-token',
  kyc: '/kyc',
  landing: '/138597b7-bac4-45ea-b9c4-8f4ee20e8233',
  legalEntity: '/legal-entities/:entityId',
  orders: '/orders',
  personalInformation: '/personal-information',
  personalSettings: '/personal-settings',
  profileRejected: '/profile-additional-review',
  privacyPolicy: '/privacy-policy',
  reset2fa: '/reset-2fa',
  resetPassword: '/reset-password/:token',
  signin: '/signin',
  signup: '/signup',
  termsAndConditions: '/terms-of-use',
  transactions: '/transactions',
  verifyEmail: '/verify-email/:token',
  verifyInvitation: '/verify-invitation/:invitationToken',
  waitlist: '/waitlist'
};

export const protectedRoutes: ProtectedRoute[] = [
  { path: urls.accountDetails, component: AccountDetailsPage },
  { path: urls.accountDetailsTab, component: AccountDetailsPage },
  { path: urls.additionalInformation, component: AdditionalInformationPage },
  { path: urls.balances, component: BalancesPage },
  { path: urls.companySettings, component: CompanySettingsPage },
  { path: urls.corporateAccount, component: CorporateAccountPage },
  { path: urls.corporateInformation, component: CorporateInformationPage, pauseIdleTimer: true },
  { path: urls.entitySelection, component: EntitySelectionPage },
  { path: urls.kyc, component: KYCPage, pauseIdleTimer: true },
  { path: urls.legalEntity, component: LegalEntityPage },
  { path: urls.orders, component: TransactionsPage },
  { path: urls.personalInformation, component: PersonalInformationPage },
  { path: urls.personalSettings, component: PersonalSettingsPage },
  { path: urls.profileRejected, component: ProfileRejectedPage },
  { path: urls.transactions, component: TransactionsPage }
];

export const defaultRoutes: Route[] = [
  { path: urls.authentication, component: AuthenticationPage },
  { path: urls.disclaimers, component: DisclaimersPage },
  { path: urls.emailVerify, component: VerifyEmailPage },
  { path: urls.emailVerified, component: EmailVerifiedPage },
  { path: urls.enable2fa, component: Enable2faPage },
  { path: urls.forgotPassword, component: ForgotPasswordPage },
  { path: urls.invalidToken, component: InvalidTokenPage },
  { path: urls.landing, component: LandingPage },
  { path: urls.privacyPolicy, component: PrivacyPolicyPage },
  { path: urls.reset2fa, component: Reset2faPage },
  { path: urls.resetPassword, component: ResetPasswordPage },
  { path: urls.signin, component: SignInPage },
  { path: urls.signup, component: SignUpPage },
  { path: urls.termsAndConditions, component: TermsAndConditionsPage },
  { path: urls.verifyEmail, component: VerifyEmailPage },
  { path: urls.verifyInvitation, component: VerifyInvitationPage },
  { path: urls.waitlist, component: WaitlistPage }
];

export const bankingRoutes: NavGroup = {
  id: 'bankingRoutes',
  navs: [
    {
      path: urls.balances,
      i18nTitle: 'banking.navigation.holdings',
      icon: 'coins'
    },
    {
      path: urls.transactions,
      i18nTitle: 'banking.navigation.transactions',
      icon: 'swap'
    }
  ]
};

export const bankingBottomRoutes: NavGroup = {
  id: 'bankingBottomRoutes',
  navs: [
    {
      path: urls.companySettings,
      i18nTitle: 'banking.navigation.settings',
      icon: 'settings'
    },
    {
      path: '', // dynamically generated with getHelpMailto
      i18nTitle: 'banking.navigation.getHelp',
      icon: 'help'
    }
  ]
};
