import { useContext, useState } from 'react';
import ActionTab from 'src/components/banking/actions/ActionTab/ActionTab';
import { getAccountDetailsObjectEntries, getWalletAddressAndCopyText } from 'src/components/banking/AccountDetailsInfo/AccountDetailsInfo.utils';
import { Account } from 'src/interfaces/banking';
import useCopyToClipboard from 'src/utils/useCopyToClipboard';
import { ActionBarContext } from 'src/contexts/ActionBar.contexts';
import { DepositContext } from 'src/contexts/actions/Deposit.contexts';

import { DepositAccountDetailsScreens } from '../Deposit.interfaces';
import DepositSelection from '../DepositSelection/DepositSelection';
import { DepositIsFromCryptoTrading } from '../DepositIsFromCryptoTrading/DepositIsFromCryptoTrading';
import { DepositWeCanNotAcceptThisDeposit } from '../DepositWeCanNotAcceptThisDeposit/DepositWeCanNotAcceptThisDeposit';
import { depositActionTabDefaultValues } from '../Deposit.utils';

import { StyledAccountDetailsInfo } from './DepositAccountDetails.styles';

export const DepositAccountDetails = () => {
  const actionBarContext = useContext(ActionBarContext);
  const depositContext = useContext(DepositContext);
  const contextsInitialized = actionBarContext && depositContext;

  const account = depositContext?.depositAccountDetailsPreviousScreen !== 'depositRejected' ? depositContext?.account : depositContext?.usdAccount;
  const [nextScreen, setNextScreen] = useState<DepositAccountDetailsScreens>();
  const [, copy] = useCopyToClipboard();

  const flowScreens = {
    depositRejected: <DepositWeCanNotAcceptThisDeposit />,
    depositFiat: <DepositIsFromCryptoTrading />,
    depositFiatUSD: <DepositSelection />
  };

  const onPrimaryActionClick = (account: Account | undefined) => {
    const copyText = getWalletAddressAndCopyText(getAccountDetailsObjectEntries(account, 'hints'), false).copyText;
    copy(copyText);
  };

  return nextScreen ? (
    flowScreens[nextScreen]
  ) : contextsInitialized ? (
    <ActionTab
      primaryActionTextKey="banking.copyAllDetails"
      onPrimaryActionClick={() => onPrimaryActionClick(account)}
      onGoBack={() => setNextScreen(depositContext?.depositAccountDetailsPreviousScreen)}
      onClose={actionBarContext.closeActionTab}
      activeStepIndex={2}
      {...depositActionTabDefaultValues}
      titleKey="banking.actions.deposit.titleFiat"
    >
      <StyledAccountDetailsInfo account={account} titleKey="banking.actions.deposit.depositAccountDetailsTitle" inActionTab />
    </ActionTab>
  ) : null;
};
