import { styled, Typography } from '@mui/material';
import Button from 'src/components/Button/Button';
import SvgIcon from 'src/components/SvgIcon/SvgIcon';
import { getBackgroundColorVariantForTransactionType } from 'src/utils/styles';

import { StyledActionButtonProps, StyledButtonProps } from './ActionButton.interfaces';

export const StyledActionButton = styled('div', {
  shouldForwardProp: prop => prop !== 'hiddenMeta'
})<StyledActionButtonProps>(({ theme, hiddenMeta }) => ({
  display: 'flex',
  gap: theme.spacing(2),
  alignItems: 'center',
  cursor: 'pointer',
  padding: theme.spacing(1, hiddenMeta ? 0 : 2, 1, hiddenMeta ? 0 : 1.5),

  '&:hover': {
    background: hiddenMeta ? 'inherit' : theme.palette.custom.grey[50],
    ...(!hiddenMeta && { borderRadius: theme.shape.borderRadius })
  }
}));

export const StyledButton = styled(Button, {
  shouldForwardProp: prop => prop !== 'transactionType' && prop !== 'hiddenMeta'
})<StyledButtonProps>(({ theme, transactionType, hiddenMeta }) => {
  const backgroundVariant = getBackgroundColorVariantForTransactionType(theme, transactionType);

  return {
    width: '40px',
    height: '40px',
    borderRadius: '50%',
    background: backgroundVariant.defaultColor,

    '&:hover': {
      background: hiddenMeta ? backgroundVariant.hoverColor : backgroundVariant.defaultColor
    }
  };
});

export const StyledIcon = styled(SvgIcon)(({ theme }) => ({
  fontSize: theme.typography.htmlFontSize
}));

export const StyledMeta = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column'
}));

export const StyledDescription = styled(Typography)(({ theme }) => ({
  color: theme.palette.custom.grey[400]
}));
