import { FC, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import DialogLayout from 'src/components/layouts/DialogLayout/DialogLayout';
import { CORPORATE_ONBOARDING_STEPS, CORPORATE_INFORMATION } from 'src/constants/onboarding';
import { useUserNavigation } from 'src/hooks/navigation';
import { ITypeformParameters } from 'src/interfaces/global';
import { Typography } from '@mui/material';
import { getUser } from 'src/api/user';
import isEqual from 'lodash.isequal';
import { useNavigate } from 'react-router-dom';

import { TypeformWidget } from './CorporateInformation.styles';

export const CorporateInformationPage: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { getUserOrNavigate } = useUserNavigation();

  const typeformRef = useRef<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);
  const [parameters, setParameters] = useState<ITypeformParameters>();

  useEffect(() => {
    if (typeformRef.current) {
      typeformRef.current = false;

      getUserOrNavigate(false).then(user => {
        const { parameters } = user?._actions?.startLegalEntityOnboarding ?? {};
        setParameters(parameters);
      });
    }
  }, [getUserOrNavigate]);

  const onFormSubmitted = (_: { responseId: string }) => {
    setLoading(true);
    getUser()?.then(current => {
      const counts = current?.data?._links?.legalEntities?.counts;
      getUserOrNavigate(true, undefined, user => !!user?._links?.legalEntities && !isEqual(user._links.legalEntities.counts, counts)).then(_ =>
        setLoading(false)
      );
    });
  };

  return (
    <DialogLayout
      loading={loading}
      contentLoading={!parameters}
      heading={t('screens.corporateInformation.title')}
      subheading={t('screens.corporateInformation.subtitle')}
      isOnboardingLayout
      withOnboardingSteps
      onboardingSteps={CORPORATE_ONBOARDING_STEPS}
      isTypeformContent
      activeStepName={CORPORATE_INFORMATION}
      secondaryActionText={t('common.goToPreviousStep')}
      onSecondaryActionClick={() => navigate(-1)}
    >
      {parameters?.formId ? (
        <TypeformWidget
          id={parameters.formId}
          onSubmit={onFormSubmitted}
          hidden={{
            region: parameters.region,
            requestor_id: parameters.requestorId
          }}
          autoResize
        />
      ) : (
        <Typography>{t('screens.corporateInformation.error')}</Typography>
      )}
    </DialogLayout>
  );
};
