import { FC, useEffect, useRef, useState } from 'react';
import parse from 'autosuggest-highlight/parse';
import { useTranslation } from 'react-i18next';
import { getAutocompletePredictions, getPlaceResults, mapAddressFields, hasStreetAddress } from 'src/utils/googlePlacesUtils';
import { useJsApiLoader } from '@react-google-maps/api';
import FormElement from 'src/components/FormElement/FormElement';
import { GoogleLibraries } from 'src/interfaces/googleApis';
import { useSnackbar } from 'notistack';

import { PlacePrediction } from '../PlacePrediction/PlacePrediction';

import { GooglePlaceAutocompleteProps, PlaceType, MainTextMatchedSubstrings } from './GooglePlaceAutocomplete.interfaces';
import { StyledGoogleAutocomplete, StyledSvgIcon, PlaceStyledTextField, StyledPopper } from './GooglePlaceAutocomplete.styles';

const googleLibraries: GoogleLibraries[] = ['places'];

export const GooglePlaceAutocomplete: FC<GooglePlaceAutocompleteProps> = ({
  country = '',
  name,
  error,
  disabled,
  labelTooltip,
  searchPerformed,
  handleError,
  handleOnMultipleChanges
}) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { isLoaded, loadError } = useJsApiLoader({
    id: 'google-api-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries: googleLibraries
  });
  if (loadError) {
    enqueueSnackbar(loadError.cause ? String(loadError.cause) : t('screens.error'), {
      variant: 'error'
    });
  }
  const [autocompleteValue, setAutocompleteValue] = useState<PlaceType | null>(null);
  const [inputValue, setInputValue] = useState('');
  const [predictions, setPredictions] = useState<PlaceType[] | null>([]);
  const geocoderRef = useRef<google.maps.Geocoder>();
  const googleAutocompleteService = useRef<google.maps.places.AutocompleteService>();

  useEffect(() => {
    if (isLoaded) {
      geocoderRef.current = new window.google.maps.Geocoder();
      googleAutocompleteService.current = new google.maps.places.AutocompleteService();
    }
  }, [isLoaded]);

  useEffect(() => {
    let activeEffect = true;
    if (inputValue === '') {
      setPredictions([]);
      return undefined;
    }
    if (googleAutocompleteService.current) {
      getAutocompletePredictions(googleAutocompleteService.current, inputValue, country).then(res => {
        if (activeEffect) {
          let newPredictions: readonly PlaceType[] = [];

          if (autocompleteValue) {
            newPredictions = [autocompleteValue];
          }
          if (res) {
            newPredictions = [...newPredictions, ...res.predictions];
          }

          setPredictions(newPredictions as PlaceType[]);
        }
      });
    }
    return () => {
      activeEffect = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputValue]);

  const handleOnAutocompleteChange = (_: unknown, newValue: PlaceType | null) => {
    if (geocoderRef.current && newValue) {
      getPlaceResults(geocoderRef.current, newValue.description, country).then(placeResults => {
        if (placeResults) {
          const chosenPlace = placeResults?.results[0];
          const changes = mapAddressFields(chosenPlace);
          const hasAddressInResponse = hasStreetAddress(changes);
          if (searchPerformed) {
            searchPerformed(hasAddressInResponse);
          }
          setAutocompleteValue(newValue);
          if (handleOnMultipleChanges) {
            handleOnMultipleChanges(changes);
          }
          if (handleError && error) {
            handleError(name, '');
          }
        }
      });
    }
  };

  return (
    <FormElement label={t('common.streetName')} labelTooltip={labelTooltip} error={error}>
      {isLoaded && (
        <StyledGoogleAutocomplete
          PopperComponent={StyledPopper}
          autoComplete
          includeInputInList
          disabled={disabled}
          disablePortal
          id="name"
          getOptionLabel={option => (typeof option === 'string' ? option : option.description)}
          filterOptions={x => x}
          options={predictions as PlaceType[]}
          filterSelectedOptions
          value={autocompleteValue}
          freeSolo
          onChange={(_event, val) => {
            if (typeof val === 'string') {
              return;
            }
            handleOnAutocompleteChange(_event, val);
          }}
          onInputChange={(_event, newInputValue) => {
            setInputValue(newInputValue);
          }}
          popupIcon={<StyledSvgIcon icon="search" />}
          renderInput={params => <PlaceStyledTextField placeholder={t('common.searchForAddress')} error={!!error} {...params} />}
          renderOption={(optionProps, option) => {
            const matches = option.structured_formatting.main_text_matched_substrings;
            const parts = parse(
              option.structured_formatting.main_text,
              matches.map((match: MainTextMatchedSubstrings) => [match.offset, match.offset + match.length])
            );
            return (
              <li {...optionProps}>
                <PlacePrediction parts={parts} option={option} />
              </li>
            );
          }}
        />
      )}
    </FormElement>
  );
};
