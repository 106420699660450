import { AxiosError, AxiosResponse } from 'axios';
import request from 'src/api/request';
import { additionalUrls } from 'src/api/additionalUrls';
import { IGetFormSchemaResponse } from 'src/interfaces/responses';
import { BeneficiaryNetwork } from 'src/interfaces/banking';

const auth2faUrl = `${additionalUrls.auth}${additionalUrls['2fa']}`;
const userPlaceholder = `${additionalUrls.users}${additionalUrls.me}`;

export const auth2faSchemaUrl = auth2faUrl;
export const authLoginSchemaUrl = `${additionalUrls.auth}${additionalUrls.login}`;
export const resetPasswordSchemaUrl = additionalUrls.resetPassword;
export const changePasswordSchemaUrl = additionalUrls.changePassword;
export const resetPasswordRequestSchemaUrl = additionalUrls.resetPasswordRequest;
export const signupSchemaUrl = additionalUrls.signup;
export const waitlistSchemaUrl = `${additionalUrls.waitlist}${additionalUrls.registrations}`;
export const reset2faSchemaUrl = `${auth2faUrl}${additionalUrls.reset}`;
export const enable2faSchemaUrl = `${auth2faUrl}${additionalUrls.enable}`;
export const personalInfoSchemaUrl = `${userPlaceholder}${additionalUrls.personalInfo}`;
export const additionalInfoSchemaUrl = `${userPlaceholder}${additionalUrls.additionalInfo}`;
export const preferencesSchemaUrl = `${userPlaceholder}${additionalUrls.preferences}`;
export const inviteTeamMemberSchemaUrl = (legalEntityId: string) => `${legalEntityId}${additionalUrls.members}`;
export const paymentOrderSchemaUrl = (accountUri: string, country?: string, beneficiaryType?: string, beneficiaryNetwork?: BeneficiaryNetwork) =>
  `${accountUri}${additionalUrls.paymentOrder}?bankCountry=${country}&isBusiness=${beneficiaryType === 'business'}&network=${beneficiaryNetwork}`;
export const cryptoPaymentOrderSchemaUrl = (accountUri: string) => `${accountUri}${additionalUrls.cryptoPaymentOrder}`;

export const getFormSchema = (schemaUrl: string): Promise<AxiosResponse<IGetFormSchemaResponse, AxiosError>> | undefined =>
  request('get', `${additionalUrls.schema}${schemaUrl}`)();
