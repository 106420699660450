import { Stack } from '@mui/system';
import { FC } from 'react';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import { useTranslation } from 'react-i18next';

import {
  StyledFlag,
  StyledListItemCurrencyLabelAndCurrency,
  TypographyWithElipsis,
  StyledTextSpan,
  StyledEditButtonSpan,
  StyledListItemWrapper
} from './StyledListItem.styles';
import { OptionInterface } from './styledListItem.utils';

export const StyledListItem: FC<{
  option: OptionInterface;
  hideLabel?: boolean;
  hideValue?: boolean;
  smallFlag?: boolean;
  filterValue?: string;
  onClickOption?: () => void;
  onEditButtonClick?: () => void;
}> = ({ option, hideValue = false, hideLabel = false, smallFlag = false, onClickOption, filterValue, onEditButtonClick, ...props }) => {
  const { t } = useTranslation();

  const getValueMatchesParts = (val: string, filterVal: string | undefined) => {
    let valMatches: [number, number][] = [];
    if (filterVal) {
      valMatches = match(val, filterVal);
    }
    return parse(val, valMatches);
  };

  return (
    <StyledListItemWrapper
      direction="row"
      {...props}
      onClick={() => {
        if (onClickOption) {
          onClickOption();
        }
      }}
      justifyContent="space-between"
    >
      <Stack direction="row">
        {option?.imageUrl && <StyledFlag src={option.imageUrl} size={smallFlag ? 16 : 32} alt="" />}
        <StyledListItemCurrencyLabelAndCurrency ml={smallFlag ? 1 : 2}>
          {!hideValue && (
            <TypographyWithElipsis variant="strongBody1">
              {getValueMatchesParts(option.value, filterValue).map((part, index) => (
                <StyledTextSpan highlight={part.highlight} key={index} valueTextSpan>
                  {part.text}
                </StyledTextSpan>
              ))}
            </TypographyWithElipsis>
          )}
          {!hideLabel && (
            <TypographyWithElipsis variant="body1">
              {getValueMatchesParts(option.label, filterValue).map((part, index) => (
                <StyledTextSpan highlight={part.highlight} key={index}>
                  {part.text}
                </StyledTextSpan>
              ))}
            </TypographyWithElipsis>
          )}
        </StyledListItemCurrencyLabelAndCurrency>
      </Stack>
      {onEditButtonClick && <StyledEditButtonSpan onClick={onEditButtonClick}>{t('common.edit')}</StyledEditButtonSpan>}
    </StyledListItemWrapper>
  );
};
