import { MenuItem } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import FormElement from '../FormElement/FormElement';

import DropdownProps from './Dropdown.interfaces';
import { StyledDropdown, StyledPlaceholder } from './Dropdown.styles';

const Dropdown: FC<DropdownProps> = ({
  name,
  value,
  error,
  label,
  labelTooltip,
  placeholder,
  dataList,
  handleError,
  handleOnChange,
  ...restProps
}) => {
  const { t } = useTranslation();

  return (
    <FormElement label={label} labelTooltip={labelTooltip} error={error}>
      <StyledDropdown
        displayEmpty
        value={value}
        error={!!error}
        inputProps={{ 'aria-label': 'Without label' }}
        MenuProps={{
          disablePortal: true
        }}
        renderValue={value ? undefined : () => <StyledPlaceholder>{placeholder || t('common.select')}</StyledPlaceholder>}
        onChange={e => {
          if (handleOnChange) {
            handleOnChange(name, e.target.value as string);
          }

          if (handleError && error) {
            handleError(name, '');
          }
        }}
        {...restProps}
      >
        {dataList &&
          dataList.map(e => (
            <MenuItem key={e.value} value={e.value}>
              {e.label}
            </MenuItem>
          ))}
      </StyledDropdown>
    </FormElement>
  );
};

export default Dropdown;
