import SvgIcon from 'src/components/SvgIcon/SvgIcon';

import { InfoBoxProps } from './InfoBox.interfaces';
import { StyledDesc, StyledInfoBox, StyledTextContent, StyledTitle, StyledDescriptionWrapper } from './InfoBox.styles';

const InfoBox = ({ title, description, descriptionIcon, icon, active = false, fullWidth, onClick, ...props }: InfoBoxProps) => (
  <StyledInfoBox onClick={onClick} active={active} fullWidth={!!fullWidth} {...props}>
    <SvgIcon icon={icon} />
    <StyledTextContent>
      <StyledTitle variant="subtitle3">{title}</StyledTitle>
      {!!description && (
        <StyledDescriptionWrapper>
          {descriptionIcon && <SvgIcon icon={descriptionIcon} />}
          <StyledDesc variant="body3" fullWidth={!!fullWidth}>
            {description}
          </StyledDesc>
        </StyledDescriptionWrapper>
      )}
    </StyledTextContent>
  </StyledInfoBox>
);

export default InfoBox;
