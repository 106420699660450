import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ERROR_INCORRECT_BACKUP_CODE, ERROR_INCORRECT_OTP } from 'src/constants/errors';
import { enable2faSchemaUrl, reset2faSchemaUrl } from 'src/api/formSchemas';
import { enable2fa, reset2fa } from 'src/api/auth';
import { IFormHandleSubmit, IOnFormChangeProps } from 'src/interfaces/forms';
import Form from 'src/components/Form/Form';
import Input from 'src/components/Input/Input';
import ActionButtons from 'src/components/ActionButtons/ActionButtons';
import { useSnackbar } from 'notistack';
import { Box, Typography, useTheme } from '@mui/material';
import Modal from 'src/components/Modal/Modal';
import { IReset2faResponse } from 'src/interfaces/responses';
import get from 'lodash.get';
import { QRCode } from 'react-qrcode-logo';
import { StorageKey } from 'src/constants/storage';

import { StyledContent, StyledSubtitle } from './AuthenticationModal.styles';
import { AuthenticationModalProps } from './AuthenticationModal.interfaces';

const Authentication2FAModal: FC<AuthenticationModalProps> = ({ isOpen, onClose }) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const { enqueueSnackbar } = useSnackbar();
  const [backupCode, setBackupCode] = useState<string>('');
  const [response, setResponse] = useState<IReset2faResponse>();

  const handleReset2faSubmit: IFormHandleSubmit = async (values, validateForm, handleError) => {
    if (validateForm(values)) {
      reset2fa(values.otpBackupCode as string)
        ?.then(response => {
          setResponse(response.data);
        })
        .catch(error => {
          try {
            const { code, message } = error.response.data;
            if (code === ERROR_INCORRECT_BACKUP_CODE) {
              handleError('otpBackupCode', message);
            } else {
              enqueueSnackbar(message, { variant: 'error' });
            }
          } catch {}
        });
    }
  };

  const handleEnable2faSubmit: IFormHandleSubmit = async (values, validateForm, handleError) => {
    if (validateForm(values)) {
      enable2fa(values.otp as string)
        ?.then(response => {
          sessionStorage.setItem(StorageKey.AUTH_TOKEN, response.data.strongToken);
          setBackupCode(response.data.otpBackupCode);
          enqueueSnackbar(t('personalSettings.security.reset2fa.success'), {
            variant: 'success'
          });
        })
        .catch(error => {
          try {
            const { code, message } = error.response.data;
            if (code === ERROR_INCORRECT_OTP) {
              handleError('otp', message);
            } else {
              enqueueSnackbar(message, { variant: 'error' });
            }
          } catch {}
        });
    }
  };

  const onFormChange = ({ values, lastChangedFieldName, submitForm }: IOnFormChangeProps) => {
    if (lastChangedFieldName === 'otp' && get(values, lastChangedFieldName, '').toString().length === 6) {
      submitForm?.();
    }
  };

  return (
    <Modal onClose={onClose} isOpen={isOpen} title={t('personalSettings.security.reset2fa.title')}>
      <StyledContent>
        {response ? (
          <>
            <Form schemaUrl={enable2faSchemaUrl} onSubmit={handleEnable2faSubmit} onChange={onFormChange}>
              <StyledSubtitle variant="body1">{t('screens.enable2fa.subtitle')}</StyledSubtitle>

              <QRCode
                size={184}
                logoWidth={50}
                value={response.otpSecretUrl}
                logoImage={`${process.env.REACT_APP_ASSETS_URL}/images/qr/${backupCode ? 'success.png' : 'logo.png'}`}
                fgColor={backupCode ? theme.palette.custom.grey[100] : theme.palette.common.black}
              />
              {backupCode ? (
                <div>
                  <Input
                    copyable
                    disabled
                    fullWidth
                    formField={false}
                    name="backupCode"
                    label={t('screens.enable2fa.backupCode')}
                    value={backupCode}
                  />
                  <Typography>{t('screens.enable2fa.backupCodeDescription')}</Typography>
                </div>
              ) : (
                <Input autoFocus name="otp" type="number" fullWidth />
              )}
            </Form>
            <ActionButtons
              primaryActionText={backupCode ? t('common.done') : undefined}
              onPrimaryActionClick={() => {
                setResponse(undefined);
                onClose();
              }}
            />
          </>
        ) : (
          <Form schemaUrl={reset2faSchemaUrl} onSubmit={handleReset2faSubmit}>
            <StyledSubtitle variant="body1">{t('personalSettings.security.reset2fa.modalSubtitle')}</StyledSubtitle>

            <Input name="otpBackupCode" />
            <Box sx={{ m: 5 }} />
            <ActionButtons primaryActionText={t('screens.reset2fa.button')} />
          </Form>
        )}
      </StyledContent>
    </Modal>
  );
};

export default Authentication2FAModal;
