import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonVariant } from 'src/constants/variants';
import SvgIcon from 'src/components/SvgIcon/SvgIcon';
import { Avatar, Typography } from '@mui/material';
import { EntityStatus } from 'src/interfaces/banking';
import { useNavigate } from 'react-router-dom';
import { useLegalEntitiesQuery } from 'src/api/queries/useLegalEntities';
import { useUserOrNavigateQuery } from 'src/api/queries/useUserOrNavigate';
import { urls } from 'src/routes';
import { StorageKey } from 'src/constants/storage';
import ModalLayout from 'src/components/layouts/ModalLayout/ModalLayout';
import { Alert } from 'src/components/Alert/Alert';

import { EntitySwitchProps } from './EntitySwitch.interfaces';
import { StyledButton, StyledContent, StyledEntity, StyledEntityText, StyledLoader, StyledSvgIcon } from './EntitySwitch.styles';

const EntitySwitch: FC<EntitySwitchProps> = ({ activeUri, onClose, onSelectEntity }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { data: user } = useUserOrNavigateQuery();
  const userUri = user?._links.self?.uri ?? '';
  const { data: entitiesData, isLoading, isError } = useLegalEntitiesQuery(userUri);
  const entities = entitiesData?.data ?? [];

  const renderEntities = () => {
    if (isError) {
      return <Alert>{t('banking.entities.error')}</Alert>;
    }
    if (entitiesData?.data && entitiesData?.data.length === 0) {
      return <Typography>{t('banking.entities.noAccounts')}</Typography>;
    }
    return entities.map(entity => {
      const { name, region, status, _links } = entity;
      const uri = _links.self.uri;

      return (
        <StyledEntity
          key={uri}
          active={uri === activeUri}
          clickable={status !== EntityStatus.Pending}
          onClick={() => {
            if (uri !== activeUri && status === EntityStatus.Approved) {
              sessionStorage.setItem(StorageKey.ENTITY_REGION, region);
              sessionStorage.setItem(StorageKey.SELECTED_ENTITY_URI, uri);
              sessionStorage.removeItem(StorageKey.PREFERRED_ENTITY_URI);
            }
            onSelectEntity(entity);
          }}
        >
          {status !== EntityStatus.Approved ? <SvgIcon icon={`entity${status}`} /> : <Avatar>{name[0]}</Avatar>}
          <StyledEntityText>
            <Typography variant="strongBody1">{name}</Typography>
            {status !== EntityStatus.Approved && <Typography variant="body3">{t(`banking.entities.status.${status}`)}</Typography>}
          </StyledEntityText>
        </StyledEntity>
      );
    });
  };

  if (isLoading) {
    return <StyledLoader />;
  }
  return (
    <ModalLayout
      icon={<StyledSvgIcon icon="entities" />}
      title={t('banking.entities.title')}
      subtitle={t('banking.entities.switchAndManage')}
      onClose={onClose}
    >
      <StyledContent>
        {renderEntities()}
        <StyledButton
          fullWidth
          icon={<SvgIcon icon="addRound" />}
          title={t('banking.entities.openAnother')}
          variant={ButtonVariant.Secondary}
          onClick={() => navigate(urls.corporateAccount)}
        />
      </StyledContent>
    </ModalLayout>
  );
};

export default EntitySwitch;
