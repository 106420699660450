import { FC } from 'react';
import { Grid } from '@mui/material';

import { PlacePredictionProps } from './PlacePrediction.interface';
import { StyledPlaceLocationIcon, StyledSecondaryText, StyledTextSpan } from './PlacePrediction.styles';

export const PlacePrediction: FC<PlacePredictionProps> = ({ parts, option }) => (
  <Grid container alignItems="center">
    <Grid item>
      <StyledPlaceLocationIcon icon="pin" />
    </Grid>
    <Grid item xs>
      {parts.map((part, index) => (
        <StyledTextSpan highlight={part.highlight} key={index}>
          {part.text}
        </StyledTextSpan>
      ))}
      {/* TODO: Consider changing all 'visual' prop to sx */}
      <StyledSecondaryText variant="body2">, {option.structured_formatting.secondary_text}</StyledSecondaryText>
    </Grid>
  </Grid>
);
