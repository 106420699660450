import { IconKeys } from 'src/components/SvgIcon/SvgIcon.interfaces';

import { IAction, ILinks } from './global';

/* TODO: Move all enums to constants */
export enum AccountsDashboardTabState {
  All = 'all',
  Fiat = 'fiat',
  Crypto = 'crypto'
}

export enum AccountHoldingsTabState {
  AllHoldings
}

export enum AccountDetailsTabState {
  Transactions = 'transactions',
  Orders = 'orders',
  AccountDetails = 'details',
  Settings = 'settings'
}

export enum AccountSorts {
  Default = 'default',
  BalanceHighest = 'balanceHighest',
  BalanceLowest = 'balanceLowest',
  AlphabeticalAZ = 'alphabeticalAZ',
  AlphabeticalZA = 'alphabeticalZA'
}

export enum TransactionFilter {
  All = 'ALL',
  Swaps = 'SWAP',
  Deposits = 'DEPOSIT',
  Withdraws = 'WITHDRAW',
  Transfers = 'TRANSFER'
}

export enum BeneficiaryFilter {
  Newest = 'NEWEST',
  Oldest = 'OLDEST',
  Alphabetical = 'ALPHABETICAL'
}

export enum BeneficiaryNetwork {
  Bitcoin = 'BITCOIN',
  Ethereum = 'ETHEREUM',
  Fedwire = 'FEDWIRE',
  Sepa = 'SEPA',
  Swift = 'SWIFT'
}

export enum TransactionStatus {
  Success = 'SUCCESS',
  Submitted = 'SUBMITTED',
  Completed = 'COMPLETED',
  Failed = 'FAILED',
  PendingApproval = 'PENDING_APPROVAL',
  Rejected = 'REJECTED'
}

export enum TransactionType {
  Swap = 'SWAP',
  Deposit = 'DEPOSIT',
  Withdraw = 'WITHDRAW',
  Transfer = 'TRANSFER'
}

export enum BookingType {
  Debit = 'DEBIT',
  Credit = 'CREDIT'
}

export enum EntityStatus {
  Approved = 'APPROVED',
  Pending = 'PENDING',
  Changes = 'CHANGES',
  Rejected = 'REJECTED'
}

export enum DecisionType {
  Approved = 'APPROVED',
  Declined = 'DECLINED'
}

export enum AccountStatus {
  Enabled = 'ENABLED',
  Pending = 'PENDING'
}

export enum CurrencyType {
  Fiat = 'FIAT',
  Crypto = 'CRYPTO'
}

export enum SwapType {
  Source = 'source',
  Target = 'target'
}

export interface Entity {
  name: string;
  region: string;
  status: EntityStatus;
  _links: ILinks;
  _actions?: {
    addMember?: IAction;
  };
}

export interface Account {
  status: AccountStatus;
  balance: string;
  currency: string;
  currencyLabel: string;
  metaData?: AccountMetaData;
  type: CurrencyType;
  details?: AccountDetails;
  valuation: Valuation;
  _links: ILinks;
  _actions?: {
    swap?: IAction;
    send?: IAction;
  };
}

export interface Address {
  street?: string;
  houseNo?: string;
  postalCode?: string;
  city?: string;
  state?: string;
  country: string;
}

export interface Counterpart {
  name: string;
  address: string;
  country: string;
  ibanOrAccountNr: string;
  bicOrRoutingCode: string;
}

export interface AccountMetaData {
  name?: string;
  description?: string;
  purpose?: string;
}

export interface Valuation {
  rate?: string;
  amount: string;
  currency: string;
}

export interface AccountDetails {
  [fieldName: string]: AccountDetails | AccountDetailsHint[] | CryptoAccountDetails | string;
}

export type AccountDetailsObjectEntries = [string, string | AccountDetails | AccountDetailsHint[] | CryptoAccountDetails];

export interface AccountDetailsHint {
  topic: string;
  text: string;
}

export interface CryptoAccountDetails {
  cryptoNetwork: string;
  walletAddress: string;
  walletName: string;
}

export interface AccountDetailsHintIcons {
  [id: string]: IconKeys;
}

export interface Transaction {
  amount: string;
  bookingDate: string;
  bookingType: BookingType;
  currency: string;
  currencyType?: CurrencyType;
  description: string;
  status: TransactionStatus;
  type: TransactionType;
  counterpart?: Counterpart;
  externalNetworkId?: string;
  externalNetworkLink?: string;
  order?: Order;
  _links: {
    self: {
      uri: string;
    };
    swapOrder?: {
      uri: string;
    };
    paymentOrder?: {
      uri: string;
    };
  };
}

export interface Rates {
  base: string;
  forward: number;
  quote: string;
  reverse: number;
}

export interface BankInfo {
  address: string;
  name: string;
  country: string;
}

export interface BankInfoExtended extends BankInfo {
  bicSwift: string;
}

export interface SwapQuotes {
  [fieldName: string]: string;
}

export interface SwapRequest {
  estimatedFee: string;
  executionDate: string;
}

export interface PaymentRequest {
  estimatedFee: string;
  executionDate: string;
}

export interface Amount {
  amount: string;
  currency: string;
  currencyType?: CurrencyType;
}

export interface SwapOrderRequest {
  createdAt: string;
  offer: {
    buy: Amount;
    sell: Amount;
    clientRate: string;
    executionDate: string;
  };
}

export interface CryptoBeneficiary {
  walletAddress?: string;
}

export interface Beneficiary {
  lastName?: string;
  firstName?: string;
  companyName?: string;
  bicSwiftCode?: string;
  accountNumber?: string;
  aba?: string;
  routingNumber?: string;
  walletAddress?: string;
  iban?: string;
  address?: Address;
  _id?: string;
  bank?: BankInfo;
  network?: BeneficiaryNetwork;
  targetCurrency?: string;
  _links?: {
    account: { uri: string };
  };
}

export interface OrderRequest {
  sourceAccountId: string;
  beneficiary: Beneficiary;
  amount: string;
  currency: string;
  vendorNote?: string;
  personalNote?: string;
  feeCoverage: string;
}

export interface ApprovalDecision {
  name: string;
  date: string;
  decision: DecisionType;
}

export interface Order extends SwapOrderRequest {
  type: TransactionType;
  description: string;
  status: TransactionStatus;
  sourceAccountId: string;
  request: OrderRequest;
  requestorName: string;
  approvals: {
    required: number;
    approved: number;
    decisions?: ApprovalDecision[];
  };
  _links: {
    self: { uri: string };
  };
  _actions?: {
    approve?: {
      uri: string;
    };
    reject?: {
      uri: string;
    };
  };
}
