import { FC, useState } from 'react';
import { IFormHandleSubmit } from 'src/interfaces/forms';
import Form from 'src/components/Form/Form';
import { reset2faSchemaUrl } from 'src/api/formSchemas';
import Input from 'src/components/Input/Input';
import SignInLayout from 'src/components/layouts/SignInLayout/SignInLayout';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { urls } from 'src/routes';
import { reset2fa } from 'src/api/auth';
import ActionButtons from 'src/components/ActionButtons/ActionButtons';
import { useSnackbar } from 'notistack';
import { ERROR_INCORRECT_BACKUP_CODE } from 'src/constants/errors';
import { useNavigateSearch } from 'src/hooks/navigation';

const Reset2faPage: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const navigateSearch = useNavigateSearch();

  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState<boolean>(false);

  const handleSubmit: IFormHandleSubmit = async (values, validateForm, handleError) => {
    if (validateForm(values)) {
      setLoading(true);

      reset2fa(values.otpBackupCode as string)
        ?.then(response => {
          const { otpSecret, otpSecretUrl } = response.data;
          navigateSearch(urls.enable2fa, { otpSecret, otpSecretUrl });
        })
        .catch(error => {
          try {
            const { code, message } = error.response.data;
            if (code === ERROR_INCORRECT_BACKUP_CODE) {
              handleError('otpBackupCode', message);
            } else {
              enqueueSnackbar(message, { variant: 'error' });
            }
          } catch {}
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  return (
    <SignInLayout loading={loading} title={t('screens.reset2fa.title')} subtitle={t('screens.reset2fa.subtitle')}>
      <Form schemaUrl={reset2faSchemaUrl} onSubmit={handleSubmit}>
        <Input name="otpBackupCode" />

        <ActionButtons
          primaryActionText={t('screens.reset2fa.button')}
          secondaryBackAction
          secondaryActionText={t('common.goBack')}
          onSecondaryActionClick={() => navigate(-1)}
        />
      </Form>
    </SignInLayout>
  );
};

export default Reset2faPage;
