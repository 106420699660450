import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { CORPORATE_ACCOUNT, CORPORATE_ONBOARDING_STEPS } from 'src/constants/onboarding';
import DialogLayout from 'src/components/layouts/DialogLayout/DialogLayout';
import { urls } from 'src/routes';
import { useLegalEntitiesQuery } from 'src/api/queries/useLegalEntities';
import { useUserOrNavigateQuery } from 'src/api/queries/useUserOrNavigate';

import { ButtonWrapper, StyledInfoBox } from './CorporateAccountPage.styles';

export const CorporateAccountPage: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { data: user } = useUserOrNavigateQuery();
  const { data: entitiesData } = useLegalEntitiesQuery(user?._links.self?.uri ?? '');

  return (
    <DialogLayout
      heading={t('screens.corporateAccount.title')}
      subheading={t('screens.corporateAccount.subtitle')}
      isOnboardingLayout
      withOnboardingSteps
      secondaryActionText={entitiesData?.data?.length ? t('screens.corporateAccount.backButton') : undefined}
      onSecondaryActionClick={() => navigate(urls.entitySelection)}
      onboardingSteps={CORPORATE_ONBOARDING_STEPS}
      activeStepName={CORPORATE_ACCOUNT}
    >
      <ButtonWrapper spacing={2} direction="column">
        <StyledInfoBox
          icon="corporationIcon"
          title={t('screens.corporateAccount.buttonOpenNewAccount')}
          onClick={() => navigate(urls.corporateInformation)}
        />
        <StyledInfoBox
          icon="corporationIcon"
          title={t('screens.corporateAccount.buttonJoinExistingOne')}
          onClick={() => undefined} // TODO: - Add Deep link invitation screen && Reference code screen
        />
      </ButtonWrapper>
    </DialogLayout>
  );
};
