import { Link, styled, Typography } from '@mui/material';

import { DialogNavLinksProps } from './DialogNavLinks.interfaces';

export const StyledNavLinkWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(2),
  padding: theme.spacing(1, 2.5),
  marginBottom: theme.spacing(1),

  '.MuiSvgIcon-root': {
    fontSize: '1rem',
    g: {
      opacity: 0.35
    }
  }
}));

export const StyledNavLink = styled(Link)(({ theme }) => ({
  color: theme.palette.common.white
}));

export const StyledSignIn = styled(Link)(({ theme }) => ({
  color: theme.palette.common.white
}));

export const StyledHaveAccount = styled(Typography)(({ theme }) => ({
  color: theme.palette.common.white,
  opacity: 0.6
}));

export const StyledSignInWrapper = styled('div')(({ theme }) => ({
  marginLeft: theme.spacing(2)
}));

export const StyledContainer = styled('div', {
  shouldForwardProp: prop => prop !== 'absolutePosition'
})<DialogNavLinksProps>(({ absolutePosition }) => ({
  ...(absolutePosition && {
    alignSelf: 'end',
    position: 'absolute',
    left: 80,
    bottom: 80
  })
}));
