import { styled } from '@mui/material';

export const StyledLabelValue = styled('div')({
  display: 'flex',
  flexDirection: 'row'
});

export const StyledIcon = styled('div')(({ theme }) => ({
  width: 40,
  height: 40,
  fontSize: 16,
  color: theme.palette.custom.grey[300],
  borderRadius: theme.shape.borderRadius,
  background: theme.palette.custom.grey[50],
  padding: theme.spacing(1.5),
  marginRight: theme.spacing(2),

  '& .MuiSvgIcon-root': {
    path: {
      fill: theme.palette.custom.grey[300]
    }
  }
}));
