import { UseQueryResult, useQuery } from '@tanstack/react-query';
import { AxiosResponse, AxiosError } from 'axios';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { QueryKey } from 'src/constants/queryKeys';
import { IGetAccountDetailsResponse } from 'src/interfaces/responses';

import { getAccountDetails } from '../banking';

export const useAccountDetailsQuery = (
  uri: string,
  showSnackbar = false
): UseQueryResult<AxiosResponse<IGetAccountDetailsResponse, AxiosError>, { response: AxiosResponse<AxiosError> }> => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  return useQuery([QueryKey.QUERY_ACCOUNT_DETAILS, uri], () => getAccountDetails(uri), {
    onError: error => {
      if (showSnackbar) {
        enqueueSnackbar(error?.response?.data?.message ?? t('screens.error'), { variant: 'error' });
      }
      return error.response;
    },
    enabled: !!uri
  });
};
