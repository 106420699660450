import MembersTabs from 'src/components/settings/companySettings/MembersTabs/MembersTabs';
import { Subpage } from 'src/components/layouts/SettingsLayout/SettingsLayout.interfaces';
import { CompanySettingsBeneficiaries } from 'src/components/settings/companySettings/CompanySettingsBeneficiaries/CompanySettingsBeneficiaries';

export const COMPANY_SETTINGS_SUBPAGES: Subpage[] = [
  {
    path: 'members',
    component: MembersTabs,
    i18nTitle: 'companySettings.common.members'
  },
  {
    path: 'beneficiaries',
    component: CompanySettingsBeneficiaries,
    i18nTitle: 'companySettings.common.beneficiaries'
  }
];
