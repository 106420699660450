import { styled } from '@mui/material';

export const StyledLoader = styled('div')(({ theme }) => ({
  padding: theme.spacing(5),
  textAlign: 'center'
}));

export const StyledTooltip = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: 6
});
