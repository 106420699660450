import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { IGetLegalEntitiesResponse, IGetLegalEntityResponse } from 'src/interfaces/responses';
import { QueryKey } from 'src/constants/queryKeys';

import { getLegalEntities, getLegalEntity } from '../banking';

export const useLegalEntitiesQuery = (userUri: string): UseQueryResult<AxiosResponse<IGetLegalEntitiesResponse, AxiosError> | undefined> =>
  useQuery([QueryKey.QUERY_LEGAL_ENTITIES, userUri], () => getLegalEntities(userUri), {
    enabled: !!userUri
  });

export const useLegalEntityQuery = (entityUri?: string): UseQueryResult<AxiosResponse<IGetLegalEntityResponse, AxiosError> | undefined> =>
  useQuery([QueryKey.QUERY_LEGAL_ENTITY, entityUri], () => getLegalEntity(entityUri ?? ''), {
    enabled: !!entityUri
  });
