import { BookingType, CurrencyType, Order, Transaction, TransactionType } from '../interfaces/banking';

import { toMiddleCut } from './strings';

export const getTransactionDescription = (transaction: Transaction) => {
  const { type, currencyType, description } = transaction;
  if (description && (type === TransactionType.Withdraw || type === TransactionType.Deposit) && currencyType === CurrencyType.Crypto) {
    const parts = description.split(' ');
    if (parts.length === 3) {
      const walletAddress = parts[2];
      return description.replace(walletAddress, toMiddleCut(walletAddress));
    }
  }

  return description;
};

export const getTransactionsFromOrders = (orders?: Order[]): Transaction[] => {
  if (orders) {
    return orders.map<Transaction>(order => ({
      order: order,
      status: order.status,
      description: order.description,
      amount: order.offer.sell.amount,
      currency: order.offer.sell.currency,
      currencyType: order.offer.sell.currencyType,
      bookingDate: order.createdAt,
      type: order.type,
      bookingType: BookingType.Debit,
      _links: order._links
    }));
  }

  return [];
};
