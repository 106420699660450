import DialogLayout from 'src/components/layouts/DialogLayout/DialogLayout';
import EntitySwitch from 'src/components/banking/EntitySwitch/EntitySwitch';
import { useNavigate } from 'react-router';
import { EntityStatus } from 'src/interfaces/banking';
import { urls } from 'src/routes';

import { StyledEntitySwitchWrapper } from './EntitySelectionPage.styles';

const EntitySelectionPage = () => {
  const navigate = useNavigate();

  return (
    <DialogLayout isOnboardingLayout contentPadding={0} showNavLinks contentMargin={4} anchorToParent centeredContentWrapper>
      <StyledEntitySwitchWrapper>
        <EntitySwitch
          onSelectEntity={entity => {
            if (entity.status === EntityStatus.Approved) {
              navigate(urls.balances);
            }
          }}
        />
      </StyledEntitySwitchWrapper>
    </DialogLayout>
  );
};

export default EntitySelectionPage;
