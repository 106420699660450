import { EmailRounded } from '@mui/icons-material';
import { styled, Typography } from '@mui/material';

export const StyledEmailRounded = styled(EmailRounded)(({ theme }) => ({
  color: theme.palette.custom.gold[400]
}));

export const StyledValidty = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(2)
}));
