import dayjs from 'dayjs';
import localeData from 'dayjs/plugin/localeData';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import { getUserLocale } from 'get-user-locale';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

import 'dayjs/locale/de';
import 'dayjs/locale/fr';
import 'dayjs/locale/it';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(localeData);
dayjs.extend(advancedFormat);
dayjs.locale(getUserLocale());

let defaultTimezone: string;

export const setDefaultTimezone = (preferencesTimezone: string) => {
  defaultTimezone = preferencesTimezone;
};

export const getTimezone = () => Intl.DateTimeFormat().resolvedOptions().timeZone;

export const formatDate = (date: string, format = 'LLL') => dayjs(date).tz(defaultTimezone).format(format);

export const localizedDateFormat = () => dayjs().localeData().longDateFormat('L');

export const formatAmount = (amount: string, decimalOverride?: number) => {
  let decimals = decimalOverride ?? 0;
  const decimalIdx = amount.indexOf('.');
  if (!decimals && Number(amount) < 1000) {
    return amount;
  } else if (!decimals && decimalIdx >= 0) {
    decimals = amount.length - decimalIdx - 1;
  }
  return new Intl.NumberFormat(getUserLocale(), {
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals
  }).format(Number(amount));
};
