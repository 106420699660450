import { FC } from 'react';
import { useParams } from 'react-router-dom';
import { useUserOrNavigateQuery } from 'src/api/queries/useUserOrNavigate';
import Loader from 'src/components/Loader/Loader';
import { additionalUrls } from 'src/api/additionalUrls';
import { StorageKey } from 'src/constants/storage';

const LegalEntityPage: FC = () => {
  const { entityId } = useParams();
  if (entityId) {
    sessionStorage.setItem(StorageKey.PREFERRED_ENTITY_URI, `${additionalUrls.legalEntities}/${entityId}`);
  }

  const { isLoading } = useUserOrNavigateQuery(true);
  return isLoading ? <Loader /> : <></>;
};

export default LegalEntityPage;
