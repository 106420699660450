import { Typography } from '@mui/material';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ActionTab from 'src/components/banking/actions/ActionTab/ActionTab';
import CopyItem from 'src/components/banking/CopyItem/CopyItem';
import { formatAmount, formatDate } from 'src/utils/localization';
import { submitSwapOrder } from 'src/api/banking';
import { getIdFromUri } from 'src/utils/strings';
import { ActionBarContext } from 'src/contexts/ActionBar.contexts';
import { getCurrencyImage } from 'src/utils/styles';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { QueryKey } from 'src/constants/queryKeys';
import { AxiosError } from 'axios';
import { useSwapRequestQuery } from 'src/api/queries/useSwapRequest';
import { IResponseError } from 'src/interfaces/responses';
import { SwapContext } from 'src/contexts/actions/Swap.contexts';
import { CurrencyType, Amount, SwapType, TransactionStatus } from 'src/interfaces/banking';
import { enqueueSnackbar } from 'notistack';

import SwapConfirmation from '../SwapConfirmation/SwapConfirmation';
import SwapSelection from '../SwapSelection/SwapSelection';
import { swapActionTabDefaultValues } from '../Swap.utils';

import { StyledCurrencyFlag, StyledDetails } from './SwapDetails.styles';

const SwapDetails = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const actionBarContext = useContext(ActionBarContext);

  const { rate = '0', amount = '0', targetAmount, targetAccount, sourceAccount } = useContext(SwapContext) ?? {};

  const sourceAccountUri = sourceAccount?._links.self.uri ?? '';
  const targetAccountId = getIdFromUri(targetAccount?._links.self.uri ?? '');

  const { data: swapRequest, isLoading: isSwapRequestLoading } = useSwapRequestQuery(getIdFromUri(sourceAccountUri), targetAccountId, amount);
  const estimatedFee = swapRequest?.data?.estimatedFee;

  const [back, setBack] = useState<boolean>(false);
  const [confirmedAmount, setConfirmedAmount] = useState<Amount>();
  const [waitingForApproval, setWaitingForApproval] = useState(false);

  const submitOrderMutation = useMutation(
    () => submitSwapOrder(sourceAccountUri, targetAccountId, targetAmount ?? amount, targetAmount ? SwapType.Target : SwapType.Source),
    {
      onSuccess: ({ data }) => {
        setConfirmedAmount(data.offer.buy);
        setWaitingForApproval(data.status === TransactionStatus.PendingApproval);
        queryClient.invalidateQueries([QueryKey.QUERY_ORDERS]);
      },
      onError: (error: AxiosError<IResponseError>) => {
        const message = error?.response?.data?.message;
        enqueueSnackbar(message ?? t('screens.error'), { variant: 'error' });
      }
    }
  );

  const renderItem = (key: string, value: string, currency?: string) => (
    <CopyItem
      label={t(`banking.actions.swap.details.${key}`)}
      value={value}
      valueDecoration={currency ? <StyledCurrencyFlag src={getCurrencyImage(currency)} alt={`${currency} flag`} /> : undefined}
      disableCopy
    />
  );

  const handleSubmit = () => submitOrderMutation.mutate();

  if (submitOrderMutation.isSuccess && confirmedAmount) {
    return <SwapConfirmation amount={confirmedAmount?.amount} currency={confirmedAmount?.currency} waitingForApproval={waitingForApproval} />;
  } else if (back) {
    return <SwapSelection />;
  }

  return actionBarContext ? (
    <ActionTab
      {...swapActionTabDefaultValues}
      loading={submitOrderMutation.isLoading || isSwapRequestLoading}
      primaryActionTextKey="common.confirm"
      onPrimaryActionClick={handleSubmit}
      onGoBack={() => setBack(true)}
      onClose={actionBarContext?.closeActionTab}
      activeStepIndex={1}
    >
      <Typography variant="strongBody1">{t('banking.actions.swap.details.title')}</Typography>
      {sourceAccount && targetAccount && (
        <StyledDetails>
          {renderItem(
            'buying',
            `${formatAmount(targetAmount ?? `${Number(rate) * Number(amount)}`, targetAccount.type === CurrencyType.Crypto ? 5 : 2)} ${
              targetAccount?.currency
            }`,
            targetAccount?.currency
          )}
          {renderItem(
            'selling',
            `${formatAmount(amount, sourceAccount.type === CurrencyType.Crypto ? 5 : 2)} ${sourceAccount?.currency}`,
            sourceAccount?.currency
          )}
          {renderItem('estimatedRate', `${formatAmount(rate, 4)} ${targetAccount?.currency} = ${formatAmount('1')} ${sourceAccount?.currency}`)}
          {renderItem(
            'settlement',
            swapRequest?.data ? formatDate(swapRequest.data.executionDate, 'lll') : t('banking.actions.swap.details.missingSettlement')
          )}
        </StyledDetails>
      )}

      {estimatedFee && Number(estimatedFee) !== 0 && (
        <StyledDetails>
          {renderItem('estimatedFee', `${formatAmount(estimatedFee, sourceAccount?.type === CurrencyType.Fiat ? 2 : 5)} ${sourceAccount?.currency}`)}
        </StyledDetails>
      )}
    </ActionTab>
  ) : null;
};

export default SwapDetails;
