import { Box, Typography } from '@mui/material';
import { FC, SetStateAction, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonVariant } from 'src/constants/variants';
import InviteMemberModal from 'src/components/modals/settings/companySettings/InviteMemberModal/InviteMemberModal';
import DeleteInviteModal from 'src/components/modals/settings/companySettings/DeleteInviteModal/DeleteInviteModal';
import RemoveMemberModal from 'src/components/modals/settings/companySettings/RemoveMemberModal/RemoveMemberModal';
import SvgIcon from 'src/components/SvgIcon/SvgIcon';
import { COMPANY_SETTINGS_TEAM_MEMBERS_TABS } from 'src/constants/tabs';
import {
  useCreateLegalEntityMember,
  useGetLegalEntityMembers,
  useRemoveLegalEntityMember,
  useResendInvitation,
  useUpdateLegalEntityMember
} from 'src/api/queries/useLegalEntityMembers';
import { getSelectedLegalEntityUri } from 'src/utils/storage';
import Loader from 'src/components/Loader/Loader';
import { LegalEntityMemberStatus } from 'src/constants/settings';
import { LegalEntityMember } from 'src/interfaces/settings';
import { IFormHandleSubmit } from 'src/interfaces/forms';
import { IResponseError } from 'src/interfaces/responses';
import { AxiosError } from 'axios';
import { ERROR_INCORRECT_OTP } from 'src/constants/errors';
import { ResendInvitationModal } from 'src/components/modals/settings/companySettings/ResendInvitationModal/ResendInvitationModal';
import { useUserOrNavigateQuery } from 'src/api/queries/useUserOrNavigate';
import { useLegalEntitiesQuery } from 'src/api/queries/useLegalEntities';
import { getEntityFromEntities } from 'src/utils/entity';

import MembersList from '../MembersList/MembersList';
import { StyledEmptyExistingMembersList, StyledEmptyExistingMembersListIcon } from '../MembersList/MembersList.styles';

import { StyledInviteButton, StyledPageHeader, StyledTabs } from './MembersTabs.styles';

const MembersTabs: FC = () => {
  const legalEntityUri = getSelectedLegalEntityUri();
  const { data: user } = useUserOrNavigateQuery();
  const userUri = user?._links.self.uri ?? '';
  const { data: legalEntitiesData } = useLegalEntitiesQuery(userUri);
  const getEntityFromEntitiesCallback = useCallback(() => {
    const entities = legalEntitiesData?.data ?? [];
    return getEntityFromEntities(legalEntityUri, entities);
  }, [legalEntitiesData, legalEntityUri]);

  const entityInUse = getEntityFromEntitiesCallback();

  const { t } = useTranslation();
  const [isDeleteInviteModalOpened, setIsDeleteInviteModalOpened] = useState<boolean>(false);
  const [selectedTabIndex, setSelectedTabIndex] = useState<number>(0);
  const [selectedMember, setSelectedMember] = useState<LegalEntityMember | null>(null);
  const [isInviteMemberModalOpened, setIsInviteMemberModalOpened] = useState<boolean>(false);
  const [isRemoveMemberModalOpened, setIsRemoveMemberModalOpened] = useState<boolean>(false);
  const [isResendInvitationModalOpened, setIsResendInvitationModalOpened] = useState<boolean>(false);
  const [invitedMembersMenuAnchorEl, setInvitedMembersMenuAnchorEl] = useState<null | HTMLElement>(null);
  const [existingMembersMenuAnchorEl, setExistingMembersMenuAnchorEl] = useState<null | HTMLElement>(null);
  const { data: legalEntityMembersData, isLoading: areLegalEntityMembersLoading } = useGetLegalEntityMembers(legalEntityUri);
  const legalEntityMembers = useMemo(() => legalEntityMembersData?.data, [legalEntityMembersData]);
  const { mutate: updateLegalEntityMember } = useUpdateLegalEntityMember(selectedMember?._actions?.edit?.uri ?? '');
  const { mutate: removeLegalEntityMember } = useRemoveLegalEntityMember(selectedMember?._actions?.delete?.uri ?? '');
  const { mutateAsync: resendInvitation } = useResendInvitation(selectedMember?._actions?.resendInvitation?.uri ?? '');
  const { mutateAsync: createLegalEntityMember } = useCreateLegalEntityMember(legalEntityUri);
  const legalEntityExistingMembers = useMemo(
    () => legalEntityMembers?.filter(legalEntityMember => legalEntityMember.status === LegalEntityMemberStatus.Accepted) || [],
    [legalEntityMembers]
  );
  const legalEntityInvitedMembers = useMemo(
    () => legalEntityMembers?.filter(member => !legalEntityExistingMembers.includes(member)) || [],
    [legalEntityMembers, legalEntityExistingMembers]
  );

  const invitedMembersMenuOpen = Boolean(invitedMembersMenuAnchorEl);
  const existingMembersMenuOpen = Boolean(existingMembersMenuAnchorEl);

  const handleInviteMemberModalOpen = (member: LegalEntityMember | null) => {
    setIsInviteMemberModalOpened(true);
    setSelectedMember(member);
    setExistingMembersMenuAnchorEl(null);
  };

  const handleResendInvitationModalOpen = (member: LegalEntityMember | null) => {
    setIsResendInvitationModalOpened(true);
    setSelectedMember(member);
    setInvitedMembersMenuAnchorEl(null);
  };

  const handleRemoveMemberModalOpen = (member: LegalEntityMember | null) => {
    setIsRemoveMemberModalOpened(true);
    setSelectedMember(member);
    setExistingMembersMenuAnchorEl(null);
  };

  const handleDeleteInviteModalOpen = (member: LegalEntityMember | null) => {
    setIsDeleteInviteModalOpened(true);
    setSelectedMember(member);
    setInvitedMembersMenuAnchorEl(null);
  };

  const handleInviteMember: IFormHandleSubmit = async (values, validateForm) => {
    if (!validateForm(values)) {
      return;
    }

    if (selectedMember) {
      updateLegalEntityMember({
        corporateTitle: values.corporateTitle as string,
        role: values.role as string
      });
    } else {
      try {
        await createLegalEntityMember(values as unknown as LegalEntityMember);
      } catch (error) {
        const { code } = (error as AxiosError<IResponseError>).response?.data || {};

        if (code === ERROR_INCORRECT_OTP) {
          return;
        }
      }
    }

    setIsInviteMemberModalOpened(false);
  };

  const handleRemoveMember = () => {
    if (selectedMember) {
      removeLegalEntityMember();
      setSelectedMember(null);
      setIsRemoveMemberModalOpened(false);
    }
  };

  const handleResendInvitationSubmit: IFormHandleSubmit = (values, validateForm) => {
    if (!validateForm(values)) {
      return;
    }

    resendInvitation(values.otp as string).then(() => setIsResendInvitationModalOpened(false));
  };

  const handleInvitationRemove = () => {
    if (selectedMember) {
      removeLegalEntityMember();
      setSelectedMember(null);
      setIsDeleteInviteModalOpened(false);
    }
  };

  const EmptyExistingMembersList = (
    <StyledEmptyExistingMembersList>
      <StyledEmptyExistingMembersListIcon icon="letterKycBlocks" />
      <Typography variant="subtitle1">{t('companySettings.members.emptyExistingMembersListTitle')}</Typography>
      <Typography variant="body1">{t('companySettings.members.emptyExistingMembersListContent')}</Typography>
    </StyledEmptyExistingMembersList>
  );

  const memberTabsContents = [
    !!legalEntityExistingMembers.length ? (
      <MembersList
        members={legalEntityExistingMembers}
        menuAnchorEl={existingMembersMenuAnchorEl}
        setMenuAnchorEl={setExistingMembersMenuAnchorEl}
        isMenuDisabled={member => !member._actions?.edit?.uri && !member._actions?.delete?.uri}
        menuOpen={existingMembersMenuOpen}
        menuItems={member => [
          ...(!!member._actions?.edit?.uri
            ? [
                {
                  title: t('common.edit'),
                  onClick: () => handleInviteMemberModalOpen(member)
                }
              ]
            : []),
          ...(!!member._actions?.delete?.uri
            ? [
                {
                  title: t('common.remove'),
                  onClick: () => handleRemoveMemberModalOpen(member)
                }
              ]
            : [])
        ]}
        onMenuClose={() => setExistingMembersMenuAnchorEl(null)}
      />
    ) : (
      EmptyExistingMembersList
    ),
    <MembersList
      members={legalEntityInvitedMembers}
      menuAnchorEl={invitedMembersMenuAnchorEl}
      setMenuAnchorEl={setInvitedMembersMenuAnchorEl}
      isMenuDisabled={member => member.status !== LegalEntityMemberStatus.Expired && !member._actions?.delete?.uri}
      menuOpen={invitedMembersMenuOpen}
      menuItems={member => [
        ...(member.status === LegalEntityMemberStatus.Expired && !!member._actions?.resendInvitation?.uri
          ? [
              {
                title: t('companySettings.members.resendInvite'),
                onClick: () => handleResendInvitationModalOpen(member)
              }
            ]
          : []),
        ...(!!member._actions?.delete?.uri
          ? [
              {
                title: t('companySettings.members.deleteInvite'),
                onClick: () => handleDeleteInviteModalOpen(member)
              }
            ]
          : [])
      ]}
      onMenuClose={() => setInvitedMembersMenuAnchorEl(null)}
    />
  ];

  return (
    <>
      <Typography variant="strongBody1">{t('companySettings.members.title')}</Typography>
      <StyledPageHeader>
        <Box>
          <Typography variant="subtitle3">{t('companySettings.members.subtitle')}</Typography>
          <Typography variant="body3" color={theme => ({ color: theme.palette.custom.grey[400] })}>
            {t('companySettings.members.description')}
          </Typography>
        </Box>
        {!!entityInUse?._actions?.addMember ? (
          <StyledInviteButton icon={<SvgIcon icon="addCircle" />} variant={ButtonVariant.Secondary} onClick={() => handleInviteMemberModalOpen(null)}>
            {t('companySettings.members.actionButton')}
          </StyledInviteButton>
        ) : undefined}
      </StyledPageHeader>
      <StyledTabs
        tabs={COMPANY_SETTINGS_TEAM_MEMBERS_TABS}
        counts={[0, legalEntityInvitedMembers.length]}
        value={selectedTabIndex}
        onChange={(_: unknown, value: SetStateAction<number>) => setSelectedTabIndex(value)}
      >
        {areLegalEntityMembersLoading ? <Loader anchorToRelative /> : memberTabsContents[selectedTabIndex]}
      </StyledTabs>
      {isInviteMemberModalOpened && (
        <InviteMemberModal
          onSubmit={handleInviteMember}
          member={selectedMember}
          isOpen={isInviteMemberModalOpened}
          onClose={() => setIsInviteMemberModalOpened(false)}
        />
      )}
      {isDeleteInviteModalOpened && (
        <DeleteInviteModal
          member={selectedMember}
          entityInUse={entityInUse}
          onInvitationRemove={handleInvitationRemove}
          isOpen={isDeleteInviteModalOpened}
          onClose={() => setIsDeleteInviteModalOpened(false)}
        />
      )}
      {isRemoveMemberModalOpened && (
        <RemoveMemberModal
          member={selectedMember}
          entity={entityInUse}
          onRemove={handleRemoveMember}
          isOpen={isRemoveMemberModalOpened}
          onClose={() => setIsRemoveMemberModalOpened(false)}
        />
      )}
      {isResendInvitationModalOpened && (
        <ResendInvitationModal
          onSubmit={handleResendInvitationSubmit}
          isOpen={isResendInvitationModalOpened}
          onClose={() => setIsResendInvitationModalOpened(false)}
        />
      )}
    </>
  );
};

export default MembersTabs;
