import { Box, Grid, Typography, styled } from '@mui/material';
import { Link } from 'react-router-dom';

const SvgBgDecorationFooter = `${process.env.REACT_APP_ASSETS_URL}/images/bg-decoration-footer.svg`;

const background = '#121212';
const primary = '#F5B68C';
const secondary = '#DA674E';
const bodyLight = '#C4C4C4';
const bodyDark = '#6B7280';

// Layout
export const StyledWrapper = styled('div')(() => ({
  backgroundColor: background,
  color: bodyLight,
  overflowX: 'hidden'
}));

export const StyledPageWrapper = styled('main')(({ theme }) => ({
  maxWidth: '120rem',
  marginInline: 'auto',
  [theme.breakpoints.up('md')]: {
    backgroundImage: `url(${SvgBgDecorationFooter})`,
    backgroundPosition: 'center bottom',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat'
  }
}));

export const StyledHeader = styled('header')(({ theme }) => ({
  paddingBlock: theme.spacing(4),
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  position: 'relative',
  zIndex: 1,
  [theme.breakpoints.down('md')]: {
    paddingBlock: theme.spacing(2),
    marginInline: theme.spacing(-2)
  }
}));

export const StyledContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  maxWidth: '80rem',
  marginInline: 'auto',
  paddingInline: theme.spacing(4)
}));

export const StyledFooter = styled(StyledContainer)(({ theme }) => ({
  backgroundColor: '#000',
  paddingBlock: theme.spacing(16, 4),
  [theme.breakpoints.down('md')]: {
    paddingBlock: theme.spacing(4)
  }
}));

export const StyledDivider = styled('hr')(() => ({
  margin: 0,
  border: 0,
  borderBottom: '1px solid #E5E7EB'
}));

// Sections
export const StyledMainSection = styled('section')(({ theme }) => ({
  position: 'relative',
  paddingTop: theme.spacing(24),
  paddingBottom: `calc(${theme.spacing(12)} + clamp(0px, calc(0px + (1vw - 9.28px) * 51.6129032258065), ${theme.spacing(64)}))`,
  [theme.breakpoints.down('md')]: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    marginBottom: theme.spacing(12)
  }
}));

export const StyledServicesSectionHeader = styled(Grid)(({ theme }) => ({
  paddingBlock: theme.spacing(4),
  backgroundPosition: 'center right',
  backgroundSize: 'contain',
  backgroundRepeat: 'no-repeat',
  [theme.breakpoints.down('md')]: {
    paddingBlock: theme.spacing(4, 0)
  }
}));

export const StyledGetInTouchSection = styled('section')(({ theme }) => ({
  backgroundColor: background,
  margin: theme.spacing(26, 'auto', 16),
  padding: theme.spacing(4),
  [theme.breakpoints.up('md')]: {
    maxWidth: '32.5rem'
  },
  [theme.breakpoints.down('md')]: {
    padding: 0,
    margin: theme.spacing(6, 'auto')
  }
}));

// Typography
export const StyledHeadingOne = styled(Typography)(() => ({
  backgroundImage: 'linear-gradient(to right, #A05A5A, #FBAC85)',
  backgroundClip: 'text',
  color: 'transparent',
  fontSize: '3rem',
  lineHeight: 1.15,
  fontWeight: 400,
  marginBottom: '0.666em'
}));

export const StyledHeadingTwo = styled(Typography)(() => ({
  color: '#fff',
  fontSize: '2.25rem',
  lineHeight: 1.111,
  fontWeight: 400,
  marginBottom: '0.55em'
}));

export const StyledHeadingThree = styled(Typography)(() => ({
  color: '#fff',
  fontSize: '1.5rem',
  lineHeight: 1.333,
  fontWeight: 400,
  marginBottom: '0.5em'
}));

export const StyledSuperscription = styled(Typography)(() => ({
  color: '#fff',
  fontSize: '1rem',
  lineHeight: 1.5,
  textTransform: 'uppercase',
  marginBottom: '2em'
}));

export const StyledBodyBig = styled(Typography)(() => ({
  color: '#fff',
  fontSize: '1.5rem',
  lineHeight: 1.333,
  marginBlock: '1em'
}));

export const StyledBody = styled(Typography)(() => ({
  fontSize: '1.125rem',
  lineHeight: 1.55
}));

export const StyledBodySmall = styled(Typography)(() => ({
  fontSize: '0.9375rem',
  lineHeight: 1.6
}));

export const StyledBodyTiny = styled(Typography)(() => ({
  color: bodyDark,
  fontSize: '0.75rem',
  lineHeight: 1.333
}));

// Components
export const StyledCard = styled(Box)(({ theme }) => ({
  backgroundColor: background,
  border: `2px solid ${primary}`,
  padding: theme.spacing(3, 2, 6),
  height: '100%'
}));

export const StyledSignUpCard = styled(Box)(({ theme }) => ({
  backgroundColor: background,
  padding: theme.spacing(8)
}));

export const StyledLoginLink = styled(Link)(() => ({
  color: secondary,
  fontSize: '1rem',
  lineHeight: 1.5,
  fontWeight: 700,
  textDecoration: 'none'
}));

export const StyledSignUpLink = styled('a')(() => ({
  color: primary,
  fontSize: '1rem',
  lineHeight: 1.5,
  textDecoration: 'none',
  display: 'inline-flex',
  '&:hover': {
    color: secondary,
    path: {
      fill: secondary
    }
  }
}));

export const StyledFooterLink = styled('a')(() => ({
  color: '#fff',
  fontSize: '0.9375rem',
  lineHeight: 1.6,
  textDecoration: 'none',
  '&:hover': {
    color: bodyLight
  }
}));

// Images
export const StyledTwentyOneCo = styled('img')(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    width: '87px'
  }
}));

export const StyledBgDecoration = styled('img')(({ theme }) => ({
  position: 'absolute',
  insetInline: 0,
  top: 0,
  width: '100%',
  pointerEvents: 'none',
  zIndex: 0,
  [theme.breakpoints.down('md')]: {
    display: 'none'
  }
}));

export const StyledWallet = styled('img')(({ theme }) => ({
  maxWidth: '440px',
  position: 'absolute',
  right: 0,
  top: 0,
  [theme.breakpoints.up('lg')]: {
    right: theme.spacing(-8)
  },
  [theme.breakpoints.down('md')]: {
    maxWidth: 'min(100%, 440px)',
    marginTop: theme.spacing(10),
    marginInline: 'auto',
    position: 'relative',
    height: 'auto'
  }
}));
