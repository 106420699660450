import { MenuItem } from '@mui/material';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Menu from 'src/components/Menu/Menu';

import { FilterProps } from './Filter.interfaces';
import { StyledFilter, StyledFilterValue } from './Filter.styles';

const Filter: FC<FilterProps> = ({ label, value, dataList, onChange }) => {
  const { t } = useTranslation();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const labelKey = dataList.find(i => i.value === value)?.label;

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (value?: string) => {
    setAnchorEl(null);
    if (value && onChange) {
      onChange(value);
    }
  };

  return (
    <>
      <StyledFilter variant="body3">
        {label ?? t('common.sortBy')}{' '}
        <StyledFilterValue as="span" onClick={handleClick}>
          {labelKey ? t(labelKey) : 'default'}
        </StyledFilterValue>
      </StyledFilter>
      <Menu open={open} anchorEl={anchorEl} onClose={() => handleClose()}>
        {dataList.map(i => (
          <MenuItem disableRipple selected={value === i.value} key={i.value} onClick={() => handleClose(i.value)}>
            {t(i.label)}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default Filter;
