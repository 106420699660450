import { styled, Typography } from '@mui/material';
import Button from 'src/components/Button/Button';

import { StyledInfoBoxProps, StyledDescProps } from './InfoBox.interfaces';

export const StyledInfoBox = styled('div', {
  shouldForwardProp: prop => prop !== 'active' && prop !== 'fullWidth'
})<StyledInfoBoxProps>(({ theme, active, fullWidth }) => ({
  display: 'flex',
  alignItems: 'center',
  background: active ? theme.palette.primary.light : theme.palette.common.white,
  maxWidth: fullWidth ? '100%' : '492px',
  gap: theme.spacing(3),
  cursor: 'pointer',
  border: active ? `2px solid ${theme.palette.custom.coralDusk[400]}` : `1px solid ${theme.palette.custom.grey[100]}`,
  padding: theme.spacing(3),
  borderRadius: theme.shape.borderRadius * 2,

  '.MuiSvgIcon-root': {
    fontSize: '80px'
  },
  '&:hover': {
    ...(!active && {
      outline: `1px solid ${theme.palette.custom.coralDusk[400]}`,
      borderColor: theme.palette.custom.coralDusk[400]
    })
  }
}));

export const StyledTextContent = styled('div')(() => ({}));

export const StyledTitle = styled(Typography)(({ theme }) => ({
  letterSpacing: '-0.01em',
  marginBottom: theme.spacing(0.25)
}));

export const StyledDesc = styled(Typography, {
  shouldForwardProp: prop => prop !== 'fullWidth'
})<StyledDescProps>(({ theme, fullWidth }) => ({
  width: fullWidth ? '100%' : '338px',
  marginBottom: theme.spacing(0.25),
  display: 'flex'
}));

export const StyledButton = styled(Button)(() => ({
  '&.MuiButton-sizeSmall': {
    fontSize: '14px',
    letterSpacing: '-0.01em',
    padding: 0
  },
  '&:hover.MuiButton-textPrimary': {
    backgroundColor: 'transparent'
  }
}));

export const StyledDescriptionWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  '& .MuiSvgIcon-root': {
    fontSize: 20,
    marginRight: theme.spacing(1)
  }
}));
