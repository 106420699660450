import { h } from 'preact'
import {
  Blur,
  Canny,
  DetectionParams,
  EdgeDetection,
  HoughLine,
} from '../OpenCV/DetectionParams'
import { useUrlSearchParam } from './tools'
import style from './ParametersPanel.scss'
import { useEffect } from 'preact/hooks'

export type ParametersPanelProps = {
  params: DetectionParams
  onParams: (params: DetectionParams) => void
}

export const ParametersPanel = ({
  onParams,
  ...props
}: ParametersPanelProps) => {
  // get potential params from URL with fallback to props.params
  const [params, setParams] = useUrlSearchParam<DetectionParams>(
    'documentAutoCaptureParams',
    props.params
  )

  // we need this to fire even the first round, otherwise params don't match the deserialized version.
  useEffect(() => {
    console.log(params)
    onParams(params)
  }, [params])

  const numberInput = (
    scope: keyof DetectionParams | undefined,
    parameter:
      | keyof HoughLine
      | keyof Blur
      | keyof Canny
      | keyof EdgeDetection
      | 'rect'
      | 'width'
      | 'height'
      | 'resizePixels'
      | 'offset',
    extra: string | undefined = undefined,
    step?: string
  ) => {
    const keys = [scope, parameter, extra].filter(
      (x): x is string => x !== undefined
    )

    const paramName = keys.join('.')

    // @ts-ignore
    const value: number = keys.reduce(
      // @ts-ignore
      (previous, current) => previous[current],
      params
    )
    return (
      <div className={style.parameters}>
        <label htmlFor="angle">{paramName}</label>
        <input
          name={paramName}
          type="number"
          step={step}
          value={value as number}
          onChange={(event) => {
            const target = event.target as HTMLInputElement
            if (
              target.value === '0' ||
              target.value === '0.' ||
              target.value === '0,'
            ) {
              // wait for the decimal before trying to update
              return
            }
            const path = [...keys]
            const last = path.pop() as string
            const newState: DetectionParams = {
              ...params,
            }

            const state = path
              // @ts-ignore
              .reduce((previous, current) => previous[current], newState)

            // @ts-ignore
            state[last] = parseFloat(target.value)

            setParams(newState)
            onParams(newState)
          }}
        />
      </div>
    )
  }

  return (
    <div>
      <div>
        <h4>Canny edge</h4>
        {numberInput('canny', 'threshold1')}
        {numberInput('canny', 'threshold2')}
        {numberInput('canny', 'aperture')}
      </div>
      <div>
        <h4>Hough Lines P</h4>
        {numberInput('houghLine', 'threshold')}
        {numberInput('houghLine', 'theta', undefined, 'any')}
        {numberInput('houghLine', 'maxLineGap')}
        {numberInput('houghLine', 'minLineLength')}
      </div>
      <div>
        <h4>Edge Thresholds</h4>
        {numberInput('edgeDetection', 'requiredEdges')}
        {numberInput('edgeDetection', 'detectLineThreshold', undefined, '0.01')}
        {numberInput('edgeDetection', 'numSegment')}
        {numberInput('edgeDetection', 'angleTolerance')}
        {numberInput('edgeDetection', 'insideMargin')}
      </div>
      <div>
        <h4>Blur threshold</h4>
        {numberInput('blur', 'threshold')}
      </div>
      <div>
        <h4>Box coordinates</h4>
        {numberInput('crop', 'offset')}
        {numberInput(undefined, 'resizePixels')}
      </div>
    </div>
  )
}
