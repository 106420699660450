import { InputAdornment, styled } from '@mui/material';
import { StyledTextField } from 'src/components/Autocomplete/Autocomplete.styles';
import SvgIcon from 'src/components/SvgIcon/SvgIcon';

export const StyledSvgIcon = styled(SvgIcon)({
  fontSize: 16
});

export const StyledInputAdornment = styled(InputAdornment)(({ theme }) => ({
  marginLeft: theme.spacing(2),
  '& .MuiAutocomplete-popupIndicator': {
    transform: 'none',
    marginRight: theme.spacing(1)
  }
}));

export const StyledBeneficiaryTextField = styled(StyledTextField)(({ theme }) => ({
  width: '100%',
  '& .MuiInputBase-root .MuiInputBase-input': {
    padding: theme.spacing(1.5, 0.5)
  }
}));
