import { styled, Typography } from '@mui/material';

export const StyledContent = styled('div')(({ theme }) => ({
  width: 520,
  [theme.breakpoints.down('md')]: {
    width: 'fit-content'
  }
}));

export const StyledSubtitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.custom.grey[600],
  marginBottom: theme.spacing(2.5)
}));
