import { styled, Typography } from '@mui/material';

export const StyledHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  minWidth: '544px',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: theme.spacing(3),
  boxShadow: theme.shadows[11],
  [theme.breakpoints.down('md')]: {
    minWidth: '100vw'
  }
}));

export const StyledHeaderGroup = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: theme.spacing(2)
}));

export const StyledHeaderText = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  textAlign: 'left'
});

export const StyledSubtitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.custom.grey[400]
}));

export const StyledCloseButton = styled('div')(({ theme }) => ({
  cursor: 'pointer',

  svg: { fontSize: '24px', padding: theme.spacing(0.5) }
}));

export const StyledContent = styled('div')(({ theme }) => ({
  padding: theme.spacing(3)
}));

export const StyledActions = styled('div')(({ theme }) => ({
  padding: theme.spacing(2, 3),
  background: theme.palette.custom.grey[50]
}));
