import { FC, ChangeEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAmountInput } from 'src/hooks/useAmountInput';
import { Account } from 'src/interfaces/banking';
import { MenuItem, Typography } from '@mui/material';
import { formatAmount } from 'src/utils/localization';
import SvgIcon from 'src/components/SvgIcon/SvgIcon';
import Menu from 'src/components/Menu/Menu';
import { IGetWithdrawalCurrencies } from 'src/interfaces/responses';

import {
  StyledBalance,
  StyledBox,
  StyledCurrency,
  StyledCurrencyMenu,
  StyledForm,
  StyledInput,
  StyledLabel,
  StyledListItem
} from './WithdrawAccountAndAmount.styles';

export interface WithdrawAccountAndAmountProps {
  disabled?: boolean;
  currencies: IGetWithdrawalCurrencies;
  currency?: string;
  setCurrency?: (currency: string) => void;
  amount?: string;
  setAmount?: (amount: string) => void;
  invalidAmount: boolean;
  account?: Account;
  estimatedRate?: string;
}

export const WithdrawAccountAndAmount: FC<WithdrawAccountAndAmountProps> = ({
  disabled = false,
  currencies,
  currency,
  setCurrency,
  amount,
  setAmount,
  invalidAmount,
  account,
  estimatedRate
}) => {
  const { t } = useTranslation();
  const { clearPrefilledAmountInput, prefillAmountInput } = useAmountInput(amount, setAmount);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (!(disabled || !currencies.length)) {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = (currency?: string) => {
    setAnchorEl(null);
    if (currency && setCurrency) {
      setCurrency(currency);
    }
  };

  return (
    <StyledBox>
      <StyledLabel variant="body3">{t('banking.actions.withdraw.amountCurrencyToBeSent')}</StyledLabel>
      <StyledForm>
        <StyledCurrencyMenu disabled={disabled || !currencies.length} onClick={handleClick}>
          {currency && <StyledCurrency>{currency}</StyledCurrency>}
          <SvgIcon icon="arrowDown" />
        </StyledCurrencyMenu>
        <Menu open={open} anchorEl={anchorEl} onClose={() => handleClose()}>
          <StyledListItem isCaption>Switch currency</StyledListItem>
          {currencies.map(c => (
            <MenuItem disableRipple selected={c.currency === currency} key={currency} onClick={() => handleClose(c.currency)}>
              <StyledListItem>
                <span>{c.currency}</span> <span>({c.currencyLabel})</span>
              </StyledListItem>
            </MenuItem>
          ))}
        </Menu>
        <StyledInput
          type="number"
          autoComplete="off"
          variant="standard"
          onFocus={() => clearPrefilledAmountInput()}
          onBlur={() => prefillAmountInput('0')}
          value={amount}
          error={invalidAmount}
          onChange={(event: ChangeEvent<HTMLInputElement>) => {
            if (setAmount) {
              setAmount(event.target.value);
            }
          }}
        />
      </StyledForm>
      {account && (
        <>
          {estimatedRate && (
            <StyledBalance>
              <Typography color="coral" variant="body4">
                {t('banking.actions.withdraw.details.estimatedRate')}
              </Typography>
              <Typography color="coral" variant="body4">
                {estimatedRate}
              </Typography>
            </StyledBalance>
          )}
          <StyledBalance>
            <Typography variant="body4">{t('banking.actions.swap.balance')}</Typography>
            <Typography variant="body4">{`${formatAmount(account.balance)} ${account.currency}`}</Typography>
          </StyledBalance>
        </>
      )}
    </StyledBox>
  );
};
