import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { QRCode } from 'react-qrcode-logo';
import ActionTab from 'src/components/banking/actions/ActionTab/ActionTab';
import useCopyToClipboard from 'src/utils/useCopyToClipboard';
import { ActionBarContext } from 'src/contexts/ActionBar.contexts';
import { Typography, useTheme } from '@mui/material';
import { DepositContext } from 'src/contexts/actions/Deposit.contexts';
import { CryptoAccountDetails } from 'src/interfaces/banking';

import DepositSelection from '../DepositSelection/DepositSelection';
import { DepositCryptoScreens } from '../Deposit.interfaces';
import { depositActionTabDefaultValues } from '../Deposit.utils';

import { StyledDisabledWalletTextField, StyledLightGrayText, StyledQRWrapper } from './DepositCrypto.styles';

export const DepositCrypto = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const actionBarContext = useContext(ActionBarContext);
  const depositContext = useContext(DepositContext);
  const contextsInitialized = actionBarContext && depositContext;
  const account = depositContext?.account;

  const [, copy] = useCopyToClipboard();
  const [nextScreen, setNextScreen] = useState<DepositCryptoScreens>();
  const walletAddress = (account?.details?.crypto as CryptoAccountDetails)?.walletAddress ?? '';

  const flowScreens = {
    depositSelect: <DepositSelection />
  };

  return nextScreen ? (
    flowScreens[nextScreen]
  ) : contextsInitialized ? (
    <ActionTab
      primaryActionTextKey="banking.copyWalletAddress"
      onPrimaryActionClick={() => copy(walletAddress)}
      onGoBack={() => setNextScreen('depositSelect')}
      onClose={actionBarContext.closeActionTab}
      activeStepIndex={2}
      {...depositActionTabDefaultValues}
      titleKey="banking.actions.deposit.titleCrypto"
    >
      {account && (
        <>
          <Typography variant="subtitle3">{`${account.currencyLabel} (${account.currency})`}</Typography>
          <StyledQRWrapper>
            <QRCode size={220} logoWidth={50} value={walletAddress} fgColor={theme.palette.common.black} />
            <StyledLightGrayText variant="body3">{t('banking.actions.deposit.scanOrCopyAddress')}</StyledLightGrayText>
            <StyledDisabledWalletTextField>{walletAddress}</StyledDisabledWalletTextField>
            <StyledLightGrayText variant="body3">
              {t('banking.actions.deposit.thisAddressCanOnlyReceive', {
                cryptoAssetLabel: account.currencyLabel
              })}
            </StyledLightGrayText>
          </StyledQRWrapper>
        </>
      )}
    </ActionTab>
  ) : null;
};
