import { styled } from '@mui/material';

export const StyledContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 3),
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.custom.gold[100]
}));

export const StyledLabelValue = styled('div')(({ theme }) => ({
  marginLeft: theme.spacing(2),
  textAlign: 'left',
  [theme.breakpoints.down('md')]: {
    width: '60vw',
    wordWrap: 'break-word'
  }
}));
