import { styled } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';

import { StyledAutocomplete, StyledPopper, StyledTextField } from '../Autocomplete/Autocomplete.styles';

const inputWidth = '63px';

export const StyledRow = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  alignContent: 'space-between',
  gap: theme.spacing(2)
}));

export const StyledCountryAutocomplete = styled(StyledAutocomplete)(({ theme }) => ({
  width: inputWidth,
  '& .MuiTextField-root': {
    minWidth: '50px',
    '& .MuiInputBase-root': {
      padding: '0',
      '& .MuiAutocomplete-input': {
        minWidth: inputWidth,
        padding: '0 0 0 10px'
      }
    }
  },
  '& + .MuiAutocomplete-popper': {
    '.MuiAutocomplete-option': {
      paddingLeft: theme.spacing(2)
    }
  }
})) as typeof Autocomplete;

export const StyledCountryAutocompleteInput = styled(StyledTextField)({
  minWidth: '100px'
});

export const StyledCountryAutocompletePopper = styled(StyledPopper)({
  minWidth: '300px'
});

export const StyledNumberInput = styled(StyledTextField)({
  'input & input[type=number]': {
    MozAppearance: 'textfield'
  },
  '& input[type=number]::-webkit-outer-spin-button': {
    WebkitAppearance: 'none',
    margin: 0
  },
  '& input[type=number]::-webkit-inner-spin-button': {
    WebkitAppearance: 'none',
    margin: 0
  }
});
