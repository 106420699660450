import { MoreVert } from '@mui/icons-material';
import { MenuItem, Typography, capitalize, Stack } from '@mui/material';
import { FC, SetStateAction, useState } from 'react';
import { useBeneficiariesQuery } from 'src/api/queries/useBeneficiaries';
import {
  StyledBeneficiaryIcon,
  StyledNoBeneficiaryLabel
} from 'src/components/banking/actions/withdraw/WithdrawSelectBenficiary/WithdrawSelectBenficiary.styles';
import Loader from 'src/components/Loader/Loader';
import Menu from 'src/components/Menu/Menu';
import { CompanySettingsBeneficiariesListProps } from 'src/components/settings/companySettings/CompanySettingsBeneficiariesList/CompanySettingsBeneficiariesList.interfaces';
import { ButtonVariant } from 'src/constants/variants';
import { Beneficiary, BeneficiaryFilter } from 'src/interfaces/banking';
import { useTranslation } from 'react-i18next';
import { getCurrencyImage } from 'src/utils/styles';
import { StyledAccountFlag } from 'src/components/banking/AccountMenu/AccountMenu.styles';
import Filter from 'src/components/banking/Filter/Filter';
import { BENEFICIARY_FILTERS } from 'src/constants/banking';

import {
  StyledListRow,
  StyledListRowActionButton,
  StyledListRowAvatar,
  StyledListRowMetadata,
  StyledRightSide
} from './CompanySettingsBeneficiariesList.styles';

const CompanySettingsBeneficiariesList: FC<CompanySettingsBeneficiariesListProps> = ({
  entityData,
  accounts,
  menuItems,
  menuAnchorEl,
  menuOpen,
  onMenuClose,
  setMenuAnchorEl
}) => {
  const { t } = useTranslation();

  const [selectedBeneficiary, setSelectedBeneficiary] = useState<Beneficiary | null>(null);
  const [sortBy, setSortBy] = useState<string>(BeneficiaryFilter.Newest);
  const [filterValue, setFilterValue] = useState<string>();

  const { data: beneficiariesData, isLoading: areBeneficiariesLoading } = useBeneficiariesQuery(entityData?._links.self.uri ?? '', filterValue);
  const beneficiaries = beneficiariesData?.data ?? [];

  const renderMenu = (beneficiary: Beneficiary) => (
    <Menu open={menuOpen} size="small" anchorEl={menuAnchorEl} onClose={onMenuClose}>
      {menuItems(beneficiary).map((menuItem, index) => (
        <MenuItem key={menuItem.title + index} disableRipple onClick={menuItem.onClick}>
          {menuItem.title}
        </MenuItem>
      ))}
    </Menu>
  );

  return areBeneficiariesLoading ? (
    <Loader anchorToRelative />
  ) : (
    <>
      {beneficiaries.length ? (
        <Filter
          value={sortBy}
          label={t('common.sortBy')}
          dataList={BENEFICIARY_FILTERS}
          onChange={value => {
            // TODO: implement actual filter values once api is enabled on BE
            switch (value) {
              case BeneficiaryFilter.Newest:
                setFilterValue('orderBy=NEWEST');
                break;
              case BeneficiaryFilter.Oldest:
                setFilterValue('orderBy=OLDEST');
                break;
              case BeneficiaryFilter.Alphabetical:
                setFilterValue('orderBy=ALPHABETICAL');
                break;
              default:
                setFilterValue('orderBy=ALPHABETICAL');
            }
            setSortBy(value);
          }}
        />
      ) : null}
      {beneficiaries.length > 0 ? (
        beneficiaries.map(beneficiary => {
          const beneficiaryAccountUri = beneficiary._links?.account.uri;
          const acc = beneficiaryAccountUri ? accounts.find(acc => acc._links.self.uri === beneficiaryAccountUri) : null;
          return (
            <StyledListRow key={beneficiary._id}>
              <StyledListRowMetadata>
                {beneficiary?.targetCurrency ? (
                  <StyledAccountFlag
                    className="account-flag"
                    size="32px"
                    src={getCurrencyImage(acc?.currency ?? '')}
                    alt={`${beneficiary?.targetCurrency} flag`}
                  />
                ) : beneficiary.firstName ? (
                  <StyledListRowAvatar>{Array.from(beneficiary.firstName)[0]}</StyledListRowAvatar>
                ) : (
                  <StyledListRowAvatar>{Array.from(beneficiary.companyName ?? '')[0]}</StyledListRowAvatar>
                )}
                <div>
                  {beneficiary.firstName ? (
                    <>
                      <Typography
                        sx={{
                          letterSpacing: '-0.01em'
                        }}
                        variant="body1"
                      >
                        {beneficiary.firstName} {beneficiary.lastName}
                      </Typography>
                      <Typography
                        variant="body3"
                        sx={theme => ({
                          color: theme.palette.custom.grey[300]
                        })}
                      >
                        {`${acc?.currencyLabel ?? ''} · `}
                        {`${capitalize(beneficiary.targetCurrency ?? '')} · `}
                        {`${capitalize(beneficiary.network ?? '')} · `}
                        {`${capitalize(beneficiary.iban ?? beneficiary.routingNumber ?? beneficiary.accountNumber ?? '')} · `}
                        {`${capitalize(beneficiary.bank?.name ?? '')}`}
                      </Typography>
                    </>
                  ) : (
                    <>
                      <Typography
                        sx={{
                          letterSpacing: '-0.01em'
                        }}
                        variant="body1"
                      >
                        {beneficiary.companyName}
                      </Typography>
                      <Typography
                        variant="body3"
                        sx={theme => ({
                          color: theme.palette.custom.grey[300]
                        })}
                      >
                        {`${acc?.currencyLabel ?? ''} · `}
                        {`${capitalize(beneficiary.targetCurrency ?? '')} · `}
                        {`${capitalize(beneficiary.network ?? '')} · `}
                        {`${capitalize(beneficiary.iban ?? beneficiary.routingNumber ?? beneficiary.accountNumber ?? '')} · `}
                        {`${capitalize(beneficiary.bank?.name ?? '')}`}
                      </Typography>
                    </>
                  )}
                </div>
              </StyledListRowMetadata>
              <StyledRightSide>
                <StyledListRowActionButton
                  hasIcon
                  variant={ButtonVariant.Text}
                  icon={<MoreVert />}
                  onClick={(evt: { currentTarget: SetStateAction<HTMLElement | null> }) => {
                    setMenuAnchorEl(evt.currentTarget);
                    setSelectedBeneficiary(beneficiary);
                  }}
                />
              </StyledRightSide>
            </StyledListRow>
          );
        })
      ) : (
        <Stack alignItems="center" textAlign="center">
          <StyledBeneficiaryIcon icon="beneficiary" />
          <StyledNoBeneficiaryLabel variant="strongBody1">{t('companySettings.beneficiaries.noBeneficiariesAddedYet')}</StyledNoBeneficiaryLabel>
          <Typography variant="body3">{t('companySettings.beneficiaries.addBeneficiariesInOrderToUseThem')}</Typography>
        </Stack>
      )}
      {selectedBeneficiary && renderMenu(selectedBeneficiary)}
    </>
  );
};

export default CompanySettingsBeneficiariesList;
