import { Account, AccountStatus, CurrencyType } from 'src/interfaces/banking';

export const BASE_ACCOUNTS_EU: Account[] = [
  {
    type: CurrencyType.Fiat,
    status: AccountStatus.Pending,
    currency: 'USD',
    currencyLabel: 'US Dollar',
    balance: '0.00',
    metaData: { name: 'Main' },
    valuation: { amount: '0.00', currency: 'USD' },
    _links: { self: { uri: '/accounts/100' } }
  },
  {
    type: CurrencyType.Fiat,
    status: AccountStatus.Pending,
    currency: 'EUR',
    currencyLabel: 'Euro',
    balance: '0.00',
    valuation: { amount: '0.00', currency: 'USD' },
    _links: { self: { uri: '/accounts/102' } }
  },
  {
    type: CurrencyType.Fiat,
    status: AccountStatus.Pending,
    currency: 'CHF',
    currencyLabel: 'Swiss Franc',
    balance: '0.00',
    valuation: { amount: '0.0', currency: 'USD' },
    _links: { self: { uri: '/accounts/103' } }
  }
];

export const ENTITY_REGION_US = 'US';
export const BASE_ACCOUNTS_US: Account[] = [...BASE_ACCOUNTS_EU];

export const CurrenciesEnum: { [key: string]: string } = {
  // TODO: this enum will have to be expanded to support new currencies. Currently causes empty string at the beginning of beneficiary description.
  CHF: 'Swiss Franc',
  EUR: 'Euro',
  USD: 'US Dollar'
};
