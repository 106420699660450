import { styled } from '@mui/material';

export const StyledDetails = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(1.5, 2),
  marginTop: theme.spacing(2),
  borderRadius: theme.shape.borderRadius,
  border: `1px solid ${theme.palette.custom.grey[100]}`
}));

export const StyledCurrencyFlag = styled('img')({
  width: '16px',
  height: '16px'
});
