import { OnboardingStep, CorporateOnboardingStep } from 'src/interfaces/types';

import i18next from '../i18n';

export const ONBOARDING_CREATE_ACCOUNT = 'create_account';
export const ONBOARDING_EMAIL_VERIFICATION = 'email_verification';
export const ONBOARDING_ENABLE_2FA = 'enable_2fa';
export const ONBOARDING_PERSONAL_INFO = 'personal_info';
export const ONBOARDING_ADDITIONAL_INFO = 'additional_info';
export const ONBOARDING_IDENTITY_VERIFICATION = 'identity_verification';

export const CORPORATE_ACCOUNT = 'corporate_account';
export const CORPORATE_INFORMATION = 'corporate_information';

export const ONBOARDING_STEPS: OnboardingStep[] = [
  {
    name: ONBOARDING_CREATE_ACCOUNT,
    value: i18next.t('onboarding.steps.createAccount')
  },
  {
    name: ONBOARDING_EMAIL_VERIFICATION,
    value: i18next.t('onboarding.steps.emailVerification')
  },
  {
    name: ONBOARDING_ENABLE_2FA,
    value: i18next.t('onboarding.steps.enable2fa')
  },
  {
    name: ONBOARDING_PERSONAL_INFO,
    value: i18next.t('onboarding.steps.personalInfo')
  },
  {
    name: ONBOARDING_ADDITIONAL_INFO,
    value: i18next.t('onboarding.steps.additionalInfo')
  },
  {
    name: ONBOARDING_IDENTITY_VERIFICATION,
    value: i18next.t('onboarding.steps.identityVerification')
  }
];

export const CORPORATE_ONBOARDING_STEPS: CorporateOnboardingStep[] = [
  {
    name: CORPORATE_ACCOUNT,
    value: i18next.t('corporateOnboarding.corporateAccount')
  },
  {
    name: CORPORATE_INFORMATION,
    value: i18next.t('corporateOnboarding.corporateInformation')
  }
];
