export const QueryKey = {
  QUERY_SWAP_QUOTES: 'query_swap_quotes',
  QUERY_TRANSACTION: 'query_transaction',
  QUERY_TRANSACTIONS: 'query_transactions',
  QUERY_ACCOUNT_DETAILS: 'query_account_details',
  QUERY_ACCOUNT_SWAP_ORDER: 'query_account_swap_order',
  QUERY_ACCOUNT_SWAP_REQUEST: 'query_account_swap_request',
  QUERY_PAYMENT_REQUEST: 'query_payment_request',
  QUERY_ENTITY_ACCOUNTS: 'query_entity_accounts',
  QUERY_WITHDRAWAL_CURRENCIES: 'query_withdrawal_currencies',
  QUERY_LEGAL_ENTITIES: 'query_legal_entities',
  QUERY_LEGAL_ENTITY: 'query_legal_entity',
  QUERY_ORDER: 'query_order',
  QUERY_ORDER_REFRESH: 'query_order_refresh',
  QUERY_ORDERS: 'query_orders',
  QUERY_RATES: 'query_rates',
  QUERY_GET_USER_OR_NAVIGATE: 'query_get_user_or_navigate',
  QUERY_ALLOWED_COUNTRIES_FOR_PAYMENT: 'query_allowed_countries_for_payment',
  QUERY_BENEFICIARIES: 'query_beneficiaries',
  QUERY_NETWORKS: 'query_networks',
  QUERY_KYC_START_DATA: 'query_kyc_start_data',
  QUERY_COUNTRIES: 'query_countries',
  QUERY_LEGAL_ENTITY_MEMBERS: 'query_legal_entity_members'
};
