import { alpha, Dialog, DialogTitle, styled, Typography } from '@mui/material';

export const StyledDialog = styled(Dialog)(({ theme }) => ({
  '.MuiDialog-paper': {
    minWidth: '380px',
    [theme.breakpoints.down('md')]: {
      minWidth: '100vw'
    }
  },
  '.MuiBackdrop-root': {
    background: alpha(theme.palette.common.black, 0.5)
  },
  '.MuiPaper-root': {
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[4]
  },
  '.MuiDialogTitle-root + .MuiDialogContent-root': {
    padding: theme.spacing(3),
    overflowX: 'hidden'
  }
}));

export const StyledDialogTitle = styled(DialogTitle)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: theme.spacing(2, 3),
  borderBottom: `1px solid ${theme.palette.grey[100]}`,
  '.MuiSvgIcon-root path': {
    color: theme.palette.grey[300]
  }
}));

export const StyledTitle = styled(Typography)({
  fontSize: '16px',
  lineHeight: '24px',
  fontWeight: 600
});

export const StyledCloseIcon = styled('div')({
  padding: 4,
  fontSize: 14,
  display: 'flex',
  justifyContent: 'center',
  cursor: 'pointer'
});
