import { FC } from 'react';
import useCopyToClipboard from 'src/utils/useCopyToClipboard';
import SvgIcon from 'src/components/SvgIcon/SvgIcon';
import { toMiddleCut } from 'src/utils/strings';

import { CopyItemProps } from './CopyItem.interfaces';
import { StyledIcon, StyledLabel, StyledCopyItem, StyledValue, StyledValueDecoration, ElipsisText, StyledLinkIcon } from './CopyItem.styles';

const CopyItem: FC<CopyItemProps> = ({
  link,
  label,
  value,
  valueDecoration,
  highlight = false,
  multiline = false,
  middleCut = false,
  disableCopy = false
}) => {
  const [, copy] = useCopyToClipboard();

  return (
    <StyledCopyItem>
      <StyledLabel variant="body3">{label}</StyledLabel>
      <StyledValue
        isLink={!!link}
        disableCopy={disableCopy}
        highlight={highlight}
        onClick={() => {
          if (!disableCopy) {
            copy(value);
          } else if (link) {
            window.open(link, '_blank');
          }
        }}
      >
        {valueDecoration && <StyledValueDecoration>{valueDecoration}</StyledValueDecoration>}
        <ElipsisText multiline={multiline} variant="strongBody3">
          {middleCut ? toMiddleCut(value) : value}
        </ElipsisText>
        {link && <StyledLinkIcon icon="externalLink" />}
        {!disableCopy && (
          <StyledIcon>
            <SvgIcon icon="copyClipboard" fontSize="inherit" />
          </StyledIcon>
        )}
      </StyledValue>
    </StyledCopyItem>
  );
};

export default CopyItem;
