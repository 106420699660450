import { FC } from 'react';

import FormElement from '../FormElement/FormElement';
import RadioButton from '../RadioButton/RadioButton';

import { RadioGroupProps } from './RadioGroup.interfaces';
import StyledFormControl, { StyledRadioGroup } from './RadioGroup.styles';

const RadioGroup: FC<RadioGroupProps> = ({
  name,
  label,
  labelTooltip,
  error,
  value,
  dataList,
  disabled,
  handleError,
  handleOnChange,
  horizontal = false,
  ...restProps
}) => (
  <FormElement label={label} labelTooltip={labelTooltip} error={error}>
    <StyledFormControl horizontal={horizontal}>
      <StyledRadioGroup horizontal={horizontal} {...restProps}>
        {dataList &&
          dataList.map(i => (
            <RadioButton
              key={i.value}
              value={i.value}
              label={i.label}
              disabled={disabled}
              checked={value === i.value}
              onChange={(_, checked) => {
                if (checked && handleOnChange) {
                  handleOnChange(name, i.value);
                }

                if (handleError && error) {
                  handleError(name, '');
                }
              }}
            />
          ))}
      </StyledRadioGroup>
    </StyledFormControl>
  </FormElement>
);

export default RadioGroup;
