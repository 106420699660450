import { FC } from 'react';

import { LoaderProps } from './Loader.interfaces';
import { StyledWrapper, StyledLoader } from './Loader.styles';

const Loader: FC<LoaderProps> = ({ anchorToRelative = false, className }) => (
  <StyledWrapper anchorToRelative={anchorToRelative}>
    <StyledLoader className={className} />
  </StyledWrapper>
);

export default Loader;
