import { AxiosError, AxiosResponse } from 'axios';
import { IFormValues } from 'src/interfaces/forms';
import { IGetUserResponse } from 'src/interfaces/responses';

import { additionalUrls } from './additionalUrls';
import request from './request';

export const getUser = (): Promise<AxiosResponse<IGetUserResponse, void>> | undefined =>
  request('get', `${additionalUrls.users}${additionalUrls.me}`)();

export const updatePreferences = (preferencesData: IFormValues): Promise<AxiosResponse<void, AxiosError>> | undefined =>
  request('post', `${additionalUrls.users}${additionalUrls.me}${additionalUrls.preferences}`)(preferencesData);
