import { Typography } from '@mui/material';
import { FC } from 'react';

import { ActionButtonProps } from './ActionButton.interfaces';
import { StyledActionButton, StyledButton, StyledDescription, StyledIcon, StyledMeta } from './ActionButton.styles';

const ActionButton: FC<ActionButtonProps> = ({ transactionType, title, description, hiddenMeta, onClick }) => (
  <StyledActionButton hiddenMeta={hiddenMeta} onClick={onClick}>
    <StyledButton
      hasIcon
      hiddenMeta={hiddenMeta}
      transactionType={transactionType}
      size="medium"
      startIcon={<StyledIcon icon={`transaction${transactionType}`} />}
    />
    {!hiddenMeta && (
      <StyledMeta>
        <Typography variant="subtitle3">{title}</Typography>
        <StyledDescription variant="body3">{description}</StyledDescription>
      </StyledMeta>
    )}
  </StyledActionButton>
);

export default ActionButton;
