import { styled, Typography } from '@mui/material';

import { StyledAccountFlagProps, StyledAccountMenuProps } from './AccountMenu.interfaces';

export const StyledAccountMenu = styled('div', {
  shouldForwardProp: prop => prop !== 'disabled'
})<StyledAccountMenuProps>(({ theme, disabled }) => ({
  zIndex: 1,
  display: 'flex',
  position: 'absolute',
  right: theme.spacing(3),
  height: '40px',
  opacity: disabled ? 0.5 : 1,
  cursor: disabled ? 'auto' : 'pointer',
  color: theme.palette.custom.grey[400],
  background: theme.palette.common.white,
  boxShadow: theme.shadows[12],
  border: `1px solid ${theme.palette.custom.grey[100]}`,
  borderRadius: '100px',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  padding: theme.spacing(2, 1.5),
  gap: theme.spacing(1),

  '.MuiSvgIcon-root': {
    fontSize: '16px'
  }
}));

export const StyledAccountFlag = styled('img', {
  shouldForwardProp: prop => prop !== 'size'
})<StyledAccountFlagProps>(({ size }) => ({
  width: size,
  height: size
}));

export const StyledItem = styled('div')({
  display: 'flex',
  minWidth: '280px',
  flexDirection: 'row',
  alignItems: 'center'
});

export const StyledLabels = styled('div')({
  marginLeft: '14px'
});

export const StyledCurrencyLabel = styled(Typography)(({ theme }) => ({
  color: theme.palette.custom.grey[300]
}));
