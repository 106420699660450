import { SvgIconProps as MuiSvgIconProps } from '@mui/material';
import { ReactComponent as ArrowLeftIcon } from 'src/assets/icons/arrow-left.svg';
import { ReactComponent as CalendarIcon } from 'src/assets/icons/calendar.svg';
import { ReactComponent as CheckIcon } from 'src/assets/icons/check.svg';
import { ReactComponent as CheckboxDefaultIcon } from 'src/assets/icons/checkbox-default.svg';
import { ReactComponent as CheckboxDisabledIcon } from 'src/assets/icons/checkbox-disabled.svg';
import { ReactComponent as CheckboxIndetermineIcon } from 'src/assets/icons/checkbox-indetermine.svg';
import { ReactComponent as CheckboxTickIcon } from 'src/assets/icons/checkbox-tick.svg';
import { ReactComponent as CloseIcon } from 'src/assets/icons/close.svg';
import { ReactComponent as CopyClipboardIcon } from 'src/assets/icons/copy-clipboard.svg';
import { ReactComponent as CoinsIcon } from 'src/assets/icons/coins.svg';
import { ReactComponent as SettingsIcon } from 'src/assets/icons/settings.svg';
import { ReactComponent as HelpIcon } from 'src/assets/icons/help.svg';
import { ReactComponent as NavigateNextIcon } from 'src/assets/icons/navigate-next.svg';
import { ReactComponent as AddRoundIcon } from 'src/assets/icons/add-round.svg';
import { ReactComponent as ArrowDownIcon } from 'src/assets/icons/arrow-down.svg';
import { ReactComponent as SwapTransactionIcon } from 'src/assets/icons/transactions/swap.svg';
import { ReactComponent as DepositTransactionIcon } from 'src/assets/icons/transactions/deposit.svg';
import { ReactComponent as TransferRoundedIcon } from 'src/assets/icons/transfer-rounded.svg';
import { ReactComponent as TransferTransactionIcon } from 'src/assets/icons/transactions/transfer.svg';
import { ReactComponent as WithdrawTransactionIcon } from 'src/assets/icons/transactions/withdraw.svg';
import { ReactComponent as EmptyTransactionsIcon } from 'src/assets/icons/transactions/empty-transactions.svg';
import { ReactComponent as InfoIcon } from 'src/assets/icons/info.svg';
import { ReactComponent as LogoIcon } from 'src/assets/icons/logo.svg';
import { ReactComponent as LogoutIcon } from 'src/assets/icons/logout.svg';
import { ReactComponent as EmailIcon } from 'src/assets/icons/email.svg';
import { ReactComponent as EntitiesIcon } from 'src/assets/icons/entities.svg';
import { ReactComponent as EntityPendingIcon } from 'src/assets/icons/entity-pending.svg';
import { ReactComponent as EntityChangesIcon } from 'src/assets/icons/entity-changes.svg';
import { ReactComponent as EntityRejectedIcon } from 'src/assets/icons/entity-rejected.svg';
import { ReactComponent as KeyIcon } from 'src/assets/icons/key.svg';
import { ReactComponent as NotificationIcon } from 'src/assets/icons/notification.svg';
import { ReactComponent as ReferenceIcon } from 'src/assets/icons/reference.svg';
import { ReactComponent as PersonIcon } from 'src/assets/icons/person.svg';
import { ReactComponent as VisibilityIcon } from 'src/assets/icons/visibility.svg';
import { ReactComponent as VisibilityOffIcon } from 'src/assets/icons/visibility-off.svg';
import { ReactComponent as WarningIcon } from 'src/assets/icons/warning.svg';
import { ReactComponent as TimeIcon } from 'src/assets/icons/time.svg';
import { ReactComponent as TimeWhiteIcon } from 'src/assets/icons/time-white.svg';
import { ReactComponent as PendingIcon } from 'src/assets/icons/pending.svg';
import { ReactComponent as USDIcon } from 'src/assets/icons/usd.svg';
import { ReactComponent as HoldingValueIcon } from 'src/assets/icons/holding-value.svg';
import { ReactComponent as ErrorSnack } from 'src/assets/icons/error-snack.svg';
import { ReactComponent as InfoSnack } from 'src/assets/icons/info-snack.svg';
import { ReactComponent as SuccessSnack } from 'src/assets/icons/success-snack.svg';
import { ReactComponent as SwapIcon } from 'src/assets/icons/swap.svg';
import { ReactComponent as WalletKycBlocksIcon } from 'src/assets/icons/kycBlocks/wallet.svg';
import { ReactComponent as LetterKycBlocksIcon } from 'src/assets/icons/kycBlocks/letter.svg';
import { ReactComponent as StallKycBlocksIcon } from 'src/assets/icons/kycBlocks/stall.svg';
import { ReactComponent as AccountCreatingBlocksIcon } from 'src/assets/icons/kycBlocks/account-creating.svg';
import { ReactComponent as ToggleBarIcon } from 'src/assets/icons/toggle-bar.svg';
import { ReactComponent as ToggleBarWhiteIcon } from 'src/assets/icons/toggle-bar-white.svg';
import { ReactComponent as CorporationIcon } from 'src/assets/icons/corporation-icon.svg';
import { ReactComponent as SearchIcon } from 'src/assets/icons/search.svg';
import { ReactComponent as SingleWireIcon } from 'src/assets/icons/single-wire.svg';
import { ReactComponent as BulkWireIcon } from 'src/assets/icons/bulk-wire.svg';
import { ReactComponent as CryptoWireIcon } from 'src/assets/icons/crypto-wire.svg';
import { ReactComponent as AddCircleIcon } from 'src/assets/icons/add-circle.svg';
import { ReactComponent as CloseRoundIcon } from 'src/assets/icons/close-round.svg';
import { ReactComponent as CheckRoundIcon } from 'src/assets/icons/check-round.svg';
import { ReactComponent as ErrorIcon } from 'src/assets/icons/error.svg';
import { ReactComponent as BeneficiaryIcon } from 'src/assets/icons/beneficiary-icon.svg';
import { ReactComponent as SearchInCircleIcon } from 'src/assets/icons/search-in-circle.svg';
import { ReactComponent as AboutIcon } from 'src/assets/icons/personalSettings/about.svg';
import { ReactComponent as LogoutSettingsIcon } from 'src/assets/icons/logout.svg';
import { ReactComponent as LogoutSettingsWhiteIcon } from 'src/assets/icons/logout-settings-white.svg';
import { ReactComponent as ProfileIcon } from 'src/assets/icons/personalSettings/profile.svg';
import { ReactComponent as SecurityIcon } from 'src/assets/icons/personalSettings/security.svg';
import { ReactComponent as ExternalLinkIcon } from 'src/assets/icons/personalSettings/external-link.svg';
import { ReactComponent as MembersIcon } from 'src/assets/icons/companySettings/members.svg';
import { ReactComponent as BeneficiaryMenuIcon } from 'src/assets/icons/companySettings/beneficiary.svg';
import { ReactComponent as PinIcon } from 'src/assets/icons/pin.svg';
import { ReactComponent as EmailBigIcon } from 'src/assets/icons/email-big.svg';

export const Icons = {
  arrowLeft: ArrowLeftIcon,
  calendar: CalendarIcon,
  check: CheckIcon,
  checkboxDefault: CheckboxDefaultIcon,
  checkboxDisabled: CheckboxDisabledIcon,
  checkboxIndetermine: CheckboxIndetermineIcon,
  checkboxTick: CheckboxTickIcon,
  close: CloseIcon,
  copyClipboard: CopyClipboardIcon,
  coins: CoinsIcon,
  settings: SettingsIcon,
  help: HelpIcon,
  navigateNext: NavigateNextIcon,
  addRound: AddRoundIcon,
  arrowDown: ArrowDownIcon,
  transactionSWAP: SwapTransactionIcon,
  transactionDEPOSIT: DepositTransactionIcon,
  transactionTRANSFER: TransferTransactionIcon,
  transactionWITHDRAW: WithdrawTransactionIcon,
  transferRounded: TransferRoundedIcon,
  emailBig: EmailBigIcon,
  emptyTransactions: EmptyTransactionsIcon,
  visibility: VisibilityIcon,
  visibilityOff: VisibilityOffIcon,
  info: InfoIcon,
  logo: LogoIcon,
  logout: LogoutIcon,
  email: EmailIcon,
  entities: EntitiesIcon,
  entityPENDING: EntityPendingIcon,
  entityCHANGES: EntityChangesIcon,
  entityREJECTED: EntityRejectedIcon,
  key: KeyIcon,
  notification: NotificationIcon,
  reference: ReferenceIcon,
  person: PersonIcon,
  warning: WarningIcon,
  time: TimeIcon,
  timeWhite: TimeWhiteIcon,
  pending: PendingIcon,
  usd: USDIcon,
  holdingValue: HoldingValueIcon,
  errorSnack: ErrorSnack,
  infoSnack: InfoSnack,
  successSnack: SuccessSnack,
  swap: SwapIcon,
  walletKycBlocks: WalletKycBlocksIcon,
  letterKycBlocks: LetterKycBlocksIcon,
  stallKycBlocks: StallKycBlocksIcon,
  accountCreatingBlocks: AccountCreatingBlocksIcon,
  toggleBar: ToggleBarIcon,
  toggleBarWhite: ToggleBarWhiteIcon,
  corporationIcon: CorporationIcon,
  search: SearchIcon,
  about: AboutIcon,
  logoutSettings: LogoutSettingsIcon,
  logoutSettingsWhite: LogoutSettingsWhiteIcon,
  profile: ProfileIcon,
  security: SecurityIcon,
  externalLink: ExternalLinkIcon,
  closeRound: CloseRoundIcon,
  checkRound: CheckRoundIcon,
  singleWire: SingleWireIcon,
  bulkWire: BulkWireIcon,
  cryptoWire: CryptoWireIcon,
  addCircle: AddCircleIcon,
  error: ErrorIcon,
  beneficiary: BeneficiaryIcon,
  searchInCircle: SearchInCircleIcon,
  members: MembersIcon,
  beneficiaries: BeneficiaryMenuIcon,
  pin: PinIcon
};

export type IconKeys = keyof typeof Icons;

export interface SvgIconProps extends Pick<MuiSvgIconProps, 'fontSize' | 'onClick' | 'className'> {
  icon: IconKeys;
}
