import { alpha, Grid, Link, styled, Typography } from '@mui/material';
import SvgIcon from 'src/components/SvgIcon/SvgIcon';

import {
  StyledWrapperProps,
  StyledContentProps,
  StyledStepProps,
  StyledStepsWrapperProps,
  StyledContentWrapperProps
} from './DialogLayout.interfaces';

const onboardingContentWidth = (isOnboardingLayout: boolean, isTypeformContent: boolean): string => {
  if (isTypeformContent) {
    return '868px';
  }
  return isOnboardingLayout ? '544px' : '464px';
};

export const StyledWrapper = styled(Grid, {
  shouldForwardProp: prop => prop !== 'isOnboardingLayout'
})<StyledWrapperProps>(({ theme, isOnboardingLayout }) => ({
  position: 'relative',
  overflowX: 'auto',
  minHeight: '100vh',
  ...(isOnboardingLayout && {
    backgroundColor: theme.palette.custom.coralDusk[900],
    backgroundImage: `url(${process.env.REACT_APP_ASSETS_URL}/images/onboarding-waves.png)`,
    backgroundSize: 'cover'
  }),

  [theme.breakpoints.down('sm')]: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3)
  }
}));

export const StyledLogo = styled(SvgIcon)(({ theme }) => ({
  fontSize: '112px',
  position: 'absolute',
  top: theme.spacing(0.5),
  left: theme.spacing(5)
}));

const StyledGrid = styled(Grid)({
  position: 'relative',
  zIndex: 1,
  width: '100%'
});

export const StyledContent = styled('div', {
  shouldForwardProp: prop =>
    prop !== 'isOnboardingLayout' &&
    prop !== 'centeredContent' &&
    prop !== 'withOnboardingSteps' &&
    prop !== 'contentPadding' &&
    prop !== 'contentMargin' &&
    prop !== 'isTypeformContent' &&
    prop !== 'anchorToParent'
})<StyledContentProps>(
  ({ theme, isOnboardingLayout, withOnboardingSteps, centeredContent, contentPadding, contentMargin, isTypeformContent, anchorToParent }) => ({
    margin: '200px auto 252px',
    width: onboardingContentWidth(isOnboardingLayout, isTypeformContent),
    textAlign: withOnboardingSteps && !centeredContent ? 'left' : 'center',
    ...(isOnboardingLayout && {
      backgroundColor: theme.palette.background.default,
      padding: theme.spacing(contentPadding),
      borderRadius: theme.shape.borderRadius,
      boxShadow: theme.shadows[8],
      position: withOnboardingSteps ? 'static' : 'absolute',
      top: '50%',
      left: '50%',
      ...(!withOnboardingSteps
        ? {
            transform: 'translate(-50%, -50%)'
          }
        : {
            marginTop: '158px',
            marginBottom: '190px',
            marginInline: isTypeformContent ? 'auto' : 0
          }),
      margin: theme.spacing(contentMargin),
      ...(anchorToParent && {
        position: 'static',
        transform: 'none'
      })
    }),
    [theme.breakpoints.down('sm')]: {
      marginInline: 'auto'
    }
  })
);

export const StyledSteps = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  marginBottom: theme.spacing(5)
}));

export const StyledContentWrapper = styled(StyledGrid, {
  shouldForwardProp: prop => prop !== 'centeredContentWrapper'
})<StyledContentWrapperProps>(({ theme, centeredContentWrapper }) => ({
  ...(centeredContentWrapper && {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }),
  [theme.breakpoints.down('md')]: {
    maxWidth: '100%'
  }
}));

export const StyledStepsWrapper = styled(StyledGrid, {
  shouldForwardProp: prop => prop !== 'isTypeformContent'
})<StyledStepsWrapperProps>(({ theme, isTypeformContent }) => ({
  margin: `198px ${theme.spacing(5)} 198px 0`,
  display: 'flex',
  justifyContent: 'space-between',
  flexDirection: 'column',
  [theme.breakpoints.down('md')]: {
    display: 'none'
  },
  [theme.breakpoints.down('lg')]: {
    display: isTypeformContent ? 'none' : 'flex'
  }
}));

export const StyledStep = styled(Link, {
  shouldForwardProp: prop => prop !== 'isActive'
})<StyledStepProps>(({ theme, isActive }) => ({
  color: theme.palette.common.white,
  opacity: isActive ? 1 : 0.6,
  letterSpacing: '-0.01em',
  padding: theme.spacing(1.5, 2),
  ...(isActive && {
    backgroundColor: alpha(theme.palette.common.white, 0.1),
    borderRadius: theme.shape.borderRadius
  })
}));

export const StyledImage = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  marginBottom: theme.spacing(5)
}));

export const StyledSubtitle = styled(Typography)(({ theme }) => ({
  margin: theme.spacing(2, 0, 5, 0),
  color: theme.palette.text.secondary,
  letterSpacing: '-0.01em',
  whiteSpace: 'pre-line'
}));

export const StyledOnboardingImage = styled('img')({
  width: '140px',
  height: '171px',
  position: 'absolute',
  bottom: '72px',
  right: '64px'
});
