import { FC, useMemo } from 'react';
import Button from 'src/components/Button/Button';
import { useTranslation } from 'react-i18next';
import { formatAmount, formatDate } from 'src/utils/localization';
import ModalLayout from 'src/components/layouts/ModalLayout/ModalLayout';
import { updateOrder } from 'src/api/banking';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { QueryKey } from 'src/constants/queryKeys';
import { getCurrencyImage } from 'src/utils/styles';
import { enqueueSnackbar } from 'notistack';
import { AxiosError } from 'axios';
import { IResponseError } from 'src/interfaces/responses';
import { useOrderQuery } from 'src/api/queries/useOrder';
import { DecisionType, TransactionStatus, TransactionType } from 'src/interfaces/banking';
import { useCountriesQuery } from 'src/api/queries/useCountries';
import { formatBankDetails, formatBeneficiary, formatCounterpart } from 'src/utils/formatting';
import IBAN from 'iban';
import { useTransactionQuery } from 'src/api/queries/useTransaction';
import { useOrderRefreshQuery } from 'src/api/queries/useOrderRefresh';
import isEqual from 'lodash/fp/isEqual';

import CopyItem from '../CopyItem/CopyItem';
import { CopyItemProps } from '../CopyItem/CopyItem.interfaces';

import { TransactionDetailProps } from './TransactionDetail.interfaces';
import { StyledActions, StyledCurrencyFlag, StyledDivider } from './TransactionDetail.styles';

const compare = <T,>(oldItem: T, refreshedItem: T) => {
  const itemsAreEqual = isEqual(oldItem, refreshedItem);

  return {
    value: refreshedItem ?? oldItem,
    areDifferent: !itemsAreEqual
  };
};

const TransactionDetail: FC<TransactionDetailProps> = ({ transaction, title, subtitle, icon, onClose }) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const orderMutation = useMutation((uri: string) => updateOrder(uri), {
    onSuccess: () => {
      queryClient.invalidateQueries([QueryKey.QUERY_TRANSACTIONS]);
      queryClient.invalidateQueries([QueryKey.QUERY_ORDERS]);
    },
    onError: (error: AxiosError<IResponseError>) => {
      const message = error?.response?.data?.message;
      if (message) {
        enqueueSnackbar(message, { variant: 'error' });
      }
    }
  });
  const { status, type, _links, order: orderPreview } = transaction;
  const orderUri = _links.swapOrder?.uri ?? _links.paymentOrder?.uri ?? orderPreview?._links.self.uri ?? '';
  const { data: transactionDetails } = useTransactionQuery(
    type === TransactionType.Deposit || type === TransactionType.Withdraw ? _links.self.uri : ''
  );
  const { data: orderDetails, isSuccess: orderSuccess } = useOrderQuery(orderUri);
  const shouldRunOrderRefresh = orderSuccess && orderDetails?.data.status === TransactionStatus.PendingApproval;
  const { data: refreshedOrderDetails, isSuccess: refreshedOrderSuccess } = useOrderRefreshQuery(orderUri, shouldRunOrderRefresh);
  const order = orderDetails?.data ?? orderPreview;
  const refreshedOrder = refreshedOrderDetails?.data ?? orderPreview;
  const differentCurrencies = order?.offer.buy.currency !== order?.offer.sell.currency;

  const { data: countries } = useCountriesQuery(!!order?.request?.beneficiary?.address?.country);

  const renderItem = (
    key: string,
    props: Omit<CopyItemProps, 'label'>,
    options?: {
      currency?: string;
      highlight?: boolean;
    }
  ) => (
    <CopyItem
      link={props.link}
      value={props.value}
      highlight={options?.highlight}
      multiline={props.multiline}
      middleCut={props.middleCut}
      label={t(`banking.transactions.details.${key}`)}
      valueDecoration={
        options?.currency ? <StyledCurrencyFlag src={getCurrencyImage(options.currency)} alt={`${options.currency} flag`} /> : undefined
      }
      disableCopy
    />
  );

  const handleOrderAction = (uri: string) => {
    onClose();
    orderMutation.mutate(uri);
  };

  const { counterpart, externalNetworkId, externalNetworkLink } = transactionDetails?.data ?? {};
  const { approve, reject } = order?._actions ?? {};
  const executionDate = shouldRunOrderRefresh
    ? compare(
        order?.offer?.executionDate && formatDate(order.offer.executionDate),
        refreshedOrder?.offer?.executionDate && formatDate(refreshedOrder.offer.executionDate)
      )
    : { value: order?.offer?.executionDate && formatDate(order.offer.executionDate), areDifferent: false };
  const estimatedRate = shouldRunOrderRefresh
    ? compare(order?.offer?.clientRate, refreshedOrder?.offer?.clientRate)
    : { value: order?.offer?.clientRate, areDifferent: false };
  const estimatedRateText = order ? `${Number(order.offer.clientRate).toFixed(6)} ${order.offer.buy.currency} = 1 ${order.offer.sell.currency}` : '';

  const approvals = order?.approvals?.decisions?.filter(d => d.decision === DecisionType.Approved);
  const declines = order?.approvals?.decisions?.filter(d => d.decision === DecisionType.Declined);

  const dialogSubtitle = useMemo(() => {
    let result = subtitle;
    if (transaction.status === TransactionStatus.PendingApproval) {
      result += ` · ${t('banking.transactions.approvals', {
        approved: 0,
        required: 1
      })}`;
    }
    return result;
  }, [subtitle, transaction, t]);

  return (
    <ModalLayout
      icon={icon}
      title={title}
      subtitle={dialogSubtitle}
      onClose={onClose}
      actions={
        (approve || reject) && (
          <StyledActions>
            {approve?.uri && (
              <Button size="small" color="success" onClick={() => handleOrderAction(approve.uri)}>
                {t('common.approve')}
              </Button>
            )}
            {reject?.uri && (
              <Button size="small" color="error" onClick={() => handleOrderAction(reject.uri)}>
                {t('common.reject')}
              </Button>
            )}
          </StyledActions>
        )
      }
    >
      {order && orderSuccess && ((refreshedOrder && refreshedOrderSuccess) || (!refreshedOrderSuccess && !shouldRunOrderRefresh)) ? (
        <>
          {renderItem('date', { value: formatDate(order.createdAt) })}
          {transaction.type === TransactionType.Withdraw && order.request && (
            <>
              {renderItem('amount', {
                value: `${formatAmount(order.offer.buy.amount)} ${order.offer.buy.currency}`
              })}
              {differentCurrencies && renderItem('balance', { value: order.offer.sell.currency }, { currency: order.offer.sell.currency })}
              {differentCurrencies && renderItem('converted', { value: `${order.offer.sell.amount} ${order.offer.sell.currency}` })}
              {differentCurrencies &&
                estimatedRate.value &&
                renderItem(
                  shouldRunOrderRefresh ? 'estimatedRate' : 'appliedRate',
                  {
                    value: estimatedRateText
                  },
                  { highlight: estimatedRate.areDifferent }
                )}
              {order.request.beneficiary &&
                renderItem('beneficiary', {
                  value: order.request.beneficiary?.walletAddress ?? formatBeneficiary(order.request.beneficiary, countries?.data),
                  multiline: true
                })}
              {order.request.beneficiary?.iban &&
                renderItem('iban', {
                  value: IBAN.printFormat(order.request.beneficiary.iban)
                })}
              {order.request.beneficiary?.accountNumber &&
                renderItem('accountNumber', {
                  value: order.request.beneficiary.accountNumber
                })}
              {(order.request.beneficiary?.bicSwiftCode || order.request.beneficiary?.routingNumber) &&
                renderItem(order.request.beneficiary?.bicSwiftCode ? 'bicSwiftCode' : 'routingNumber', {
                  value: formatBankDetails(order.request.beneficiary, countries?.data),
                  multiline: true
                })}
              {renderItem('vendorNote', {
                value: order.request.vendorNote ?? t('common.notAvailable'),
                multiline: true
              })}
              {renderItem('personalNote', {
                value: order.request.personalNote ?? t('common.notAvailable'),
                multiline: true
              })}
              {order.request.feeCoverage && renderItem('feeCoverage', { value: order.request.feeCoverage })}
            </>
          )}
          {transaction.type === TransactionType.Swap && (
            <>
              {renderItem(
                'buying',
                {
                  value: `${formatAmount(order.offer.buy.amount)} ${order.offer.buy.currency}`
                },
                { currency: order.offer.buy.currency }
              )}
              {renderItem(
                'selling',
                {
                  value: `${formatAmount(order.offer.sell.amount)} ${order.offer.sell.currency}`
                },
                { currency: order.offer.sell.currency }
              )}
              {estimatedRate.value &&
                renderItem(
                  shouldRunOrderRefresh ? 'estimatedRate' : 'appliedRate',
                  { value: estimatedRateText },
                  { highlight: estimatedRate.areDifferent }
                )}
            </>
          )}
          {order.requestorName && renderItem('requestor', { value: order.requestorName })}
          {(!!approvals?.length || !!declines?.length) && (
            <>
              {!!approvals?.length &&
                renderItem('approvedBy', {
                  value: approvals.map(d => d.name).join('\n'),
                  multiline: true
                })}
              {!!declines?.length &&
                renderItem('rejectedBy', {
                  value: declines.map(d => d.name).join('\n'),
                  multiline: true
                })}
            </>
          )}
          <StyledDivider />
          {renderItem('status', {
            value: t(`banking.transactions.status.${status}`)
          })}
          {executionDate.value && renderItem('settlement', { value: executionDate.value }, { highlight: executionDate.areDifferent })}
        </>
      ) : type === TransactionType.Deposit ? (
        <>
          {transaction.bookingDate && renderItem('date', { value: formatDate(transaction.bookingDate) })}
          {renderItem(
            'amount',
            {
              value: `${formatAmount(transaction.amount)} ${transaction.currency}`
            },
            { currency: transaction.currency }
          )}
          {counterpart &&
            renderItem('sender', {
              value: formatCounterpart(counterpart, countries?.data),
              multiline: true
            })}
          {renderItem('status', {
            value: t(`banking.transactions.status.${status}`)
          })}
        </>
      ) : (
        <>
          {renderItem('amount', {
            value: `${formatAmount(transaction.amount)} ${transaction.currency}`
          })}
          {renderItem('status', {
            value: t(`banking.transactions.status.${status}`)
          })}
          {transaction.bookingDate &&
            renderItem('executionDate', {
              value: formatDate(transaction.bookingDate)
            })}
        </>
      )}
      {externalNetworkId &&
        renderItem('externalRef', {
          value: externalNetworkId,
          link: externalNetworkLink,
          middleCut: true
        })}
    </ModalLayout>
  );
};

export default TransactionDetail;
