import { Dispatch, SetStateAction } from 'react';

export const useAmountInput = (target: string | undefined, setTarget: Dispatch<SetStateAction<string>> | ((amount: string) => void) | undefined) => {
  const prefillAmountInput = (value: string) => (Number(target) === 0 || target === '') && setTarget && setTarget(value);

  const clearPrefilledAmountInput = () => prefillAmountInput('');

  return {
    prefillAmountInput,
    clearPrefilledAmountInput
  };
};
