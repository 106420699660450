import { Chip, styled, Theme, Typography, Divider, Dialog } from '@mui/material';
import { CSSProperties } from 'react';
import SvgIcon from 'src/components/SvgIcon/SvgIcon';
import { TransactionStatus } from 'src/interfaces/banking';
import { getBackgroundColorVariantForTransactionType } from 'src/utils/styles';

import { StyledTransactionIconWrapperProps, StyledTransactionsMutedTextProps, StyledTransactionStatusProps } from './Transactions.interfaces';

const getColorVariantForTransactionStatus = (theme: Theme, transactionStatus: TransactionStatus): CSSProperties => {
  switch (transactionStatus) {
    case TransactionStatus.Submitted:
    case TransactionStatus.PendingApproval:
      return {
        color: theme.palette.custom.gold[600],
        backgroundColor: theme.palette.custom.gold.accent
      };
    case TransactionStatus.Success:
    case TransactionStatus.Completed:
      return {
        color: theme.palette.success.main,
        backgroundColor: theme.palette.success.light
      };
    case TransactionStatus.Failed:
    case TransactionStatus.Rejected:
      return {
        color: theme.palette.error.main,
        backgroundColor: theme.palette.error.light
      };
  }
};

export const StyledEmptyTransactions = styled('div')(({ theme }) => ({
  width: '360px',
  margin: 'auto',
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  marginTop: '120px',
  [theme.breakpoints.down('md')]: {
    width: '80vw'
  }
}));

export const StyledEmptyIcon = styled('div')(({ theme }) => ({
  fontSize: 170,
  marginBottom: theme.spacing(3)
}));

export const StyledEmptyText = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(4)
}));

export const StyledTransactionsMutedText = styled(Typography, {
  shouldForwardProp: prop => prop !== 'capitalize'
})<StyledTransactionsMutedTextProps>(({ theme, capitalize = false }) => ({
  color: theme.palette.custom.grey[400],
  letterSpacing: '-0.01em',
  textTransform: capitalize ? 'capitalize' : 'none'
}));

export const StyledTransactionsDate = styled(StyledTransactionsMutedText)(({ theme }) => ({
  padding: theme.spacing(3, 0, 1, 0)
}));

export const StyledTransactionItemsWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column'
});

export const StyledTransactionWrapper = styled('div')(({ theme }) => ({
  marginInline: theme.spacing(-1)
}));

export const StyledTransactionDivider = styled(Divider)(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.custom.grey[50]}`
}));

export const StyledTransaction = styled('div')(({ theme, onClick }) => ({
  display: 'grid',
  gridTemplateColumns: '50% 15% 35%',
  width: '100%',
  borderRadius: theme.shape.borderRadius,
  alignItems: 'center',
  padding: theme.spacing(1),
  margin: theme.spacing(1, 0),

  ...(onClick && {
    ':hover': {
      cursor: 'pointer',
      background: theme.palette.custom.grey[50]
    }
  })
}));

export const StyledTransactionIconWrapper = styled('div', {
  shouldForwardProp: prop => prop !== 'transactionType'
})<StyledTransactionIconWrapperProps>(({ theme, transactionType }) => ({
  backgroundColor: getBackgroundColorVariantForTransactionType(theme, transactionType).defaultColor,
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(1.5)
}));

export const StyledTransactionIcon = styled(SvgIcon)(({ theme }) => ({
  fontSize: theme.typography.htmlFontSize
}));

export const StyledTransactionDescWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  minWidth: '200px',
  [theme.breakpoints.down('md')]: {
    minWidth: '70px',
    p: {
      fontSize: 12
    }
  }
}));

export const StyledTransactionLeftSideWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(2)
}));

export const StyledTransactionStatus = styled(Chip, {
  shouldForwardProp: prop => prop !== 'transactionStatus'
})<StyledTransactionStatusProps>(({ theme, transactionStatus }) => ({
  width: 'fit-content',
  display: 'flex',
  height: 24,
  borderRadius: 6,
  padding: theme.spacing(0.25, 1),
  ...theme.typography.strongBody4,
  ...getColorVariantForTransactionStatus(theme, transactionStatus),

  '& .MuiChip-label': {
    padding: 0
  }
}));

export const StyledTransactionAmountWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-end',
  minWidth: '100px',
  [theme.breakpoints.down('md')]: {
    marginInline: theme.spacing(1),
    minWidth: '70px',
    p: {
      fontSize: 12
    }
  }
}));

export const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiPaper-root': {
    [theme.breakpoints.down('md')]: {
      margin: 0
    }
  }
}));
