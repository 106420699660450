import { FC, useMemo, useState } from 'react';
import { useSnackbar } from 'notistack';
import { IFormHandleSubmit, IFormValues } from 'src/interfaces/forms';
import Form from 'src/components/Form/Form';
import { personalInfoSchemaUrl } from 'src/api/formSchemas';
import Input from 'src/components/Input/Input';
import { savePersonalInfo } from 'src/api/onboarding';
import { ONBOARDING_PERSONAL_INFO } from 'src/constants/onboarding';
import { useTranslation } from 'react-i18next';
import { ERROR_INVALID_BIRTHDATE, ERROR_INVALID_PHONE_NUMBER } from 'src/constants/errors';
import { useUserNavigation } from 'src/hooks/navigation';
import { IGetUserResponse } from 'src/interfaces/responses';
import Dropdown from 'src/components/Dropdown/Dropdown';
import ActionButtons from 'src/components/ActionButtons/ActionButtons';
import Autocomplete from 'src/components/Autocomplete/Autocomplete';
import { GooglePlaceAutocomplete } from 'src/components/googleApis/GooglePlaceAutocomplete/GooglePlaceAutocomplete';
import PhoneNumber from 'src/components/PhoneNumber/PhoneNumber';
import { useCountriesQuery } from 'src/api/queries/useCountries';
import { useUserOrNavigateQuery } from 'src/api/queries/useUserOrNavigate';
import DialogLayout from 'src/components/layouts/DialogLayout/DialogLayout';

import {
  StyledInputWrapperHouseNo,
  StyledInputWrapperStreet,
  StyledLabel,
  StyledRow,
  StyledRowStreetAndHouseNo
} from './PersonalInformationPage.styles';

const PersonalInformationPage: FC = () => {
  const { t } = useTranslation();
  const { getUserOrNavigate } = useUserNavigation();
  const { enqueueSnackbar } = useSnackbar();

  const { data: countriesList, isLoading: isLoadingCountriesList } = useCountriesQuery(true, true);
  const { data: user, isLoading: isLoadingUser, isFetching: isFetchingUser } = useUserOrNavigateQuery(false, true);
  const [initialValues, setInitialValues] = useState<IFormValues>();
  const [loading, setLoading] = useState<boolean>(false);
  const country = user?.postalAddress?.country ?? user?.countryOfResidence;
  const [showAddressFields, setShowAddressFields] = useState<boolean>(false);

  const initialiseUser = (user: IGetUserResponse) => {
    if (user.postalAddress?.street) {
      setShowAddressFields(true);
    }
    setInitialValues({
      gender: user.gender,
      dateOfBirth: user.dateOfBirth,
      firstName: user.firstName,
      lastName: user.lastName,
      taxId: user.taxInfo?.taxId,
      nationality: user.nationality,
      mobileNumber: user.mobileNumber,
      postalAddress: {
        street: user.postalAddress?.street,
        houseNo: user.postalAddress?.houseNo,
        addition: user.postalAddress?.addition,
        postalCode: user.postalAddress?.postalCode,
        city: user.postalAddress?.city,
        state: user.postalAddress?.state,
        country: user.postalAddress?.country ?? user.countryOfResidence
      }
    });
  };

  useMemo(() => {
    if (user && !isLoadingUser && !isFetchingUser) {
      initialiseUser(user);
    }
  }, [user, isLoadingUser, isFetchingUser]);

  const contentLoading = isLoadingCountriesList || isLoadingUser || isFetchingUser;

  const handleSubmit: IFormHandleSubmit = async (values, validateForm, handleError) => {
    if (user && validateForm(values, true)) {
      setLoading(true);

      savePersonalInfo(user._links.self.uri, values)
        ?.then(() => {
          getUserOrNavigate();
        })
        .catch(error => {
          try {
            const { code, message } = error.response.data;
            if (code === ERROR_INVALID_BIRTHDATE) {
              handleError('dateOfBirth', message);
            } else if (code === ERROR_INVALID_PHONE_NUMBER) {
              handleError('mobileNumber', message);
            } else {
              enqueueSnackbar(message, { variant: 'error' });
            }
          } catch {}
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  return (
    <DialogLayout
      loading={loading}
      contentLoading={contentLoading}
      heading={t('screens.personalInformation.title')}
      subheading={t('screens.personalInformation.subtitle')}
      isOnboardingLayout
      activeStepName={ONBOARDING_PERSONAL_INFO}
      withOnboardingSteps
    >
      <Form initialValues={initialValues} schemaUrl={personalInfoSchemaUrl} onSubmit={handleSubmit}>
        <StyledRow>
          <Dropdown name="gender" />
          <Input name="dateOfBirth" type="date" />
        </StyledRow>
        <Input name="firstName" />
        <Input name="lastName" />
        <Autocomplete name="nationality" showOptionValue />
        <PhoneNumber name="mobileNumber" initialCountry={user?.countryOfResidence} countriesList={countriesList?.data ?? []} />
        <Input name="taxId" />

        <StyledLabel>{t('common.enterAddress')}</StyledLabel>
        {country && <Autocomplete disabled name="postalAddress.country" />}

        {!showAddressFields && <GooglePlaceAutocomplete name="postalAddress.street" country={country} searchPerformed={setShowAddressFields} />}
        {showAddressFields && (
          <>
            <StyledRowStreetAndHouseNo>
              <StyledInputWrapperStreet>
                <Input name="postalAddress.street" />
              </StyledInputWrapperStreet>
              <StyledInputWrapperHouseNo>
                <Input name="postalAddress.houseNo" />
              </StyledInputWrapperHouseNo>
            </StyledRowStreetAndHouseNo>
            <Input name="postalAddress.addition" />
            <StyledRow>
              <Input name="postalAddress.postalCode" />
              <Input name="postalAddress.city" />
            </StyledRow>
            <Input name="postalAddress.state" />
          </>
        )}
        <ActionButtons primaryActionText={t('common.continue')} />
      </Form>
    </DialogLayout>
  );
};

export default PersonalInformationPage;
