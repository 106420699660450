import { Link, styled, Typography } from '@mui/material';

export const StyledAdditionalInfo = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between'
});

export const StyledSwitch = styled(Link)({
  display: 'flex',
  justifyContent: 'center',
  marginTop: 32,
  whiteSpace: 'pre-wrap'
});

export const StyledHighlightSignUp = styled(Typography)(({ theme }) => ({
  color: theme.palette.custom.coralDusk[400],
  lineHeight: '24px'
}));

export const StyledLink = styled(Link)(({ theme }) => ({
  color: theme.palette.primary.main
}));
