import { Typography } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Input from 'src/components/Input/Input';
import { StyledPasswordCriteria, StyledPasswordTooltip } from 'src/hooks/usePassword/usePassword.styles';
import {
  checkPasswordHasLowerCases,
  checkPasswordHasNumbers,
  checkPasswordHasRequiredLength,
  checkPasswordHasSpecialChars,
  checkPasswordHasUpperCases
} from 'src/utils/passwordCriteriaValidators';

export const usePassword = () => {
  const [password, setPassword] = useState<string>('');
  const [passwordConfirmation, setPasswordConfirmation] = useState<string>('');
  const [passwordConfirmationError, setPasswordConfirmationError] = useState<string>();
  const { t } = useTranslation();

  const renderPasswordInput = (name = 'password') => (
    <Input
      fullWidth
      name={name}
      tooltip={
        <StyledPasswordTooltip>
          <Typography variant="strongBody1">{t('forms.passwordRequirements.title')}</Typography>
          <StyledPasswordCriteria fulfilled={checkPasswordHasRequiredLength(password)} variant="body1">
            {t('forms.passwordRequirements.atLeast8Chars')}
          </StyledPasswordCriteria>
          <StyledPasswordCriteria fulfilled={checkPasswordHasNumbers(password)} variant="body1">
            {t('forms.passwordRequirements.atLeast1Num')}
          </StyledPasswordCriteria>
          <StyledPasswordCriteria fulfilled={checkPasswordHasUpperCases(password)} variant="body1">
            {t('forms.passwordRequirements.atLeast1UpCaseChar')}
          </StyledPasswordCriteria>
          <StyledPasswordCriteria fulfilled={checkPasswordHasLowerCases(password)} variant="body1">
            {t('forms.passwordRequirements.atLeast1LowCaseChar')}
          </StyledPasswordCriteria>
          <StyledPasswordCriteria fulfilled={checkPasswordHasSpecialChars(password)} variant="body1">
            {t('forms.passwordRequirements.atLeast1SpecChar')}
          </StyledPasswordCriteria>
        </StyledPasswordTooltip>
      }
      type="password"
      value={password}
      handleOnChange={(_, value) => setPassword(value as string)}
    />
  );

  const renderPasswordConfirmationInput = (isNew = false) => (
    <Input
      fullWidth
      formField={false}
      type="password"
      name="passwordConfirmation"
      label={t(`forms.passwordConfirmation.label${isNew ? 'New' : ''}`)}
      placeholder={t('forms.passwordConfirmation.placeholder')}
      value={passwordConfirmation}
      error={passwordConfirmationError}
      handleError={(_, value) => setPasswordConfirmationError(value)}
      handleOnChange={(_, value) => setPasswordConfirmation(value as string)}
    />
  );

  const checkForPasswordConfirmationErrors = (): boolean => {
    if (!passwordConfirmation) {
      setPasswordConfirmationError(t('formValidation.required'));
      return true;
    } else if (password !== passwordConfirmation) {
      setPasswordConfirmationError(t('forms.passwordMismatch'));
      return true;
    } else {
      return false;
    }
  };

  const clearPasswordInputs = () => {
    setPassword('');
    setPasswordConfirmation('');
    setPasswordConfirmationError('');
  };

  return {
    renderPasswordInput,
    renderPasswordConfirmationInput,
    password,
    checkForPasswordConfirmationErrors,
    clearPasswordInputs
  };
};
