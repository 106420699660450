import { FC, useState, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { ViewModeType } from 'src/components//ViewMode/ViewMode.interfaces';
import Tabs from 'src/components/banking/Tabs/Tabs';
import ViewMode from 'src/components/ViewMode/ViewMode';
import { ACCOUNT_SORTS } from 'src/constants/banking';
import { ACCOUNT_DETAILS_TABS, ACCOUNT_HOLDINGS_TABS } from 'src/constants/tabs';
import { AccountSorts, Account } from 'src/interfaces/banking';

import Filter from '../Filter/Filter';

import { AccountsTabProps, AccountGroup } from './AccountsTab.interfaces';
import { StyledAccountFilter, StyledAccountTiles } from './AccountsTab.styles';
import AccountsTabGridItem from './components/AccountsTabGridItem/AccountsTabGridItem';
import AccountsTabGroupToggle from './components/AccountsTabGroupToggle/AccountsTabGroupToggle';
import AccountsTabListItem from './components/AccountsTabListItem/AccountsTabListItem';

const AccountsTab: FC<AccountsTabProps> = ({ accounts }) => {
  const navigate = useNavigate();
  const [sort, setSort] = useState<string>(AccountSorts.Default);
  const [viewMode, setViewMode] = useState<ViewModeType>('grid');

  const accountsGrouped = useMemo(
    () =>
      accounts.reduce((prev, curr) => {
        if (!prev[curr.type]) {
          prev[curr.type] = {
            items: [],
            total: 0,
            currency: curr.valuation.currency
          };
        }

        prev[curr.type] = {
          ...prev[curr.type],
          total: prev[curr.type].total + Number(curr.valuation.amount),
          items: [...prev[curr.type].items, curr]
        };
        return prev;
      }, {} as AccountGroup),
    [accounts]
  );

  const accountsSorted = useMemo(() => {
    if (!!sort && sort === AccountSorts.Default) {
      return accountsGrouped;
    }

    const accountsGroup: AccountGroup = {};
    for (const group in accountsGrouped) {
      if (accountsGrouped.hasOwnProperty(group)) {
        const accountsGroupItems = [...accountsGrouped[group].items].sort((a, b) => {
          switch (sort) {
            case AccountSorts.BalanceHighest:
              return Number(b.valuation.amount) - Number(a.valuation.amount);
            case AccountSorts.BalanceLowest:
              return Number(a.valuation.amount) - Number(b.valuation.amount);
            case AccountSorts.AlphabeticalAZ:
              return a.currencyLabel.localeCompare(b.currencyLabel);
            case AccountSorts.AlphabeticalZA:
              return b.currencyLabel.localeCompare(a.currencyLabel);
            default:
              return 0;
          }
        });

        accountsGroup[group] = {
          ...accountsGrouped[group],
          items: accountsGroupItems
        };
      }
    }
    return accountsGroup;
  }, [accountsGrouped, sort]);

  const handleNavigate = (account: Account) => {
    navigate(`${account._links.self.uri}/${ACCOUNT_DETAILS_TABS[0].state}`, {
      state: account
    });
  };

  return (
    <>
      <Tabs tabs={ACCOUNT_HOLDINGS_TABS} value={0} />
      <div role="tabpanel">
        <StyledAccountFilter>
          <Filter value={sort} dataList={ACCOUNT_SORTS} onChange={setSort} />
          <ViewMode viewMode={viewMode} handleViewModeChange={setViewMode} />
        </StyledAccountFilter>

        {Object.keys(accountsSorted).map(accountGroupName => {
          const currentGroup = accountsSorted[accountGroupName];
          return (
            <div key={accountGroupName}>
              <AccountsTabGroupToggle name={accountGroupName} currency={currentGroup.currency} total={currentGroup.total}>
                {expanded => (
                  <StyledAccountTiles expanded={expanded} viewMode={viewMode}>
                    {accountsSorted[accountGroupName].items.map((account, index) =>
                      viewMode === 'grid' ? (
                        <AccountsTabGridItem key={`${accountGroupName}-${index}`} account={account} onClick={handleNavigate} />
                      ) : (
                        <AccountsTabListItem key={`${accountGroupName}-${index}`} account={account} onClick={handleNavigate} />
                      )
                    )}
                  </StyledAccountTiles>
                )}
              </AccountsTabGroupToggle>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default AccountsTab;
