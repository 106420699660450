import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import SvgIcon from 'src/components/SvgIcon/SvgIcon';
import { IconKeys } from 'src/components/SvgIcon/SvgIcon.interfaces';
import ActionTab from 'src/components/banking/actions/ActionTab/ActionTab';
import { ActionBarContext } from 'src/contexts/ActionBar.contexts';
import { DepositContext } from 'src/contexts/actions/Deposit.contexts';

import { DepositAccountDetails } from '../DepositAccountDetails/DepositAccountDetails';
import { DepositWeCanNotAcceptThisDeposit } from '../DepositWeCanNotAcceptThisDeposit/DepositWeCanNotAcceptThisDeposit';
import DepositSelection from '../DepositSelection/DepositSelection';
import { DepositIsFromCryptoTradingNextScreens } from '../Deposit.interfaces';
import { depositActionTabDefaultValues } from '../Deposit.utils';

import { StyledButtonTitle, StyledButtonWrapper, StyledButton, StyledTextWrapper, StyledIcon } from './DepositIsFromCryptoTrading.styles';

export const DepositIsFromCryptoTrading = () => {
  const { t } = useTranslation();
  const actionBarContext = useContext(ActionBarContext);
  const depositContext = useContext(DepositContext);
  const contextsInitialized = actionBarContext && depositContext;

  const [nextScreen, setNextScreen] = useState<DepositIsFromCryptoTradingNextScreens>();

  const getStyledButton = (icon: IconKeys, titleKey: string, descriptionKey: string, isFromCrypto: boolean) => (
    <StyledButton direction="row" onClick={() => handleOnClick(isFromCrypto)}>
      <StyledIcon>
        <SvgIcon icon={icon} />
      </StyledIcon>
      <StyledTextWrapper>
        <StyledButtonTitle variant="body1" fontWeight={500}>
          {t(titleKey)}
        </StyledButtonTitle>
        <Typography variant="body3">{t(descriptionKey)}</Typography>
      </StyledTextWrapper>
    </StyledButton>
  );

  const handleOnClick = (isFromCrypto: boolean) => {
    const screen = isFromCrypto ? 'depositRejected' : 'depositAccountDetails';
    if (screen === 'depositRejected') {
      depositContext?.setDepositAccountDetailsPreviousScreen('depositRejected');
    } else {
      depositContext?.setDepositAccountDetailsPreviousScreen('depositFiat');
    }
    setNextScreen(screen);
  };

  const flowScreens = {
    depositAccountDetails: <DepositAccountDetails />,
    depositRejected: <DepositWeCanNotAcceptThisDeposit />,
    depositSelect: <DepositSelection />
  };

  return nextScreen ? (
    flowScreens[nextScreen]
  ) : contextsInitialized ? (
    <ActionTab
      {...depositActionTabDefaultValues}
      titleKey="banking.actions.deposit.titleFiat"
      onGoBack={() => setNextScreen('depositSelect')}
      onClose={actionBarContext.closeActionTab}
      activeStepIndex={1}
    >
      <Typography variant="subtitle3">{t('banking.actions.deposit.areTheseFundsFromCryptorading')}</Typography>
      <StyledButtonWrapper>
        {getStyledButton('checkRound', 'common.yes', 'banking.actions.deposit.fundsAreFromCryptoTradingInfo', true)}
        {getStyledButton('closeRound', 'common.no', 'banking.actions.deposit.fundsAreNotFromCryptoTradingInfo', false)}
      </StyledButtonWrapper>
    </ActionTab>
  ) : null;
};
