import { Typography } from '@mui/material';
import { ChangeEvent, FC } from 'react';
import AccountMenu from 'src/components/banking/AccountMenu/AccountMenu';
import { formatAmount } from 'src/utils/localization';
import { StyledFrom, StyledLabel, StyledInput, StyledBalance } from 'src/components/banking/actions/swap/SwapSelection/SwapSelection.styles';
import { Account } from 'src/interfaces/banking';
import { useTranslation } from 'react-i18next';
import { useAmountInput } from 'src/hooks/useAmountInput';

import { StyledBox } from '../actions/withdraw/WithdrawAmountAndCountry/WithdrawAmountAndCountry.styles';

export interface AccountAndAmountProps {
  label?: string;
  disabled?: boolean;
  accounts: Account[];
  account?: Account;
  setAccount?: (account: Account) => void;
  amount?: string;
  setAmount?: (amount: string) => void;
  invalidAmount: boolean;
  estimatedRate?: string;
}

export const AccountAndAmount: FC<AccountAndAmountProps> = ({
  label = 'common.from',
  disabled = false,
  accounts,
  account,
  setAccount,
  amount,
  setAmount,
  invalidAmount,
  estimatedRate
}) => {
  const { t } = useTranslation();
  const { clearPrefilledAmountInput, prefillAmountInput } = useAmountInput(amount, setAmount);

  return (
    <StyledBox>
      <StyledFrom>
        <AccountMenu disabled={disabled} accounts={accounts} value={account?._links.self.uri} onChange={setAccount} />
        <StyledLabel variant="body3">{t(label)}</StyledLabel>
        <StyledInput
          type="number"
          autoComplete="off"
          variant="standard"
          onFocus={() => clearPrefilledAmountInput()}
          onBlur={() => prefillAmountInput('0')}
          value={amount}
          error={invalidAmount}
          onChange={(event: ChangeEvent<HTMLInputElement>) => {
            if (setAmount) {
              setAmount(event.target.value);
            }
          }}
        />

        {account && (
          <>
            {estimatedRate && (
              <StyledBalance>
                <Typography color="coral" variant="body4">
                  {t('banking.actions.swap.balance')}
                </Typography>
                <Typography color="coral" variant="body4">
                  {estimatedRate}
                </Typography>
              </StyledBalance>
            )}
            <StyledBalance>
              <Typography variant="body4">{t('banking.actions.swap.balance')}</Typography>
              <Typography variant="body4">{`${formatAmount(account.balance)} ${account.currency}`}</Typography>
            </StyledBalance>
          </>
        )}
      </StyledFrom>
    </StyledBox>
  );
};
