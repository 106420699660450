import ActionTab from 'src/components/banking/actions/ActionTab/ActionTab';
import { TransactionType } from 'src/interfaces/banking';
import { useContext } from 'react';
import { ActionBarContext } from 'src/contexts/ActionBar.contexts';

const TransferSelection = () => {
  const actionBarContext = useContext(ActionBarContext);

  return actionBarContext ? (
    <ActionTab
      titleKey="banking.actions.transfer.title"
      descriptionKey="banking.actions.transfer.description"
      primaryActionTextKey="common.continue"
      onPrimaryActionClick={() => undefined}
      onGoBack={actionBarContext.returnToActionBar}
      onClose={actionBarContext.closeActionTab}
      type={TransactionType.Transfer}
      activeStepIndex={0}
    >
      Transfer Selection
    </ActionTab>
  ) : null;
};

export default TransferSelection;
