import { Account } from 'src/interfaces/banking';
import { BASE_ACCOUNTS_EU, BASE_ACCOUNTS_US, ENTITY_REGION_US } from 'src/constants/baseAccounts';

import { StorageKey } from '../constants/storage';

export const getDefaultAccount = (accounts: Account[], uri?: string): Account =>
  accounts.find(a => a._links.self.uri === uri) || accounts.find(a => a.metaData?.name === 'Main') || accounts[0];

export const getMatchedAccounts = (accounts: Account[]): Account[] =>
  (sessionStorage.getItem(StorageKey.ENTITY_REGION) === ENTITY_REGION_US ? BASE_ACCOUNTS_US : BASE_ACCOUNTS_EU).map(ba => {
    const matchedAccount = accounts.find(a => a.currency === ba.currency && ba.metaData?.name === a.metaData?.name);
    return matchedAccount ?? ba;
  });
