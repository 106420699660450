import { FC, PropsWithChildren } from 'react';
import { DialogActions, DialogContent } from '@mui/material';
import Button from 'src/components/Button/Button';
import { toKebabCase } from 'src/utils/strings';
import { ButtonVariant } from 'src/constants/variants';

import SvgIcon from '../SvgIcon/SvgIcon';

import { ModalProps } from './Modal.interfaces';
import { StyledCloseIcon, StyledDialog, StyledDialogTitle, StyledTitle } from './Modal.styles';

const Modal: FC<PropsWithChildren<ModalProps>> = ({
  children,
  leftActionTitle,
  onClose,
  onLeftAction,
  onRightAction,
  isOpen,
  rightActionTitle,
  title,
  testId
}) => (
  <StyledDialog
    open={isOpen}
    onClose={onClose}
    aria-labelledby={`${toKebabCase(title || 'default')}-modal`}
    aria-describedby={`${toKebabCase(title || 'default')}-modal`}
    id={`${testId || toKebabCase(title || 'default')}-modal`}
  >
    {!!title && (
      <StyledDialogTitle>
        <StyledTitle>{title}</StyledTitle>

        <StyledCloseIcon onClick={onClose}>
          <SvgIcon icon="close" fontSize="inherit" />
        </StyledCloseIcon>
      </StyledDialogTitle>
    )}

    <DialogContent>{children}</DialogContent>

    {(!!leftActionTitle || !!rightActionTitle) && (
      <DialogActions>
        {!!leftActionTitle && (
          <Button onClick={onLeftAction} variant={ButtonVariant.Text}>
            {leftActionTitle}
          </Button>
        )}

        {!!rightActionTitle && <Button onClick={onRightAction}>{rightActionTitle}</Button>}
      </DialogActions>
    )}
  </StyledDialog>
);

export default Modal;
