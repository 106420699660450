import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import GridViewIcon from '@mui/icons-material/GridView';
import { FC } from 'react';
import { ButtonVariant } from 'src/constants/variants';

import Button from '../Button/Button';
import Tooltip from '../Tooltip/Tooltip';

import { ViewModeProps } from './ViewMode.interfaces';
import { StyledViewModeWrapper } from './ViewMode.styles';

const ViewMode: FC<ViewModeProps> = ({ viewMode, handleViewModeChange }) => (
  <StyledViewModeWrapper>
    <Tooltip title="Grid view" placement="top" arrow dark>
      <Button
        onClick={() => handleViewModeChange('grid')}
        size="small"
        hasIcon
        variant={viewMode === 'grid' ? ButtonVariant.Primary : ButtonVariant.Secondary}
      >
        <GridViewIcon />
      </Button>
    </Tooltip>
    <Tooltip title="List view" placement="top" arrow dark>
      <Button
        aria-label="List view"
        onClick={() => handleViewModeChange('list')}
        size="small"
        hasIcon
        variant={viewMode === 'list' ? ButtonVariant.Primary : ButtonVariant.Secondary}
      >
        <FormatListBulletedIcon />
      </Button>
    </Tooltip>
  </StyledViewModeWrapper>
);

export default ViewMode;
