import { FC } from 'react';

import { MobileStepperProps } from './MobileStepper.interfaces';
import { StyledStepperWrapper, StyledStep } from './MobileStepper.styles';

export const MobileStepper: FC<MobileStepperProps> = ({ totalSteps = 1, activeStep = 1 }) => (
  <StyledStepperWrapper direction="row">
    {[...Array(totalSteps)].map((st, index) => (
      <StyledStep key={index} fillColor={index + 1 <= activeStep} />
    ))}
  </StyledStepperWrapper>
);
