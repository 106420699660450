import escapeRegExp from 'lodash.escaperegexp';

import { HighlightProps } from './Highlight.interfaces';
import { StyledHighlightedText } from './Highlight.styles';

const Highlight = ({ text, search }: HighlightProps) => {
  if (!search.trim()) {
    return <>{text}</>;
  }

  const regex = new RegExp(`(${escapeRegExp(search)})`, 'gi');
  const parts = text.split(regex);

  return (
    <div>
      {parts.map((part, i) => (regex.test(part) ? <StyledHighlightedText key={i}>{part}</StyledHighlightedText> : <span key={i}>{part}</span>))}
    </div>
  );
};

export default Highlight;
