import { alpha, CircularProgress, styled } from '@mui/material';

import { StyledWrapperProps } from './Loader.interfaces';

export const StyledWrapper = styled('div', {
  shouldForwardProp: prop => prop !== 'anchorToRelative'
})<StyledWrapperProps>(({ theme, anchorToRelative }) => ({
  zIndex: 1,
  position: anchorToRelative ? 'static' : 'fixed',
  display: 'flex',
  ...(!anchorToRelative && {
    backgroundColor: alpha(theme.palette.common.black, 0.3)
  }),
  justifyContent: 'center',
  alignItems: 'center',
  top: 0,
  bottom: 0,
  left: 0,
  right: 0
}));

export const StyledLoader = styled(CircularProgress)(({ theme }) => ({
  padding: 5,
  borderRadius: '50%',
  backgroundColor: theme.palette.background.default
}));
