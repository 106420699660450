import { Link, styled, Typography } from '@mui/material';

export const StyledLastUpdated = styled(Typography)(({ theme }) => ({
  marginBlock: theme.spacing(5)
}));

export const StyledTitle = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(2)
}));

export const StyledPolicyWrapper = styled('div')({
  gap: '10px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start'
});

export const StyledLink = styled(Link)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontSize: '0.75rem',
  fontWeight: 500
}));
