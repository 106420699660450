import { FC, useState } from 'react';
import { useSnackbar } from 'notistack';
import i18next from 'i18next';
import { IFormHandleSubmit } from 'src/interfaces/forms';
import Form from 'src/components/Form/Form';
import Input from 'src/components/Input/Input';
import ActionButtons from 'src/components/ActionButtons/ActionButtons';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { waitlist } from 'src/api/onboarding';
import StatusIcon from 'src/components/StatusIcon/StatusIcon';
import SvgIcon from 'src/components/SvgIcon/SvgIcon';
import DialogLayout from 'src/components/layouts/DialogLayout/DialogLayout';
import { waitlistSchemaUrl } from 'src/api/formSchemas';
import { urls } from 'src/routes';

const WaitlistPage: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const { enqueueSnackbar } = useSnackbar();

  const email = searchParams.get('email') ?? '';
  const country = searchParams.get('country') ?? '';
  const [loading, setLoading] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);

  const handleSubmit: IFormHandleSubmit = async (values, validateForm) => {
    if (validateForm(values)) {
      setLoading(true);

      waitlist(values.email as string, country, i18next.language)
        ?.then(_ => {
          setSuccess(true);
        })
        .catch(error => {
          try {
            const { message } = error.response.data;
            enqueueSnackbar(message, { variant: 'error' });
          } catch {}
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  if (success) {
    return (
      <DialogLayout
        isOnboardingLayout
        headerImage={<StatusIcon icon={<SvgIcon icon="check" />} />}
        heading={t('screens.waitlist.success.title')}
        subheading={t('screens.waitlist.success.subtitle')}
        secondaryActionText={t('common.backToHomepage')}
        onSecondaryActionClick={() => navigate(urls.signin)}
      />
    );
  }

  return (
    <DialogLayout
      loading={loading}
      isOnboardingLayout
      headerImage={<StatusIcon icon={<SvgIcon icon="notification" />} />}
      heading={t('screens.waitlist.title')}
      subheading={t('screens.waitlist.subtitle')}
    >
      <Form schemaUrl={waitlistSchemaUrl} onSubmit={handleSubmit}>
        <Input name="email" type="email" value={email} fullWidth />

        <ActionButtons
          primaryActionText={t('screens.waitlist.joinWaitlist')}
          secondaryActionText={t('common.backToHomepage')}
          onSecondaryActionClick={() => navigate(urls.signin)}
        />
      </Form>
    </DialogLayout>
  );
};

export default WaitlistPage;
