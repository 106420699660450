import { Typography } from '@mui/material';
import { FC, useState } from 'react';
import SvgIcon from 'src/components/SvgIcon/SvgIcon';
import { IconKeys } from 'src/components/SvgIcon/SvgIcon.interfaces';
import { useTranslation } from 'react-i18next';
import Loader from 'src/components/Loader/Loader';
import { formatAmount, formatDate } from 'src/utils/localization';
import { TRANSACTION_FILTERS } from 'src/constants/banking';
import { BookingType, CurrencyType, Transaction, TransactionFilter, TransactionType } from 'src/interfaces/banking';
import { updateOrder } from 'src/api/banking';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { QueryKey } from 'src/constants/queryKeys';
import { enqueueSnackbar } from 'notistack';
import { AxiosError } from 'axios';
import { IResponseError } from 'src/interfaces/responses';
import { getTransactionDescription } from 'src/utils/transactions';

import Filter from '../Filter/Filter';
import TransactionDetail from '../TransactionDetail/TransactionDetail';

import { TransactionsProps } from './Transactions.interfaces';
import {
  StyledEmptyIcon,
  StyledEmptyText,
  StyledEmptyTransactions,
  StyledTransaction,
  StyledTransactionAmountWrapper,
  StyledTransactionDescWrapper,
  StyledTransactionIcon,
  StyledTransactionIconWrapper,
  StyledTransactionItemsWrapper,
  StyledTransactionLeftSideWrapper,
  StyledTransactionsMutedText,
  StyledTransactionStatus,
  StyledTransactionWrapper,
  StyledTransactionsDate,
  StyledTransactionDivider,
  StyledDialog
} from './Transactions.styles';

const Transactions: FC<TransactionsProps> = ({ transactions, orders, viewBottomRef }) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const [filter, setFilter] = useState<string>(TransactionFilter.All);
  const [dialogContent, setDialogContent] = useState<JSX.Element>();
  const closeDialog = () => setDialogContent(undefined);

  const orderMutation = useMutation((uri: string) => updateOrder(uri), {
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QueryKey.QUERY_TRANSACTIONS]
      });
      queryClient.invalidateQueries([QueryKey.QUERY_ORDERS]);
    },
    onError: (error: AxiosError<IResponseError>) => {
      const message = error?.response?.data?.message;
      if (message) {
        enqueueSnackbar(message, { variant: 'error' });
      }
    }
  });

  const renderTransaction = (transaction: Transaction, index: number, allTransactions: Transaction[]) => {
    const { status, order, type: transactionType } = transaction;
    const differentCurrencies = order?.offer.buy.currency !== order?.offer.sell.currency;
    const title = getTransactionDescription(transaction);
    const subtitle = t(`banking.transactions.type.${transactionType}`);
    const icon = (
      <StyledTransactionIconWrapper transactionType={transactionType}>
        <StyledTransactionIcon icon={`transaction${transactionType}` as IconKeys} />
      </StyledTransactionIconWrapper>
    );
    const currentTransactionDate = formatDate(transaction.bookingDate ?? '', 'LL');
    const previousTransactionDate = formatDate(allTransactions[index - 1]?.bookingDate ?? '', 'LL');

    const onClick = () =>
      setDialogContent(<TransactionDetail icon={icon} title={title} subtitle={subtitle} transaction={transaction} onClose={closeDialog} />);

    return (
      <div key={transaction._links.self.uri}>
        {previousTransactionDate !== currentTransactionDate && <StyledTransactionsDate>{currentTransactionDate}</StyledTransactionsDate>}
        <StyledTransactionWrapper>
          <StyledTransaction onClick={onClick}>
            <StyledTransactionLeftSideWrapper>
              {icon}
              <StyledTransactionDescWrapper>
                <Typography variant="strongBody3">{title}</Typography>
                <StyledTransactionsMutedText variant="body3">{subtitle}</StyledTransactionsMutedText>
              </StyledTransactionDescWrapper>
            </StyledTransactionLeftSideWrapper>
            <StyledTransactionStatus transactionStatus={status} label={t(`banking.transactions.status.${status}`)} />
            <StyledTransactionAmountWrapper>
              <Typography variant="strongBody3">
                {`${transaction.bookingType === BookingType.Debit ? '-' : '+'} ${formatAmount(
                  transaction.amount,
                  transaction.currencyType === CurrencyType.Fiat ? 2 : 5
                )} ${transaction.currency}`}
              </Typography>
              {order &&
                (transactionType === TransactionType.Swap ? (
                  <StyledTransactionsMutedText variant="body3">
                    {`${transaction.bookingType === BookingType.Debit ? '+' : '-'} ${order.offer.buy.amount} ${order.offer.buy.currency}`}
                  </StyledTransactionsMutedText>
                ) : (
                  differentCurrencies && (
                    <StyledTransactionsMutedText variant="body3">
                      {`- ${order.offer.buy.amount} ${order.offer.buy.currency}`}
                    </StyledTransactionsMutedText>
                  )
                ))}
            </StyledTransactionAmountWrapper>
          </StyledTransaction>
        </StyledTransactionWrapper>
        <StyledTransactionDivider />
      </div>
    );
  };

  if (!transactions) {
    return (
      <StyledEmptyTransactions>
        <Loader anchorToRelative />
      </StyledEmptyTransactions>
    );
  } else if (transactions.length === 0) {
    const preFix = `banking.${orders ? 'orders' : 'transactions'}.empty.`;
    return (
      <StyledEmptyTransactions>
        <StyledEmptyIcon>
          <SvgIcon icon="emptyTransactions" fontSize="inherit" />
        </StyledEmptyIcon>
        <Typography variant="h3">{t(`${preFix}title`)}</Typography>
        <StyledEmptyText align="center">{t(`${preFix}text`)}</StyledEmptyText>
      </StyledEmptyTransactions>
    );
  }

  return (
    <>
      {orderMutation.isLoading && <Loader />}
      <StyledDialog open={!!dialogContent} onClose={closeDialog}>
        {dialogContent}
      </StyledDialog>

      {!!transactions.length && <Filter value={filter} label={t('common.filterBy')} dataList={TRANSACTION_FILTERS} onChange={setFilter} />}

      <StyledTransactionItemsWrapper>
        {transactions.filter(t => !filter || filter === TransactionFilter.All || t.type === filter).map(renderTransaction)}
        <div ref={viewBottomRef}></div>
      </StyledTransactionItemsWrapper>
    </>
  );
};

export default Transactions;
