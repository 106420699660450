import { alpha, styled } from '@mui/material';
import { UICustomizationOptions } from 'onfido-sdk-ui';
import theme from 'src/constants/theme';

export const StyledOnfidoContainer = styled('div')(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    height: '80vh'
  },
  '.onfido-sdk-ui-Theme-root': {
    width: '100%',
    [theme.breakpoints.down('md')]: {
      width: '80vw',
      height: '80vh',
      marginTop: 16
    }
  }
}));

export const onfidoCustomUI: UICustomizationOptions = {
  borderRadiusSurfaceModal: `${theme.shape.borderRadius}px`,

  fontFamilyTitle: theme.typography.fontFamily,
  fontSizeTitle: '30px',

  fontFamilySubtitle: theme.typography.fontFamily,
  fontSizeSubtitle: '16px',

  fontFamilyBody: theme.typography.fontFamily,
  fontSizeBody: '16px',

  colorBorderDocTypeButtonHover: theme.palette.primary.main,

  colorContentButtonPrimaryText: theme.palette.common.white,
  colorBackgroundButtonPrimary: theme.palette.primary.main,
  colorBackgroundButtonPrimaryHover: theme.palette.primary.dark,
  colorBackgroundButtonPrimaryActive: theme.palette.primary.dark,
  colorBorderButtonPrimary: alpha(theme.palette.common.black, 0),

  colorContentButtonSecondaryText: theme.palette.primary.main,
  colorBackgroundButtonSecondary: alpha(theme.palette.common.black, 0),
  colorBackgroundButtonSecondaryHover: alpha(theme.palette.common.black, 0),
  colorBackgroundButtonSecondaryActive: theme.palette.primary.main,
  colorBorderButtonSecondary: theme.palette.primary.main,

  colorContentTitle: theme.palette.text.primary,
  colorContentSubtitle: theme.palette.text.primary,
  colorContentBody: theme.palette.text.primary,

  colorBorderLinkUnderline: theme.palette.primary.main,
  colorBackgroundLinkHover: theme.palette.primary.dark,
  colorBackgroundLinkActive: theme.palette.primary.main,

  colorBackgroundIcon: theme.palette.primary.main,

  colorBackgroundButtonCameraHover: theme.palette.primary.main,
  colorBackgroundButtonCameraActive: theme.palette.primary.dark,

  colorBackgroundInfoPill: theme.palette.text.primary,
  colorContentInfoPill: theme.palette.common.white,

  colorBackgroundAlertInfo: theme.palette.primary.main
};
