import { Typography, useMediaQuery } from '@mui/material';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonVariant } from 'src/constants/variants';
import { TransactionType } from 'src/interfaces/banking';
import ToggleIcon from 'src/components/banking/ToggleIcon/ToggleIcon';
import { ActionBarContext } from 'src/contexts/ActionBar.contexts';
import Tooltip from 'src/components/Tooltip/Tooltip';
import theme from 'src/constants/theme';

import ActionButton from '../ActionButton/ActionButton';
import SwapSelection from '../swap/SwapSelection/SwapSelection';
import TransferSelection from '../transfer/TransferSelection/TransferSelection';
import DepositSelection from '../deposit/DepositSelection/DepositSelection';
import { WithdrawSelectBenficiary } from '../withdraw/WithdrawSelectBenficiary/WithdrawSelectBenficiary';

import { ActionBarProps } from './ActionBar.interfaces';
import { StyledToggleButton, StyledExpandedHeader, StyledActionBar, StyledActionButtons, StyledDescription } from './ActionBar.styles';
import { ACTION_BUTTON_TYPES } from './constants';

const ActionBar: FC<ActionBarProps> = ({ hidden, setHidden, disabled }) => {
  const { t } = useTranslation();
  const [actionTabVisible, setActionTabVisible] = useState<boolean>(false);
  const [selectedTransactionType, setSelectedTransactionType] = useState<TransactionType | undefined>();
  const isMobileView = useMediaQuery(theme.breakpoints.down('md'));

  const showActionTab = (transactionType: TransactionType) => {
    setHidden(false);
    setSelectedTransactionType(transactionType);
    setActionTabVisible(true);
  };

  const closeActionTab = () => {
    setHidden(true);
    setActionTabVisible(false);
  };

  const returnToActionBar = () => {
    setActionTabVisible(false);
  };

  const createInitialActionTab = () => {
    switch (selectedTransactionType) {
      case TransactionType.Swap:
        return <SwapSelection />;
      case TransactionType.Transfer:
        return <TransferSelection />;
      case TransactionType.Deposit:
        return <DepositSelection />;
      case TransactionType.Withdraw:
        return <WithdrawSelectBenficiary />;
      default:
        return null;
    }
  };

  const renderActionButton = (type: TransactionType) => {
    const prefix = `banking.actions.${type.toLocaleLowerCase()}.`;
    return (
      <ActionButton
        key={type}
        transactionType={type}
        hiddenMeta={hidden}
        title={t(`${prefix}title`)}
        description={t(`${prefix}description`)}
        onClick={() => showActionTab(type)}
      />
    );
  };

  return actionTabVisible ? (
    <ActionBarContext.Provider
      value={{
        closeActionTab,
        returnToActionBar
      }}
    >
      {createInitialActionTab()}
    </ActionBarContext.Provider>
  ) : (
    <StyledActionBar hidden={hidden}>
      <Tooltip
        title={<Typography variant="body3">{t(`banking.actions.${hidden ? 'showActionBar' : 'hideActionBar'}`)}</Typography>}
        dark
        arrow
        placement={isMobileView ? 'right' : 'left'}
        sx={{
          alignSelf: 'flex-start'
        }}
      >
        <div>
          <StyledToggleButton
            hidden={hidden}
            size="small"
            variant={ButtonVariant.Secondary}
            hasIcon
            onClick={() => setHidden(prevState => !prevState)}
            startIcon={<ToggleIcon rotate={hidden} />}
          />
        </div>
      </Tooltip>
      {!hidden && (
        <StyledExpandedHeader>
          <Typography variant="h3">{t('banking.actions.title')}</Typography>
          <StyledDescription variant="body1">{t('banking.actions.description')}</StyledDescription>
        </StyledExpandedHeader>
      )}
      <StyledActionButtons disabled={disabled}>
        {ACTION_BUTTON_TYPES.map(type =>
          hidden ? (
            <Tooltip title={<Typography variant="body3">{t(`banking.transactions.type.${type}`)}</Typography>} arrow dark placement="left" key={type}>
              <div>{renderActionButton(type)}</div>
            </Tooltip>
          ) : (
            renderActionButton(type)
          )
        )}
      </StyledActionButtons>
    </StyledActionBar>
  );
};

export default ActionBar;
