import { Typography } from '@mui/material';
import { FC } from 'react';

import Icon from '../Icon/Icon';

import { LabelValueProps } from './LabelValue.interfaces';
import { StyledContainer, StyledLabelValue } from './LabelValue.styles';

const LabelValue: FC<LabelValueProps> = ({ label, value, icon }) => (
  <StyledContainer>
    <Icon icon={icon} />
    <StyledLabelValue>
      <Typography>{label}</Typography>
      <Typography variant="strongBody1">{value}</Typography>
    </StyledLabelValue>
  </StyledContainer>
);

export default LabelValue;
