import { useTranslation } from 'react-i18next';
import SvgIcon from 'src/components/SvgIcon/SvgIcon';
import { urls } from 'src/routes';

import { StyledReferenceIconWrapper, StyledLink, StyledWrapper } from './FooterLayout.styles';

const FooterLayout = () => {
  const { t } = useTranslation();

  return (
    <StyledWrapper>
      <StyledLink href={`${urls.privacyPolicy}`}>
        {t('common.privacyPolicy')}
        <StyledReferenceIconWrapper>
          <SvgIcon icon="reference" />
        </StyledReferenceIconWrapper>
      </StyledLink>
      <StyledLink href="#">{t('common.copyright')}</StyledLink>
    </StyledWrapper>
  );
};

export default FooterLayout;
