import { Stack, Typography } from '@mui/material';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ActionTab from 'src/components/banking/actions/ActionTab/ActionTab';
import { ActionBarContext } from 'src/contexts/ActionBar.contexts';
import { DepositContext } from 'src/contexts/actions/Deposit.contexts';
import { useUserOrNavigateQuery } from 'src/api/queries/useUserOrNavigate';
import { useLegalEntitiesQuery } from 'src/api/queries/useLegalEntities';
import { getHelpMailto } from 'src/utils/mailing';

import { DepositAccountDetails } from '../DepositAccountDetails/DepositAccountDetails';
import { DepositIsFromCryptoTrading } from '../DepositIsFromCryptoTrading/DepositIsFromCryptoTrading';
import { DepositWeCanNotAcceptThisDepositScreens } from '../Deposit.interfaces';
import { depositActionTabDefaultValues } from '../Deposit.utils';
import { StyledDesc } from '../Deposit.styles';

export const DepositWeCanNotAcceptThisDeposit = () => {
  const { t } = useTranslation();
  const actionBarContext = useContext(ActionBarContext);
  const depositContext = useContext(DepositContext);
  const contextsInitialized = actionBarContext && depositContext;
  const [nextScreen, setNextScreen] = useState<DepositWeCanNotAcceptThisDepositScreens>();
  const { data: user } = useUserOrNavigateQuery();
  const { data: legalEntities } = useLegalEntitiesQuery(user?._links.self?.uri ?? '');

  const flowScreens = {
    depositAccountDetails: <DepositAccountDetails />,
    depositIsFromCryptoTrading: <DepositIsFromCryptoTrading />
  };

  return nextScreen ? (
    flowScreens[nextScreen]
  ) : contextsInitialized ? (
    <ActionTab
      {...depositActionTabDefaultValues}
      titleKey="banking.actions.deposit.titleFiat"
      primaryActionTextKey="banking.actions.deposit.showUSDDetails"
      goBackActionTextKey="common.cancel"
      onPrimaryActionClick={() => {
        window.location.href = getHelpMailto(user, legalEntities?.data);
      }}
      onGoBack={() => {
        setNextScreen('depositIsFromCryptoTrading');
      }}
      onClose={actionBarContext.closeActionTab}
      activeStepIndex={2}
      loading={false}
    >
      <Stack>
        <Typography variant="subtitle3">{t('banking.actions.deposit.cantAcceptFundsTitle')}</Typography>
        <StyledDesc variant="body3" fontWeight={400}>
          {t('banking.actions.deposit.cantAcceptFundsDescription')}
          <ul>
            <li>{t('banking.actions.deposit.cantAcceptFundsDescriptionPointOne')}</li>
            <li>{t('banking.actions.deposit.cantAcceptFundsDescriptionPointTwo')}</li>
          </ul>
        </StyledDesc>
      </Stack>
    </ActionTab>
  ) : null;
};
