import { MenuItem, Typography } from '@mui/material';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Menu from 'src/components/Menu/Menu';
import { Account } from 'src/interfaces/banking';
import SvgIcon from 'src/components/SvgIcon/SvgIcon';
import { getCurrencyImage } from 'src/utils/styles';

import { AccountMenuProps } from './AccountMenu.interfaces';
import { StyledAccountMenu, StyledAccountFlag, StyledCurrencyLabel, StyledItem, StyledLabels } from './AccountMenu.styles';

const AccountMenu: FC<AccountMenuProps> = ({ value, disabled = false, accounts, onChange }) => {
  const { t } = useTranslation();

  const selectedAccount = accounts.find(a => a._links.self.uri === value);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if (!(disabled || !accounts.length)) {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = (account?: Account) => {
    setAnchorEl(null);
    if (account && onChange) {
      onChange(account);
    }
  };

  return (
    <>
      <StyledAccountMenu disabled={disabled || !accounts.length} onClick={handleClick}>
        {selectedAccount?.currency ? (
          <StyledAccountFlag size="24px" src={getCurrencyImage(selectedAccount.currency)} alt={`${selectedAccount.currency} flag`} />
        ) : (
          <Typography variant="body3">{t('common.select')}</Typography>
        )}
        <SvgIcon icon="arrowDown" />
      </StyledAccountMenu>
      <Menu open={open} anchorEl={anchorEl} onClose={() => handleClose()}>
        {accounts.map(a => {
          const uri = a._links.self.uri;
          const addition = a.metaData?.name;
          return (
            <MenuItem disableRipple selected={value === uri} key={uri} onClick={() => handleClose(a)}>
              <StyledItem>
                <StyledAccountFlag size="32px" src={getCurrencyImage(a.currency)} alt={`${a.currency} flag`} />
                <StyledLabels>
                  <Typography>{a.currencyLabel}</Typography>
                  <StyledCurrencyLabel variant="body3">{`${a.currency}${addition ? ` · ${addition}` : ''}`}</StyledCurrencyLabel>
                </StyledLabels>
              </StyledItem>
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
};

export default AccountMenu;
