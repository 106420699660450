import { Children, cloneElement, isValidElement, ReactElement, ReactNode, useEffect, useState } from 'react';
import get from 'lodash.get';
import { IUseForm } from 'src/components/Form/hooks/useForm.interfaces';
import set from 'lodash.set';
import cloneDeep from 'lodash.clonedeep';
import ActionButtons from 'src/components/ActionButtons/ActionButtons';
import Autocomplete from 'src/components/Autocomplete/Autocomplete';
import Dropdown from 'src/components/Dropdown/Dropdown';
import RadioGroup from 'src/components/RadioGroup/RadioGroup';
import Checkbox from 'src/components/Checkbox/Checkbox';
import Input from 'src/components/Input/Input';
import { formatValues } from 'src/utils/formData';
import { FIELD_VALUE_NO, FIELD_VALUE_TRUE, FIELD_VALUE_YES } from 'src/constants/form';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import { IChange } from 'src/interfaces/forms';
import { GooglePlaceAutocomplete } from 'src/components/googleApis/GooglePlaceAutocomplete/GooglePlaceAutocomplete';
import PhoneNumber from 'src/components/PhoneNumber/PhoneNumber';

import { StyledTooltip } from '../Form.styles';

const useForm = ({
  names,
  values,
  errors,
  setErrors,
  setValues,
  formProps,
  onSubmit,
  validateForm,
  onChange,
  disabled
}: IUseForm): ((children: ReactNode) => ReactNode) => {
  const { t } = useTranslation();
  const [lastChangedFieldName, setLastChangedFieldName] = useState<string>('');

  useEffect(() => {
    if (onChange) {
      onChange({
        values,
        errors,
        lastChangedFieldName,
        submitForm
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values, lastChangedFieldName]);

  const handleChanges = (name: string, value: string | boolean) =>
    setValues(prevState => (get(prevState, name, '') === value ? prevState : set(cloneDeep(prevState), name, value.toString() || undefined)));

  const handleErrors = (name: string, error: string) =>
    setErrors(prevState => (get(prevState, name, '') === error ? prevState : set(cloneDeep(prevState), name, error)));

  const handleLastChangedFieldName = (name: string) => setLastChangedFieldName(prevState => (prevState === name ? prevState : name));

  const submitForm = () => onSubmit && onSubmit(formatValues(values, names, formProps), validateForm, handleErrors);

  const processChildren = (children: ReactNode) =>
    Children.map<ReactNode, ReactNode>(children, (child): ReactNode => {
      if (!isValidElement(child)) {
        return child;
      }

      if (child.props.children) {
        return cloneElement(child as ReactElement, {
          children: processChildren(child.props.children)
        });
      }

      if (child.type instanceof Function) {
        const { formField, ...filteredProps } = child.props;
        const filteredChild = { ...child, props: filteredProps };

        const name = child.props.name;
        const type = get(formProps?.types, name);

        // Hide form elements if not in schema
        if (
          (child.type === Input || child.type === Dropdown || child.type === Autocomplete || child.type === Checkbox || child.type === RadioGroup) &&
          formField !== false &&
          !type
        ) {
          return null;
        }

        const value = child.props.value || get(values, name, '');
        const label = child.props.label || get(formProps?.labels, name, '');
        const error = child.props.error || get(errors, name, '');
        const description = child.props.description || get(formProps?.descriptions, name, '');
        const placeholder = child.props.placeholder || get(formProps?.placeholders, name, '');
        const formatDescription = get(formProps.formatDescriptions, name, '') as string;

        const handleOnChange = (name: string, value: string | boolean) => {
          handleLastChangedFieldName(name);

          if (child.props.handleOnChange) {
            child.props.handleOnChange(name, value);
          } else {
            handleChanges(name, value);
          }
        };
        const handleError = (name: string, value: string) => {
          if (child.props.handleError) {
            child.props.handleError(name, value);
          } else {
            handleErrors(name, value);
          }
        };
        const handleOnMultipleChanges = (changes: IChange[]) =>
          changes.forEach(change => {
            handleOnChange(change.name, change.value);

            if (change.value) {
              handleError(change.name, '');
            }
          });
        const sharedProps = {
          error,
          label,
          handleError,
          handleOnChange,
          disabled: disabled || child.props.disabled
        };

        switch (child.type) {
          case Autocomplete:
          case Dropdown:
            return cloneElement(filteredChild, {
              ...filteredProps,
              ...sharedProps,
              value,
              placeholder,
              dataList: child.props.dataList || get(formProps?.enums, name, [])
            });
          case PhoneNumber:
            return cloneElement(filteredChild, {
              ...filteredProps,
              ...sharedProps,
              value,
              placeholder,
              onSubmitEditing: submitForm
            });
          case ActionButtons:
            return cloneElement(filteredChild, {
              ...filteredProps,
              onPrimaryActionClick: submitForm
            });
          case Checkbox:
            return cloneElement(filteredChild, {
              ...filteredProps,
              ...sharedProps,
              value: value.toString() === FIELD_VALUE_TRUE,
              description
            });
          case RadioGroup:
            return cloneElement(filteredChild, {
              ...filteredProps,
              ...sharedProps,
              dataList: get(
                formProps?.enums,
                name,
                child.props.dataList || [
                  {
                    label: t('common.yes'),
                    value: FIELD_VALUE_YES
                  },
                  {
                    label: t('common.no'),
                    value: FIELD_VALUE_NO
                  }
                ]
              ),
              value
            });
          case Input:
            return cloneElement(filteredChild, {
              ...filteredProps,
              ...sharedProps,
              tooltip:
                child.props.tooltip ??
                (formatDescription.length > 0 && (
                  <StyledTooltip>
                    {formatDescription.split('\n').map((text, i) => {
                      const isTitle = i === 0 && text.endsWith(':');
                      return (
                        <Typography key={`tooltip-${isTitle ? 'title' : `item-${i}`}`} variant={isTitle ? 'strongBody1' : 'body2'}>
                          {text}
                        </Typography>
                      );
                    })}
                  </StyledTooltip>
                )),
              value,
              placeholder,
              onSubmitEditing: submitForm
            });
          case GooglePlaceAutocomplete:
            return cloneElement(filteredChild, {
              ...filteredProps,
              ...sharedProps,
              handleOnMultipleChanges,
              value,
              placeholder
            });
          default:
            return child;
        }
      }

      return child;
    });

  return processChildren;
};

export default useForm;
