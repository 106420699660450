import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { AxiosResponse, AxiosError } from 'axios';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { QueryKey } from 'src/constants/queryKeys';
import { IGetBeneficiariesResponse } from 'src/interfaces/responses';

import { getBeneficiaries } from '../banking';

export const useBeneficiariesQuery = (
  entityUri: string,
  filterValue?: string,
  showSnackbar = false
): UseQueryResult<AxiosResponse<IGetBeneficiariesResponse, AxiosError>, { response: AxiosResponse<AxiosError> }> => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  return useQuery([QueryKey.QUERY_BENEFICIARIES, entityUri + filterValue], () => getBeneficiaries(entityUri, filterValue ?? ''), {
    onError: error => {
      if (showSnackbar) {
        enqueueSnackbar(error?.response?.data?.message ?? t('screens.error'), { variant: 'error' });
      }
      return error.response;
    },
    enabled: !!entityUri
  });
};
