import { FC } from 'react';

import { TooltipProps } from './Tooltip.interfaces';
import StyledTooltip from './Tooltip.styles';

const Tooltip: FC<TooltipProps> = ({ children, dark = false, ...restProps }) => (
  <StyledTooltip
    dark={dark}
    PopperProps={{
      disablePortal: true
    }}
    {...restProps}
  >
    {children}
  </StyledTooltip>
);

export default Tooltip;
