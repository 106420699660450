import React, { FC } from 'react';
import { AccountStatus, CurrencyType } from 'src/interfaces/banking';
import { formatAmount } from 'src/utils/localization';
import { getCurrencyImage } from 'src/utils/styles';

import { AccountsTabGridItemProps } from './AccountsTabGridItem.interfaces';
import {
  StyledAccountTile,
  StyledAccountflagTitleWrapper,
  StyledAccountNameAndCurrency,
  StyledAccountFlag,
  StyledAccountName,
  StyledAccountTileTopPart,
  StyledAccountCurrencyName,
  StyledAccountBalance,
  StyledAccountCurrency,
  StyledAccountTileBottomPart,
  StyledAccountBalanceExchanged
} from './AccountsTabGridItem.styles';

const AccountsTabGridItem: FC<AccountsTabGridItemProps> = ({ account, onClick }) => (
  <StyledAccountTile
    disabled={account.status === AccountStatus.Pending}
    withExchangedBalance={!!account.valuation.amount}
    key={account._links.self.uri}
    onClick={() => onClick(account)}
  >
    <StyledAccountTileTopPart>
      <StyledAccountflagTitleWrapper>
        <StyledAccountFlag src={getCurrencyImage(account.currency)} alt={`${account.currency} flag`} />
        <StyledAccountNameAndCurrency>
          <StyledAccountCurrencyName variant="strongBody1">{account.currencyLabel ?? account.currency}</StyledAccountCurrencyName>
          <StyledAccountName variant="body3">{account.metaData?.name}</StyledAccountName>
        </StyledAccountNameAndCurrency>
      </StyledAccountflagTitleWrapper>
    </StyledAccountTileTopPart>
    <StyledAccountTileBottomPart>
      <StyledAccountBalance>
        {`${formatAmount(account.balance, account.type === CurrencyType.Crypto ? 5 : undefined)} `}
        <StyledAccountCurrency as="span">{account.currency}</StyledAccountCurrency>
      </StyledAccountBalance>
      {account.valuation.amount && account.currency !== 'USD' && (
        <StyledAccountBalanceExchanged variant="strongBody3">
          {`${formatAmount(account.valuation.amount)} ${account.valuation.currency}`}
        </StyledAccountBalanceExchanged>
      )}
    </StyledAccountTileBottomPart>
  </StyledAccountTile>
);

export default AccountsTabGridItem;
