import { getUser } from 'src/api/user';
import { IGetUserResponse, IGetUserResponseActions, IResponseError } from 'src/interfaces/responses';
import { createSearchParams, NavigateOptions, URLSearchParamsInit, useNavigate } from 'react-router-dom';
import { KYCStatus } from 'src/interfaces/types';
import { StorageKey } from 'src/constants/storage';
import { urls } from 'src/routes';
import { getLegalEntities } from 'src/api/banking';
import { EntityStatus } from 'src/interfaces/banking';
import { GET_USER_OR_NAVIGATE_RETRY_COUNT, GET_USER_OR_NAVIGATE_RETRY_DELAY } from 'src/constants/api';
import { enqueueSnackbar } from 'notistack';
import { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { setDefaultTimezone } from 'src/utils/localization';

export const useNavigateSearch = () => {
  const navigate = useNavigate();
  return (pathname: string, params: URLSearchParamsInit, options?: NavigateOptions) =>
    navigate({ pathname, search: `?${createSearchParams(params)}` }, options);
};

export const useUserNavigation = (): {
  getUserOrNavigate: (
    redirect?: boolean,
    fallbackScreen?: string,
    retryCondition?: (user: IGetUserResponse) => boolean,
    retryCount?: number
  ) => Promise<IGetUserResponse | undefined>;
} => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const actionToScreenMap = {
    setPersonalInfo: urls.personalInformation,
    setAdditionalInfo: urls.additionalInformation,
    startIdentityVerification: urls.kyc
  };

  const getUserOrNavigate = async (
    redirect = true,
    fallbackScreen?: string,
    retryCondition?: (user: IGetUserResponse) => boolean,
    retryCount = GET_USER_OR_NAVIGATE_RETRY_COUNT
  ): Promise<IGetUserResponse | undefined> => {
    try {
      const response = await getUser();
      if (response) {
        setDefaultTimezone(response.data.preferences.timezone);
        if (retryCount > 0 && retryCondition && !retryCondition(response.data)) {
          return await new Promise(resolve => {
            setTimeout(() => resolve(getUserOrNavigate(redirect, fallbackScreen, retryCondition, retryCount - 1)), GET_USER_OR_NAVIGATE_RETRY_DELAY);
          });
        }

        if (fallbackScreen || redirect) {
          const { kycCheck, email, _links } = response.data;
          for (const [action, screen] of Object.entries(actionToScreenMap)) {
            if (response.data._actions[action as keyof IGetUserResponseActions]) {
              navigate(screen);
              return response.data;
            }
          }
          const kycStatus = kycCheck?.status;
          if (kycStatus === KYCStatus.Verified) {
            const { approved = 0, pending = 0 } = _links.legalEntities?.counts ?? {};
            const preferredUri = sessionStorage.getItem(StorageKey.PREFERRED_ENTITY_URI);
            if ((preferredUri && approved > 0) || (approved === 1 && pending === 0)) {
              let entity;
              const entities = await getLegalEntities(_links.self.uri);
              if (preferredUri) {
                sessionStorage.removeItem(StorageKey.PREFERRED_ENTITY_URI);
                entity = entities?.data.find(e => e._links.self.uri === preferredUri);
              }
              if (!entity) {
                entity = entities?.data.find(e => e.status === EntityStatus.Approved);
              }
              if (entity) {
                sessionStorage.setItem(StorageKey.SELECTED_ENTITY_URI, entity._links.self.uri);
                navigate(urls.balances);
              }
            } else if (approved === 0 && pending === 0) {
              navigate(urls.corporateAccount);
            } else {
              navigate(urls.entitySelection);
            }
          } else if (kycStatus === KYCStatus.Pending) {
            navigate(urls.kyc);
          } else if (kycStatus === KYCStatus.Rejected) {
            navigate(urls.profileRejected, {
              state: { email }
            });
          } else if (fallbackScreen) {
            navigate(fallbackScreen);
          }
        }
        return response.data;
      }
    } catch (error) {
      const { response } = error as AxiosError<IResponseError>;
      enqueueSnackbar(response?.data?.message ?? t('screens.error'), {
        variant: 'error'
      });
    }

    return undefined;
  };

  return {
    getUserOrNavigate
  };
};
