import { styled, Avatar, Typography, Grid } from '@mui/material';

export const StyledTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
  marginBottom: theme.spacing(5),
  display: 'block',
  width: '100%'
}));

export const StyledAvatar = styled(Avatar, {
  shouldForwardProp: prop => prop !== 'avatarColor'
})<{ avatarColor: string }>(({ theme, avatarColor }) => ({
  width: 56,
  height: 56,
  backgroundColor: avatarColor ?? theme.palette.custom.coralDusk[400],
  fontSize: 30,
  marginBottom: theme.spacing(4)
}));

export const StyledRow = styled(Grid)(({ theme }) => ({
  paddingBottom: theme.spacing(4),
  marginBottom: theme.spacing(4),
  borderBottom: `solid 1px ${theme.palette.custom.grey[50]}`
}));

export const StyledLeftItem = styled(Grid)({
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  flexWrap: 'wrap',

  '& > div': {
    marginBottom: 0,
    width: '100%'
  }
});

export const StyledRightItem = styled(Grid)({
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'flex-start',
  flexWrap: 'wrap',

  '& > div': {
    marginBottom: 0,
    width: '100%'
  }
});

export const StyledRowTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
  marginBottom: theme.spacing(0.25),
  display: 'block',
  width: '100%'
}));

export const StyledRowSubtitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.custom.grey[400],
  marginBottom: theme.spacing(5),
  display: 'block',
  width: '100%'
}));
