import { CircularProgress } from '@mui/material';
import { FC, PropsWithChildren } from 'react';
import useForm from 'src/components/Form/hooks/useForm';
import useFormData from 'src/components/Form/hooks/useFormData';
import { Alert } from 'src/components/Alert/Alert';
import { getFormNames } from 'src/utils/formData';
import { useTranslation } from 'react-i18next';

import { IFormProps } from './Form.interfaces';
import { StyledLoader } from './Form.styles';

const Form: FC<PropsWithChildren<IFormProps>> = props => {
  const { t } = useTranslation();

  const { schemaUrl, children, onSubmit, onChange, initialValues, disabled } = props;
  const names = getFormNames(children);
  const { values, errors, setValues, setErrors, formLoading, formProps, validateForm } = useFormData({
    names,
    schemaUrl,
    initialValues
  });
  const processChildren = useForm({
    names,
    values,
    errors,
    setErrors,
    setValues,
    formProps,
    onSubmit,
    validateForm,
    onChange,
    disabled
  });

  if (formLoading) {
    return (
      <StyledLoader>
        <CircularProgress />
      </StyledLoader>
    );
  }

  return Object.keys(formProps.types).length ? <>{processChildren(children)}</> : <Alert>{t('forms.schemaError')}</Alert>;
};

export default Form;
