import { Typography, styled } from '@mui/material';
import SvgIcon from 'src/components/SvgIcon/SvgIcon';

import { PrimaryTextSpanProps } from './PlacePrediction.interface';

export const StyledPlaceLocationIcon = styled(SvgIcon)(({ theme }) => ({
  marginRight: theme.spacing(1),
  width: '16px',
  height: '16px'
}));

export const StyledTextSpan = styled('span', {
  shouldForwardProp: prop => prop !== 'highlight'
})<PrimaryTextSpanProps>(({ theme, highlight }) => ({
  color: highlight ? theme.palette.custom.coralDusk[400] : theme.palette.text.secondary
}));

export const StyledSecondaryText = styled(Typography)(({ theme }) => ({
  display: 'inline',
  color: theme.palette.text.secondary
}));
