import { Box, styled, Typography } from '@mui/material';

export const StyledText = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(2)
}));

export const StyledQRWrapper = styled(Box)(({ theme }) => ({
  marginLeft: theme.spacing(-1),
  [theme.breakpoints.down('md')]: {
    margin: 'auto',
    width: 'fit-content'
  }
}));
