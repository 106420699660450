import { CssBaseline, ThemeProvider } from '@mui/material';
import { StrictMode, Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { SnackbarProvider } from 'notistack';
import Snackbar from 'src/components/Snackbar/Snackbar';
import Pusher from 'pusher-js';
import './i18n';

import App from './App';
import history from './routes/history';
import Loader from './components/Loader/Loader';
import theme from './constants/theme';
import CustomRouter from './components/CustomRouter/CustomRouter';
import { QueryProvider } from './components/QueryProvider/QueryProvider';
import './index.css';
import './components/Snackbar/Snackbar.css';

if (process.env.REACT_APP_PUSHER_LOGS_ENABLED === 'true') {
  Pusher.logToConsole = true;
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <StrictMode>
    <QueryProvider>
      <CustomRouter history={history}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Suspense fallback={<Loader />}>
            <SnackbarProvider
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right'
              }}
              autoHideDuration={10000}
              classes={{
                root: 'custom-root' // Root container for the notifications
              }}
              Components={{
                default: Snackbar,
                success: Snackbar,
                warning: Snackbar,
                info: Snackbar,
                error: Snackbar
              }}
              dense
              maxSnack={5}
              //preventDuplicate
            >
              <App />
            </SnackbarProvider>
          </Suspense>
        </ThemeProvider>
      </CustomRouter>
    </QueryProvider>
  </StrictMode>
);
