import { styled, Typography } from '@mui/material';
import SvgIcon from 'src/components/SvgIcon/SvgIcon';
import Loader from 'src/components/Loader/Loader';
import { Alert } from 'src/components/Alert/Alert';
import { StyledListItem } from 'src/components/StyledListItem/StyledListItem';

export const StyledBeneficiaryFieldLabel = styled(Typography, {
  shouldForwardProp: prop => prop !== 'useDefaultColor'
})<{ useDefaultColor?: boolean }>(({ theme, useDefaultColor }) => ({
  marginTop: theme.spacing(3),
  marginBottom: theme.spacing(1),
  color: useDefaultColor ? theme.palette.primary.main : theme.palette.custom.grey[300]
}));
export const StyledAddCircle = styled(SvgIcon)(({ theme }) => ({
  height: 32,
  width: 32,
  margin: theme.spacing(2, 0)
}));

export const StyledAddButton = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  cursor: 'pointer',
  padding: theme.spacing(0, 2),
  gap: theme.spacing(2),
  border: `1px solid ${theme.palette.custom.grey[100]}`,
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.background.default,
  '&:hover': {
    outline: `1px solid ${theme.palette.custom.coralDusk[400]}`,
    borderColor: theme.palette.custom.coralDusk[400]
  }
}));

export const StyledEntityText = styled(Typography)(({ theme }) => ({
  color: theme.palette.custom.coralDusk[400],
  textAlign: 'left',
  ...theme.typography.button,
  fontSize: '14px'
}));

export const StyledAlert = styled(Alert)(({ theme }) => ({
  marginTop: theme.spacing(3)
}));

export const StyledBeneficiaryWithPadding = styled(StyledListItem)(({ theme }) => ({
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(1),
  paddingLeft: theme.spacing(2),
  '&:hover': {
    background: theme.palette.custom.grey[50],
    borderRadius: theme.spacing(1),
    cursor: 'pointer'
  }
}));

export const StyledBeneficiaryIcon = styled(SvgIcon)(({ theme }) => ({
  fontSize: 48,
  margin: theme.spacing(5, 0, 1.5, 0)
}));

export const StyledNoBeneficiaryLabel = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(1)
}));

export const StyledLoader = styled(Loader)(({ theme }) => ({
  marginTop: theme.spacing(3)
}));
