import { Stack, Typography } from '@mui/material';
import { FC, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Account, Beneficiary } from 'src/interfaces/banking';
import { getCurrencyImage } from 'src/utils/styles';
import { formatBeneficiaryName } from 'src/utils/formatting';
import { CompanySettingsCreateBeneficiaryModal } from 'src/components/settings/modals/CompanySettingsCreateBeneficiaryModal/CompanySettingsCreateBeneficiaryModal';

import {
  StyledNoBeneficiaryLabel,
  StyledBeneficiaryWithPadding,
  StyledBeneficiaryIcon,
  StyledBeneficiaryFieldLabel
} from '../WithdrawSelectBenficiary/WithdrawSelectBenficiary.styles';
import { StyledInputAdornment, StyledSvgIcon, StyledBeneficiaryTextField } from '../Withdraw.styles';
import { OptionInterface } from '../Withdraw.utils';
import { AllBeneficiariesModal } from '../AllBeneficiariesModal/AllBeneficiariesModal';

export const BeneficiarySearchBlock: FC<{
  beneficiaryList: Beneficiary[];
  accounts: Account[];
  onOptionSelect: (option: OptionInterface) => void;
}> = ({ beneficiaryList, accounts, onOptionSelect }) => {
  const { t } = useTranslation();
  const [dataList, setDataList] = useState<OptionInterface[]>([]);
  const inputRef = useRef<string>('');
  const [searchResultList, setSearchResultList] = useState<OptionInterface[]>([]);

  const [beneficiaryToEdit, setBeneficiaryToEdit] = useState<Beneficiary | undefined>();

  const [isAllBeneficiariesModalOpened, setIsAllBeneficiariesModalOpened] = useState(false);

  useMemo(() => {
    const newDataList: OptionInterface[] = beneficiaryList.map(beneficiary => {
      const beneficiaryAccountUri = beneficiary._links?.account.uri;
      const acc = beneficiaryAccountUri ? accounts.find(acc => acc._links.self.uri === beneficiaryAccountUri) : null;
      return {
        imageUrl: getCurrencyImage(acc?.currency ?? ''),
        value: formatBeneficiaryName(beneficiary),
        label: `${acc?.currencyLabel} - ${beneficiary.iban || beneficiary.accountNumber || beneficiary.aba}`,
        key: String(beneficiary._id)
      };
    });
    setDataList(newDataList);
  }, [beneficiaryList, accounts]);

  const filterOptions = (inputValue: string): OptionInterface[] => {
    const filteredOptions = dataList.filter(
      o => o.value.toLowerCase().includes(inputValue.toLowerCase()) || o.label.toLowerCase().includes(inputValue.toLowerCase())
    );
    return filteredOptions;
  };

  const getListBlock = () => (
    <Stack>
      {inputRef.current ? (
        searchResultList.length ? (
          <>
            <StyledNoBeneficiaryLabel variant="body2">{t('banking.actions.withdraw.searchResults')}</StyledNoBeneficiaryLabel>
            {getBeneficiaryList(searchResultList)}
          </>
        ) : (
          <Stack alignItems="center" textAlign="center">
            <StyledBeneficiaryIcon icon="searchInCircle" />
            <StyledNoBeneficiaryLabel variant="strongBody1">{t('banking.actions.withdraw.noResultsFound')}</StyledNoBeneficiaryLabel>
            <Typography variant="body3">
              {t('banking.actions.withdraw.thereWasNoBeneficiary', {
                inputVal: inputRef.current
              })}
            </Typography>
          </Stack>
        )
      ) : beneficiaryList.length ? (
        <>
          <Stack direction="row" justifyContent="space-between">
            <StyledBeneficiaryFieldLabel variant="body2">{t('banking.actions.withdraw.recent')}</StyledBeneficiaryFieldLabel>
            <StyledBeneficiaryFieldLabel variant="body2" useDefaultColor onClick={() => setIsAllBeneficiariesModalOpened(true)}>
              {t('banking.actions.withdraw.viewAll')}
            </StyledBeneficiaryFieldLabel>{' '}
          </Stack>
          {getBeneficiaryList(dataList)}
        </>
      ) : (
        <Stack alignItems="center" textAlign="center">
          <StyledBeneficiaryIcon icon="beneficiary" />
          <StyledNoBeneficiaryLabel variant="strongBody1">{t('banking.actions.withdraw.noBeneficiariesYet')}</StyledNoBeneficiaryLabel>
          <Typography variant="body3">{t('banking.actions.withdraw.thereAreNoBeneficiariesYet')}</Typography>
        </Stack>
      )}
    </Stack>
  );

  const getBeneficiaryList = (benefList: OptionInterface[]) => (
    <>
      {benefList &&
        benefList.map((beneficiary: OptionInterface) => (
          <StyledBeneficiaryWithPadding
            option={beneficiary}
            filterValue={inputRef.current}
            key={beneficiary.key}
            onClickOption={() => onOptionSelect(beneficiary)}
          />
        ))}
    </>
  );

  return (
    <>
      <StyledBeneficiaryFieldLabel variant="body2">{t('banking.actions.withdraw.recentlyUsedBeneficiaries')}</StyledBeneficiaryFieldLabel>
      <StyledBeneficiaryTextField
        disabled={!beneficiaryList.length}
        placeholder={t('banking.actions.withdraw.beneficiarySearchPlaceholder')}
        InputProps={{
          startAdornment: (
            <StyledInputAdornment position="start">
              <StyledSvgIcon icon="search" />
            </StyledInputAdornment>
          )
        }}
        onChange={el => {
          inputRef.current = el.target.value;
          setSearchResultList(filterOptions(el.target.value));
        }}
      />
      {getListBlock()}
      <AllBeneficiariesModal
        accounts={accounts}
        isOpen={!!isAllBeneficiariesModalOpened}
        onClose={() => setIsAllBeneficiariesModalOpened(false)}
        editBeneficiary={beneficiary => {
          setBeneficiaryToEdit(beneficiary);
        }}
      />
      {Boolean(beneficiaryToEdit) && (
        <CompanySettingsCreateBeneficiaryModal beneficiary={beneficiaryToEdit} isOpen onClose={() => setBeneficiaryToEdit(undefined)} type="edit" />
      )}
    </>
  );
};
