import { FC } from 'react';

import { ToggleIconProps } from './ToggleIcon.interfaces';
import { StyledToggleIcon } from './ToggleIcon.styles';

const ToggleIcon: FC<ToggleIconProps> = ({ rotate, onClick, useWhite = false }) => (
  <StyledToggleIcon onClick={onClick} rotate={rotate} icon={useWhite ? 'toggleBarWhite' : 'toggleBar'} />
);

export default ToggleIcon;
