import { styled, Typography } from '@mui/material';
import Button from 'src/components/Button/Button';

import { StyledInfoBlocksProps } from './GetStarted.interfaces';

export const StyledGetStarted = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(6),
  maxWidth: '1014px'
}));

export const StyledHeadingSection = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center'
});

export const StyledHeading = styled(Typography)({
  fontWeight: 600,
  letterSpacing: '-0.02em'
});

export const StyledCloseButton = styled(Button)(({ theme }) => ({
  width: '28px',
  height: '28px',
  background: theme.palette.common.white
}));

export const StyledInfoBlocks = styled('div', {
  shouldForwardProp: prop => prop !== 'fullWidth'
})<StyledInfoBlocksProps>(({ theme, fullWidth }) => ({
  marginTop: theme.spacing(3),
  display: fullWidth ? 'block' : 'flex',
  flexWrap: 'wrap',
  gap: theme.spacing(3)
}));
