import { Link, styled, Typography } from '@mui/material';

export const StyledPasswordContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(3),
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    gap: 0
  }
}));

export const StyledInfoText = styled(Typography)(({ theme }) => ({
  textAlign: 'center',
  color: theme.palette.text.secondary,
  marginTop: theme.spacing(2)
}));

export const StyledLink = styled(Link)(({ theme }) => ({
  color: theme.palette.primary.main,
  textDecoration: 'underline',

  '&:hover': {
    textDecoration: 'none'
  }
}));
