import { styled, Typography } from '@mui/material';

export const StyledContent = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  textAlign: 'center',
  alignItems: 'center',
  marginTop: theme.spacing(1)
}));

export const StyledTitle = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(5),
  marginBottom: theme.spacing(1)
}));

export const StyledReviewIcon = styled('div', {
  shouldForwardProp: prop => prop !== 'error'
})(({ theme }) => ({
  width: 80,
  height: 80,
  borderRadius: 40,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: theme.palette.custom.gold[100],
  boxShadow: theme.shadows[6]
}));
