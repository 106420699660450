/* eslint-disable @typescript-eslint/no-explicit-any */
import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import { Method } from 'src/interfaces/types';
import history from 'src/routes/history';
import i18next from 'src/i18n';
import { urls } from 'src/routes';
import { getAuthorizationHeader, getSignature, logout } from 'src/utils/authentication';
import { CUSTOM_HEADERS } from 'src/constants/headers';

const axiosInstance: AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL
});

axiosInstance.interceptors.request.use((config: AxiosRequestConfig) => {
  if (config && config.headers) {
    config.headers['Accept-Language'] = i18next.language;

    const timestamp = Date.now();
    config.headers[CUSTOM_HEADERS.XTIMESTAMP] = timestamp;
    config.headers[CUSTOM_HEADERS.XSIGNATURE] = getSignature(timestamp, config.method ?? '', config.url ?? '');

    const authorizationHeader = getAuthorizationHeader();

    if (authorizationHeader) {
      config.headers.Authorization = authorizationHeader;
    }
  }

  return config;
});

axiosInstance.interceptors.response.use(
  (response: AxiosResponse) => response,
  (reject: AxiosError) => {
    if (reject.response?.status === 401 && history.location.pathname !== urls.signin) {
      logout();
      history.push(urls.signin);
    }

    return Promise.reject(reject);
  }
);

const makeRequest = (method: Method, url: string, ...params: any) => axiosInstance[method](url, ...params);

const request =
  (method: Method, url: string) =>
  (...params: any) =>
    makeRequest(method, url, ...params);

export default request;
