import { FC, useCallback, useRef, useState } from 'react';
import ActionButtons from 'src/components/ActionButtons/ActionButtons';
import Form from 'src/components/Form/Form';
import Input from 'src/components/Input/Input';
import Autocomplete from 'src/components/Autocomplete/Autocomplete';
import FormElement from 'src/components/FormElement/FormElement';
import { IFormHandleSubmit, IFormValues, IOnFormChangeProps } from 'src/interfaces/forms';
import { useTranslation } from 'react-i18next';
import { Beneficiary, CurrencyType } from 'src/interfaces/banking';
import {
  StyledToggleButton,
  StyledToggleButtonGroup
} from 'src/components/banking/actions/withdraw/WithdrawBenficiaryDetails/WithdrawBenficiaryDetails.styles';
import { Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { bankLookup } from 'src/api/banking';
import { IdentifierType } from 'src/constants/banking';
import IBAN from 'iban';
import { IError } from 'src/interfaces/global';
import { cryptoPaymentOrderSchemaUrl } from 'src/api/formSchemas';
import cloneDeep from 'lodash/fp/cloneDeep';

import { BENEFICIARY_FORM_TYPE } from './utils';

const clearRedundantData = (beneficiary: Beneficiary, beneficiaryType: string) => {
  const newBeneficiary = cloneDeep(beneficiary);

  switch (beneficiaryType) {
    case BENEFICIARY_FORM_TYPE.INDIVIDUAL:
      newBeneficiary.companyName = '';
      break;
    case BENEFICIARY_FORM_TYPE.BUSINESS:
      newBeneficiary.firstName = '';
      newBeneficiary.lastName = '';
      break;
  }

  return newBeneficiary;
};

const getCountryName = (countryName: string, countriesList: { [key: string]: string }) => countriesList[countryName] ?? '';

export const StepTwo: FC<{
  beneficiary: Beneficiary | undefined;
  currencyType: CurrencyType;
  onBack: (beneficiary?: Beneficiary) => void;
  onContinue: (beneficiary: Beneficiary) => void;
  allowedCountriesData: { [key: string]: string };
}> = ({ beneficiary, onBack, currencyType = CurrencyType.Fiat, onContinue, allowedCountriesData }) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [beneficiaryBusinessType, _setBeneficiaryBusinessType] = useState<string>(
    beneficiary?.firstName ? BENEFICIARY_FORM_TYPE.INDIVIDUAL : BENEFICIARY_FORM_TYPE.BUSINESS
  );
  const setBeneficiaryBusinessType = useCallback(
    (str: string) => {
      _setBeneficiaryBusinessType(str);

      if (!formValuesRef.current || !beneficiary) {
        return;
      }

      switch (str) {
        case BENEFICIARY_FORM_TYPE.INDIVIDUAL:
          delete beneficiary.firstName;
          delete beneficiary.lastName;
          break;
        case BENEFICIARY_FORM_TYPE.BUSINESS:
          delete beneficiary.companyName;
          break;
      }
    },
    [_setBeneficiaryBusinessType, beneficiary]
  );
  const beneficiarySchemaFiat = `${beneficiary?._links?.account.uri}/payment-order?bankCountry=${beneficiary?.bank?.country ?? ''}&isBusiness=${
    beneficiaryBusinessType === BENEFICIARY_FORM_TYPE.BUSINESS
  }${beneficiary?.network ? `&network=${beneficiary?.network}` : ''}`;
  const beneficiarySchemaCrypto = cryptoPaymentOrderSchemaUrl(beneficiary?._links?.account.uri ?? '');
  const formValuesRef = useRef<Beneficiary>();

  const handleSubmit: IFormHandleSubmit = async (values, validateForm, handleError) => {
    if (validateForm(values)) {
      const beneficiary = clearRedundantData(values.beneficiary as unknown as Beneficiary, beneficiaryBusinessType);
      const beneficiaryIban = beneficiary?.iban ?? '';
      if (beneficiary.hasOwnProperty('iban') && !IBAN.isValid(beneficiaryIban)) {
        handleError('beneficiary.iban', t('banking.actions.withdraw.beneficiary.invalidIban'));
        return;
      }
      const identifier = beneficiary?.bicSwiftCode || beneficiary?.routingNumber;
      if (identifier) {
        const isBicSwift = beneficiary?.bicSwiftCode;
        bankLookup(identifier, isBicSwift ? IdentifierType.BicSwift : IdentifierType.RoutingNumber)
          .then(response => {
            if (beneficiary.bank?.country === response.data.country) {
              onContinue({ ...beneficiary, bank: response.data });
            } else {
              handleError(
                isBicSwift ? 'beneficiary.bicSwiftCode' : 'beneficiary.routingNumber',
                `The country of the bank you chose, ${getCountryName(
                  beneficiary.bank?.country ?? '',
                  allowedCountriesData
                )}, does not match the country, ${getCountryName(response.data.country, allowedCountriesData)}, associated with the ${
                  isBicSwift ? 'BIC Swift Code' : 'Routing number'
                } code or routing number you provided.
                `
              );
            }
          })
          .catch(error => {
            if (error?.response?.data?.errors?.find((e: IError) => ['invalid_aba', 'invalid_bic'].includes(e.code))) {
              handleError(isBicSwift ? 'beneficiary.bicSwiftCode' : 'beneficiary.routingNumber', t('formValidation.invalid'));
            } else {
              enqueueSnackbar(t('screens.error'), { variant: 'error' });
            }
          });
      } else {
        onContinue(beneficiary);
      }
    }
  };

  const onChange = (val: IOnFormChangeProps) => {
    const beneficiary = val.values?.beneficiary as unknown as Beneficiary;
    if (beneficiary) {
      formValuesRef.current = beneficiary;
    }
  };

  const onBackAction = () => {
    onBack(formValuesRef.current);
  };

  return (
    <>
      <StyledToggleButtonGroup value={beneficiaryBusinessType} exclusive>
        <StyledToggleButton value={BENEFICIARY_FORM_TYPE.BUSINESS} onClick={() => setBeneficiaryBusinessType(BENEFICIARY_FORM_TYPE.BUSINESS)}>
          <Typography variant="strongBody3">{t('banking.actions.withdraw.business')}</Typography>
        </StyledToggleButton>
        <StyledToggleButton value={BENEFICIARY_FORM_TYPE.INDIVIDUAL} onClick={() => setBeneficiaryBusinessType(BENEFICIARY_FORM_TYPE.INDIVIDUAL)}>
          <Typography variant="strongBody3">{t('banking.actions.withdraw.individual')}</Typography>
        </StyledToggleButton>
      </StyledToggleButtonGroup>
      {currencyType === CurrencyType.Fiat ? (
        <Form
          schemaUrl={beneficiarySchemaFiat}
          onSubmit={(values, validateForm, handleError) => {
            handleSubmit(values, validateForm, handleError);
          }}
          initialValues={{
            beneficiary: beneficiary as unknown as IFormValues
          }}
          onChange={onChange}
        >
          <Input name="beneficiary.companyName" />
          <Input name="beneficiary.firstName" />
          <Input name="beneficiary.lastName" />

          <Input name="beneficiary.iban" inputProps={{ sx: { textTransform: 'uppercase' } }} />
          <Input name="beneficiary.accountNumber" />
          <Input name="beneficiary.aba" />
          <Input name="beneficiary.routingNumber" />
          <Input name="beneficiary.bicSwiftCode" />

          <FormElement label={t('banking.actions.withdraw.beneficiary.address')}>
            <Autocomplete name="beneficiary.address.country" showOptionValue />
            <Input name="beneficiary.address.street" />
            <Input name="beneficiary.address.postalCode" />
            <Input name="beneficiary.address.city" />
            <Input name="beneficiary.address.state" />
          </FormElement>

          <ActionButtons primaryActionText={t('common.continue')} secondaryActionText={t('common.goBack')} onSecondaryActionClick={onBackAction} />
        </Form>
      ) : (
        <Form
          schemaUrl={beneficiarySchemaCrypto}
          initialValues={{ beneficiary: beneficiary as unknown as IFormValues }}
          onSubmit={handleSubmit}
          onChange={onChange}
        >
          <Input name="beneficiary.companyName" />
          <Input name="beneficiary.firstName" />
          <Input name="beneficiary.lastName" />
          <Input name="beneficiary.walletAddress" multiline maxRows={2} />
          <Input name="vendorNote" multiline maxRows={4} />
          <Input name="personalNote" multiline maxRows={2} />

          <ActionButtons primaryActionText={t('common.continue')} secondaryActionText={t('common.goBack')} onSecondaryActionClick={onBackAction} />
        </Form>
      )}
    </>
  );
};
