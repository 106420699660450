import { Typography, Box } from '@mui/material';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Loader from 'src/components/Loader/Loader';
import { ButtonVariant } from 'src/constants/variants';
import { CompanySettingsRemoveBeneficiaryModal } from 'src/components/settings/modals/CompanySettingsRemoveBeneficiaryModal/CompanySettingsRemoveBeneficiaryModal';
import { Account, Beneficiary, CurrencyType } from 'src/interfaces/banking';
import { CompanySettingsCreateBeneficiaryModal } from 'src/components/settings//modals/CompanySettingsCreateBeneficiaryModal/CompanySettingsCreateBeneficiaryModal';
import { StorageKey } from 'src/constants/storage';
import { useLegalEntityQuery } from 'src/api/queries/useLegalEntities';
import SvgIcon from 'src/components/SvgIcon/SvgIcon';
import { useAccountsQuery } from 'src/api/queries/useAccounts';

import CompanySettingsBeneficiariesList from '../CompanySettingsBeneficiariesList/CompanySettingsBeneficiariesList';

import { StyledAddButton, StyledPageDescription, StyledPageHeader } from './CompanySettingsBeneficiaries.styles';

export const CompanySettingsBeneficiaries: FC = () => {
  const legalEntityUri = sessionStorage.getItem(StorageKey.SELECTED_ENTITY_URI) ?? '';
  const { t } = useTranslation();
  const [selectedBeneficiary, setSelectedBeneficiary] = useState<Beneficiary>();
  const [isCreateBeneficiaryModalOpened, setIsCreateBeneficiaryModalOpened] = useState<boolean>();
  const [isRemoveBeneficiaryModalOpened, setIsRemoveBeneficiaryModalOpened] = useState<boolean>(false);

  const [existingBeneficiariesMenuAnchorEl, setExistingBeneficiariesMenuAnchorEl] = useState<null | HTMLElement>(null);

  const { data: entityData, isLoading: isLoadingLegalEntity } = useLegalEntityQuery(legalEntityUri);

  const { data: accountsData } = useAccountsQuery();
  const accounts: Account[] = accountsData?.data.filter(account => !!account._actions?.send && account.type === CurrencyType.Fiat) ?? [];

  const existingBeneficiariesMenuOpen = Boolean(existingBeneficiariesMenuAnchorEl);

  const handleCreateBeneficiaryModalOpen = (beneficiaryToUpdate?: Beneficiary) => {
    setIsCreateBeneficiaryModalOpened(true);
    setSelectedBeneficiary(beneficiaryToUpdate);
    setExistingBeneficiariesMenuAnchorEl(null);
  };

  const handleRemoveBeneficiaryModalOpen = (beneficiary: Beneficiary) => {
    setSelectedBeneficiary(beneficiary);
    setIsRemoveBeneficiaryModalOpened(true);
    setExistingBeneficiariesMenuAnchorEl(null);
  };

  return (
    <>
      <Typography variant="strongBody1">{t('companySettings.beneficiaries.title')}</Typography>
      <StyledPageHeader>
        <Box>
          <Typography variant="subtitle3">{t('companySettings.beneficiaries.subtitle')}</Typography>
          <StyledPageDescription variant="body3">{t('companySettings.beneficiaries.description')}</StyledPageDescription>
        </Box>
        <StyledAddButton icon={<SvgIcon icon="addCircle" />} variant={ButtonVariant.Secondary} onClick={() => handleCreateBeneficiaryModalOpen()}>
          {t('companySettings.beneficiaries.actionButton')}
        </StyledAddButton>
      </StyledPageHeader>

      {isLoadingLegalEntity ? (
        <Loader anchorToRelative />
      ) : (
        <CompanySettingsBeneficiariesList
          accounts={accounts}
          entityData={entityData?.data ?? undefined}
          menuAnchorEl={existingBeneficiariesMenuAnchorEl}
          setMenuAnchorEl={setExistingBeneficiariesMenuAnchorEl}
          menuOpen={existingBeneficiariesMenuOpen}
          menuItems={beneficiary => [
            {
              title: t('common.edit'),
              onClick: () => handleCreateBeneficiaryModalOpen(beneficiary)
            },
            {
              title: t('common.remove'),
              onClick: () => handleRemoveBeneficiaryModalOpen(beneficiary)
            }
          ]}
          onMenuClose={() => setExistingBeneficiariesMenuAnchorEl(null)}
        />
      )}

      {isCreateBeneficiaryModalOpened && (
        <CompanySettingsCreateBeneficiaryModal
          beneficiary={selectedBeneficiary}
          isOpen
          onClose={() => setIsCreateBeneficiaryModalOpened(false)}
          type={selectedBeneficiary ? 'edit' : 'create'}
        />
      )}
      {isRemoveBeneficiaryModalOpened && selectedBeneficiary && entityData && (
        <CompanySettingsRemoveBeneficiaryModal
          legalEntity={entityData.data}
          beneficiary={selectedBeneficiary}
          isOpen
          onClose={() => setIsRemoveBeneficiaryModalOpened(false)}
        />
      )}
    </>
  );
};
