import { Box, TextField, Typography, styled } from '@mui/material';

export const StyledBox = styled('div')(({ theme }) => ({
  background: theme.palette.custom.grey[50],
  borderRadius: 12,
  padding: theme.spacing(3)
}));

export const StyledLabel = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(0.5),
  color: theme.palette.custom.grey[400]
}));

export const StyledForm = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1)
}));

export const StyledCurrencyMenu = styled('button', {
  shouldForwardProp: prop => prop !== 'disabled'
})<{ disabled: boolean }>(({ theme, disabled }) => ({
  zIndex: 1,
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: theme.spacing(0.5),
  opacity: disabled ? 0.5 : 1,
  cursor: disabled ? 'auto' : 'pointer',
  border: 0,
  padding: 0,
  margin: 0,

  '.MuiSvgIcon-root': {
    fontSize: '16px'
  }
}));

export const StyledCurrency = styled(Typography)(({ theme }) => ({
  fontWeight: 700,
  fontSize: 24,
  lineHeight: 1.333,
  letterSpacing: '-0.02em',
  color: theme.palette.text.primary
}));

export const StyledInput = styled(TextField)(({ theme }) => ({
  '& .MuiInputBase-root': {
    '& .MuiInputBase-input': {
      border: 'none',
      padding: theme.spacing(1, 0),
      fontSize: 24,
      fontWeight: 700,
      lineHeight: 32,
      textAlign: 'right'
    }
  },

  '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
    display: 'none'
  },
  '& input[type=number]': {
    MozAppearance: 'textfield'
  }
}));

export const StyledBalance = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(1),
  color: theme.palette.custom.grey[300],
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between'
}));

export const StyledListItem = styled('div')<{ isCaption?: boolean }>(({ theme, isCaption }) =>
  isCaption
    ? {
        color: theme.palette.custom.grey[300],
        margin: theme.spacing(1, 2),
        userSelect: 'none',
        ...theme.typography.body3
      }
    : {
        span: theme.typography.strongBody2,
        'span + span': {
          color: theme.palette.custom.grey[300],
          ...theme.typography.body2
        }
      }
);
