import { styled } from '@mui/material';
import Tabs from 'src/components/banking/Tabs/Tabs';
import Button from 'src/components/Button/Button';

export const StyledPageHeader = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(6.75),
  marginBottom: theme.spacing(3),
  display: 'flex',
  justifyContent: 'space-between'
}));

export const StyledInviteButton = styled(Button)(({ theme }) => ({
  '&.MuiButton-outlinedPrimary': {
    color: theme.palette.custom.coralDusk[400],
    padding: theme.spacing(1.75, 2)
  },
  '.MuiSvgIcon-root': {
    width: '16px',
    height: '16px'
  }
}));

export const StyledTabs = styled(Tabs)(({ theme }) => ({
  marginBottom: theme.spacing(2)
}));
