import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { useUserNavigation } from 'src/hooks/navigation';
import { IGetUserResponse } from 'src/interfaces/responses';
import { QueryKey } from 'src/constants/queryKeys';
import { useSnackbar } from 'notistack';
import { AxiosError, AxiosResponse } from 'axios';
import { useTranslation } from 'react-i18next';

export const useUserOrNavigateQuery = (
  redirect = false,
  showSnackbar = false
): UseQueryResult<IGetUserResponse | undefined, { response: AxiosResponse<AxiosError> }> => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { getUserOrNavigate } = useUserNavigation();

  return useQuery([QueryKey.QUERY_GET_USER_OR_NAVIGATE], () => getUserOrNavigate(redirect), {
    onError: error => {
      if (showSnackbar) {
        enqueueSnackbar(error?.response?.data?.message ?? t('screens.error'), { variant: 'error' });
      }
      return error.response;
    }
  });
};
