export type GoogleLibraries = 'places' | 'drawing' | 'geometry' | 'localContext' | 'visualization';

export enum FormAddressFieldsNames {
  Street = 'postalAddress.street',
  StreetNumber = 'postalAddress.houseNo',
  AdditionalDetails = 'postalAddress.addition',
  City = 'postalAddress.city',
  PostalCode = 'postalAddress.postalCode',
  State = 'postalAddress.state'
}

export type GooglePlacePredictions = google.maps.places.AutocompleteResponse | void;
export type GeocoderPlaceResult = google.maps.GeocoderResponse | void;
export type GeocoderAddress = google.maps.GeocoderAddressComponent | undefined;
