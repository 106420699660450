import { IFormErrors } from 'src/interfaces/forms';
import { RequiredError } from 'ajv/dist/vocabularies/validation/required';
import set from 'lodash.set';
import { DefinedError } from 'ajv';
import { LimitNumberError } from 'ajv/dist/vocabularies/validation/limitNumber';
import { PatternError } from 'ajv/dist/vocabularies/validation/pattern';
import { REGEX_ONLY_NUMBERS } from 'src/constants/regexes';

import i18n from '../i18n';

export const handleRequiredError = (errObj: IFormErrors, err: RequiredError) => {
  const propName = err.params.missingProperty;
  const fieldPath = err.instancePath ? [...err.instancePath.split('/').filter(p => !!p), propName].join('.') : propName;

  set(errObj, fieldPath, i18n.t('formValidation.required'));
};

export const handleLimitError = (errObj: IFormErrors, err: DefinedError, errMessage: string) => {
  const limit = (err as LimitNumberError).params.limit;

  handleGenericError(errObj, err, `${errMessage} ${limit}`);
};

export const handlePatternError = (errObj: IFormErrors, err: PatternError) => {
  let errMessage = '';

  switch (err.params.pattern) {
    case REGEX_ONLY_NUMBERS.source:
      errMessage = i18n.t('formValidation.onlyNumbers');
      break;
    default:
      errMessage = i18n.t('formValidation.invalid');
      break;
  }

  handleGenericError(errObj, err, errMessage);
};

export const handleGenericError = (errObj: IFormErrors, err: DefinedError, errMessage = '') => {
  if (err.instancePath) {
    const slicedPath = err.instancePath.split('/');
    const propertyPath = slicedPath.filter(path => path !== '/' && path !== '').join('.');

    set(errObj, propertyPath, errMessage === '' ? `${err.parentSchema?.title} ${err.message}` : errMessage);
  }
};
