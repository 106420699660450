import { styled, Typography } from '@mui/material';

export const StyledRow = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: 24,
  [theme.breakpoints.down('sm')]: {
    gap: 12
  }
}));

export const StyledRowStreetAndHouseNo = styled(StyledRow)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    gap: 0
  }
}));

export const StyledLabel = styled(Typography)(({ theme }) => ({
  color: theme.palette.custom.grey[400],
  marginBottom: 24
}));

export const StyledInputWrapperStreet = styled('div')(({ theme }) => ({
  minWidth: 320,
  [theme.breakpoints.down('sm')]: {
    minWidth: '100%'
  }
}));

export const StyledInputWrapperHouseNo = styled('div')(({ theme }) => ({
  '& .MuiTextField-root': {
    width: 120,
    minWidth: 120,
    [theme.breakpoints.down('sm')]: {
      minWidth: '100%'
    }
  }
}));
