import { Typography } from '@mui/material';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ActionTab from 'src/components/banking/actions/ActionTab/ActionTab';
import CopyItem from 'src/components/banking/CopyItem/CopyItem';
import { formatAmount, formatDate } from 'src/utils/localization';
import { getCurrencyImage } from 'src/utils/styles';
import { WithdrawCryptoContext } from 'src/contexts/actions/Withdraw.contexts';
import { ActionBarContext } from 'src/contexts/ActionBar.contexts';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { submitCryptoPaymentOrder } from 'src/api/banking';
import { QueryKey } from 'src/constants/queryKeys';
import { AxiosError } from 'axios';
import { IResponseError } from 'src/interfaces/responses';
import { enqueueSnackbar } from 'notistack';
import { usePaymentRequestQuery } from 'src/api/queries/usePaymentRequest';
import { getIdFromUri } from 'src/utils/strings';
import { CurrencyType } from 'src/interfaces/banking';

import WithdrawConfirmation from '../WithdrawConfirmation/WithdrawConfirmation';
import { WithdrawCryptoDetailsScreens } from '../Withdraw.interfaces';
import { withdrawActionTabDefaultValues } from '../Withdraw.utils';
import { WithdrawCrypto } from '../WithdrawCrypto/WithdrawCrypto';

import { StyledCurrencyFlag, StyledDetails } from './WithdrawCryptoDetails.styles';

const WithdrawCryptoDetails = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const actionBarContext = useContext(ActionBarContext);
  const { account, amount = '0', paymentDetails = {}, beneficiaryDetails = {} } = useContext(WithdrawCryptoContext) ?? {};

  const [waitingForApproval, setWaitingForApproval] = useState(false);
  const [nextScreen, setNextScreen] = useState<WithdrawCryptoDetailsScreens>();

  const accountUri = account?._links.self.uri ?? '';
  const { data: paymentRequest, isLoading: isLoadingPaymentRequest } = usePaymentRequestQuery(getIdFromUri(accountUri), amount);

  const submitOrderMutation = useMutation(() => submitCryptoPaymentOrder(accountUri, amount, beneficiaryDetails, paymentDetails), {
    onSuccess: () => {
      setWaitingForApproval(false);
      setNextScreen('withdrawConfirmation');
    },
    onError: (error: AxiosError<IResponseError>) => {
      const message = error?.response?.data?.message;
      enqueueSnackbar(message ?? t('screens.error'), { variant: 'error' });
    },
    onSettled: () => queryClient.invalidateQueries([QueryKey.QUERY_ORDERS])
  });

  const renderItem = (key: string, value: string, multiline = false, currency?: string) => (
    <CopyItem
      multiline={multiline}
      label={t(`banking.actions.withdraw.details.${key}`)}
      value={value}
      valueDecoration={currency ? <StyledCurrencyFlag src={getCurrencyImage(currency)} alt={`${currency} flag`} /> : undefined}
      disableCopy
    />
  );

  const flowScreens = {
    cryptoWire: <WithdrawCrypto />,
    withdrawConfirmation: <WithdrawConfirmation waitingForApproval={waitingForApproval} />
  };

  return nextScreen ? (
    flowScreens[nextScreen]
  ) : actionBarContext ? (
    <ActionTab
      activeStepIndex={4}
      loading={submitOrderMutation.isLoading}
      primaryActionTextKey="common.confirm"
      onPrimaryActionClick={submitOrderMutation.mutate}
      onClose={actionBarContext.closeActionTab}
      onGoBack={() => setNextScreen('cryptoWire')}
      {...withdrawActionTabDefaultValues}
    >
      <Typography variant="strongBody1">{t('banking.actions.withdraw.details.title')}</Typography>
      <StyledDetails>
        {amount && account && renderItem('amount', `${formatAmount(amount, 5)} ${account.currency}`, false, account.currency)}
        {beneficiaryDetails?.walletAddress && renderItem('beneficiary', beneficiaryDetails.walletAddress, true)}
        {renderItem('vendorNote', paymentDetails?.vendorNote ?? t('common.notAvailable'), true)}
        {renderItem('personalNote', paymentDetails?.personalNote ?? t('common.notAvailable'), true)}
        {!isLoadingPaymentRequest &&
          renderItem(
            'settlement',
            paymentRequest?.data ? formatDate(paymentRequest.data.executionDate, 'lll') : t('banking.actions.swap.details.missingSettlement')
          )}
      </StyledDetails>

      {paymentRequest?.data?.estimatedFee && (
        <StyledDetails>
          {renderItem(
            'estimatedFee',
            `${formatAmount(paymentRequest.data.estimatedFee, account?.type === CurrencyType.Fiat ? 2 : 5)} ${account?.currency}`
          )}
        </StyledDetails>
      )}
    </ActionTab>
  ) : null;
};

export default WithdrawCryptoDetails;
