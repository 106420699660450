import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useUserOrNavigateQuery } from 'src/api/queries/useUserOrNavigate';
import { useLegalEntitiesQuery } from 'src/api/queries/useLegalEntities';
import { getHelpMailto } from 'src/utils/mailing';
import SvgIcon from 'src/components/SvgIcon/SvgIcon';
import { Link } from '@mui/material';

const GetHelpLink: FC = () => {
  const { t } = useTranslation();

  const { data: user } = useUserOrNavigateQuery();
  const { data: legalEntities } = useLegalEntitiesQuery(user?._links.self?.uri ?? '');

  return (
    <>
      <SvgIcon icon="help" />
      <Link
        href={getHelpMailto(user, legalEntities?.data)}
        sx={{
          color: 'common.white'
        }}
      >
        {t('banking.navigation.getHelp')}
      </Link>
    </>
  );
};

export default GetHelpLink;
