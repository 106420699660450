import { alpha, Stack, styled } from '@mui/material';

import { StyledStepProps } from './MobileStepper.interfaces';

export const StyledStep = styled('div', {
  shouldForwardProp: prop => prop !== 'fillColor'
})<StyledStepProps>(({ theme, fillColor }) => ({
  borderColor: theme.palette.custom.coralDusk[400],
  background: fillColor ? theme.palette.custom.coralDusk[400] : alpha(theme.palette.grey[300], 0.25),
  height: 4,
  flex: '1 1 0',
  width: 0,
  borderRadius: 100
}));

export const StyledStepperWrapper = styled(Stack)(({ theme }) => ({
  width: '100%',
  background: 'transparent',
  justifyContent: 'space-between',
  gap: '8px',
  margin: theme.spacing(2)
}));
