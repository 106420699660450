import { styled, TextField, Typography } from '@mui/material';
import Button from 'src/components/Button/Button';
import { getBackgroundColorVariantForTransactionType } from 'src/utils/styles';
import { TransactionType } from 'src/interfaces/banking';
import SvgIcon from 'src/components/SvgIcon/SvgIcon';
import { Alert } from 'src/components/Alert/Alert';

import { StyledButtonProps } from './SwapSelection.interfaces';

export const StyledAlert = styled(Alert)(({ theme }) => ({
  marginBottom: theme.spacing(2)
}));

export const StyledBox = styled('div')(({ theme }) => ({
  background: theme.palette.custom.grey[50],
  borderRadius: '16px'
}));

export const StyledFrom = styled('div')(({ theme }) => ({
  position: 'relative',
  padding: theme.spacing(3)
}));

export const StyledInput = styled(TextField)(({ theme }) => ({
  '& .MuiInputBase-root': {
    '& .MuiInputBase-input': {
      border: 'none',
      padding: theme.spacing(1, 0),
      fontSize: 24,
      fontWeight: 700,
      lineHeight: 32
    }
  },

  '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
    display: 'none'
  },
  '& input[type=number]': {
    MozAppearance: 'textfield'
  }
}));

export const StyledSwitch = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  marginTop: theme.spacing(2)
}));

export const StyledButton = styled(Button, {
  shouldForwardProp: prop => prop !== 'disabled'
})<StyledButtonProps>(({ theme, disabled }) => ({
  opacity: disabled ? 0.5 : 1,
  width: '48px',
  height: '48px',
  borderRadius: '50%',
  background: getBackgroundColorVariantForTransactionType(theme, TransactionType.Swap).defaultColor
}));

export const StyledIcon = styled(SvgIcon)(({ theme }) => ({
  fontSize: theme.typography.htmlFontSize
}));

export const StyledInfoIcon = styled(SvgIcon)({
  fontSize: 14
});

export const StyledTo = styled('div')(({ theme }) => ({
  position: 'relative',
  padding: theme.spacing(0, 3),
  marginTop: theme.spacing(2)
}));

export const StyledLabel = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(0.5),
  color: theme.palette.custom.grey[400]
}));

export const StyledBalance = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(1),
  color: theme.palette.custom.grey[300],
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between'
}));

export const StyledEstimatedRate = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(2),
  color: theme.palette.custom.coralDusk[400],
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between'
}));

export const StyledRateLabel = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: theme.spacing(0.5)
}));

export const StyledRate = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: 5,

  '.MuiSvgIcon-root': {
    fontSize: 14
  }
});
