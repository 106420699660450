import { Typography } from '@mui/material';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ActionTab from 'src/components/banking/actions/ActionTab/ActionTab';
import CopyItem from 'src/components/banking/CopyItem/CopyItem';
import { formatAmount, formatDate } from 'src/utils/localization';
import { getCurrencyImage } from 'src/utils/styles';
import { WithdrawContext } from 'src/contexts/actions/Withdraw.contexts';
import { ActionBarContext } from 'src/contexts/ActionBar.contexts';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { submitPaymentOrder } from 'src/api/banking';
import { QueryKey } from 'src/constants/queryKeys';
import { AxiosError } from 'axios';
import { IResponseError } from 'src/interfaces/responses';
import { enqueueSnackbar } from 'notistack';
import { useCountriesQuery } from 'src/api/queries/useCountries';
import { formatBankDetails, formatBeneficiary } from 'src/utils/formatting';
import { usePaymentRequestQuery } from 'src/api/queries/usePaymentRequest';
import { getIdFromUri } from 'src/utils/strings';
import { TransactionStatus } from 'src/interfaces/banking';
import { useRates } from 'src/api/queries/useRates';

import WithdrawConfirmation from '../WithdrawConfirmation/WithdrawConfirmation';
import { WithdrawDetailsScreens } from '../Withdraw.interfaces';
import { WithdrawPaymentDetails } from '../WithdrawPaymentDetails/WithdrawPaymentDetails';
import { withdrawActionTabDefaultValues } from '../Withdraw.utils';

import { StyledCurrencyFlag, StyledDetails } from './WithdrawDetails.styles';

const WithdrawDetails = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const actionBarContext = useContext(ActionBarContext);
  const {
    account,
    amount = '0',
    outgoingCurrency,
    country,
    paymentDetails = {},
    beneficiaryDetails = {},
    beneficiaryNetwork
  } = useContext(WithdrawContext) ?? {};

  const { data: ratesData } = useRates(account?.currency ?? '', outgoingCurrency ?? '');
  const rates = ratesData?.data;
  const estimatedRates = rates ? `${Number(rates.forward).toFixed(5)} ${rates.quote} = 1 ${rates.base}` : undefined;

  const { data: countries } = useCountriesQuery(!!beneficiaryDetails?.address?.country);

  const [waitingForApproval, setWaitingForApproval] = useState(false);
  const [nextScreen, setNextScreen] = useState<WithdrawDetailsScreens>();

  const accountUri = account?._links.self.uri ?? '';
  const { data: paymentRequest, isLoading: isPaymentRequestLoading } = usePaymentRequestQuery(
    getIdFromUri(accountUri),
    amount,
    country,
    beneficiaryNetwork
  );

  beneficiaryDetails.network = beneficiaryNetwork;
  beneficiaryDetails.targetCurrency = outgoingCurrency;
  const submitOrderMutation = useMutation(() => submitPaymentOrder(accountUri, amount, beneficiaryDetails, paymentDetails), {
    onSuccess: ({ data }) => {
      setWaitingForApproval(data.status === TransactionStatus.PendingApproval);
      setNextScreen('withdrawConfirmation');
    },
    onError: (error: AxiosError<IResponseError>) => {
      const message = error?.response?.data?.message;
      enqueueSnackbar(message ?? t('screens.error'), { variant: 'error' });
    },
    onSettled: () => queryClient.invalidateQueries([QueryKey.QUERY_ORDERS])
  });

  const renderItem = (key: string, value: string, multiline = false, currency?: string) => (
    <CopyItem
      multiline={multiline}
      label={t(`banking.actions.withdraw.details.${key}`)}
      value={value}
      valueDecoration={currency ? <StyledCurrencyFlag src={getCurrencyImage(currency)} alt={`${currency} flag`} /> : undefined}
      disableCopy
    />
  );

  const flowScreens = {
    withdrawPaymentDetails: <WithdrawPaymentDetails />,
    withdrawConfirmation: <WithdrawConfirmation waitingForApproval={waitingForApproval} />
  };

  return nextScreen ? (
    flowScreens[nextScreen]
  ) : actionBarContext ? (
    <ActionTab
      activeStepIndex={4}
      loading={submitOrderMutation.isLoading}
      primaryActionTextKey="common.confirm"
      onPrimaryActionClick={submitOrderMutation.mutate}
      onClose={actionBarContext.closeActionTab}
      onGoBack={() => setNextScreen('withdrawPaymentDetails')}
      {...withdrawActionTabDefaultValues}
    >
      <Typography variant="strongBody1">{t('banking.actions.withdraw.details.title')}</Typography>
      <StyledDetails>
        {amount && outgoingCurrency && renderItem('amount', `${formatAmount(amount, 2)} ${outgoingCurrency}`, false)}
        {account && renderItem('withdrawBalance', account.currency, false, account.currency)}
        {account &&
          estimatedRates &&
          renderItem('converted', `${((1 / Number(rates?.forward ?? 1)) * Number(amount)).toFixed(3)} ${account.currency}`)}
        {estimatedRates && renderItem('estimatedRate', estimatedRates, false)}
        {beneficiaryDetails && renderItem('beneficiary', formatBeneficiary(beneficiaryDetails, countries?.data), true)}
        {beneficiaryDetails?.iban && renderItem('iban', beneficiaryDetails.iban)}
        {beneficiaryDetails?.accountNumber && renderItem('accountNumber', beneficiaryDetails.accountNumber)}
        {(beneficiaryDetails?.bicSwiftCode || beneficiaryDetails?.routingNumber) &&
          renderItem(
            beneficiaryDetails?.bicSwiftCode ? 'bicSwiftCode' : 'routingNumber',
            formatBankDetails(beneficiaryDetails, countries?.data),
            true
          )}
        {renderItem('vendorNote', paymentDetails?.vendorNote ?? t('common.notAvailable'), true)}
        {renderItem('personalNote', paymentDetails?.personalNote ?? t('common.notAvailable'), true)}
        {paymentDetails?.feeCoverage && renderItem('feeCoverage', paymentDetails.feeCoverage)}
        {!isPaymentRequestLoading &&
          renderItem(
            'settlement',
            paymentRequest?.data ? formatDate(paymentRequest.data.executionDate, 'lll') : t('banking.actions.swap.details.missingSettlement')
          )}
      </StyledDetails>
    </ActionTab>
  ) : null;
};

export default WithdrawDetails;
