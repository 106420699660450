import { alpha, Stack, styled, Toolbar, Typography } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { StyledContentWithoutMenuBarProps, StyledSubPageButtonProps } from 'src/components/layouts/SettingsLayout/SettingsLayout.interfaces';
import { MENU_BAR_WIDTH } from 'src/constants/dimensions';

export const StyledSettingsLayout = styled(Stack)({
  minHeight: '100vh',
  overflowX: 'hidden'
});

export const StyledSettingsLayoutMobileHeader = styled(Toolbar)(({ theme }) => ({
  height: '5vh',
  backgroundColor: theme.palette.custom.coralDusk[400],
  width: '100vw'
}));

export const StyledMenuIcon = styled(MenuIcon)(({ theme }) => ({
  color: theme.palette.primary.contrastText
}));

export const StyledMobileLogoutButtonWrapper = styled('div')({
  fontSize: '1rem'
});

export const StyledSettingsMobileHeader = styled(Typography)(({ theme }) => ({
  margin: 'auto',
  color: theme.palette.primary.contrastText,
  ...theme.typography.button
}));

export const StyledContentWithoutMenuBar = styled('div', {
  shouldForwardProp: prop => prop !== 'menuBarExpanded'
})<StyledContentWithoutMenuBarProps>(({ theme, menuBarExpanded }) => ({
  width: `calc(100% - ${menuBarExpanded ? MENU_BAR_WIDTH.expanded : MENU_BAR_WIDTH.hidden})`,
  marginLeft: menuBarExpanded ? MENU_BAR_WIDTH.expanded : MENU_BAR_WIDTH.hidden,
  backgroundColor: theme.palette.common.white,
  display: 'flex',
  flexShrink: 0,
  [theme.breakpoints.down('md')]: {
    width: '100vw',
    marginLeft: 0,
    padding: theme.spacing(1)
  }
}));

export const StyledSubPagesNavWrapper = styled('div')(({ theme }) => ({
  width: 276,
  flexShrink: 0,
  padding: theme.spacing(4),
  borderRight: `solid 1px ${theme.palette.custom.grey[50]}`,
  [theme.breakpoints.down('md')]: {
    width: 'fit-content',
    padding: theme.spacing(0.5)
  }
}));

export const StyledSubPagesTitle = styled(Typography)(({ theme }) => ({
  lineHeight: '24px',
  paddingLeft: theme.spacing(2),
  color: theme.palette.custom.grey[300],
  marginBottom: theme.spacing(1),
  [theme.breakpoints.down('md')]: {
    display: 'none'
  }
}));

export const StyledSubPageButton = styled('div', {
  shouldForwardProp: prop => prop !== 'isActive'
})<StyledSubPageButtonProps>(({ isActive, theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  gap: theme.spacing(1.5),
  cursor: 'pointer',
  borderRadius: theme.shape.borderRadius,
  color: theme.palette.common.black,
  marginBottom: theme.spacing(1),
  padding: theme.spacing(1, 2),
  backgroundColor: isActive ? alpha(`${theme.palette.custom.grey[300]}`, 0.15) : 'transparent',

  '&:hover': {
    backgroundColor: alpha(`${theme.palette.custom.grey[300]}`, 0.15)
  },

  '.MuiTypography-root': {
    letterSpacing: '-0.01em',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    maxWidth: '116px',
    overflow: 'hidden'
  },

  '.MuiSvgIcon-root': {
    fontSize: '1rem',
    cursor: 'pointer'
  }
}));

export const StyledSubPageButtonText = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    display: 'none'
  }
}));

export const StyledSubPagesContentWrapper = styled('div')(({ theme }) => ({
  width: '100%',
  maxHeight: '100vh',
  padding: theme.spacing(4, 6),
  overflowY: 'auto',
  [theme.breakpoints.down('md')]: {
    width: 'fit-content',
    padding: theme.spacing(2)
  }
}));
