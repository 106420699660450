import { SvgIcon as MuiSvgIcon } from '@mui/material';
import { FC, ForwardedRef, forwardRef } from 'react';

import { SvgIconProps, Icons } from './SvgIcon.interfaces';
import StyledSvg from './SvgIcon.styles';

const SvgIcon: FC<SvgIconProps> = forwardRef(({ icon, fontSize, onClick, className }, ref: ForwardedRef<HTMLDivElement>) => (
  <StyledSvg ref={ref}>
    <MuiSvgIcon onClick={onClick} component={Icons[icon]} fontSize={fontSize} inheritViewBox className={className} />
  </StyledSvg>
));

export default SvgIcon;
