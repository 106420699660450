import { Method } from 'axios';
import hmacSHA256 from 'crypto-js/hmac-sha256';

import { StorageKey } from '../constants/storage';

import PusherClient from './pusher';

const getStrongToken = () => sessionStorage.getItem(StorageKey.AUTH_TOKEN);

const getToken = () => getStrongToken() || sessionStorage.getItem(StorageKey.WEAK_AUTH_TOKEN);

export const getAuthorizationHeader = () => {
  const token = getToken();

  return token ? `Bearer ${getToken()}` : null;
};

export const isLoggedIn = () => !!getStrongToken();

export const logout = () => {
  if (!isLoggedIn()) {
    return;
  }
  sessionStorage.clear();
  PusherClient.destroy();
};

export const getSignature = (timestamp: number, method: Method | string, url: string) =>
  hmacSHA256(`${timestamp}${method.toUpperCase()}/v1/api${url}`, process.env.REACT_APP_REQUEST_ENCRYPTION_KEY).toString();
