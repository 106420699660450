import { styled, Typography } from '@mui/material';

export const StyledTitle = styled(Typography)(({ theme }) => ({
  lineHeight: '24px',
  marginTop: theme.spacing(4)
}));

export const StyledDesc = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(2)
}));
