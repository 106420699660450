import { Typography, useTheme } from '@mui/material';
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { QRCode } from 'react-qrcode-logo';
import CopyItem from 'src/components/banking/CopyItem/CopyItem';
import IconText from 'src/components/banking/IconText/IconText';
import SvgIcon from 'src/components/SvgIcon/SvgIcon';
import { ACCOUNT_DETAIL_HINT_ICONS } from 'src/constants/banking';
import { AccountDetails, AccountDetailsHint, CurrencyType } from 'src/interfaces/banking';
import useCopyToClipboard from 'src/utils/useCopyToClipboard';

import { AccountDetailsInfoProps } from './AccountDetailsInfo.interfaces';
import { StyledTitle, StyledButton, StyledContainer, StyledDetailsWihBorder, StyledInfo, StyledQRWrapper } from './AccountDetailsInfo.styles';
import { getAccountDetailsObjectEntries, getWalletAddressAndCopyText } from './AccountDetailsInfo.utils';

export const AccountDetailsInfo: FC<AccountDetailsInfoProps> = ({ account, titleKey = 'common.details', inActionTab = false }) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const [, copy] = useCopyToClipboard();
  const isCrypto = account?.type === CurrencyType.Crypto;
  const hints = account?.details?.hints as AccountDetailsHint[];
  const objectEntriesWithoutHints = useMemo(() => getAccountDetailsObjectEntries(account, 'hints'), [account]) as [string, AccountDetails][];
  const orderOfEntryTypes = ['swift', 'sepa', 'ach', 'localWire'];
  const sortedEntriesWithoutHints = useMemo(
    () => [...objectEntriesWithoutHints].sort((a, b) => orderOfEntryTypes.indexOf(a[0]) - orderOfEntryTypes.indexOf(b[0])),
    [objectEntriesWithoutHints, orderOfEntryTypes]
  );
  const walletAddressAndCopyText = useMemo(
    () => getWalletAddressAndCopyText(sortedEntriesWithoutHints, isCrypto),
    [sortedEntriesWithoutHints, isCrypto]
  );

  const copyText = walletAddressAndCopyText.copyText;
  const walletAddress = walletAddressAndCopyText.walletAddress;

  return account?.details ? (
    <StyledContainer>
      <StyledDetailsWihBorder inActionTab={inActionTab}>
        <Typography variant="subtitle3">{t(titleKey)}</Typography>

        {walletAddress && (
          <StyledQRWrapper>
            <QRCode size={220} logoWidth={50} value={walletAddress} fgColor={theme.palette.common.black} />
            <Typography variant="body3" color={theme => theme.palette.custom.grey[400]}>
              {t('banking.actions.deposit.scanOrCopyAddress')}
            </Typography>
          </StyledQRWrapper>
        )}

        {sortedEntriesWithoutHints.map(([group, items]) => {
          const prefix = `banking.${group}`;
          return (
            <div key={group}>
              <StyledTitle variant="strongBody1">{t(`${prefix}.label`)}</StyledTitle>
              {Object.entries(items).map(([key, value]) => (
                <CopyItem key={key} disableCopy={isCrypto} label={`${t(`${prefix}.${key}`)}:`} value={value as string} />
              ))}
            </div>
          );
        })}
        {walletAddress && !inActionTab && <StyledButton onClick={() => copy(walletAddress)}>{t('banking.copyWalletAddress')}</StyledButton>}
        {copyText && !inActionTab && <StyledButton onClick={() => copy(copyText)}>{t('banking.copyAllDetails')}</StyledButton>}
      </StyledDetailsWihBorder>

      {hints && !inActionTab && (
        <StyledInfo>
          {hints.map(hint => (
            <IconText key={hint.topic} icon={<SvgIcon icon={ACCOUNT_DETAIL_HINT_ICONS[hint.topic] ?? hint.topic} fontSize="inherit" />}>
              <Typography variant="body3">{hint.text}</Typography>
            </IconText>
          ))}
        </StyledInfo>
      )}
    </StyledContainer>
  ) : (
    <Typography>No account details</Typography>
  );
};
