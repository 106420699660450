import { Typography } from '@mui/material';
import { FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import ActionTab from 'src/components/banking/actions/ActionTab/ActionTab';
import StatusIcon from 'src/components/StatusIcon/StatusIcon';
import SvgIcon from 'src/components/SvgIcon/SvgIcon';
import { ActionBarContext } from 'src/contexts/ActionBar.contexts';

import { swapActionTabDefaultValues } from '../Swap.utils';

import { StyledContent, StyledReviewIcon, StyledTitle } from './SwapConfirmation.styles';
import { SwapConfirmationProps } from './SwapConfirmation.interfaces';

const SwapConfirmation: FC<SwapConfirmationProps> = ({ amount, currency, waitingForApproval }) => {
  const { t } = useTranslation();
  const actionBarContext = useContext(ActionBarContext);

  return actionBarContext ? (
    <ActionTab
      primaryActionTextKey="common.done"
      onPrimaryActionClick={actionBarContext.closeActionTab}
      onClose={actionBarContext.closeActionTab}
      activeStepIndex={3}
      {...swapActionTabDefaultValues}
    >
      {waitingForApproval ? (
        <StyledContent>
          <StyledReviewIcon>
            <SvgIcon icon="timeWhite" />
          </StyledReviewIcon>
          <StyledTitle variant="subtitle1">{t('banking.actions.swap.waitingForApproval.title')}</StyledTitle>
          <Typography>
            {t('banking.actions.swap.waitingForApproval.text', {
              amount,
              currency
            })}
          </Typography>
        </StyledContent>
      ) : (
        <StyledContent>
          <StatusIcon icon={<SvgIcon icon="check" />} />

          <StyledTitle variant="subtitle1">{t('banking.actions.swap.confirmation.title')}</StyledTitle>
          <Typography>
            {t('banking.actions.swap.confirmation.text', {
              amount,
              currency
            })}
          </Typography>
        </StyledContent>
      )}
    </ActionTab>
  ) : null;
};

export default SwapConfirmation;
