import { Stack, styled } from '@mui/material';
import InfoBox from 'src/components/InfoBox/InfoBox';

export const ButtonWrapper = styled(Stack)(({ theme }) => ({
  marginTop: theme.spacing(3),
  justifyContent: 'space-between'
}));

export const StyledInfoBox = styled(InfoBox)({
  maxWidth: '100%',
  '.MuiSvgIcon-root': {
    fontSize: 64
  }
});
