import { styled } from '@mui/material';

export const StyledCurrencyFlag = styled('img')({
  width: '16px',
  height: '16px'
});

export const StyledActions = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  gap: theme.spacing(1.5)
}));

export const StyledDivider = styled('hr')(({ theme }) => ({
  border: 0,
  borderBottom: `1px solid ${theme.palette.grey[50]}`,
  width: '100%'
}));
