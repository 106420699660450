import { Box, Typography } from '@mui/material';
import Modal from 'src/components/Modal/Modal';
import { FC, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useBeneficiariesQuery } from 'src/api/queries/useBeneficiaries';
import { formatBeneficiaryName } from 'src/utils/formatting';
import { getCurrencyImage } from 'src/utils/styles';
import { StorageKey } from 'src/constants/storage';

import { OptionInterface } from '../Withdraw.utils';
import { StyledBeneficiaryWithPadding } from '../WithdrawSelectBenficiary/WithdrawSelectBenficiary.styles';
import { StyledToggleButton, StyledToggleButtonGroup } from '../WithdrawBenficiaryDetails/WithdrawBenficiaryDetails.styles';

import { AllBeneficiaryModalProps } from './AllBeneficiariesModal.interfaces';
import { StyledAllBeneficiariesModalWrapper } from './AllBeneficiariesModal.styles';

export const AllBeneficiariesModal: FC<AllBeneficiaryModalProps> = ({ isOpen, onClose, accounts, editBeneficiary, ...restProps }) => {
  const { t } = useTranslation();
  const entityUri = sessionStorage.getItem(StorageKey.SELECTED_ENTITY_URI) ?? '';
  const [dataList, setDataList] = useState<OptionInterface[] | []>([]);
  const [beneficiaryType, setBeneficiaryType] = useState<'NEWEST' | 'ALPHABETICAL'>('NEWEST');

  // TODO: add alphabetical order implementation and infinite scroll
  const { data: beneficiariesData } = useBeneficiariesQuery(entityUri, `orderBy=${beneficiaryType}`);
  const inputRef = useRef<string>('');

  useMemo(() => {
    const newDataList = beneficiariesData?.data.map(beneficiary => {
      const beneficiaryAccountUri = beneficiary._links?.account.uri;
      const acc = beneficiaryAccountUri ? accounts?.find(acc => acc._links.self.uri === beneficiaryAccountUri) : null;
      return {
        imageUrl: getCurrencyImage(acc?.currency ?? ''),
        value: formatBeneficiaryName(beneficiary),
        label: `${acc?.currencyLabel} - ${beneficiary.iban || beneficiary.accountNumber || beneficiary.aba}`,
        key: String(beneficiary._id)
      };
    });
    setDataList(newDataList ?? []);
  }, [beneficiariesData?.data, accounts]);

  return (
    <Modal onClose={onClose} isOpen={isOpen} title={t('banking.actions.withdraw.allBeneficiaries')} {...restProps}>
      <StyledAllBeneficiariesModalWrapper>
        <Box
          sx={
            !beneficiariesData?.data
              ? {
                  marginTop: 3
                }
              : undefined
          }
        >
          <StyledToggleButtonGroup
            value={beneficiaryType}
            exclusive
            onChange={(_, value) => {
              if (value) {
                setBeneficiaryType(value);
              }
            }}
          >
            <StyledToggleButton value="NEWEST">
              <Typography variant="strongBody3">{t('banking.actions.withdraw.recentBeneficiaries')}</Typography>
            </StyledToggleButton>
            <StyledToggleButton value="ALPHABETICAL">
              <Typography variant="strongBody3">{t('banking.actions.withdraw.alphabeticalBeneficiaries')}</Typography>
            </StyledToggleButton>
          </StyledToggleButtonGroup>
          {dataList &&
            dataList.map((beneficiary: OptionInterface) => (
              <StyledBeneficiaryWithPadding
                option={beneficiary}
                filterValue={inputRef.current}
                key={beneficiary.key}
                onEditButtonClick={() => {
                  const beneficiaryToEdit = beneficiariesData?.data.find(item => item._id === beneficiary.key);
                  editBeneficiary(beneficiaryToEdit);
                }}
              />
            ))}
        </Box>
      </StyledAllBeneficiariesModalWrapper>
    </Modal>
  );
};
