import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SvgIcon from 'src/components/SvgIcon/SvgIcon';
import Tooltip from 'src/components/Tooltip/Tooltip';
import { Typography, useMediaQuery } from '@mui/material';
import { formatAmount } from 'src/utils/localization';
import IconText from 'src/components/banking/IconText/IconText';
import { IconKeys } from 'src/components/SvgIcon/SvgIcon.interfaces';
import { ButtonVariant } from 'src/constants/variants';
import { Close } from '@mui/icons-material';
import { getCurrencyImage } from 'src/utils/styles';
import theme from 'src/constants/theme';
import { CurrencyType } from 'src/interfaces/banking';
import { useLocation } from 'react-router-dom';
import { urls } from 'src/routes';

import { TotalBalanceProps } from './TotalBalance.interfaces';
import {
  StyledTotalBalanceAmount,
  StyledTotalBalanceContainer,
  StyledTotalBalanceChange,
  StyledTotalBalanceChangeWrapper,
  StyledTotalBalanceCurrency,
  StyledTotalBalanceTitle,
  StyledTotalBalanceTitleAndInfoIcon,
  StyledTotalBalance,
  StyledIcon,
  StyledAccountFlag,
  StyledLabelValue,
  StyledLabel,
  StyledAccountInfo,
  StyledCloseButton
  //StyledTrackWalletButton
} from './TotalBalance.styles';

const TotalBalance: FC<TotalBalanceProps> = ({
  type,
  currency,
  currencyLabel,
  totalBalance,
  performance,
  valuation,
  metaData,
  disabled,
  combined,
  onClose
}) => {
  const { t } = useTranslation();
  const location = useLocation();
  const [isTrackWalletVisible, setIsTrackWalletVisible] = useState<boolean>(false);
  const isMobileView = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    if (location.pathname === urls.balances) {
      setIsTrackWalletVisible(true);
    }
  }, [location]);

  const renderBalance = () => {
    const balance = (
      <StyledTotalBalanceAmount variant={!isMobileView ? 'h2' : 'h4'}>
        {formatAmount(totalBalance, combined ? 2 : type === CurrencyType.Crypto ? 5 : undefined)}
      </StyledTotalBalanceAmount>
    );

    if (type === CurrencyType.Crypto) {
      return (
        <Tooltip
          dark
          arrow
          title={
            <Typography variant="body3" textAlign="center">
              {totalBalance}
            </Typography>
          }
        >
          {balance}
        </Tooltip>
      );
    }
    return balance;
  };

  const renderAccountInfo = (icon: IconKeys, label: string, value: string) => (
    <StyledAccountInfo>
      <IconText icon={<SvgIcon icon={icon} fontSize="inherit" />}>
        <StyledLabelValue>
          <StyledLabel variant="body3">{label}</StyledLabel>
          <Typography variant="strongBody3">{value}</Typography>
        </StyledLabelValue>
      </IconText>
    </StyledAccountInfo>
  );

  return (
    <>
      {combined ? (
        <StyledTotalBalanceTitleAndInfoIcon disabled={disabled}>
          <StyledTotalBalanceTitle variant="body1">
            {`${t('banking.totalBalance')} ${t('banking.totalBalanceDefaultCurrencyName')}`}
          </StyledTotalBalanceTitle>
          <Tooltip
            dark
            arrow
            title={
              <Typography variant="body3" textAlign="center">
                {t('banking.accountInfo.balance')}
              </Typography>
            }
          >
            <StyledIcon>
              <SvgIcon icon="info" />
            </StyledIcon>
          </Tooltip>
        </StyledTotalBalanceTitleAndInfoIcon>
      ) : (
        <StyledTotalBalanceTitleAndInfoIcon disabled={disabled}>
          <StyledAccountFlag src={getCurrencyImage(currency)} alt={`${currency} flag`} />
          <StyledTotalBalanceTitle>{`${currencyLabel ?? currency}${metaData?.name ? ` · ${metaData.name}` : ''}`}</StyledTotalBalanceTitle>

          {metaData?.description && (
            <Tooltip
              dark
              arrow
              title={
                <Typography variant="body3" textAlign="center">
                  {metaData.description}
                </Typography>
              }
            >
              <div>
                <SvgIcon icon="info" />
              </div>
            </Tooltip>
          )}
        </StyledTotalBalanceTitleAndInfoIcon>
      )}
      <StyledTotalBalanceContainer disabled={disabled}>
        <StyledTotalBalance>
          {renderBalance()}
          <StyledTotalBalanceCurrency variant={!isMobileView ? 'h2' : 'h4'}>{currency}</StyledTotalBalanceCurrency>
          {!!performance && !disabled && (
            <StyledTotalBalanceChangeWrapper>
              <StyledTotalBalanceChange>${performance} (24H)</StyledTotalBalanceChange>
            </StyledTotalBalanceChangeWrapper>
          )}
          {valuation && valuation.currency !== currency && (
            <>
              {renderAccountInfo('holdingValue', `${t('banking.holdingValue')}:`, `${formatAmount(valuation.amount)} ${valuation.currency}`)}
              {valuation.rate &&
                renderAccountInfo(
                  'usd',
                  `1 ${currency} =`,
                  `${formatAmount(valuation.rate, type === CurrencyType.Crypto ? 5 : undefined)} ${valuation.currency}`
                )}
            </>
          )}
        </StyledTotalBalance>
        {onClose && <StyledCloseButton variant={ButtonVariant.Secondary} size="small" onClick={onClose} startIcon={<Close />} />}
        {/*
          isTrackWalletVisible && (
            <StyledTrackWalletButton icon={<SvgIcon icon="addCircle"/>} variant={ButtonVariant.Secondary}>
              {t('banking.trackWallet')}
            </StyledTrackWalletButton>
          )
        */}
      </StyledTotalBalanceContainer>
    </>
  );
};

export default TotalBalance;
