import { Menu, styled } from '@mui/material';

import { StyledMenuProps } from './Menu.interfaces';

export const StyledMenu = styled(Menu, {
  shouldForwardProp: prop => prop !== 'size'
})<StyledMenuProps>(({ theme, size }) => ({
  '& .MuiPaper-root': {
    minWidth: 180,
    marginTop: theme.spacing(1),
    boxShadow: theme.shadows[5],
    borderRadius: theme.shape.borderRadius,

    '& .MuiMenu-list': {
      padding: theme.spacing(1)
    },

    '& .Mui-selected': {
      fontWeight: 500,
      color: theme.palette.custom.coralDusk[400],
      backgroundColor: theme.palette.custom.coralDusk[50]
    },

    '& .MuiMenuItem-root': {
      fontSize: 15,
      display: 'block',
      borderRadius: '4px',
      padding: size === 'large' ? theme.spacing(1, 2) : theme.spacing(0.5, 1),

      '&:first-letter': {
        textTransform: 'capitalize'
      },
      '&:hover': {
        backgroundColor: theme.palette.custom.grey[50]
      },
      '&:active': {
        backgroundColor: theme.palette.custom.coralDusk[50]
      }
    }
  }
}));
