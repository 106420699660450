import { FC, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { enqueueSnackbar } from 'notistack';
import { useUserOrNavigateQuery } from 'src/api/queries/useUserOrNavigate';
import { ButtonVariant } from 'src/constants/variants';
import Autocomplete from 'src/components/Autocomplete/Autocomplete';
import { IFormValues, IOnFormChangeProps } from 'src/interfaces/forms';
import Loader from 'src/components/Loader/Loader';
import { formatAddress } from 'src/utils/formatting';
import { useCountriesQuery } from 'src/api/queries/useCountries';
import { preferencesSchemaUrl } from 'src/api/formSchemas';
import Form from 'src/components/Form/Form';
import { updatePreferences } from 'src/api/user';
import Input from 'src/components/Input/Input';
import Button from 'src/components/Button/Button';
import { useTheme } from '@mui/material';
import { getHelpMailto } from 'src/utils/mailing';
import { useLegalEntitiesQuery } from 'src/api/queries/useLegalEntities';

import { StyledTitle, StyledRowTitle, StyledRowSubtitle, StyledAvatar, StyledRow, StyledLeftItem, StyledRightItem } from './ProfileForm.styles';

const ProfileForm: FC = () => {
  const theme = useTheme();
  const { t } = useTranslation();

  const { data: user, isLoading, isFetching } = useUserOrNavigateQuery(false, true);
  const { data: legalEntities } = useLegalEntitiesQuery(user?._links.self?.uri ?? '');
  const { data: countries, isLoading: isCountriesLoading } = useCountriesQuery(!!user?.postalAddress?.country);
  const [initialValues, setInitialValues] = useState<IFormValues>();

  useMemo(() => {
    const { preferences } = user ?? {};
    if (preferences && !isLoading && !isFetching) {
      setInitialValues({
        locale: preferences.locale,
        timezone: preferences.timezone
      });
    }
  }, [user, isLoading, isFetching]);

  const handlePreferencesChange = async ({ values, lastChangedFieldName }: IOnFormChangeProps) => {
    if (lastChangedFieldName === 'timezone' && values?.timezone) {
      updatePreferences({
        locale: user?.preferences.locale,
        timezone: values.timezone
      })
        ?.then(_ =>
          enqueueSnackbar(t('personalSettings.profile.timezone.success'), {
            variant: 'success'
          })
        )
        .catch(error => {
          try {
            const { message } = error.response.data;
            enqueueSnackbar(message, { variant: 'error' });
          } catch {}
        });
    }
  };

  if (isLoading || isFetching || isCountriesLoading) {
    return <Loader anchorToRelative />;
  }

  return (
    <>
      <StyledTitle variant="subtitle3">{t('personalSettings.profile.title')}</StyledTitle>

      {user?.firstName && <StyledAvatar avatarColor={theme.palette.primary.main}>{Array.from(user.firstName)[0]}</StyledAvatar>}

      <StyledRow container spacing={4}>
        <StyledLeftItem item xs={6}>
          <Input name="firstName" label={t('personalSettings.profile.firstName')} defaultValue={user?.firstName} disabled />
        </StyledLeftItem>

        <StyledRightItem item xs={6}>
          <Input name="lastName" label={t('personalSettings.profile.lastName')} defaultValue={user?.lastName} disabled />
        </StyledRightItem>
      </StyledRow>

      <StyledRow container spacing={4}>
        <StyledLeftItem item xs={6}>
          <StyledRowTitle variant="subtitle3">{t('personalSettings.profile.residentialAddress')}</StyledRowTitle>
        </StyledLeftItem>

        <StyledRightItem item xs={6}>
          <Input
            name="residentialAddress"
            defaultValue={formatAddress(user?.postalAddress, countries?.data)}
            multiline
            minRows={4}
            maxRows={4}
            disabled
          />
        </StyledRightItem>
      </StyledRow>

      <StyledRow container spacing={4}>
        <StyledLeftItem item xs={6}>
          <StyledRowTitle variant="subtitle3">{t('personalSettings.profile.mobileNumber')}</StyledRowTitle>
        </StyledLeftItem>

        <StyledRightItem item xs={6}>
          <Input name="mobileNumber" defaultValue={user?.mobileNumber} disabled />
        </StyledRightItem>
      </StyledRow>

      <StyledRow container spacing={4}>
        <StyledLeftItem item xs={6}>
          <StyledRowTitle variant="subtitle3">{t('personalSettings.profile.email')}</StyledRowTitle>
        </StyledLeftItem>

        <StyledRightItem item xs={6}>
          <Input name="email" defaultValue={user?.email} disabled />
        </StyledRightItem>
      </StyledRow>

      <Form schemaUrl={preferencesSchemaUrl} onChange={handlePreferencesChange} initialValues={initialValues}>
        <StyledRow container spacing={4}>
          <StyledLeftItem item xs={6}>
            <StyledRowTitle variant="subtitle3">{t('personalSettings.profile.language.title')}</StyledRowTitle>
            <StyledRowSubtitle variant="body3">{t('personalSettings.profile.language.subtitle')}</StyledRowSubtitle>
          </StyledLeftItem>

          <StyledRightItem item xs={1} />

          <StyledRightItem item xs={5}>
            <Autocomplete name="locale" disabled />
          </StyledRightItem>
        </StyledRow>

        <StyledRow container spacing={4}>
          <StyledLeftItem item xs={6}>
            <StyledRowTitle variant="subtitle3">{t('personalSettings.profile.timezone.title')}</StyledRowTitle>
            <StyledRowSubtitle variant="body3">{t('personalSettings.profile.timezone.subtitle')}</StyledRowSubtitle>
          </StyledLeftItem>

          <StyledRightItem item xs={1} />

          <StyledRightItem item xs={5}>
            <Autocomplete name="timezone" />
          </StyledRightItem>
        </StyledRow>
      </Form>

      <StyledRow container spacing={4}>
        <StyledLeftItem item xs={6}>
          <StyledRowTitle variant="subtitle3">{t('personalSettings.profile.personalDetails.title')}</StyledRowTitle>
          <StyledRowSubtitle variant="body3">{t('personalSettings.profile.personalDetails.subtitle')}</StyledRowSubtitle>
        </StyledLeftItem>

        <StyledRightItem item xs={6}>
          <Button variant={ButtonVariant.Secondary} href={getHelpMailto(user, legalEntities?.data)}>
            {t('personalSettings.profile.personalDetails.buttonTitle')}
          </Button>
        </StyledRightItem>
      </StyledRow>

      <StyledRow container spacing={4}>
        <StyledLeftItem item xs={6}>
          <StyledRowTitle variant="subtitle3">{t('personalSettings.profile.deleteProfile.title')}</StyledRowTitle>
          <StyledRowSubtitle variant="body3">{t('personalSettings.profile.deleteProfile.subtitle')}</StyledRowSubtitle>
        </StyledLeftItem>

        <StyledRightItem item xs={6}>
          <Button variant={ButtonVariant.Secondary}>{t('personalSettings.profile.deleteProfile.buttonTitle')}</Button>
        </StyledRightItem>
      </StyledRow>
    </>
  );
};

export default ProfileForm;
