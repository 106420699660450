import { styled, Typography } from '@mui/material';
import SvgIcon from 'src/components/SvgIcon/SvgIcon';

export const StyledWrapper = styled('div')(() => ({
  position: 'relative',
  display: 'flex',
  overflow: 'hidden'
}));

export const StyledLeftSide = styled('div')(({ theme }) => ({
  position: 'relative',
  [theme.breakpoints.down('md')]: {
    display: 'none'
  }
}));

export const StyledHeading = styled(Typography)(({ theme }) => ({
  position: 'absolute',
  top: '144px',
  left: theme.spacing(5),
  right: theme.spacing(5),
  letterSpacing: '-0.02em',
  maxWidth: '464px',
  color: theme.palette.custom.gold[200]
}));

export const StyledLogo = styled(SvgIcon)(({ theme }) => ({
  position: 'absolute',
  fontSize: '112px',
  top: theme.spacing(0.5),
  left: theme.spacing(5)
}));

export const StyledRightSide = styled('div')({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  justifyContent: 'center'
});

export const StyledMainContent = styled('div')(({ theme }) => ({
  margin: 'auto',
  width: '464px',
  [theme.breakpoints.down('md')]: {
    marginTop: '50px',
    width: '80vw'
  }
}));
export const StyledSubtitle = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(2)
}));

export const StyledChildren = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(5)
}));

export const StyledVideoWrapper = styled('div')(() => ({
  display: 'block',
  width: '576px',
  height: '100vh',
  minHeight: '100%',
  '& > div': {
    height: '100%',
    '& > svg': {
      objectFit: 'cover'
    }
  }
}));

export const StyledLinkWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  flexWrap: 'wrap'
});

export const StyledFooterContentWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  marginInline: 'auto',
  marginBottom: theme.spacing(2),
  width: `calc(100% - ${theme.spacing(12)})`,
  gap: '8px',
  p: {
    fontSize: '10px',
    fontWeight: 500,
    lineHeight: '10px',
    color: theme.palette.custom.grey[300]
  }
}));
