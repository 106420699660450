import { UseQueryResult, useQuery } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { QueryKey } from 'src/constants/queryKeys';
import { IGetWithdrawalCurrencies } from 'src/interfaces/responses';
import { Account } from 'src/interfaces/banking';

import { getWithdrawalCurrencies } from '../banking';

export const useWithdrawalCurrencies = (
  account?: Account | string
): UseQueryResult<AxiosResponse<IGetWithdrawalCurrencies, AxiosError>, { response: AxiosResponse<AxiosError> }> => {
  const { t } = useTranslation();

  const uri = typeof account === 'string' ? account : account?._links?.self?.uri;
  const { enqueueSnackbar } = useSnackbar();

  return useQuery([QueryKey.QUERY_WITHDRAWAL_CURRENCIES, uri], () => getWithdrawalCurrencies(uri as string), {
    onError: error => {
      enqueueSnackbar(error?.response?.data?.message ?? t('screens.error'), {
        variant: 'error'
      });
    },
    enabled: !!uri
  });
};
