import { useMemo } from 'react';
import AccountsTab from 'src/components/banking/AccountsTab/AccountsTab';
import BankingLayout from 'src/components/layouts/BankingLayout/BankingLayout';
import { AccountStatus, AccountsDashboardTabState } from 'src/interfaces/banking';
import { useAccountsQuery } from 'src/api/queries/useAccounts';
import { Alert } from 'src/components/Alert/Alert';
import { useTranslation } from 'react-i18next';
import { getMatchedAccounts } from 'src/utils/accounts';

import { getTotalBalance } from './BalancesPage.utils';

const BalancesPage = () => {
  const { t } = useTranslation();

  const { data: accountsData, isLoading, isError } = useAccountsQuery();
  const accounts = useMemo(() => getMatchedAccounts(accountsData?.data ?? []), [accountsData?.data]);
  const isAccountCreation = accounts.findIndex(a => a.status !== AccountStatus.Pending) === -1;

  const content = isError ? <Alert>{t('banking.errors.accounts')}</Alert> : <AccountsTab accounts={accounts} key={AccountsDashboardTabState.All} />;

  return (
    <BankingLayout
      combined
      loading={isLoading}
      currency="USD"
      totalBalance={getTotalBalance(accounts)}
      content={content}
      isAccountCreation={isAccountCreation}
      disabled={isError || isAccountCreation}
      mobileHeader={t('banking.navigation.holdings')}
    />
  );
};

export default BalancesPage;
