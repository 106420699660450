import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonVariant } from 'src/constants/variants';
import Authentication2FAModal from 'src/components/modals/settings/personalSettings/AuthenticationModal/Authentication2FAModal';
import AuthenticationPasswordModal from 'src/components/modals/settings/personalSettings/AuthenticationModal/AuthenticationPasswordModal';

import { StyledButton, StyledLeftItem, StyledRightItem, StyledRow, StyledRowSubtitle, StyledRowTitle, StyledTitle } from './SecurityForm.styles';

const SecurityForm: FC = () => {
  const { t } = useTranslation();

  const [isReset2faModalOpened, setReset2faModalOpened] = useState<boolean>(false);
  const [isPasswordModalOpened, setPasswordModalOpened] = useState<boolean>(false);

  return (
    <>
      <StyledTitle variant="subtitle3">{t('personalSettings.security.title')}</StyledTitle>

      <StyledRow container spacing={4}>
        <StyledLeftItem item xs={6}>
          <StyledRowTitle variant="subtitle3">{t('personalSettings.security.changePassword.title')}</StyledRowTitle>
        </StyledLeftItem>

        <StyledRightItem item xs={6}>
          <StyledButton variant={ButtonVariant.Secondary} onClick={() => setPasswordModalOpened(true)}>
            {t('personalSettings.security.changePassword.buttonTitle')}
          </StyledButton>
        </StyledRightItem>
      </StyledRow>

      <StyledRow container spacing={4}>
        <StyledLeftItem item xs={6}>
          <StyledRowTitle variant="subtitle3">{t('personalSettings.security.reset2fa.title')}</StyledRowTitle>
          <StyledRowSubtitle variant="body3">{t('personalSettings.security.reset2fa.subtitle')}</StyledRowSubtitle>
        </StyledLeftItem>

        <StyledRightItem item xs={6}>
          <StyledButton variant={ButtonVariant.Secondary} onClick={() => setReset2faModalOpened(true)}>
            {t('personalSettings.security.reset2fa.buttonTitle')}
          </StyledButton>
        </StyledRightItem>
      </StyledRow>

      <StyledRow container spacing={4}>
        <StyledLeftItem item xs={12}>
          <StyledRowTitle variant="subtitle3">{t('personalSettings.security.accountActivity.title')}</StyledRowTitle>
          <StyledRowSubtitle variant="body3">{t('personalSettings.security.accountActivity.subtitle')}</StyledRowSubtitle>
        </StyledLeftItem>
      </StyledRow>

      <Authentication2FAModal isOpen={isReset2faModalOpened} onClose={() => setReset2faModalOpened(false)} />

      <AuthenticationPasswordModal isOpen={isPasswordModalOpened} onClose={() => setPasswordModalOpened(false)} />
    </>
  );
};

export default SecurityForm;
