import { Entity } from 'src/interfaces/banking';
import { User } from 'src/interfaces/user';
import { Environment } from 'src/constants/environment';
import { SUPPORT_BUSINESS_EMAIL } from 'src/constants/mailing';

import i18next from '../i18n';

import { getEnvironment } from './environment';
import { getSelectedLegalEntityUri } from './storage';

const { t } = i18next;

const getHelpBody = (userEmail: string, userName?: string, accountName?: string) =>
  `${t('getHelpEmailData.bodyTitle')}:%0D%0A
${t('getHelpEmailData.bodyUserEmail')}: ${userEmail}%0D%0A
${userName ? `${t('getHelpEmailData.bodyUserName')}: ${userName}%0D%0A` : ''}
${accountName ? `${t('getHelpEmailData.bodyAccountName')}: ${accountName}%0D%0A` : ''}
%0D%0A${t('getHelpEmailData.bodyDescription')}:%0D%0A%0D%0A
${t('getHelpEmailData.bodyNote')}`;

export const getHelpMailto = (user?: User, legalEntities?: Entity[]) => {
  const env = getEnvironment();
  return `mailto:${SUPPORT_BUSINESS_EMAIL}?subject=${t('getHelpEmailData.subject')}${env !== Environment.PROD ? ` - ${env}` : ''}&body=${getHelpBody(
    user?.email || '',
    [user?.firstName, user?.lastName].filter(Boolean).join(' '),
    legalEntities?.find(entity => entity._links.self.uri === getSelectedLegalEntityUri())?.name || undefined
  )}`;
};
