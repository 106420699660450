import { FC } from 'react';
import DialogLayout from 'src/components/layouts/DialogLayout/DialogLayout';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import SvgIcon from 'src/components/SvgIcon/SvgIcon';
import { urls } from 'src/routes';

const InvitationLinkExpired: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <DialogLayout
      isOnboardingLayout
      headerImage={<SvgIcon icon="timeWhite" />}
      heading={t('companySettings.members.invitationLinkExpiredModalTitle')}
      subheading={t('companySettings.members.invitationLinkExpiredModalContent')}
      secondaryActionText={t('common.backToHomepage')}
      onSecondaryActionClick={() => navigate(urls.balances)}
    />
  );
};

export default InvitationLinkExpired;
