import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { urls } from 'src/routes';
import { logout, isLoggedIn } from 'src/utils/authentication';
import GetHelpLink from 'src/components/GetHelpLink/GetHelpLink';

import SvgIcon from '../SvgIcon/SvgIcon';

import { StyledNavLinkWrapper, StyledNavLink, StyledSignInWrapper, StyledHaveAccount, StyledSignIn, StyledContainer } from './DialogNavLinks.styles';
import { DialogNavLinksProps } from './DialogNavLinks.interfaces';

export const DialogNavLinks: FC<DialogNavLinksProps> = ({ absolutePosition = false }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return isLoggedIn() ? (
    <StyledContainer absolutePosition={absolutePosition}>
      <StyledNavLinkWrapper>
        <GetHelpLink />
      </StyledNavLinkWrapper>
      <StyledNavLinkWrapper>
        <SvgIcon icon="logout" />
        <StyledNavLink href={urls.signin} variant="body2" onClick={logout}>
          {t('common.logout')}
        </StyledNavLink>
      </StyledNavLinkWrapper>
    </StyledContainer>
  ) : (
    <StyledSignInWrapper>
      <StyledHaveAccount variant="strongBody1">{t('common.haveAccount')}</StyledHaveAccount>
      <StyledSignIn variant="strongBody1" onClick={() => navigate(urls.signin)}>
        {t('common.signIn')}
      </StyledSignIn>
    </StyledSignInWrapper>
  );
};
