import { Box, Typography } from '@mui/material';
import { FC, useState, useMemo, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import ActionButtons from 'src/components/ActionButtons/ActionButtons';
import Modal from 'src/components/Modal/Modal';
import { inviteTeamMemberSchemaUrl } from 'src/api/formSchemas';
import Form from 'src/components/Form/Form';
import Input from 'src/components/Input/Input';
import Dropdown from 'src/components/Dropdown/Dropdown';
import { getSelectedLegalEntityUri } from 'src/utils/storage';
import { IFormHandleSubmit, IFormValues, IOnFormChangeProps } from 'src/interfaces/forms';
import { useNavigate } from 'react-router-dom';
import { urls } from 'src/routes';
import Button from 'src/components/Button/Button';
import { ButtonVariant } from 'src/constants/variants';
import get from 'lodash.get';
import { StyledModalWrapper } from 'src/components/modals/styles';

import { InviteMemberModalProps } from './InviteMemberModal.interfaces';
import { StyledModalHeading } from './InviteMemberModal.styles';

const InviteMemberModal: FC<InviteMemberModalProps> = ({ isOpen, onClose, onSubmit, member, ...restProps }) => {
  const { t } = useTranslation();
  const [isSecondStepVisible, setIsSecondStepVisible] = useState<boolean>(false);
  const [companyMemberEmail, setCompanyMemberEmail] = useState<string>('');
  const navigate = useNavigate();
  const isEditMode = useMemo(() => Boolean(member), [member]);
  const otpAuthenticationCodeFieldRef = useRef<HTMLInputElement>();

  useEffect(() => {
    if (otpAuthenticationCodeFieldRef.current) {
      otpAuthenticationCodeFieldRef.current.focus();
    }
  }, [isSecondStepVisible]);

  const handleFormChange = ({ values, lastChangedFieldName, submitForm }: IOnFormChangeProps) => {
    if (lastChangedFieldName === 'otp' && get(values, lastChangedFieldName, '').toString().length === 6) {
      submitForm?.();
    }
  };

  const handleSubmit: IFormHandleSubmit = (values, validateForm, handleError) => {
    if (!isEditMode) {
      if (isSecondStepVisible) {
        onSubmit(values, validateForm, handleError);
      } else {
        if (!validateForm(values)) {
          return;
        }

        setIsSecondStepVisible(true);
        setCompanyMemberEmail(values.email as string);
        handleError('otp', '');
      }
    } else {
      onSubmit(values, validateForm, handleError);
    }
  };

  const SecondStepHeading = (
    <>
      {t('companySettings.members.otpAuthenticationCodeModalContent')}
      <Typography variant="strongBody1">{companyMemberEmail}</Typography>
    </>
  );

  const FirstStepFormContent = (
    <>
      <Input name="firstName" disabled={!!member?.firstName} />
      <Input name="lastName" disabled={!!member?.lastName} />
      <Input name="email" disabled={!!member?.contact.email} />
      <Dropdown name="corporateTitle" />
      <Dropdown name="role" />

      <ActionButtons
        primaryActionText={isEditMode ? t('common.save') : t('common.continue')}
        secondaryActionText={t('common.cancel')}
        onSecondaryActionClick={onClose}
      />
    </>
  );

  const SecondStepFormContent = (
    <>
      {/* TODO: Make a component for OTP code (probably ref should be used) */}
      <Input inputRef={otpAuthenticationCodeFieldRef} name="otp" type="number" fullWidth />

      <Button
        variant={ButtonVariant.Text}
        sx={{
          marginTop: 2
        }}
        fullWidth
        onClick={() => navigate(urls.reset2fa)}
      >
        {t('screens.authentication.backText')}
      </Button>

      <ActionButtons
        sx={{
          marginTop: 1
        }}
        secondaryActionText={t('common.goBack')}
        onSecondaryActionClick={() => setIsSecondStepVisible(false)}
      />
    </>
  );

  return (
    <Modal
      onClose={onClose}
      isOpen={isOpen}
      title={isEditMode ? t('companySettings.members.editMemberModalTitle') : t('companySettings.members.inviteMemberModalTitle')}
      {...restProps}
    >
      <StyledModalWrapper>
        {!isEditMode && (
          <StyledModalHeading variant="body1" as="div">
            {isSecondStepVisible ? SecondStepHeading : t('companySettings.members.inviteMemberModalContent')}
          </StyledModalHeading>
        )}
        <Box
          sx={
            !isEditMode
              ? {
                  marginTop: 3
                }
              : undefined
          }
        >
          <Form
            schemaUrl={inviteTeamMemberSchemaUrl(getSelectedLegalEntityUri())}
            initialValues={{
              ...(member as unknown as IFormValues),
              email: member?.contact.email
            }}
            onSubmit={handleSubmit}
            onChange={handleFormChange}
          >
            {isSecondStepVisible ? SecondStepFormContent : FirstStepFormContent}
          </Form>
        </Box>
      </StyledModalWrapper>
    </Modal>
  );
};

export default InviteMemberModal;
