import { FC, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Loader from 'src/components/Loader/Loader';
import InfoBox from 'src/components/InfoBox/InfoBox';
import { StorageKey } from 'src/constants/storage';
import { Drawer, Typography, useMediaQuery } from '@mui/material';
import theme from 'src/constants/theme';
import { MenuBarContext } from 'src/contexts/MenuBar.contexts';
import TotalBalance from 'src/components/banking/TotalBalance/TotalBalance';
import MenuBar from 'src/components/banking/MenuBar/MenuBar';
import GetStarted from 'src/components/banking/GetStarted/GetStarted';
import ActionBar from 'src/components/banking/actions/ActionBar/ActionBar';
import ToggleIcon from 'src/components/banking/ToggleIcon/ToggleIcon';
import { CurrencyType } from 'src/interfaces/banking';
import { useLocation } from 'react-router';
import { urls } from 'src/routes';

import {
  StyledBankingLayout,
  StyledContent,
  StyledContentWrapper,
  StyledInfoBlocks,
  StyledBankingLayoutMobileHeader,
  StyledMenuIcon
} from './BankingLayout.styles';
import { BankingLayoutProps } from './BankingLayout.interfaces';

const BankingLayout: FC<BankingLayoutProps> = ({
  type = CurrencyType.Fiat,
  content,
  currency,
  currencyLabel,
  totalBalance,
  performance,
  valuation,
  metaData,
  loading = false,
  isAccountCreation = false,
  disabled,
  combined,
  onClose,
  mobileHeader
}) => {
  const { t } = useTranslation();
  const location = useLocation();

  const [actionBarHidden, setActionBarHidden] = useState<boolean>(true);
  const isMobileView = useMediaQuery(theme.breakpoints.down('md'));
  const menuBarContext = useContext(MenuBarContext);
  const [getStartedSectionVisible, setGetStartedSectionVisibility] = useState<boolean>(!sessionStorage.getItem(StorageKey.GET_STARTED));
  useEffect(() => {
    if (location.pathname !== urls.balances) {
      setGetStartedSectionVisibility(false);
    }
  }, [location]);

  return loading ? (
    <Loader />
  ) : (
    <StyledBankingLayout direction={!isMobileView ? 'row' : 'column'}>
      {isMobileView ? (
        <>
          <StyledBankingLayoutMobileHeader>
            <StyledMenuIcon onClick={() => menuBarContext.setMenuBarHidden(false)} />
            <Typography variant="button" color="#FFF" margin="auto">
              {mobileHeader ?? ''}
            </Typography>
            <ToggleIcon useWhite rotate={actionBarHidden} onClick={() => setActionBarHidden(prevState => !prevState)} />
          </StyledBankingLayoutMobileHeader>
          <Drawer variant="persistent" anchor="left" open={!menuBarContext.menuBarHidden}>
            <MenuBar hidden={menuBarContext.menuBarHidden} setHidden={menuBarContext.setMenuBarHidden} disabled={disabled} />
          </Drawer>
          <Drawer variant="persistent" anchor="right" open={!actionBarHidden}>
            <ActionBar hidden={actionBarHidden} setHidden={setActionBarHidden} disabled={disabled} />
          </Drawer>
        </>
      ) : (
        <MenuBar hidden={menuBarContext.menuBarHidden} setHidden={menuBarContext.setMenuBarHidden} disabled={disabled} />
      )}
      <StyledContentWrapper actionBarExpanded={!actionBarHidden} menuBarExpanded={!menuBarContext.menuBarHidden}>
        <TotalBalance
          type={type}
          currency={currency}
          currencyLabel={currencyLabel}
          totalBalance={totalBalance}
          performance={performance}
          valuation={valuation}
          metaData={metaData}
          disabled={disabled}
          combined={combined}
          onClose={onClose}
        />
        {!isAccountCreation && !disabled && getStartedSectionVisible && (
          <GetStarted depositFunds={Number(totalBalance) <= 0} onVisibilityChange={setGetStartedSectionVisibility} />
        )}
        {isAccountCreation && (
          <StyledInfoBlocks>
            <InfoBox
              icon="accountCreatingBlocks"
              title={t('banking.accountCreating.title')}
              description={t('banking.accountCreating.description')}
              descriptionIcon="pending"
              fullWidth
              onClick={() => undefined}
            />
          </StyledInfoBlocks>
        )}
        <StyledContent disabled={disabled} smallerMargin={getStartedSectionVisible}>
          {content}
        </StyledContent>
      </StyledContentWrapper>
      {!isMobileView && <ActionBar hidden={actionBarHidden} setHidden={setActionBarHidden} disabled={disabled} />}
    </StyledBankingLayout>
  );
};

export default BankingLayout;
