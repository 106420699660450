import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import ActionButtons from 'src/components/ActionButtons/ActionButtons';
import Modal from 'src/components/Modal/Modal';
import { ModalProps } from 'src/components/Modal/Modal.interfaces';
import { urls } from 'src/routes';
import { logout } from 'src/utils/authentication';

import { StyledLogoutText } from './SettingsLogoutModal.styles';

const SettingsLogoutModal: FC<ModalProps> = ({ isOpen, onClose, ...restProps }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <Modal onClose={onClose} isOpen={isOpen} title={t('common.logout')} {...restProps}>
      <StyledLogoutText>{t('settings.logoutQuestion')}</StyledLogoutText>

      <ActionButtons
        primaryActionText={t('common.logout')}
        onPrimaryActionClick={() => {
          logout();
          navigate(urls.signin);
        }}
        secondaryActionText={t('common.cancel')}
        onSecondaryActionClick={onClose}
      />
    </Modal>
  );
};

export default SettingsLogoutModal;
