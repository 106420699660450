import { Account, TransactionType } from 'src/interfaces/banking';

import { ActionTabDefaults } from '../ActionTab/ActionTab.interfaces';

export const getFormatedCurrencyText = (account: Account) =>
  account.metaData?.name ? `${account.currency} - ${account.metaData.name}` : account.currency;

export const depositActionTabDefaultValues: ActionTabDefaults = {
  titleKey: 'banking.actions.deposit.title',
  descriptionKey: 'banking.actions.deposit.description',
  type: TransactionType.Deposit
};
