import Close from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import InfoBox from 'src/components/InfoBox/InfoBox';
import { ButtonVariant } from 'src/constants/variants';
import { StorageKey } from 'src/constants/storage';
import { getSelectedLegalEntityUri } from 'src/utils/storage';
import { useGetLegalEntityMembers } from 'src/api/queries/useLegalEntityMembers';
import { useMemo } from 'react';
import { LegalEntityMemberStatus } from 'src/constants/settings';
import { useNavigate } from 'react-router';
import { urls } from 'src/routes';

import { GetStartedProps } from './GetStarted.interfaces';
import { StyledCloseButton, StyledGetStarted, StyledHeading, StyledHeadingSection, StyledInfoBlocks } from './GetStarted.styles';

const GetStarted = ({ depositFunds, onVisibilityChange }: GetStartedProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleCloseButtonClick = () => {
    sessionStorage.setItem(StorageKey.GET_STARTED, 'true');
    onVisibilityChange(false);
  };

  const legalEntityUri = getSelectedLegalEntityUri();
  const { data: legalEntityMembersData, isLoading: areLegalEntityMembersLoading } = useGetLegalEntityMembers(legalEntityUri);
  const legalEntityMembers = useMemo(
    () =>
      legalEntityMembersData?.data?.filter(
        member => member.status === LegalEntityMemberStatus.Accepted || member.status === LegalEntityMemberStatus.Invited
      ) || [],
    [legalEntityMembersData]
  );
  const inviteMembers = !areLegalEntityMembersLoading && legalEntityMembers.length === 0;

  if (!inviteMembers && !depositFunds) {
    return null;
  }

  const fullWidth = !depositFunds || !inviteMembers;
  return (
    <StyledGetStarted>
      <StyledHeadingSection>
        <StyledHeading variant="h3">{t('banking.getStarted.title')}</StyledHeading>
        <StyledCloseButton variant={ButtonVariant.Secondary} size="small" onClick={handleCloseButtonClick} startIcon={<Close />} />
      </StyledHeadingSection>
      <StyledInfoBlocks fullWidth={fullWidth}>
        {depositFunds && (
          <InfoBox
            fullWidth={fullWidth}
            icon="walletKycBlocks"
            title={t('banking.getStarted.deposit.title')}
            description={t('banking.getStarted.deposit.description')}
            onClick={() => undefined}
          />
        )}
        {inviteMembers && (
          <InfoBox
            fullWidth={fullWidth}
            icon="letterKycBlocks"
            title={t('banking.getStarted.invite.title')}
            description={t('banking.getStarted.invite.description')}
            onClick={() => navigate(urls.companySettings)}
          />
        )}
      </StyledInfoBlocks>
    </StyledGetStarted>
  );
};

export default GetStarted;
