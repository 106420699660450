import { FC, useState } from 'react';
import Loader from 'src/components/Loader/Loader';
import { useTranslation } from 'react-i18next';
import Modal from 'src/components/Modal/Modal';
import { MobileStepper } from 'src/components/MobileStepper/MobileStepper';
import { useAccountsQuery } from 'src/api/queries/useAccounts';
import { Beneficiary, BeneficiaryNetwork } from 'src/interfaces/banking';
import { useAllowedCountriesForPaymentQuery } from 'src/api/queries/useAllowedCountriesForPayment';
import isEqual from 'lodash.isequal';
import cloneDeep from 'lodash.clonedeep';

import { StyledContainer, StyledModalWrapper, StyledStepperWrapper } from './CompanySettingsCreateBeneficiaryModal.styles';
import { CompanySettingsBeneficiaryModalProps, StepOneData } from './CompanySettingsCreateBeneficiaryModal.interfaces';
import { StepOne } from './StepOne';
import { StepTwo } from './StepTwo';
import { StepThree } from './StepThree';
import { getStepOneData } from './utils';

const mapStepOneDataToBeneficiary = (stepOneData: StepOneData, beneficiary?: Beneficiary) => {
  const updatedBeneficiary = cloneDeep(removeSwiftCodeOrRoutingNumberInBeneficiary(stepOneData, beneficiary ?? {}));
  const networkHasChanged = beneficiary?.network !== stepOneData.network;

  updatedBeneficiary._links = {
    ...updatedBeneficiary._links,
    account: {
      ...updatedBeneficiary._links?.account,
      uri: stepOneData.accountUri
    }
  };

  updatedBeneficiary.network = stepOneData.network as BeneficiaryNetwork;
  if (stepOneData.country !== updatedBeneficiary.bank?.country) {
    updatedBeneficiary.bank = {
      country: stepOneData.country,
      name: '',
      address: ''
    };
  }

  updatedBeneficiary.targetCurrency = stepOneData.targetCurrency;

  if (networkHasChanged) {
    updatedBeneficiary.iban = '';
    updatedBeneficiary.bicSwiftCode = '';
  }

  return updatedBeneficiary;
};

const removeSwiftCodeOrRoutingNumberInBeneficiary = (updatedStepOneData: StepOneData, beneficiary: Beneficiary) => {
  const updatedBeneficaryObject = beneficiary ? cloneDeep(beneficiary) : {};
  const originalStepOneSetup = getStepOneData(beneficiary);
  if (beneficiary && !isEqual(originalStepOneSetup, updatedStepOneData)) {
    if (updatedBeneficaryObject.iban) {
      updatedBeneficaryObject.iban = undefined;
    }
    if (updatedBeneficaryObject.bicSwiftCode) {
      updatedBeneficaryObject.bicSwiftCode = undefined;
    }
    if (updatedBeneficaryObject.routingNumber) {
      updatedBeneficaryObject.routingNumber = undefined;
    }
  }
  return updatedBeneficaryObject;
};

export const CompanySettingsCreateBeneficiaryModal: FC<CompanySettingsBeneficiaryModalProps> = ({
  isOpen,
  onClose,
  beneficiary,
  type,
  ...restProps
}) => {
  const { t } = useTranslation();

  const [step, setStep] = useState<number>(1);
  const [stepOneData, setStepOneData] = useState<StepOneData>(getStepOneData(beneficiary));
  const [updatedBeneficiary, setUpdatedBeneficiary] = useState<Beneficiary>();
  const { data: allowedCountriesData, isLoading: isLoadingAllowedCountries } = useAllowedCountriesForPaymentQuery();

  const { data: accountsData, isLoading: isLoadingUserAccounts } = useAccountsQuery();
  const getStep = () => {
    switch (step) {
      case 1:
        return (
          <StepOne
            savedData={stepOneData}
            accounts={accountsData?.data}
            onContinue={stepOneData => {
              setStepOneData(stepOneData);
              setUpdatedBeneficiary(mapStepOneDataToBeneficiary(stepOneData, updatedBeneficiary ?? beneficiary));
              setStep(prev => prev + 1);
            }}
            isLoadingAllowedCountries={isLoadingAllowedCountries}
            allowedCountriesData={allowedCountriesData?.data ?? {}}
            onClose={onClose}
          />
        );
      case 2:
        return stepOneData ? (
          <StepTwo
            currencyType={stepOneData.type}
            beneficiary={updatedBeneficiary}
            onBack={(beneficiary?: Beneficiary) => {
              setUpdatedBeneficiary(beneficiary);
              setStep(prev => prev - 1);
            }}
            onContinue={(stepTwoBeneficiary: Beneficiary) => {
              setUpdatedBeneficiary(stepTwoBeneficiary);
              setStep(prev => prev + 1);
            }}
            allowedCountriesData={allowedCountriesData?.data ?? {}}
          />
        ) : null;
      case 3:
        return updatedBeneficiary && stepOneData ? (
          <StepThree
            crudType={type}
            accounts={accountsData?.data ?? []}
            beneficiaryDetails={updatedBeneficiary}
            onClose={() => {
              setUpdatedBeneficiary(undefined);
              setStep(1);
              onClose();
            }}
            onBack={() => setStep(step - 1)}
          />
        ) : null;
    }
  };

  return (
    <Modal
      onClose={() => {
        setUpdatedBeneficiary(undefined);
        setStep(1);
        onClose();
      }}
      isOpen={isOpen}
      title={beneficiary ? t('companySettings.beneficiaries.editBeneficiaryModalTitle') : t('companySettings.beneficiaries.addBeneficiaryModalTitle')}
      {...restProps}
    >
      <StyledModalWrapper>
        <StyledStepperWrapper>
          <MobileStepper totalSteps={3} activeStep={step} />
        </StyledStepperWrapper>
        <StyledContainer
          sx={
            !beneficiary
              ? {
                  marginTop: 3
                }
              : undefined
          }
        >
          {isLoadingUserAccounts ? <Loader /> : getStep()}
        </StyledContainer>
      </StyledModalWrapper>
    </Modal>
  );
};
