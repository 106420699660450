import { FC } from 'react';
import { ButtonVariant } from 'src/constants/variants';

import Button from '../Button/Button';
import SvgIcon from '../SvgIcon/SvgIcon';

import { StyledActionButtonsWrapper, StyledBackButton } from './ActionButtons.styles';
import { ActionButtonsProps } from './ActionButtons.interfaces';

const ActionButtons: FC<ActionButtonsProps> = ({
  primaryActionText,
  onPrimaryActionClick,
  secondaryActionText,
  secondaryBackAction,
  onSecondaryActionClick,
  sx
}) => {
  if (!primaryActionText && !secondaryActionText) {
    return null;
  }

  return (
    <StyledActionButtonsWrapper sx={sx}>
      {primaryActionText && (
        <Button fullWidth onClick={onPrimaryActionClick}>
          {primaryActionText}
        </Button>
      )}
      {secondaryActionText && (
        <StyledBackButton variant={ButtonVariant.Text} fullWidth onClick={onSecondaryActionClick}>
          {secondaryBackAction && <SvgIcon icon="arrowLeft" fontSize="small" />}
          {secondaryActionText}
        </StyledBackButton>
      )}
    </StyledActionButtonsWrapper>
  );
};

export default ActionButtons;
