import { styled, Stack, Typography, Button } from '@mui/material';

export const StyledFlag = styled('img')<{ size: number }>(({ size }) => ({
  width: size,
  height: size,
  alignSelf: 'center'
}));

export const StyledListItemWrapper = styled(Stack)({
  '&:hover button': {
    display: 'block'
  }
});

export const StyledListItemCurrencyLabelAndCurrency = styled(Stack)({
  alignSelf: 'center'
});

export const TypographyWithElipsis = styled(Typography)(({ theme }) => ({
  maxWidth: 216,
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  [theme.breakpoints.down('md')]: {
    maxWidth: '55vw'
  }
}));

export const StyledTextSpan = styled('span', {
  shouldForwardProp: prop => prop !== 'highlight' && prop !== 'valueTextSpan'
})<{ highlight?: boolean; valueTextSpan?: boolean; hide?: boolean }>(({ theme, highlight, valueTextSpan }) => ({
  fontWeight: 400,
  color: highlight ? theme.palette.custom.coralDusk[400] : valueTextSpan ? theme.palette.custom.grey.offBlack : theme.palette.custom.grey[300],
  fontSize: valueTextSpan ? 16 : 14
}));

export const StyledEditButtonSpan = styled(Button)(({ theme }) => ({
  fontWeight: 400,
  color: theme.palette.custom.coralDusk[400],
  marginLeft: theme.spacing(2),
  alignSelf: 'center',
  fontSize: 14,
  display: 'none',
  '&:hover': {
    backgroundColor: 'inherit'
  }
}));
