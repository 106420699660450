import { FC, useEffect, useRef, useState } from 'react';
import { IFormHandleSubmit, IOnFormChangeProps } from 'src/interfaces/forms';
import Form from 'src/components/Form/Form';
import { enable2faSchemaUrl } from 'src/api/formSchemas';
import Input from 'src/components/Input/Input';
import DialogLayout from 'src/components/layouts/DialogLayout/DialogLayout';
import { ONBOARDING_ENABLE_2FA } from 'src/constants/onboarding';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import get from 'lodash.get';
import { ERROR_AUTH_DEVICE_EXPIRED, ERROR_INCORRECT_OTP } from 'src/constants/errors';
import StatusIcon from 'src/components/StatusIcon/StatusIcon';
import Modal from 'src/components/Modal/Modal';
import { Typography, useTheme } from '@mui/material';
import { useSnackbar } from 'notistack';
import { REGEX_GROUP_OF_FOUR } from 'src/constants/regexes';
import { QRCode } from 'react-qrcode-logo';
import SvgIcon from 'src/components/SvgIcon/SvgIcon';
import { StorageKey } from 'src/constants/storage';
import { urls } from 'src/routes';
import { enable2fa, request2fa } from 'src/api/auth';
import { useUserNavigation } from 'src/hooks/navigation';
import SignInLayout from 'src/components/layouts/SignInLayout/SignInLayout';
import ActionButtons from 'src/components/ActionButtons/ActionButtons';

import { StyledQRWrapper, StyledText } from './Enable2faPage.styles';

const Enable2faPage: FC = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { getUserOrNavigate } = useUserNavigation();
  const [searchParams] = useSearchParams();

  const refreshRef = useRef<number>();
  const { enqueueSnackbar } = useSnackbar();

  const otpSecretParam = searchParams.get('otpSecret');

  const [loading, setLoading] = useState<boolean>(false);
  const [refreshKey, setRefreshKey] = useState<number>(0);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const [otpSecret, setOtpSecret] = useState<string>(otpSecretParam ?? '');
  const [otpSecretUrl, setOtpSecretUrl] = useState<string>(searchParams.get('otpSecretUrl') ?? '');
  const [backupCode, setBackupCode] = useState<string>('');

  useEffect(() => {
    if (!otpSecret && refreshKey !== refreshRef.current) {
      refreshRef.current = refreshKey;

      request2fa()
        ?.then(response => {
          setOtpSecret(response.data.otpSecret);
          setOtpSecretUrl(response.data.otpSecretUrl);
        })
        .catch(error => {
          setError(error.response.data.message);
        });
    }
  }, [otpSecret, refreshKey]);

  const handleSubmit: IFormHandleSubmit = async (values, validateForm, handleError) => {
    if (validateForm(values)) {
      setLoading(true);

      enable2fa(values.otp as string)
        ?.then(response => {
          sessionStorage.setItem(StorageKey.AUTH_TOKEN, response.data.strongToken);
          setBackupCode(response.data.otpBackupCode);
        })
        .catch(error => {
          try {
            const { code, message } = error.response.data;
            if (code === ERROR_INCORRECT_OTP) {
              handleError('otp', message);
            } else {
              if (code === ERROR_AUTH_DEVICE_EXPIRED) {
                setRefreshKey(refreshKey + 1);
              }
              enqueueSnackbar(message, { variant: 'error' });
            }
          } catch {}
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const onFormChange = ({ values, lastChangedFieldName, submitForm }: IOnFormChangeProps) => {
    if (lastChangedFieldName === 'otp' && get(values, lastChangedFieldName, '').toString().length === 6) {
      submitForm?.();
    }
  };

  const renderModal = () => (
    <Modal title={t('screens.enable2fa.modal.title')} isOpen={modalOpen} onClose={() => setModalOpen(false)}>
      <StyledText>{t('screens.enable2fa.modal.subtitle')}</StyledText>
      <Input name="secret" copyable disabled fullWidth value={otpSecret?.match(REGEX_GROUP_OF_FOUR)?.join(' ') ?? ''} />
    </Modal>
  );

  if (error) {
    return (
      <DialogLayout
        headerImage={<StatusIcon error icon={<SvgIcon icon="warning" />} />}
        heading={t('screens.error')}
        subheading={error}
        secondaryBackAction
        secondaryActionText={t('common.backToHomepage')}
        onSecondaryActionClick={() => navigate(urls.signin)}
        activeStepName={ONBOARDING_ENABLE_2FA}
      />
    );
  } else if (otpSecret && otpSecretUrl) {
    const content = (
      <>
        {renderModal()}
        <Form schemaUrl={enable2faSchemaUrl} onSubmit={handleSubmit} onChange={onFormChange}>
          <StyledQRWrapper>
            <QRCode
              size={184}
              logoWidth={50}
              value={otpSecretUrl}
              logoImage={`${process.env.REACT_APP_ASSETS_URL}/images/qr/${backupCode ? 'success.png' : 'logo.png'}`}
              fgColor={backupCode ? theme.palette.custom.grey[100] : theme.palette.common.black}
              bgColor={theme.palette.background.default}
            />
          </StyledQRWrapper>
          {backupCode ? (
            <div>
              <Input copyable disabled fullWidth formField={false} name="backupCode" label={t('screens.enable2fa.backupCode')} value={backupCode} />
              <Typography>{t('screens.enable2fa.backupCodeDescription')}</Typography>
            </div>
          ) : (
            <Input autoFocus name="otp" type="number" fullWidth />
          )}
        </Form>
        <ActionButtons
          primaryActionText={backupCode ? t('common.continue') : undefined}
          onPrimaryActionClick={getUserOrNavigate}
          secondaryActionText={!backupCode ? t('screens.enable2fa.codeDescription') : undefined}
          onSecondaryActionClick={() => setModalOpen(true)}
        />
      </>
    );

    const title = t('screens.enable2fa.title');
    const subtitle = t('screens.enable2fa.subtitle');

    // If otpSecretParam is passed it's not onboarding (layout)
    return otpSecretParam ? (
      <SignInLayout loading={loading} title={title} subtitle={subtitle}>
        {content}
      </SignInLayout>
    ) : (
      <DialogLayout
        loading={loading}
        heading={title}
        subheading={subtitle}
        isOnboardingLayout
        withOnboardingSteps
        activeStepName={ONBOARDING_ENABLE_2FA}
      >
        {content}
      </DialogLayout>
    );
  }
  return <DialogLayout heading={t('screens.enable2fa.title')} contentLoading />;
};

export default Enable2faPage;
