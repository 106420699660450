import { FC, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import FormElement from '../FormElement/FormElement';
import Highlight from '../Highlight/Highlight';

import AutocompleteProps from './Autocomplete.interfaces';
import { StyledTextField, StyledPopper, StyledAutocomplete, StyledLiContent, StyledOptionValue } from './Autocomplete.styles';

const Autocomplete: FC<AutocompleteProps> = ({
  name,
  value,
  error,
  label,
  labelTooltip,
  dataList,
  placeholder,
  showOptionValue,
  handleError,
  handleOnChange,
  ...restProps
}) => {
  const { t } = useTranslation();
  const [inputValue, setInputValue] = useState<string>('');

  const emptyValue = useMemo(() => ({ label: '', value: '' }), []);
  const options = useMemo(() => [emptyValue, ...(dataList ?? [])], [dataList, emptyValue]);

  return (
    <FormElement label={label} labelTooltip={labelTooltip} error={error}>
      <StyledAutocomplete
        PopperComponent={StyledPopper}
        disableClearable
        forcePopupIcon={false}
        renderInput={params => <StyledTextField placeholder={placeholder || t('common.select')} error={!!error} {...params} />}
        options={options}
        value={options?.find(o => o.value === value)}
        onInputChange={(_, newValue) => setInputValue(newValue)}
        renderOption={(props, option) => (
          <li {...props}>
            <StyledLiContent>
              <Highlight search={inputValue} text={option.label} />
              {showOptionValue && <StyledOptionValue>{option.value}</StyledOptionValue>}
            </StyledLiContent>
          </li>
        )}
        filterOptions={(options, state) =>
          options.filter(
            o =>
              (o.label && o.label.toLowerCase().includes(state.inputValue.toLowerCase())) ||
              (o.value && o.value.toLowerCase().includes(state.inputValue.toLowerCase()))
          )
        }
        onChange={(_, option) => {
          if (handleOnChange) {
            handleOnChange(name, option ? option.value : '');
          }

          if (handleError && error) {
            handleError(name, '');
          }
        }}
        {...restProps}
      />
    </FormElement>
  );
};

export default Autocomplete;
