import { Box, Stack, styled, Typography } from '@mui/material';

export const StyledButtonWrapper = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(2)
}));

export const StyledIcon = styled('div')(({ theme }) => ({
  width: 48,
  height: 48,
  margin: theme.spacing(2),
  borderRadius: '50%',
  backgroundColor: theme.palette.custom.coralDusk[50],
  boxShadow: theme.shadows[13],

  '.MuiSvgIcon-root': { fontSize: '14px' }
}));

export const StyledButton = styled(Stack)(({ theme }) => ({
  justifyContent: 'space-between',
  minHeight: '104px',
  borderRadius: theme.spacing(1),
  marginInline: `-${theme.spacing(2)}`,
  '&:hover': {
    background: theme.palette.custom.grey[50],
    cursor: 'pointer'
  },
  '.MuiSvgIcon-root': {
    fontSize: '48px',
    margin: theme.spacing(2)
  }
}));

export const StyledTextWrapper = styled(Box)(({ theme }) => ({
  margin: theme.spacing(2, 2, 2, 0)
}));

export const StyledButtonTitle = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(1)
}));
