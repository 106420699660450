import { useContext, useState } from 'react';
import ActionTab from 'src/components/banking/actions/ActionTab/ActionTab';
import { ActionBarContext } from 'src/contexts/ActionBar.contexts';
import { IFormHandleSubmit, IFormValues } from 'src/interfaces/forms';
import ActionButtons from 'src/components/ActionButtons/ActionButtons';
import Form from 'src/components/Form/Form';
import Input from 'src/components/Input/Input';
import { useTranslation } from 'react-i18next';
import { WithdrawContext } from 'src/contexts/actions/Withdraw.contexts';
import { WithdrawPaymentDetails as PaymentDetails } from 'src/interfaces/contexts';
import RadioGroup from 'src/components/RadioGroup/RadioGroup';
import { paymentOrderSchemaUrl } from 'src/api/formSchemas';

import { WithdrawPaymentDetailsScreens } from '../Withdraw.interfaces';
import { withdrawActionTabDefaultValues } from '../Withdraw.utils';
import { WithdrawBeneficiaryDetails } from '../WithdrawBenficiaryDetails/WithdrawBenficiaryDetails';
import WithdrawDetails from '../WithdrawDetails/WithdrawDetails';

import { StyledActionButtons } from './WithdrawPaymentDetails.styles';

const handleOnInput = (evt: React.ChangeEvent<HTMLInputElement>) => {
  const charactersLimitPerLine = 30;
  const textarea = evt.target;
  const lines = textarea.value.split('\n');

  for (let i = 0; i < lines.length; i++) {
    if (lines[i].length > charactersLimitPerLine) {
      let j = 0;
      let spaceIndex = -1;
      while (j++ < charactersLimitPerLine) {
        if (lines[i][j] === ' ') {
          spaceIndex = j;
        }
      }
      if (spaceIndex !== -1) {
        lines[i + 1] = lines[i].substring(spaceIndex + 1) + (lines[i + 1] || '');
        lines[i] = lines[i].substring(0, spaceIndex);
      } else {
        lines[i + 1] = lines[i].substring(charactersLimitPerLine) + (lines[i + 1] || '');
        lines[i] = lines[i].substring(0, charactersLimitPerLine);
      }
    }
  }

  textarea.value = lines.join('\n');
};

export const WithdrawPaymentDetails = () => {
  const { t } = useTranslation();
  const actionBarContext = useContext(ActionBarContext);
  const { account, country, beneficiaryType, beneficiaryNetwork, paymentDetails, setPaymentDetails } = useContext(WithdrawContext) ?? {};

  const [nextScreen, setNextScreen] = useState<WithdrawPaymentDetailsScreens>();
  const initialValues = paymentDetails as unknown as IFormValues;

  const handleSubmit: IFormHandleSubmit = async (values, validateForm) => {
    if (setPaymentDetails && validateForm(values)) {
      setNextScreen('withdrawDetails');
      setPaymentDetails(values as unknown as PaymentDetails);
    }
  };

  const flowScreens = {
    withdrawDetails: <WithdrawDetails />,
    withdrawBeneficiaryDetails: <WithdrawBeneficiaryDetails />
  };

  return nextScreen ? (
    flowScreens[nextScreen]
  ) : actionBarContext ? (
    <ActionTab activeStepIndex={3} onClose={actionBarContext.closeActionTab} {...withdrawActionTabDefaultValues}>
      <Form
        schemaUrl={paymentOrderSchemaUrl(account?._links.self.uri ?? '', country, beneficiaryType, beneficiaryNetwork)}
        initialValues={{ feeCoverage: 'SHA', ...initialValues }}
        onSubmit={handleSubmit}
      >
        <Input name="executionDate" type="date" disabled />
        <Input
          name="vendorNote"
          multiline
          maxRows={4}
          onInput={handleOnInput}
          InputProps={{
            sx: {
              fontFamily: 'monoSpace'
            }
          }}
        />
        <Input
          name="personalNote"
          multiline
          maxRows={2}
          onInput={handleOnInput}
          InputProps={{
            sx: {
              fontFamily: 'monoSpace'
            }
          }}
        />
        <RadioGroup name="feeCoverage" labelTooltip={t('banking.actions.withdraw.feeTooltip')} horizontal />

        <StyledActionButtons>
          <ActionButtons
            primaryActionText={t('common.continue')}
            secondaryActionText={t('common.goBack')}
            onSecondaryActionClick={() => setNextScreen('withdrawBeneficiaryDetails')}
          />
        </StyledActionButtons>
      </Form>
    </ActionTab>
  ) : null;
};
