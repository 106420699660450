export enum KYCStatus {
  Pending = 'PENDING',
  Verified = 'VERIFIED',
  Rejected = 'REJECTED'
}

export type Method = 'get' | 'post' | 'patch' | 'delete';

export type ErrorResponse = {
  code?: string;
  message?: string;
  statusCode: number;
};

export type OnboardingStepName =
  | 'create_account'
  | 'email_verification'
  | 'enable_2fa'
  | 'personal_info'
  | 'additional_info'
  | 'identity_verification';

export type CorporateOnboardingStepName = 'corporate_account' | 'corporate_information';

export type OnboardingStep = {
  name: OnboardingStepName;
  value: string;
};

export type CorporateOnboardingStep = {
  name: CorporateOnboardingStepName;
  value: string;
};
