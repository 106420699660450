import { FC, useState } from 'react';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import { IFormHandleSubmit } from 'src/interfaces/forms';
import Form from 'src/components/Form/Form';
import { signupSchemaUrl } from 'src/api/formSchemas';
import Input from 'src/components/Input/Input';
import ActionButtons from 'src/components/ActionButtons/ActionButtons';
import { signup } from 'src/api/onboarding';
import DialogLayout from 'src/components/layouts/DialogLayout/DialogLayout';
import { ONBOARDING_CREATE_ACCOUNT } from 'src/constants/onboarding';
import Autocomplete from 'src/components/Autocomplete/Autocomplete';
import Checkbox from 'src/components/Checkbox/Checkbox';
import {
  ERROR_EMAIL_ALREADY_USED,
  ERROR_UNSUPPORTED_COUNTRY,
  E_SIGN_AGREEMENT_NOT_ACCEPTED,
  PROVIDERS_TOS_AND_PP_NOT_ACCEPTED,
  TOS_AND_PP_NOT_ACCEPTED
} from 'src/constants/errors';
import i18next from 'i18next';
import { useNavigateSearch } from 'src/hooks/navigation';
import { urls } from 'src/routes';
import { getTimezone } from 'src/utils/localization';
import { usePassword } from 'src/hooks/usePassword/usePassword';
import { useLocation, useNavigate } from 'react-router-dom';
import { StorageKey } from 'src/constants/storage';

import { StyledPasswordContainer, StyledLink } from './SignUpPage.styles';

const SignUpPage: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const navigateSearch = useNavigateSearch();
  const { state } = useLocation();

  const { enqueueSnackbar } = useSnackbar();
  const { renderPasswordInput, renderPasswordConfirmationInput, password, checkForPasswordConfirmationErrors } = usePassword();

  const [loading, setLoading] = useState<boolean>(false);
  const { email: invitationEmail, invitationToken } = state || {};

  const handleSubmit: IFormHandleSubmit = async (values, validateForm, handleError) => {
    let valid = true;

    if (checkForPasswordConfirmationErrors()) {
      valid = false;
    }

    const formValues = {
      ...values,
      password
    };
    if (validateForm(formValues)) {
      if (!values.acceptTermsOfServiceAndPrivacyPolicy) {
        handleError('acceptTermsOfServiceAndPrivacyPolicy', t('screens.signUp.acceptToC'));
        valid = false;
      }

      if (!values.acceptProvidersTermsOfServiceAndPrivacyPolicy) {
        handleError('acceptProvidersTermsOfServiceAndPrivacyPolicy', t('screens.signUp.acceptProvidersToC'));
        valid = false;
      }

      if (valid) {
        setLoading(true);

        const email = values.email as string;
        const countryOfResidence = values.countryOfResidence as string;
        signup({
          ...formValues,
          invitationToken,
          locale: i18next.language,
          timezone: getTimezone()
        })
          ?.then(response => {
            if (response.data._actions.request2fa) {
              sessionStorage.setItem(StorageKey.WEAK_AUTH_TOKEN, response.data.signupToken);
              navigate(urls.enable2fa);
            } else if (response.data._actions.verifyEmail) {
              navigateSearch(
                urls.emailVerify,
                {
                  region: response.data?.region
                },
                {
                  state: {
                    email
                  }
                }
              );
            }
          })
          .catch(error => {
            try {
              const { code, message } = error.response.data;
              if (code === ERROR_UNSUPPORTED_COUNTRY) {
                navigateSearch(urls.waitlist, {
                  email,
                  country: countryOfResidence
                });
              } else if (code === ERROR_EMAIL_ALREADY_USED) {
                handleError('email', message);
              } else if (code === PROVIDERS_TOS_AND_PP_NOT_ACCEPTED) {
                handleError('acceptProvidersTermsOfServiceAndPrivacyPolicy', message);
              } else if (code === TOS_AND_PP_NOT_ACCEPTED) {
                handleError('acceptTermsOfServiceAndPrivacyPolicy', message);
              } else if (code === E_SIGN_AGREEMENT_NOT_ACCEPTED) {
                handleError('acceptESignAgreement', message);
              } else {
                enqueueSnackbar(message, { variant: 'error' });
              }
            } catch {}
          })
          .finally(() => {
            setLoading(false);
          });
      }
    }
  };

  return (
    <DialogLayout
      loading={loading}
      heading={t('screens.signUp.title')}
      subheading={t('screens.signUp.subtitle')}
      isOnboardingLayout
      withOnboardingSteps
      activeStepName={ONBOARDING_CREATE_ACCOUNT}
    >
      <Form schemaUrl={signupSchemaUrl} initialValues={invitationEmail ? { email: invitationEmail } : undefined} onSubmit={handleSubmit}>
        <Autocomplete name="countryOfResidence" fullWidth showOptionValue />

        <Input name="email" type="email" disabled={!!invitationEmail} fullWidth />
        <StyledPasswordContainer>
          {renderPasswordInput()}
          {renderPasswordConfirmationInput()}
        </StyledPasswordContainer>

        <Checkbox
          name="acceptTermsOfServiceAndPrivacyPolicy"
          label={
            <Typography variant="body3">
              {t('screens.signUp.acknowledge')} {t('common.our')}{' '}
              <StyledLink href="https://linktr.ee/21.co" target="_blank">
                {t('common.termsOfService')}
              </StyledLink>{' '}
              {t('common.and')}{' '}
              <StyledLink href="https://linktr.ee/21.co" target="_blank">
                {t('common.privacyPolicy')}
              </StyledLink>
            </Typography>
          }
        />
        <Checkbox
          name="acceptProvidersTermsOfServiceAndPrivacyPolicy"
          label={
            <Typography variant="body3">
              {t('screens.signUp.acknowledge')} {t('common.the')}{' '}
              <StyledLink href="https://linktr.ee/21.co" target="_blank">
                {t('screens.signUp.providers')} {t('common.termsOfService')}
              </StyledLink>{' '}
              {t('common.and')}{' '}
              <StyledLink href="https://linktr.ee/21.co" target="_blank">
                {t('common.privacyPolicy')}
              </StyledLink>
            </Typography>
          }
        />

        <ActionButtons primaryActionText={t('screens.signUp.button')} />
      </Form>
    </DialogLayout>
  );
};

export default SignUpPage;
